import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { AddCustomerEndPoient } from "../service/global.service";
import { israelPhoneNumberValidator } from './../../validators/israelPhoneNumberValidator';

@Component({
  selector: "app-customers",
  templateUrl: "./customers.component.html",
  styleUrls: ["./customers.component.scss"]
})

export class CustomersComponent implements OnInit {
   @Input() isInsideModal: boolean = false;

  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addCustomer:FormGroup;
  updateCustomer:FormGroup;
  customersData:any=[];
  customerDataMerchant:any=[];
  customerTypeData:any=[];
  customerId: number;
  updatedData: any;
  viewData: any;
  currentPage: number = 1;
  limit: number = 50;
  offset: number = 0;
  total: number = 0; 
  public show: boolean = false;
  public validate = false;
  public updatevalidate = false;
  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));

    this.addCustomer = this.fb.group({
      customer_name_he: ['', Validators.required],
      customer_name_en: ['',],
      customer_name_ar: ['', ],
      customer_type: ['', Validators.required],
      customer_phone: [''],
      id_number: [null],
      customer_vat_number: [null],
      customer_vat_tax: [null],
      customer_address_str: [''],  // جعل هذا الحقل اختياريًا
      customer_address_city: [''], // جعل هذا الحقل اختياريًا
      customer_address_zip_code: [''], // جعل هذا الحقل اختياريًا
      customer_Building_number: [''], // جعل هذا الحقل اختياريًا
    });

  this.updateCustomer = this.fb.group({
      customer_name_en: [''],
      customer_name_ar: [''],
      customer_name_he: ['', [Validators.required, Validators.maxLength(80)]],  
      customer_address_str: ['', [Validators.maxLength(20)]],
      customer_address_city: ['', [Validators.maxLength(20)]],
      customer_address_zip_code: ['', [Validators.maxLength(20)]],
      customer_Building_number: ['', [Validators.maxLength(20)]],
      customer_phone: [null, [israelPhoneNumberValidator()]],  
      customer_vat_number: [null, Validators.pattern('[0-9]{9}')], 
      customer_vat_tax: [null], 
      id_number: [null],  
      customer_type: ['', Validators.required],  
      updated_by: [this.userRegistId],
  });
  };

  onCustomerTypeChange() {
    this.addCustomer.get('customer_type')?.valueChanges.subscribe(value => {
      if (value == 5) {
        this.addCustomer.get('id_number')?.setValidators([Validators.required, Validators.maxLength(9)]);
        this.addCustomer.get('customer_vat_number')?.clearValidators();
      } else if (value == 6) {
        this.addCustomer.get('customer_vat_number')?.setValidators([Validators.required, Validators.pattern('[0-9]{9}')]);
        this.addCustomer.get('id_number')?.clearValidators();
      }
      this.addCustomer.get('id_number')?.updateValueAndValidity();
      this.addCustomer.get('customer_vat_number')?.updateValueAndValidity();
    });
  }
  onPageChange(page: number) {
    this.currentPage = page;
    const offset = (page - 1) * this.limit;
    this.getAllCustomersBasedOnMerchant(this.limit, offset);
  } 
  getAllCustomersBasedOnMerchant( limit?: number, offset?: number ) {
    this.spinner.show();
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
    this.service.getAllCustomersBasedOnMerchant(limit, offset).subscribe({
      next: res => {
        this.customersData = res.customers;
   
        this.total = res.count;
  
        // استخدم forEach لعرض وتنسيق جميع أرقام الهواتف
        this.customersData.forEach(customer => {
          if (customer.customer_phone) {
            customer.formattedPhone = this.formatPhoneNumber(customer.customer_phone);
          }
        });
  
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }
  

  formatPhoneNumber(phoneNumber: string): string {
    // Remove any non-digit characters except '+'
    const cleaned = phoneNumber.replace(/[^ \d]/g, '');
  
    // Ensure the number ends with '+'
    if (!cleaned.endsWith('+')) {
      return `${cleaned}+`;
    }
  
    return cleaned;
  };

  getAllCustomerType() {
    this.spinner.show();
    this.service.getAllCustomerType().subscribe({
      next: res => {
        this.customerTypeData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  filter(event:any)
  {
    let x = event.target.value;
    
   };
   isNumber(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    } else {
      return true;
    }
  }
   
  addedData() {
 
    if (this.addCustomer.invalid) {
        this.validate = true;  // تفعيل عرض رسائل الفالديشن
         Object.keys(this.addCustomer.controls).forEach(key => {
            const control = this.addCustomer.get(key);
            if (control.invalid) {
             }
        });
        return;
    }

    // نسخ قيمة customer_name_he إلى customer_name_en و customer_name_ar
    const customerNameHe = this.addCustomer.get('customer_name_he').value;
    this.addCustomer.patchValue({
        customer_name_en: customerNameHe,
        customer_name_ar: customerNameHe
    });

 
    let model = {
        customer_name_en: this.addCustomer.value.customer_name_en,
        customer_name_ar: this.addCustomer.value.customer_name_ar,
        customer_name_he: this.addCustomer.value.customer_name_he,
        customer_address_str: this.addCustomer.value.customer_address_str,
        customer_address_city: this.addCustomer.value.customer_address_city,
        customer_address_zip_code: this.addCustomer.value.customer_address_zip_code,
        customer_Building_number: this.addCustomer.value.customer_Building_number,
        customer_phone: this.addCustomer.value.customer_phone !== '' ? '+972' + this.addCustomer.value.customer_phone : '',
        customer_vat_number: this.addCustomer.value.customer_vat_number,
        customer_vat_tax: this.addCustomer.value.customer_vat_tax,
        id_number: this.addCustomer.value.id_number,
        customer_type: Number(this.addCustomer.value.customer_type),
        merchant_id: Number(this.merchantId),
        created_by: Number(this.userRegistId),
    };

 
    this.spinner.show();
    this.service.Post(AddCustomerEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
            Swal.fire({
                icon: 'success',
                title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
            this.getAllCustomersBasedOnMerchant();
            this.getAllCustomerType();
            this.addCustomer.reset({
                customer_name_en: '',
                customer_name_ar: '',
                customer_name_he: '',
                customer_address_str: '',
                customer_address_city: '',
                customer_address_zip_code: '',
                customer_Building_number: '',
                customer_phone: '',
                customer_vat_number: null,
                customer_vat_tax: null,
                id_number: null,
                customer_type: null,
            });
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
            let errorMessage;
            switch (res.status) {
                case 400:
                case 403:
                case 500:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                default:
                    errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            }
            Swal.fire({
                icon: 'error',
                title: errorMessage
            });
        }
        this.spinner.hide();
    });
}




  updatedCustomerData() {
    let customerPhone = this.updateCustomer.value.customer_phone;
    if (customerPhone?.startsWith('0')) {
        customerPhone = customerPhone?.substring(1);
    }
 

    // التحقق من الحقول المطلوبة
    const requiredFields = [
        'customer_name_he', 
     //   'customer_address_str', 
      //  'customer_address_city', 
      //  'customer_address_zip_code', 
       // 'customer_Building_number'
    ];
    
    for (const field of requiredFields) {
        if (!this.updateCustomer.get(field).value) {
            Swal.fire({
                icon: 'warning',
                title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة' : 
                       (this.curentLang === 'en' ? 'Please fill the required fields' : 
                       (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ' : ''))
            });
            this.updatevalidate = !this.updatevalidate;
            return;
        }
    }

    // التحقق من صحة رقم الضريبة
    let merchantTaxNumber = this.updateCustomer.value.customer_vat_number;
    if (merchantTaxNumber !== '' && merchantTaxNumber?.length < 9) {
        Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? 'يجب ألا يقل الرقم الضريبي عن 9 أرقام' : 
                   (this.curentLang === 'en' ? 'The tax number must be at least 9 digits long' : 
                   (this.curentLang === 'he' ? 'מספר המס חייב להיות לפחות 9 מספרים' : ''))
        });
        return;
    }

    // التحقق من صحة رقم الهاتف
    let merchantPhone = this.updateCustomer.value.customer_phone;
    if (merchantPhone !== '' && merchantPhone?.length < 7) {
        Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? 'يجب ألا يقل رقم الهاتف عن 7 أرقام' : 
                   (this.curentLang === 'en' ? 'The phone number must be at least 7 digits long' : 
                   (this.curentLang === 'he' ? 'מספר הטלפון חייב להיות בן 7 ספרות לפחות' : ''))
        });
        return;
    }

    const validPrefixes = ['50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
    let validationStartPhoneNumber = merchantPhone?.substring(0, 2);
    
     
    if (merchantPhone !== null && !validPrefixes.includes(validationStartPhoneNumber)) {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يجب أن يبدأ رقم الهاتف بأي رقم من هذة الأرقام (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
                   (this.curentLang === 'en' ? 'Phone number must start with one of these numbers (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
                   (this.curentLang === 'he' ? 'צריך להתחיל עם אחד מהמספרים (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : ''))
        });
        return;
    }

   
     

    // إزالة الصفر الأولي من رقم الهاتف إذا كان موجودًا
    let customerPhoneFinal = this.updateCustomer.value.customer_phone;
    if (customerPhoneFinal?.startsWith('0')) {
        customerPhoneFinal = customerPhoneFinal?.substring(1);
    }

    // تعيين قيمة customer_name_he إلى الحقول الأخرى
    const customerNameHe = this.updateCustomer.value.customer_name_he;
    this.updateCustomer.patchValue({
        customer_name_ar: customerNameHe,
        customer_name_en: customerNameHe
    });

    let model = {
        customer_name_en: this.updateCustomer.value.customer_name_en,
        customer_name_ar: this.updateCustomer.value.customer_name_ar,
        customer_name_he: this.updateCustomer.value.customer_name_he,
        customer_address_str: this.updateCustomer.value.customer_address_str,
        customer_address_city: this.updateCustomer.value.customer_address_city,
        customer_address_zip_code: this.updateCustomer.value.customer_address_zip_code,
        customer_Building_number: this.updateCustomer.value.customer_Building_number,
        customer_phone : customerPhone == null  ? customerPhone : '+972' + customerPhone,
        customer_vat_number: this.updateCustomer.value.customer_vat_number,
        customer_vat_tax: this.updateCustomer.value.customer_vat_tax,
        id_number: this.updateCustomer.value.id_number,
        customer_type: Number(this.updateCustomer.value.customer_type),
        updated_by: Number(this.userRegistId),
    };

    this.spinner.show();
    this.service.updateCustomersById(this.customerId, model).subscribe((res) => {
        if (res.status === 200) {
            Swal.fire({
                icon: 'success',
                title: this.curentLang === 'ar' ? res.messageAr : 
                       (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
            this.getAllCustomerType();
            this.getAllCustomersBasedOnMerchant();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
            let errorMessage;
            switch (res.status) {
                case 400:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                case 403:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                case 500:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                default:
                    errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : 
                                   (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            }
            Swal.fire({
                icon: 'error',
                title: errorMessage
            });
        }
        this.spinner.hide();
    });
}

  
  getCustomerTypeName() {
    const customerTypeId = this.addCustomer.get('customer_type').value;
    const selectedType = this.customerTypeData.find(item => item.lookup_detail_id === customerTypeId);
    return selectedType ? (this.curentLang === 'en' ? selectedType.lookupDetailEnName : 
                           (this.curentLang === 'ar' ? selectedType.lookupDetailArName : selectedType.lookupDetailHeName)) : '';
  }


  changeStatus(id:number, customer_status:any){
    this.spinner.show();
  
    let model =
    {
      "customer_status": customer_status == 1 ? 59 : 1,
     "updated_by": this.userRegistId
    }
  
    this.service.changeCustomerStatus(id , model ).subscribe(({
      
      next:res =>{
       if (res.status ==200){

        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        })
          this.getAllCustomersBasedOnMerchant();
          this.spinner.hide();
       }
  
      },
      error:err =>{
        Swal.fire({
          icon:"error",
          title:err.error.message[0]
        });
        this.spinner.hide();
  
      }
      
    }))
  };

  setValue(customerId: number) {
    for (let i = 0; i < this.customersData.length; i++) {
      if (this.customersData[i].customer_id == this.customerId) {
        const selectedCategory = this.customersData.find(item => item.customer_id === customerId);

        this.updateCustomer.controls.customer_name_en.setValue(this.customersData[i].customer_name_en);
        this.updateCustomer.controls.customer_name_ar.setValue(this.customersData[i].customer_name_ar);
        this.updateCustomer.controls.customer_name_he.setValue(this.customersData[i].customer_name_he);
        this.updateCustomer.controls.customer_address_str.setValue(this.customersData[i].customer_address_str);
        this.updateCustomer.controls.customer_address_city.setValue(this.customersData[i].customer_address_city);
        this.updateCustomer.controls.customer_address_zip_code.setValue(this.customersData[i].customer_address_zip_code);
        this.updateCustomer.controls.customer_Building_number.setValue(this.customersData[i].customer_Building_number);

        let phone = this.customersData[i].customer_phone;
        if (phone?.startsWith('+972')) {
          phone = phone.slice(4);  
        }
        this.updateCustomer.controls.customer_phone.setValue(phone);

        this.updateCustomer.controls.customer_vat_number.setValue(this.customersData[i].customer_vat_number);
        this.updateCustomer.controls.customer_vat_tax.setValue(this.customersData[i].customer_vat_tax);
        this.updateCustomer.controls.id_number.setValue(this.customersData[i].id_number);
        this.updateCustomer.controls.customer_type.setValue(this.customersData[i].customer_type);
      }
    };
  }


  executeFunctions(CustomerID: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(CustomerID);
    this.setValue(CustomerID);
    this.updateMd(this.updatedData);
 
  };

  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  getId(id: number) {
    this.customerId = id;
  };

 
  

executeFunctionsView(CustomerID: number, view: any) {
  this.viewData = view;
  this.getId(CustomerID);
  this.setValue(CustomerID);
  this.updateViewMd(this.viewData);
};

updateViewMd(paymentsUpdated: any) {
  this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
};

openVerticallyCentered(dataDeleted:any) {
  this.modalService.open(dataDeleted, { centered: true });
};

deleteCustomerById() {
  this.spinner.show();
  this.service.deleteCustomerById(this.customerId).subscribe({
    next: (res) => {
      if (res.status == 400) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.spinner.hide();
        return;
      } else {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? 'تم حذف العميل بنجاح' : (this.curentLang === 'en' ? ' Customer deleted successfully' : 'הלקוח נמחק בהצלחה'),
        });
        this.getAllCustomerType();
        this.getAllCustomersBasedOnMerchant();
        this.modalService.dismissAll();
        this.spinner.hide();
      }
    },
    error: (err) => {
      Swal.fire(err.messageAr);
      this.spinner.hide(); 
    }
  });
};
  ngOnInit() 
  {
    this.onCustomerTypeChange();

  this.getAllCustomersBasedOnMerchant();
  this.getAllCustomerType();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
  };
};



