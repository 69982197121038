import { Component, OnInit } from "@angular/core";
import jsPDF from "jspdf";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { ToastrService } from "ngx-toastr";
import { baseUrl } from "../../service/global.service";

@Component({
  selector: "app-document-pdf",
  templateUrl: "./document-pdf.component.html",
  styleUrls: ["./document-pdf.component.scss"]
})

export class DocumentPdfComponent implements OnInit {
  invoices: any[] = [];
  curentLang: string = "";
  Confirm: any;
  DocumentLanguage: any;
  layoutVersion: any;
  userRegistId: any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  routeId = JSON.parse(localStorage.getItem('routeId'))
  invoiceInfoData: any = [];
  id: any;
  DocumentNumber: any;
  toDate: any;
  CompanyVat:any;
  MerchantNameEn:any;
  curentLangDocument: string = ''
  pdfInvoiceId:  any;

  constructor(
    private spinner: NgxSpinnerService,
    private service: HttpHelpService,
    activatedRoute: ActivatedRoute,
    private http: HttpClient,
  ) {
    this.curentLang = localStorage.getItem('curentLang');
        this.id = activatedRoute.snapshot.params['id'];
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.routeId = JSON.parse(localStorage.getItem('routeId'));
  }

  getPdfByInvoiceId() {
    this.spinner.show();
    this.service.getInvoiceAndRecipeToPdf(this.id).subscribe({
      next: res => {
        this.invoiceInfoData = res;  
        console.log(this.invoiceInfoData ,`ddddddd`);
                
        this.Confirm = this.invoiceInfoData?.confirm;
        this.curentLang = this.invoiceInfoData?.language;
        this.pdfInvoiceId  = this.invoiceInfoData?.invoice_id;
        this.curentLangDocument = this.invoiceInfoData?.language;

        this.DocumentNumber = this.invoiceInfoData?.document_number;
        this.CompanyVat = this.invoiceInfoData?.company_vat;
        this.MerchantNameEn = this.invoiceInfoData?.merchant?.merchant_name_en;
        this.spinner.hide();

      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  downloadDocumentPdf() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      color: 'green',
      backdrop: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
    Toast.fire({
      icon: 'success',
      title: this.curentLang === 'ar' ? 'جاري الآن تجهيز الملف' : (this.curentLang === 'en' ? 'The file is now being prepared' : 'הקובץ נמצא כעת בהכנה')
    });
    this.service.downloadDocumentPdf(this.pdfInvoiceId, this.curentLangDocument).subscribe({
      next: res => {
        this.spinner.hide();
        
        // إنشاء رابط لتحميل الـ PDF
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Invoice-${this.pdfInvoiceId}.pdf`; // تحديد اسم الملف
        link.click();
  
        // إطلاق الـ URL لتجنب أي تسرب في الذاكرة
        window.URL.revokeObjectURL(url);
      },
      error: error => {
        this.spinner.hide();
        console.error('Error downloading the file', error);
      }
    });
  }

  // updateVersionCopy() {
  //   this.spinner.show();
  //   this.service.updateVersionCopy(this.id).subscribe({
  //     next: res => {
  //       this.invoiceInfoData = res;
  //       this.spinner.hide();
  //     },
  //     error: error => {
  //       this.spinner.hide();
  //     }
  //   });
  // };

  async generatePDF2() {
    if (this.invoiceInfoData?.confirm === 0) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'لا يمكن تصدير الفاتورة لأنها غير مؤكدة' : (this.curentLang === 'en' ? 'The invoice cannot be exported because it is not confirmed' : 'לא ניתן לייצא את החשבונית מכיוון שהיא לא אושרה')
      });
      return;
    }
  
    try {
      this.spinner.show();
      const pages = document.querySelectorAll('.page');
      const pdf = new jspdf.jsPDF('p', 'mm', 'a4');
  
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const xOffset = 10;
      const yOffset = 10;
      const pdfPageHeight = pageHeight - yOffset * 2;
      const totalNumberOfPages = pages.length;

      for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const pageElement = pages[pageIndex] as HTMLElement;
        const canvas = await html2canvas(pageElement, {
          scale: 2,
          useCORS: true,
          scrollX: 0,
          scrollY: -window.scrollY,
          windowWidth: pageElement.scrollWidth,
          windowHeight: pageElement.scrollHeight
        });
  
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const imgHeight = (canvas.height * pageWidth) / canvas.width;
        let remainingHeight = canvas.height;
        let yPosition = 0;
  
        while (remainingHeight > 0) {
          if (yPosition > 0) {
            pdf.addPage();
          }
  
          const sliceHeight = Math.min(remainingHeight, pdfPageHeight * canvas.width / pageWidth);
  
          const canvasSlice = document.createElement('canvas');
          canvasSlice.width = canvas.width;
          canvasSlice.height = sliceHeight;
  
          const ctx = canvasSlice.getContext('2d');
          ctx.drawImage(canvas, 0, yPosition, canvas.width, sliceHeight, 0, 0, canvas.width, sliceHeight);
  
          const imgSlice = canvasSlice.toDataURL('image/jpeg', 1.0);
          pdf.addImage(imgSlice, 'JPEG', xOffset, yOffset, pageWidth - 2 * xOffset, (sliceHeight * pageWidth) / canvas.width);
  
          yPosition += sliceHeight;
          remainingHeight -= sliceHeight;
  
          // Adding page numbers
          pdf.setFontSize(10);
          pdf.text(`Page ${pageIndex + 1} of ${totalNumberOfPages}`, pageWidth - 20, pageHeight - 5); // Add page number and total pages to each page
        }
      }
     if(this.user_type == 300 || this.user_type == 301)
     {
      pdf.save(`thinvoices-${this.DocumentNumber}.pdf`);
      this.spinner.hide();
     }
     else
     {
      const pdfBlob = pdf.output('blob');
      await this.sendPdfToApi(pdfBlob, `thinvoices-${this.DocumentNumber}.pdf`);
      this.spinner.hide();
     }
     

      // this.updateVersionCopy();
      // this.getPdfByInvoiceId();
      this.spinner.hide();
    } catch (error) {
       this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: this.curentLang === 'ar' ? 'حدث خطأ أثناء تصدير الفاتورة' : (this.curentLang === 'en' ? 'An error occurred while exporting the invoice' : 'אירעה שגיאה במהלך ייצוא החשבונית')
      });
    }
  };

  async sendPdfToApi(pdfBlob: Blob, fileName: string) {
    const formData: FormData = new FormData();
    formData.append('file', pdfBlob, fileName);
    formData.append('companyName', this.CompanyVat); // الرقم الضريبي للشركه
    formData.append('signerName', this.MerchantNameEn); // اسم التاجر انجلش

    try {
      const response: Blob = await this.http.post(`${baseUrl}sign-pdf/sign`, formData, { responseType: 'blob' }).toPromise();
      this.downloadSignedPdf(response, `${fileName}`);
      // Swal.fire({
      //   icon: 'success',
      //   title: this.curentLang === 'ar' ? 'تم إرسال الفاتورة بنجاح' : (this.curentLang === 'en' ? 'Invoice sent successfully' : 'החשבונית נשלחה בהצלחה')
      // });
    } catch (error) {
      // Swal.fire({
      //   icon: 'error',
      //   title: this.curentLang === 'ar' ? 'حدث خطأ أثناء إرسال الفاتورة' : (this.curentLang === 'en' ? 'An error occurred while sending the invoice' : 'אירעה שגיאה במהלך שליחת החשבונית')
      // });
    }
  }

  downloadSignedPdf(blob: Blob, fileName: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  async generateOnePDF(invoice: any): Promise<jsPDF> {
    const invoiceDate = new Date(invoice.invoice_date);
    const formattedInvoiceDate = invoiceDate.toLocaleDateString('en-GB'); // This will format the date as dd/mm/yyyy
    // Create an HTML element for the invoice
    const invoiceElement = document.createElement('div');
    invoiceElement.style.width = '800px'; // Adjust width as needed
    invoiceElement.classList.add('page', 'm-auto', 'invoice-content');

    invoiceElement.innerHTML =  invoiceElement.innerHTML = `
    <div class="page m-auto  invoice-content" style="width: 800px;">
      <div class="card-2">
        <div class="row m-2 ">
          <div class="col-lg-12">
            <div class="invoice-inner" id="invoice_wrapper">
              <div class="invoice-top">
                <div class="row">
                  <div class="col-sm-4">
                    ${invoice.logo ? `
                    <div class="logo my-2">
                      <img src="${invoice.logo}" alt="logo" style="width: 100px;height: 100px; border-radius: 5%;">
                    </div>
                    ` : ''}
                  </div>
                  <div class="col-sm-4 py-5">
                    <h2 class="text-center water-mark "
                      style="color:#ccc ; font-size:30px ;  opacity: 0.5; 
      transform: rotate(-15deg);
       position: absolute;
       top:25%; 
        left: 0%; 
      transform-origin: center; 
      z-index: -0"
                    >${this.curentLang === 'en' ? 'Accountant copy' : (this.curentLang === 'ar' ? 'نسخة المحاسب' : 'עותק רואה חשבון')}</h2>
                  </div>
                  <div class="col-sm-4"></div>
                </div>
              </div>
              <div class="invoice-info">
                <div class="row">
                  <div class="col-sm-6 mb-30">
                    <div class="invoice-number ">
                      <h4 class="inv-title-1" style="font-weight: bold; font-size: 14px;">${this.curentLang === 'en' ? 'For ' : (this.curentLang === 'ar' ? 'لحضرة' : 'לכבוד   ')}</h4>
                      <div class="invo-addr-1">
                        <div style="font-weight: 300;"><strong>${invoice.customer_name}</strong></div>
                        ${invoice.customer_address ? `
                          <div style="font-weight: 300;"><strong>${invoice.customer_address}</strong></div>
                        ` : `
                          <div style="font-weight: 300;">
                            <strong>${this.curentLang === 'en' ? 'Customer Vat Number' : (this.curentLang === 'ar' ? 'مشغل رقم' : 'מס עוסק מורשה    ')}</strong>
                          </div>
                          <div style="font-weight: 300;"><strong>${invoice.customer_vat_number}</strong></div>
                        `}
                         <div style="font-weight: 300;"><strong>${invoice.customer_id_number}</strong></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 mb-30">
                    <div class="invoice-number text-end">
                      <div class="invo-addr-1">
                        <div style="font-weight: bold;"><strong>${invoice.company_name}</strong></div>
                        <div style="font-weight: 300;">
                          <strong>${invoice.company_address_str}</strong>
                          <strong>${invoice.company_Building_number}</strong>
                          <strong>${invoice.company_address_city}</strong>
                        </div>
                       
                        <div style="font-weight: 300;"><strong>${invoice.company_vat}</strong></div>
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                 <div class="col-sm-4"></div>
               
                  <div class="invoice text-center  col-sm-4">
                    <p class="mb-1">${invoice.invoice_reference_number !== null ? `<span>${invoice.invoice_reference_number}</span>` : ''}</p>
                    <div class="mb-1" style="font-weight: bold;">
                      ${invoice.document_number !== null ? `
                        <strong>${invoice.invoice_type == 305 ? 
                          (this.curentLang === 'en' ? 'Tax Invoice Number' : (this.curentLang === 'ar' ? 'فاتورة ضريبية رقم' : '     חשבונית מס מספר ')) :
                          invoice.invoice_type == 320 ? 
                          (this.curentLang === 'en' ? 'Invoice With Receipt Number' : (this.curentLang === 'ar' ? 'فاتورة مع ايصال رقم' : 'חשבונית עם מספר קבלה')) :
                          invoice.invoice_type == 332 ? 
                          (this.curentLang === 'en' ? ' Financial Request Number' : (this.curentLang === 'ar' ? 'مطالبة مالية رقم' : ' תביעה כספית מס ')) :
                          invoice.invoice_type == 400 ? 
                          (this.curentLang === 'en' ? 'Receipt Number' : (this.curentLang === 'ar' ? ' ايصال رقم' : ' מס קבלה ')) :
                          invoice.invoice_type == 330 ? 
                          (this.curentLang === 'en' ? 'Reverce Invoice Number' : (this.curentLang === 'ar' ? ' فاتورة عكسية رقم ' : '    חשבונית זיכוי קבלה')) :
                          invoice.invoice_type == 100 ? 
                          (this.curentLang === 'en' ? ' order' : (this.curentLang === 'ar' ? 'طلبية    ' : ' לְהַזמִין')) :
                          invoice.invoice_type == 200 ? 
                          (this.curentLang === 'en' ? ' mission' : (this.curentLang === 'ar' ? 'ارسالية    ' : ' תעודת משלוח')) :
                          invoice.invoice_type == 0 ? 
                          (this.curentLang === 'en' ? ' Offer Price' : (this.curentLang === 'ar' ? 'عرض سعر      ' : ' מחיר הצעה')) :
                          ''}</strong>` : ''}
                      <strong style="font-weight: 400; color: black;">${invoice.document_number}</strong>

                    </div>
                     ${invoice.confirmation_number != null ? `
                  <div style="font-weight: 300;color: black;">
                    <strong style="font-weight: bold;" class="mx-1">${this.curentLang === 'en' ? 'Confirmation Number' : (this.curentLang === 'ar' ? ' رقم تخصيص' : 'מספר הקצאה  ')}: </strong> 
                    <strong>${invoice.confirmation_number}</strong>
                  </div>` : ''}
                  </div>

                     <div class="col-sm-4  text-end">
                    <div class="mb-0" style="font-weight: bold;">${this.curentLang === 'en' ? ' Date' : (this.curentLang === 'ar' ? 'تاريخ ' : 'תאריך ')}</div>
                      <div style="font-weight: 400; color: black;">${formattedInvoiceDate}</div>
                  </div>
                </div>
              </div>
              <div class="order-summary">
                <div class="table-responsive">
                  <table class="table invoice-table">
                    <thead class="bg-active" style="font-weight: bold;">
                      <tr>
                        <th class="text-center" style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Item Name' : (this.curentLang === 'ar' ? 'اسم الصنف' : 'פריט  ')}</th>
                         <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Price' : (this.curentLang === 'ar' ? 'السعر ' : ' מחיר ')}</th>
                        <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Quantity' : (this.curentLang === 'ar' ? 'الكمية' : 'כמות')}</th>
                        <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Discount' : (this.curentLang === 'ar' ? 'الخصم' : 'הנחה')}</th>
                        <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Total' : (this.curentLang === 'ar' ? 'المجموع' : '  סה"כ ')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${invoice.soldItems?.map(item => `
                        <tr>
                          <td class="text-center" style="font-weight: 400; color: black;">${item.item_name}</td>
                            <td class="text-center" style="font-weight: 400; color: black;">${parseFloat(item.price_per_unit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                          <td class="text-center" style="font-weight: 400; color: black;">${item.quantity}</td>
                         <td class="text-center" style="font-weight: 400; color: black;">${parseFloat(item.discount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                          <td class="text-center" style="font-weight: 400; color: black;">${parseFloat(item.sub_total).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        </tr>`).join('')}
                    </tbody>
                  </table>
                </div>
                <div class="small-table my-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="d-flex justify-content-between" style="background-color: #F1F1F1;">
                        <div class="col-md-6 p-2">
                          <div style="width: 300px; font-weight: bold;" class="m-1">${this.curentLang === 'en' ? 'Amount Before Discount:' : (this.curentLang === 'ar' ? 'المبلغ قبل الخصم' : ' מחיר לפני הנחה    ')}</div>
                          <div style="width: 300px; font-weight: bold;" class="m-1">${this.curentLang === 'en' ? 'Discount:' : (this.curentLang === 'ar' ? 'الخصم' : 'הנחה')}</div>
                          <div style="width: 300px; font-weight: bold;" class="m-1">${this.curentLang === 'en' ? 'Payment Amount:' : (this.curentLang === 'ar' ? ' المبلغ المدفوع ' : ' סה"כ   ')}</div>
                          <div style="width: 300px; font-weight: bold;" class="m-1">${this.curentLang === 'en' ? 'VAT Amount:' : (this.curentLang === 'ar' ? ' المبلغ الضريبي ' : '   מע"מ ')}</div>
                          <div style="width: 300px; font-weight: bold;" class="m-1">${this.curentLang === 'en' ? 'Payment Amount Including VAT:' : (this.curentLang === 'ar' ? ' المبلغ المدفوع شامل الضريبة ' : 'סה"כ לשתלום')}</div>
                        </div>
                        <div class="col-md-6 text-end px-3 p-2">
                          <div style="font-weight: 400; color: black;" class="my-1">${parseFloat(invoice.amount_before_discount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
                          <div style="font-weight: 400; color: black;" class="my-1">${parseFloat(invoice.all_discount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
                          <div style="font-weight: 400; color: black;" class="my-1">${parseFloat(invoice.payment_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
                          <div style="font-weight: 400; color: black;" class="my-1">${parseFloat(invoice.vat_percentage).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
                          <div style="font-weight: 400; color: black;" class="my-1">${parseFloat(invoice.payment_amount_including_vat).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
                         </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              ${invoice.invoice_type == 400 || invoice.invoice_type == 320 ? `
              <div class="order-summary">
                <div class="col-lg-12">
                  <div class="mt-4 d-flex justify-content-between">
                    <span style="font-weight: bold; " class="mx-2">${this.curentLang === 'en' ? 'Payment Info' : (this.curentLang === 'ar' ? 'بيانات الدفع ' : ' נתוני תשלום')}</span>
                  </div>
                  <div class="table-responsive">
                    <table class="table invoice-table">
                      <thead>
                        <tr>
                          <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Payment Method ' : (this.curentLang === 'ar' ? 'طرق الدفع ' : 'אמצעי תשלום')}</th>
                          <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Details' : (this.curentLang === 'ar' ? 'تفاصيل ' : 'פרטים')}</th>
                          <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Date' : (this.curentLang === 'ar' ? ' تاريخ ' : ' תאריך')}</th>
                          <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Total' : (this.curentLang === 'ar' ? 'المجموع ' : 'סכום')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        ${invoice.receiptsDetails.map(item => `
                          <tr>
                            <td class="text-center" style="font-weight: 400; color: black;">
                              ${item.payment_type == 1 ? (this.curentLang === 'en' ? 'Cash' : (this.curentLang === 'ar' ? 'كاش' : 'כַּךָ')) :
                              item.payment_type == 2 ? (this.curentLang === 'en' ? 'Check' : (this.curentLang === 'ar' ? 'شيك' : 'סִּכָּה')) :
                              item.payment_type == 3 ? (this.curentLang === 'en' ? 'Bank' : (this.curentLang === 'ar' ? 'بنك' : 'בַּנק')) :
                              item.payment_type == 4 ? (this.curentLang === 'en' ? 'Credit' : (this.curentLang === 'ar' ? 'ائتمان' : 'קִרְּדָּן')) : ''}
                            </td>
                            <td class="text-center description-column" style="word-wrap: break-word; max-width: 200px; white-space:normal ;font-weight: 400; color: black;">${item.details}</td>
                            <td class="text-center" style="font-weight: 400; color: black;">${new Date(item.date).toLocaleDateString()}</td>
                            <td class="text-center" style="font-weight: 400; color: black;">${parseFloat(item.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                          </tr>`).join('')}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="small-table my-4">
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-between">
                      <div class="col-md-6 mt-5"></div>
                      <div class="col-md-6">
                        <div class="row d-flex justify-content-between mx-1" style="background-color: #F1F1F1;">
                          <div class="col-md-6 py-1">
                            <span class="py-1">${this.curentLang === 'en' ? 'Deduction at source' : (this.curentLang === 'ar' ? 'الخصم من المصدر ' : 'ניכוי במקור  ')}: </span>
                          </div>
                          <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                            <span class="mx-1">${parseFloat(invoice.deduction_from_source).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                          </div>
                          <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                            <span>${this.curentLang === 'en' ? 'Total' : (this.curentLang === 'ar' ? 'المجموع' : 'סה"כ')}: </span>
                          </div>
                          <div class="col-md-6 text-end py-1"  style="font-weight: 400; color: black;">
                            <span class="mx-1" >${parseFloat(invoice.total_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6"></div>
                    </div>
                    ` : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>`;

    // Add the element to the document body
    document.body.appendChild(invoiceElement);

    try {
        // Create a new jsPDF instance
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: 'a4' // You can change this to 'letter' if needed
        });

        // Capture the invoiceElement as an image using html2canvas
        const canvas = await html2canvas(invoiceElement, {
            scale: 1.5, // Adjust scale as needed
            useCORS: true,
            scrollY: -window.scrollY
        });

        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let remainingHeight = canvas.height;
        let yPosition = 0;

        while (remainingHeight > 0) {
            const pageHeight = pdf.internal.pageSize.getHeight();
            const canvasSlice = document.createElement('canvas');
            canvasSlice.width = canvas.width;
            const sliceHeight = Math.min(remainingHeight, pageHeight * canvas.width / imgWidth);
            canvasSlice.height = sliceHeight;

            const ctx = canvasSlice.getContext('2d');
            ctx.drawImage(canvas, 0, yPosition, canvas.width, sliceHeight, 0, 0, canvas.width, sliceHeight);

            const imgSlice = canvasSlice.toDataURL('image/jpeg', 1.0);
            pdf.addImage(imgSlice, 'JPEG', 0, 0, imgWidth, (sliceHeight * imgWidth) / canvas.width);

            yPosition += sliceHeight;
            remainingHeight -= sliceHeight;

            if (remainingHeight > 0) {
                pdf.addPage();
            }
        }
        const logoImg = await this.getLogoImageData();

        // Remove the invoiceElement from the document body
        document.body.removeChild(invoiceElement);


        return pdf;
    } catch (error) {
        this.spinner.hide();
        Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? 'حدث خطأ أثناء تصدير الفاتورة' : (this.curentLang === 'en' ? 'An error occurred while exporting the invoice' : 'אירעה שגיאה במהלך ייצוא החשבונית')
        });
        return null;
    }
}
  


  async generateAllPrintPDF(invoices: any[]) {
    this.spinner.show();
    const pdfs = [];
    for (const invoice of invoices) {
      const pdf = await this.generateOnePDF(invoice);
      if (pdf) {
        pdfs.push({ pdf, document_number: invoice.document_number , invoice_type: invoice.invoice_type});
      }
    }
    return pdfs;
  }
  
  // Function to fetch company logo image data
  async getLogoImageData(): Promise<any> {
    const img = new Image();
    img.src = '../../../../assets/images/logo/thi.png'; // Adjust path as needed
    img.crossOrigin = 'Anonymous';
    img.style.width = '10px';
    img.style.height = '10px';
    await new Promise<void>(resolve => {
      img.onload = () => resolve();
    });
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL('image/png');
  }

  async generateAndDownloadAllInvoicesZip(invoices: any[]) {
       
    if(invoices.length === 0)
      {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          color: 'red',
          backdrop:true,
           
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
         Toast.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? '   عذرا لا توجد فواتير لهذة الفترة    ' : (this.curentLang === 'en' ? '  Sorry, there are no invoices for this period ' : '   מצטערים, אין חשבוניות לתקופה זו     ')
        })

        this.spinner.hide();
        return

        
      }

      

    this.spinner.show();
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      color: 'green',
      backdrop:true,
       
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
     Toast.fire({
      icon: 'success',
      title: this.curentLang === 'ar' ? 'جاري الأن تجهيز الملف' : (this.curentLang === 'en' ? ' The file is now being prepared ' : 'הקובץ נמצא כעת בהכנה ')
    })
    this.spinner.show();
     const zip = new JSZip();
  
    try {
      const pdfs = await this.generateAllPrintPDF(invoices);
  
      pdfs.forEach(({ pdf, document_number , invoice_type }) => {
        const pdfBlob = pdf.output('blob');
        zip.file(`invoice_${document_number}_${invoice_type}.pdf`, pdfBlob);
      });
  
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, `invoices_${invoices[0].company_name}.zip`);
       this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: this.curentLang === 'ar' ? 'حدث خطأ أثناء إنشاء ملف ZIP' : (this.curentLang === 'en' ? 'An error occurred while creating the ZIP file' : 'אירעה שגיאה במהלך יצירת קובץ ה-ZIP')
      });
    }
    
   
  }

  ngOnInit() {
    this.getPdfByInvoiceId();
  }
}
