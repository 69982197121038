<app-breadcrumb [title]="curentLang === 'en' ? 'Closed months Info' : (curentLang === 'ar' ? 'معلومات الشهور المغلقة' : 'מידע על חודשים סגורים')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Closed months Info' : (curentLang === 'ar' ? 'معلومات الشهور المغلقة' : 'מידע על חודשים סגורים')">
</app-breadcrumb>

<div class="container-fluid basic_table my-2">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active3" (navChange)="onNavChange1($event)" class="nav-tabs">


                <li [ngbNavItem]="1">
                    <a ngbNavLink hidden>
                      {{curentLang === 'en' ? 'Close month ' : (curentLang === 'ar' ? '  اغلاق شهر ' : 'סגירת חודש')}}    
                    </a>
                    <ng-template ngbNavContent>
                      <div class="card-block row">
                        <div class="row d-flex justify-content-between">
                          <div class="col-md-3">
                            <div class="form-group search">
                              <label for="search" style="color: grey;">
                                {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                              </label>
                              <div class="input-group">
                                <input class="form-control form-control-search my-1" type="text" name="search"
                                       [ngModelOptions]="{standalone: true}" id="search" [(ngModel)]="term" style="color: black;"
                                       placeholder="{{curentLang === 'en' ? 'Search   ' : (curentLang === 'ar' ? 'بحث ' : 'מֶחקָר')}}"
                                >
                              </div>
                            </div>
                          </div>
                
                          
                          <div class="card">
                            <div class="row product-page-main">
                              <div class="col-sm-12">
                                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav nav-tabs border-tab nav-b mb-0">
                                  <li [ngbNavItem]="1">
                                    <a ngbNavLink>
                                      {{ curentLang === 'en' ? 'Determine the closing period' : (curentLang === 'ar' ? 'تحديد فترة الاغلاق' : 'קבע את תקופת הסגירה') }}
                                    </a>
                                    <ng-template ngbNavContent>
                                      <div class="mb-0 m-t-20" [formGroup]="closedMonthForm">
                                        <div class="row justify-content-start align-items-start mx-2  ">
                       
                      
                                          <div class="col-xl-2 col-md-6 col-sm-12   ">
                                            <label class="form-label px-2 " style="color: grey;"> 
                                              {{ curentLang === 'en' ? 'Month' : (curentLang === 'ar' ? 'شهر' : 'חוֹדֶשׁ  ')}}
                                            </label>
                                            <select class="form-control  form-select" style="cursor: pointer" 
                                            (change)="filterCloseMonth($event)"  formControlName="month">
                                              <option [value]="01">1</option>
                                              <option [value]="02">2</option>
                                              <option [value]="03">3</option>
                                              <option [value]="04">4</option>
                                              <option [value]="05">5</option>
                                              <option [value]="06">6</option>
                                              <option [value]="07">7</option>
                                              <option [value]="08">8</option>
                                              <option [value]="09">9</option>
                                              <option [value]="10">10</option>
                                              <option [value]="11">11</option>
                                              <option [value]="12">12</option>
                                              
                                            </select>
                                          </div> 
                                          <!---->
          
                                          <div class="col-xl-2 col-md-6 col-sm-12">
                                            <label class="form-label px-2 " style="color: grey;"> 
                                              {{ curentLang === 'en' ? 'Year' : (curentLang === 'ar' ? 'عام' : 'כְּלָלִי  ')}}
                                            </label>
                                            <select class="form-control  form-select" formControlName="year" style="cursor: pointer" (change)="filterYear($event)"  >
                                              <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                                            </select>
                                          </div> 
          
                                          <div class="col-xl-2 col-md-6 col-sm-12 my-4">
                                           <button class="btn"  [disabled]="closedMonthForm.invalid"
                                           style="background-color: #141414; color: #fff;" (click)="closeMonth()">
                                           {{curentLang === 'en' ? 'Closed ' : (curentLang === 'ar' ? 'إغلاق  ' : '  סְגִירָה    ')}}
          
                                           </button>
                                          </div> 
          
                                        </div>
                                        </div>
          
                                        <div class="col-sm-12 col-lg-12 col-xl-12">
                                          <div class="table-responsive">
                                            <table class="table table-light  text-start table-hover" style="background-color: #F1F1F1;">
                                              <thead>
                                                <tr>
                                                  <th scope="col" >  {{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }} </th>
                                                  <th scope="col">{{ curentLang === 'en' ? '  Company Name' : (curentLang === 'ar' ? ' أسم الشركة  ' : 'שם החברה') }}</th>
                                                  <th scope="col">{{ curentLang === 'en' ? ' Merchant Name ' : (curentLang === 'ar' ? ' اسم التاجر' : 'שם הסוחר  ')}}</th>   
          
                                                  <th scope="col">{{ curentLang === 'en' ? 'Year' : (curentLang === 'ar' ? 'عام' : " כְּלָלִי  ")}}</th>
                                                  <th scope="col">{{ curentLang === 'en' ? '  Month   ' : (curentLang === 'ar' ? ' شهر  ' : '  חוֹדֶשׁ  ')}}</th>   
                                                  <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th>
                                                  <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th>
          
                                                    </tr>
                                              </thead>
                            
                                              <tbody>
                                                <tr 
                                                *ngFor="let item of companyClosedMonthData | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index"
                                               
                                                
                                                style="cursor: pointer"
                                              >
                                              <td scope="row">{{i+1}}</td>
                                                  <td scope="row">{{curentLang === 'en' ? item?.company?.company_name_en : (curentLang === 'ar' ? item?.company?.company_name_ar  : item?.company?.company_name_he )}}</td>
                                                  <td>
                                                    {{curentLang === 'en' ? item?.merchant?.merchant_name_en: (curentLang === 'ar' ? item?.merchant?.merchant_name_ar  : item?.merchant?.merchant_name_he )}}
                                                    </td>
                                                   <td>{{item?.year}}</td>
                                                   <td>{{item?.month}}</td>
                                                   <td>
                                                    <button  *ngIf="item.is_locked == true" class="btn" style="border: none;" 
                                                    (click)="getId(item?.closed_months_id);changeLockClosedStatus(item.is_locked)"
                                                   >
                                                      <i class="fa fa-lock" style=" color: #141414;"></i>
                                                    </button> 
                                
                                                    <button *ngIf="item.is_locked == false" class="btn" style="border: none;"
                                                    (click)="getId(item?.closed_months_id);changeLockClosedStatus(item.is_locked)"
                                                    >
                                                      <i class="fa fa-unlock" style=" color: red;"></i>
                                                    </button> 
                                                  </td>
          
                                                  <td >
                                                    <button 
                                                    [ngStyle]="{ 'pointer-events': item.is_locked == true ? 'none' : 'auto', 'color': item.is_locked == true ? 'gray' : 'red' }" 
                                                    class="btn" 
                                                    style="border: none;" 
                                                    (click)="getId(item?.closed_months_id);openVerticallyCentered(dataDeleted)">
                                                      <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                                                      </button>
                                            
                                                     </td>
                            
                                                </tr>
                                              </tbody>
                                            </table>
                                            <div *ngIf="companyClosedMonthData?.length == 0" class="alert text-danger mt-3">
                                                <span> {{ curentLang === 'en' ?'There are no specific closing periods yet...' : (curentLang === 'ar' ? ' لا توجد فترات اغلاق محددة حتي الأن ... ' : 'אין עדיין תקופות סגירה ספציפיות...')}}</span>
                                            </div>

                                            <div class="pagination-container">
                                              <pagination-controls (pageChange)="p = $event"  class="m-auto my-3 current">
                                              </pagination-controls>
                                             </div>
                                          </div>
                            
                                          <div class="d-flex justify-content-between">
                                            <div></div>
                                            <div>
                                              <a routerLink="/dashboard/company-documents/{{companyUUid}}">
                                                <button class="btn btn-danger m-2">
                                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה ')}}
                                                  <i class="fa fa-rotate-left mx-1"></i>
                                                </button>
                                              </a>
                                            </div>
                                          </div>
                                        </div>  
          
                                    </ng-template>
                                  </li>
                                  <li [ngbNavItem]="2">
                                    <a ngbNavLink>
                                      {{curentLang === 'en' ? 'Closed months invoices' : (curentLang === 'ar' ? 'فواتير الشهور المغلقة' : 'חשבונות לחודשים סגורים')}}
                                    </a>
                                    <ng-template ngbNavContent>
                                      <div class="mb-0 m-t-20">
                                        <div class="col-sm-12 col-lg-12 col-xl-12">
                                          <div class="table-responsive">
                                            <table class="table table-light  text-start table-hover" style="background-color: #F1F1F1;">
                                              <thead>
                                                <tr>
                                                  <th scope="col"> {{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? '   رقم المستند ' : ' מס׳    ')}}</th>
                                                  <th scope="col"> {{ curentLang === 'en' ? ' Invoice Type' : (curentLang === 'ar' ? 'نوع الفاتورة' : '    סוג מסמך     ')}}</th>
                                                  <th scope="col"> {{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? '  تاريخ الفاتورة ' : '    תאריך  ')}}</th>
                                                  <th scope="col"> {{ curentLang === 'en' ? 'Company Name  ' : (curentLang === 'ar' ? ' أسم الشركة ' : 'שם החברה  ')}}</th>
                                                  <th scope="col"> {{ curentLang === 'en' ? 'Customer Name  ' : (curentLang === 'ar' ? '  أسم العميل   ' : ' שם   ')}}</th>
                                                  <th scope="col"> {{ curentLang === 'en' ? 'Amount Before Discount  ' : (curentLang === 'ar' ? ' المبلغ قبل الخصم   ' : 'מחיר לפני הנחה   ')}}</th>
                                                  <th scope="col"> {{ curentLang === 'en' ? ' Payment Amount  ' : (curentLang === 'ar' ? 'المبلغ المدفوع  ' : ' סה"כ ')}}</th>
                                                  <th class="col">{{ curentLang === 'en' ? 'View Invoice' : (curentLang === 'ar' ? 'عرض الفاتورة' : 'הצג חשבונית')}}</th>
                                                  <th class="col">  {{ curentLang === 'en' ?'Cancel warning' : (curentLang === 'ar' ? 'الغاء التحذير' : 'אזהרת ביטול')}}</th>
          
                                                    </tr>
                                              </thead>
                            
                                              <tbody>
                                                <tr 
                                                *ngFor="let item of allInvoicescCompanyDataClosed | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index"
                                         style="cursor: pointer"
                                              >
                                             
                                              <td>{{item.document_number}}</td>
                                              <td *ngIf=" item?.invoice_type == 305 "><span>{{curentLang === 'en' ? 'Tax Invoice' : (curentLang === 'ar' ? 'فاتورة ضريبية' : ' חשבונית מס') }}</span></td>
                                              <td *ngIf=" item?.invoice_type == 320 "><span>{{curentLang === 'en' ? 'Invoice With Receipt' : (curentLang === 'ar' ? 'فاتورة مع ايصال' : ' חשבונית עם חשבונית') }}</span></td>
                                              <td *ngIf=" item?.invoice_type == 332 "><span>{{curentLang === 'en' ? ' Financial Request' : (curentLang === 'ar' ? 'مطالبة مالية' : ' בקשה פינאלית') }}</span></td>
                                              <td *ngIf=" item?.invoice_type == 400 "><span>{{curentLang === 'en' ? 'Receipt' : (curentLang === 'ar' ? ' ايصال' : '  חשבונית') }}</span></td>
                                              <td *ngIf=" item?.invoice_type == 330 "><span>{{curentLang === 'en' ? 'Reverce Invoice' : (curentLang === 'ar' ? ' فاتورة عكسية' : '  חשבונית עם חשבונית') }}</span></td>
                                              <td *ngIf=" item?.invoice_type == 100 "><span>{{curentLang === 'en' ? 'order ' : (curentLang === 'ar' ? ' طلبية  ' : '     לְהַזמִין   ') }}</span></td>
                                              <td *ngIf=" item?.invoice_type == 200 "><span>{{curentLang === 'en' ? 'mission ' : (curentLang === 'ar' ? ' ارسالية  ' : 'תעודת משלוח') }}</span></td>
                                              <td *ngIf=" item?.invoice_type == 000 "><span>{{curentLang === 'en' ? 'Offer Price ' : (curentLang === 'ar' ? ' عرض سعر  ' : ' מחיר הצעה') }}</span></td>
                                              <td>{{ item.invoice_date | date: 'dd/MM/yyyy' }}</td>
                                              <td>{{ curentLang === 'en' ? item.company_name.substring(0, 10) : (curentLang === 'ar' ? item.company_name.substring(0, 10) : item.company_name.substring(0, 10)) }}</td>
                                              <td>{{ curentLang === 'en' ? item?.customer_name.substring(0, 10) : (curentLang === 'ar' ? item?.customer_name.substring(0, 10) : item?.customer_name.substring(0, 10)) }}</td>
                                              <td>{{ item.amount_before_discount }}</td>
                                              <td>{{ item.payment_amount }}</td>
                                              
          
                                              <td>
                                                <a *ngIf="item.pdf_link !=  null" [href]="item.pdf_link" target="_blank" rel="noopener noreferrer">
                                                    <button style=" background-color: #141414; color: white;"
                                                   
                                                    class="btn  btn-sm"  ><i class="fa fa-check-circle-o text-white "></i> {{ curentLang === 'en' ? 'View Invoice' : (curentLang === 'ar' ? 'عرض الفاتورة' : 'הצג חשבונית')}}
                                                  </button>
                                                  </a>
          
                                                  <a *ngIf="item.pdf_link === null"  >
                                                    <button style=" background-color: #141414; color: white;"
                                                    (click)="getInvoiceId(item.invoice_uuid);executeFunctions(item.company_id , content4)"
                                                    
                                                    class="btn  btn-sm"  ><i class="fa fa-check-circle-o text-white "></i> {{ curentLang === 'en' ? 'View Invoice' : (curentLang === 'ar' ? 'عرض الفاتورة' : 'הצג חשבונית')}}
                                                  </button>
                                                  </a>
          
                                              </td>
                                
                                              <td>
                                                <button class="btn btn-sm" style="background-color: rgb(28, 80, 221); color: whitesmoke;"
                                                (click)="getIdInvoiceId(item?.invoice_id);openVerticallyCenteredRejected(dataReject)">
                                                 <i class="fa fa-times-circle " style="color: #fff;"></i>
                                                {{ curentLang === 'en' ?'Cancel warning' : (curentLang === 'ar' ? 'الغاء التحذير' : 'אזהרת ביטול')}}
                                                </button>       
                                             </td>
                            
                                                </tr>
                                              </tbody>
                                            </table>

                                            <div *ngIf="allInvoicescCompanyDataClosed?.length == 0" class="alert text-danger mt-3">
                                                <span> {{ curentLang === 'en' ? 'There are no documents in the closed months yet...' : (curentLang === 'ar' ? ' لا توجد مستندات في شهور مغلقة حتي الأن ... ' : 'אין עדיין מסמכים בחודשים הסגורים...')}}</span>
                                            </div>

                                            <div class="pagination-container">
                                              <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current">
                                              </pagination-controls>
                                             </div>
                                          </div>
                            
                                          <div class="d-flex justify-content-between">
                                            <div></div>
                                            <div>
                                              <a routerLink="/dashboard/company-documents/{{companyUUid}}">
                                                <button class="btn btn-danger m-2">
                                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה ')}}
                                                  <i class="fa fa-rotate-left mx-1"></i>
                                                </button>
                                              </a>
                                            </div>
                                          </div>
                                        </div>  
                                      </div>
                                    </ng-template>
                                  </li>
                                
                                
                                </ul>
                                <div [ngbNavOutlet]="nav" class="mt-2"></div>
                              </div>
                            </div>
                          </div>
                       
                
                          
                        </div>
                      </div>
                    </ng-template>
                  </li>

            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
</div>






 <!--Delete-->

              
 <ng-template #dataDeleted let-modal>
    <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
          {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : ' האם אתה בטוח שברצונך למחוק אותו?      ') }}
  
        </p>
    </div>
    <div class="modal-footer">
  
      <button type="button" 
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="deleteClosedMonth()"
       > 
       {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>
  
        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
          {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>
  
    </div>
  </ng-template>
  
  
  
    <!--cancle-->
  
    <ng-template #dataReject let-modal>
      <div class="modal-header">
      <h4 class="modal-title"></h4>
      <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
      </div>
      <div class="modal-body text-center">
        <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
          {{curentLang === 'en' ? 'Are you sure you want to cancel the warning on this bill?' : (curentLang === 'ar' ? 'هل تريد بالتأكيد الغاء التحذير علي هذة الفاتورة ؟' : 'האם אתה בטוח שאתה רוצה לבטל את האזהרה על החשבון הזה?') }}
        </p>
      </div>
    
      <div class="modal-footer">
  
        <button type="button" 
         class="btn btn-sm m-auto mt-2" 
        style="background-color: #141414; color: #fff;"
         (click)="cancleAert()"
         > 
         {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تأكيد' : 'כדי להיות בטוח')}}
          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
      </button>
  
          <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
          </button>
  
        
  
      </div>
  
  
    </ng-template>




    <ng-template #content4 let-modal>
        <div class="modal-header d-flex justify-content-between">
          <h6 class="modal-title"style="color: grey;">
            {{curentLangDocument === 'en' ? 'Invoice Details' : (curentLangDocument === 'ar' ? 'تفاصيل الفاتورة ' : '  פרטי חשבונית   ')}} 
          </h6>
          <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <button type="button" (click)="downloadDocumentPdf();modal.close('Close click')" class="btn btn-sm my-3"
          style=" background-color: #141414; color: #fff;">
          <i class="fa fa-file-pdf-o mx-1"></i>
          {{ curentLang === 'en' ? ' Print ' : (curentLang === 'ar' ? 'طباعة نسخة  ' : 'הפקת העתק') }}
        </button>
      <div class="container" [hidden]="invoiceInfoData?.confirm == 0" >
          <div class="row m-auto">
              <div class="col-sm-12 ">
      
                  <div class="page m-auto  invoice-content"  id="printDiv">
      
                      <div class="card-2" style="background-color: #fff; box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);">
      
                          <div class="row m-2 ">
                              <div class="col-lg-12">
                                  <div class="invoice-inner" id="invoice_wrapper">
                                      <div class="invoice-top">
                                          <div class="row">
                                              <div class="col-sm-4">
                                                  <div class="logo my-2"  *ngIf="invoiceInfoData?.logo" >
                                                      <img 
                                                          [src]="invoiceInfoData?.logo"
                                                           title="company logo"
                                                          style="width: 100px;height: 100px; border-radius: 5%;">
                                                  </div>
                                              </div>
      
                                              <div [class.py-5]="invoiceInfoData?.logo" [class.py-2]="!invoiceInfoData?.logo" class="col-sm-4" *ngIf="user_type == 300 || user_type == 301">
                                                  <h2 class="text-center water-mark">
                                                      {{ curentLangDocument === 'en' ? ' Accounting Copy' : (curentLangDocument === 'ar' ? 'نسخة المحاسب' : ' עותק רואה חשבון')}}
                                                  </h2>
                                              </div>
      
                                              <div [class.py-5]="invoiceInfoData?.logo" [class.py-2]="!invoiceInfoData?.logo" class="col-sm-4" *ngIf="user_type == 200 || user_type == 201">
                                                  <h2 class="text-center water-mark">{{invoiceInfoData?.copy_version}}</h2>
                                              </div>
                                              
                                              <div class="col-sm-4">
                                           
                                              </div>
                                          </div>
                                      </div>
                                      <div class="invoice-info py-2">
                                          <div class="row">
                                             
                                              <div class="col-sm-6 mb-30">
                                                  <div class="invoice-number ">
                                                      <h4 class="inv-title-1" style="font-weight: bold; font-size: 14px;">{{ curentLangDocument === 'en' ? 'For ' : (curentLangDocument === 'ar' ? '   لحضرة' : 'לכבוד  ')}}</h4>
                                                      <div class="invo-addr-1">
                                                          <div style="font-weight: 400;">
                                                              <!-- <strong> {{ curentLangDocument === 'en' ? 'Customer Name' : (curentLangDocument === 'ar' ? 'اسم العميل' : 'שם הלקוח')}} / </strong> -->
                                                              <strong>{{ invoiceInfoData?.customer_name }}</strong>
                                                          </div>
                                                          
                                                          <div *ngIf="invoiceInfoData?.customer_address; else noAddress" style="font-weight: 300;" class="m-0">
                                                              <strong>{{ invoiceInfoData?.customer_address }}</strong>
                                                          </div>
                                                          
                                                          <ng-template #noAddress>
                                                              <div style="font-weight: 300;">
                                                                  <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : 'מס עוסק מורשה    ')}} </strong>
                                                                  <div style="font-weight: 300;">
                                                                      <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                                  </div>
                                                              </div>
                                                          </ng-template>
                                                          
                                                          <div *ngIf="invoiceInfoData?.customer_address" style="font-weight: 300;">
                                                              <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : '    מס עוסק מורשה ')}} </strong>
                                                              <div style="font-weight: 300;">
                                                                  <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                              </div>
                                                          </div>
                                                          
      
                                                          
                                                          <div style="font-weight: 300;">
                                                              <!-- <strong>{{ curentLangDocument === 'en' ? 'Customer Id Number' : (curentLangDocument === 'ar' ? '  رقم الهوية للعميل' : '  מספר זיהוי לקוח ')}} / </strong> -->
                                                              <strong>{{ invoiceInfoData?.customer_id_number }}</strong>
                                                          </div>
                                                          
                                                      </div>
                                                  </div>
                                                  
                                              </div>
      
                                              <div class="col-sm-6 mb-30">
                                                  <div class="invoice-number text-end">
                                                      <!-- <h4 class="inv-title-1"> {{ curentLangDocument === 'en' ? 'Invoice From' : (curentLangDocument === 'ar' ? 'فاتورة من' : 'חשבונית מ')}} </h4> -->
                                                      <div class="invo-addr-1">
                                                          <div style="font-weight: bold;">
                                                              <!-- <strong>{{ curentLangDocument === 'en' ? 'Company Name' : (curentLangDocument === 'ar' ? 'اسم الشركة' : 'שם החברה')}} / </strong>  -->
                                                              <strong>{{ invoiceInfoData?.company_name }}</strong>
                                                          </div>
                                                          
                                                          <div style="font-weight: 300;">
                                                              <!-- <strong> {{ curentLangDocument === 'en' ? 'Company Address' : (curentLangDocument === 'ar' ? 'عنوان الشركة' : 'כתובת החברה')}} / </strong> -->
                                                              <strong>{{ invoiceInfoData?.company_address_str }} -</strong>
                                                              <strong>{{ invoiceInfoData?.company_Building_number }} -</strong>
      
                                                              
                                                              <strong>{{ invoiceInfoData?.company_address_city }}</strong>
                                                          </div>
                                                          
                                                        
                                                          <!-- <div style="font-weight: 400;">{{ invoiceInfoData?.company_address_zip_code }}</div> -->
                                                          <!-- <div style="font-weight: 300;">
                                                              <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? '  الرقم الضريبي للشركة' : 'מספר מס חברה')}} / </strong>
                                                              <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                          
                                                          </div> -->
      
                                                          <div  style="font-weight: 300;"> 
                                                              <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : '     מס עוסק מורשה     ')}}  </strong> 
                                                         </div>
                                                         <div  style="font-weight: 300;"> 
                                                         <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                         </div>
                                                        
      
                                                        
                                                          
                                                      </div>
                                                  </div>
                                                  
                                                  
                                                  
                                              </div>
                                          </div>
                                          <div class="row  ">
                                              <div class="col-sm-4"></div>
      
                                              
      
                                              <div class="invoice text-center   col-sm-4">
                                                  <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Invoice Details' : (curentLangDocument === 'ar' ? 'تفاصيل الفاتورة' : ' פרטי החשבונית  ')}}</h4> -->
      
                                             
      
                                                  <div class="mb-1"*ngIf="invoiceInfoData?.document_number !== null">
                                                 <strong *ngIf="invoiceInfoData?.invoice_type == 305" style="font-weight: bold;" class="mx-1">
                                                  {{curentLangDocument === 'en' ? 'Tax Invoice Number' : (curentLangDocument === 'ar' ? 'فاتورة ضريبية رقم' : '  חשבונית מס מספר       ') }}
                                                </strong> 
      
                                                <strong *ngIf="invoiceInfoData?.invoice_type == 320" style="font-weight: bold;" class="mx-1">
                                                  {{curentLangDocument === 'en' ? 'Invoice With Receipt Number' : (curentLangDocument === 'ar' ? 'فاتورة مع  ايصال رقم' : 'חשבונית עם מספר קבלה') }}
                                               </strong> 
      
                                               <strong *ngIf="invoiceInfoData?.invoice_type == 332" style="font-weight: bold;" class="mx-1">
                                                  {{curentLangDocument === 'en' ? ' Financial Request Number' : (curentLangDocument === 'ar' ? 'مطالبة مالية رقم' : ' תביעה כספית מס  ') }}
                                               </strong> 
      
                                               <strong *ngIf="invoiceInfoData?.invoice_type == 400" style="font-weight: bold;" class="mx-1">
                                                  {{curentLangDocument === 'en' ? 'Receipt Number' : (curentLangDocument === 'ar' ? ' ايصال رقم' : ' מס קבלה  ') }}
                                               </strong> 
      
                                               <strong *ngIf="invoiceInfoData?.invoice_type == 330" style="font-weight: bold;" class="mx-1">
                                                  {{curentLangDocument === 'en' ? 'Reverce Invoice Number' : (curentLangDocument === 'ar' ? '  فاتورة عكسية رقم  ' : '      חשבונית זיכוי קבלה ') }}
                                               </strong> 
      
                                               <strong *ngIf="invoiceInfoData?.invoice_type == 100" style="font-weight: bold;" class="mx-1">
                                                {{curentLang === 'en' ? '    order' : (curentLang === 'ar' ? 'طلبية    ' : '   לְהַזמִין   ') }}
                                             </strong> 
      
                                             <strong *ngIf="invoiceInfoData?.invoice_type == 200" style="font-weight: bold;" class="mx-1">
                                                {{curentLang === 'en' ? '    mission' : (curentLang === 'ar' ? 'ارسالية    ' : '   תעודת משלוח   ') }}
                                             </strong> 
      
                                             <strong *ngIf="invoiceInfoData?.invoice_type == 000" style="font-weight: bold;" class="mx-1">
                                                {{curentLang === 'en' ? '    Offer Price' : (curentLang === 'ar' ? 'عرض سعر     ' : ' מחיר הצעה') }}
                                             </strong> 
      
                                               <strong style="font-weight: 400; color: black;">{{invoiceInfoData?.document_number}}</strong>
      
      
                                                  
                                              </div>
                                              <div [hidden]="invoiceInfoData?.confirmation_number == null"  style="font-weight: 300;color: black;">
                                                  <strong style="font-weight: bold;" class="mx-1">{{ curentLangDocument === 'en' ? 'Confirmation Number' : (curentLangDocument === 'ar' ? ' رقم تخصيص' : ' מספר הקצאה  ')}} : </strong> 
                                                 <strong>{{ invoiceInfoData?.confirmation_number }}  </strong>
                                             </div>
                                                 
                                                 
                                              </div>
      
                                              <div class="col-sm-4    text-end">
                                                  <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' التاريخ  ' : ' תאריך  ')}}</h4> -->
                                                 
                                                       <div class="mb-0" style="font-weight: bold;">
                                                          {{ curentLangDocument === 'en' ? ' Date' : (curentLangDocument === 'ar' ? 'تاريخ ' : 'תאריך ')}}
                                                      </div> 
                                                      <div style="font-weight: 400; color: black;">{{ invoiceInfoData?.invoice_date| date:'dd/MM/yyyy' }}</div>
                                              </div>
      
      
                                          </div>
                                      </div>
                                      <div class="order-summary" *ngIf="invoiceInfoData?.invoice_type !== 400" >
                                          <div class="table-responsive">
                                              <table class="table invoice-table">
                                                  <thead class="bg-active" style="font-weight: bold;   ">
                                                      <tr>
                                                          <th
                                                              class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Item Name' : (curentLangDocument === 'ar' ? 'اسم الصنف' : 'פריט  ')}}</th>
                                                           <th
                                                              class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Description' : (curentLangDocument === 'ar' ? 'الوصف' : 'תיאור')}}</th> 
                                                          <!-- <th
                                                              class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Measure Unit' : (curentLangDocument === 'ar' ? 'وحدة القياس' : 'יחידת מידה')}}</th> -->
                                                          <th
                                                              class="text-center" style="font-weight: bold;"> {{ curentLangDocument === 'en' ? 'Price' : (curentLangDocument === 'ar' ? 'السعر  ' : ' מחיר  ')}}</th>
                                                          <th
                                                              class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Quantity' : (curentLangDocument === 'ar' ? 'الكمية' : 'כמות')}}</th>
                                                          <th
                                                              class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Discount' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</th>
                                                          <th class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}</th>
      
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      <tr
                                                          *ngFor=" let item of invoiceInfoData?.lines">
                                                          <td class="text-center" style="font-weight: 400; color: black;" >{{item?.item_name}}</td>  
                                                          
                                                          <td  class="text-center  description-column"style="font-weight: 400; color: black;">{{item?.description}}</td>
      
                                                        <!--   <td class="text-center  description-column" style="font-weight: 400; color: black;">{{item?.measure_unit_description}}</td> -->
      
                                                          <td class="text-center" style="font-weight: 400; color: black;" >{{item?.price_per_unit | number:'1.2-2'}}</td>
      
                                                          <td class="text-center" style="font-weight: 400; color: black;" >{{item?.quantity}}</td>
      
                                                          <td class="text-center" style="font-weight: 400; color: black;" >{{item?.discount}}</td>
      
                                                          <td class="text-center" style="font-weight: 400; color: black;" >{{item?.sub_total | number:'1.2-2'}}</td>
      
                                                      </tr>
      
                                                  </tbody>
                                              </table>
      
                                            
      
                                          </div>
                                          <div class="small-table my-4">
                                              <div class="row">
                                                  <div class="col-md-12  ">
                                                      <div class="d-flex justify-content-between" style="background-color: #F1F1F1;">
                                                          <div class="col-md-6  p-2 ">
                                                              <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Amount Before Discount:' : (curentLangDocument === 'ar' ? 'المبلغ قبل الخصم' : '   מחיר לפני הנחה    ')}}</div>
                                                              <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Discount:' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</div>
                                                              <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Payment Amount:' : (curentLangDocument === 'ar' ? '   المبلغ المدفوع    ' : '      סה"כ      ')}}</div>
                                                              <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'VAT Amount:' : (curentLangDocument === 'ar' ? '       المبلغ الضريبي    ' : 'מע"מ')}}</div>
                                                              <div style="width: 300px; font-weight: bold;" class="m-1"> {{ curentLangDocument === 'en' ? 'Payment Amount Including VAT:' : (curentLangDocument === 'ar' ? ' المبلغ المدفوع شامل الضريبة ' : 'סה"כ לשתלום')}}</div>
      
                                                          </div>
      
                                                       
      
                                                          <div class="col-md-6 text-end px-3 p-2">
                                                              <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.amount_before_discount | number:'1.2-2'}}</div>
                                                              <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.all_discount | number:'1.2-2'}}</div>
                                                              <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount | number:'1.2-2'}}</div>
                                                              <div style="font-weight: 400; color: black;" class="my-1">({{invoiceInfoData?.vat_percentage}} %)   {{invoiceInfoData?.all_vat_amount | number:'1.2-2'}}</div>
                                                              <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount_including_vat | number:'1.2-2'}}</div>
      
                                                          </div>
                                                         
                                                       
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          
                                          
      
                                      </div>
      
      
                                        <div class="order-summary  " *ngIf="invoiceInfoData?.invoice_type == 320 || invoiceInfoData?.invoice_type == 400">
      
                                          <div class="col-lg-12 " >
                                              <ng-container  >
                                                  <div class="mt-4 d-flex justify-content-between">
                                                      <span style="font-weight: bold; " class="mx-2">{{ curentLangDocument === 'en' ? 'Payment Info' : (curentLangDocument === 'ar' ? 'بيانات الدفع  ' : ' נתוני תשלום')}} </span>
                                                      <!-- <span style="font-weight: 500;" class="mx-3">{{ receiptInfoData.checks_amount | number:'1.0-0' }}</span> -->
                                                  </div>  
                                          
                                                  <div class="table-responsive ">
                                                      <table class="table invoice-table">
                                                          <thead>
                                                          
                                                              <tr>
                                                                  <th
                                                                      class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Payment Method ' : (curentLangDocument === 'ar' ? 'طرق الدفع  ' : 'דרכי תשלום')}}
                                                                  </th>
                                                                  <!-- <th
                                                                      class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Ref' : (curentLangDocument === 'ar' ? 'مرجع  ' : 'רפ')}}</th> -->
                                                                  <!-- <th
                                                                      class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك  ' : 'בַּנק')}}
                                                                  </th> -->
                                                                  <!-- <th
                                                                      class="text-center" style="font-weight: bold;" > {{ curentLangDocument === 'en' ? 'Branch' : (curentLangDocument === 'ar' ? 'فرع  ' : 'סניף')}}
                                                                  </th>
                                                                  
                                                                  <th
                                                                      class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Account' : (curentLangDocument === 'ar' ? 'حساب  ' : 'חֶשְׁבּוֹן')}}
                                                                  </th> -->
                          
                                                                  <th
                                                                      class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Details' : (curentLangDocument === 'ar' ? 'تفاصيل  ' : 'פרטים')}}</th>
                                                                      <th
                                                                      class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' تاريخ  ' : ' תאריך')}}</th>
                                                                  <th class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع  ' : 'סכום')}}</th>
                          
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              <tr *ngFor=" let item of invoiceInfoData?.receiptsDetails">
                                                                  <td class="text-center" style="font-weight: 400; color: black;">
                                                                      <span *ngIf=" item?.payment_type == 1"> {{ curentLangDocument === 'en' ? 'Cash' : (curentLangDocument === 'ar' ? 'كاش' : 'מְזוּמָנִים')}}</span>
                                                                      <span *ngIf=" item?.payment_type == 2"> {{ curentLangDocument === 'en' ? 'Check' : (curentLangDocument === 'ar' ? 'شيك' : 'לִבדוֹק')}}</span>
                                                                      <span *ngIf=" item?.payment_type == 3"> {{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك' : 'בַּנק')}}</span>
                                                                      <span *ngIf=" item?.payment_type == 4"> {{ curentLangDocument === 'en' ? 'Credit' : (curentLangDocument === 'ar' ? 'ائتمان' : 'אַשׁרַאי')}}</span>
                                                                  </td>
                                                                  <!-- <td class="text-center">{{ item.bank }}</td>
                                                                  <td class="text-center">{{ item.branch }}</td>
                                                                  <td class="text-center">{{ item.account }}</td>
                                                                   -->
                          
                                                                  <td class="text-center description-column" style="font-weight: 400; color: black;">{{ item.details }}</td>
                                                                  <td class="text-center" style="font-weight: 400; color: black;">{{ item.date | date: 'yyyy-MM-dd' }}</td>
                                                                  <td class="text-center" style="font-weight: 400; color: black;">{{ item.amount | number:'1.2-2'}}</td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </div>
                                              </ng-container>
                                          </div>
      
      
                    
      
                      <div class="small-table my-4" >
                          <div class="row">
                              <div class="col-md-12 d-flex justify-content-between" >
                                  <div class="col-md-6 mt-5">
                                    <!-- <span>Weighted date: 05/05/24</span> -->
                                   </div>
                                   <div class="col-md-6">
                                      <div class="row d-flex justify-content-between mx-1" style="background-color: #F1F1F1;">
                                          <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                              <span class="py-1"> {{ curentLangDocument === 'en' ? 'Deduction at source' : (curentLangDocument === 'ar' ? 'الخصم من المصدر  ' : '  ניכוי במקור')}} :  </span>
                                          </div>
      
                                          <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                              <span class="mx-1">{{invoiceInfoData.deduction_from_source | number:'1.0-0'}}</span>
                                          </div>
      
                                          <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                              <span>{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}  :  </span>
                                          </div>
      
                                          <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                              <span class="mx-1">{{ invoiceInfoData.total_amount | number:'1.2-2' }}</span>
      
                                          </div>
      
      
                                      </div>
                                      
                                   </div>
      
                                   <div class="col-md-6">
      
                                   </div>
                              </div>
                          </div>
                      </div>
                      
                      
                      
                      <!-- <div class=" d-flex justify-content-between"> 
      
                          <button type="button"  hidden
                          class="btn   m-2" 
                         style="background-color: #141414; color: #fff;"
                          
                          > 
                           {{ curentLangDocument === 'en' ? 'Update' : (curentLangDocument === 'ar' ? '     تحديث ' : '     עדכן  ') }}
                           <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                       </button>
                  
                         
      
                              <img src="../../../../assets/images/logo/thi.png"  class="  m-2 "  alt="">
                        
                            
                          
                  
                        </div> -->
                      
                  </div>
                                          
                                          
                                      </div>
      
                                  </div>
      
                              </div>
                          </div>
                      </div>
                  </div>
      
      
                  
      
      
      
              </div>
      
              
      
          </div>
      
      
      
      
          <!--back-->
          <div class=" d-flex justify-content-between"> 
      
              <button type="button"  hidden
              class="btn   m-2" 
             style="background-color: #141414; color: #fff;"
              
              > 
               {{ curentLangDocument === 'en' ? 'Update' : (curentLangDocument === 'ar' ? '     تحديث ' : '     עדכן  ') }}
               <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
           </button>
      
          <!--    <a routerLink="/dashboard/company-documents/{{invoiceInfoData?.company_uuid}}" *ngIf="user_type == 300 || user_type == 301">
                <button class="btn btn-danger m-2 " >
                  {{ curentLangDocument === 'en' ? 'Back' : (curentLangDocument === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
      -->
              <a routerLink="/dashboard/documents/{{invoiceInfoData?.company_uuid}}" *ngIf="user_type == 200 || user_type == 201">
                  <button class="btn btn-danger m-2 " >
                    {{ curentLangDocument === 'en' ? 'Back' : (curentLangDocument === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                    <i class="fa fa-rotate-left mx-1"></i>
                  </button>
                </a>
      
                <button type="button" class="btn btn-sm mt-2 btn-danger" *ngIf="user_type == 300 || user_type == 301"
                (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>
      
            </div>
          </div>
        </div>
      </ng-template>