import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHelpService } from 'src/app/Data/service/http-help.service';
import Swal from "sweetalert2";
@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {
  userRegistId: any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  curentLang: string = "";
  LockedMonthInvoicesData: any = [];
  invoiceId:  any;
  public openMessageBox: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private service: HttpHelpService,
    private modalService: NgbModal,
  ) {

    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
   }



  getLockedMonthInvoicesByAccess() {

    this.spinner.show();
    this.service.getLockedMonthInvoicesByAccess().subscribe({
      next: res => {
        this.LockedMonthInvoicesData = res;
         
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }
  
  toggleMessageBox() {
    this.openMessageBox = !this.openMessageBox;
  }

  openVerticallyCenteredRejected(dataReject:any) {
    this.modalService.open(dataReject, { centered: true });
  };

  getId(id: number) {
    this.invoiceId = id;
    console.log(this.invoiceId, `this.invoiceId`);
  }
  
  cancleAert() {
    this.spinner.show();
    
    const updatedBy = this.userRegistId;
    this.service.cancleAert(this.invoiceId, updatedBy).subscribe({
      next: (res) => {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        });
        this.getLockedMonthInvoicesByAccess();
        this.spinner.hide();
           this.modalService.dismissAll();
      },
      error: (err) => {
        Swal.fire({
          icon: 'error',
          title: err.error.message
        });
        this.spinner.hide();
      }
    });
  }
  ngOnInit() {
    this.getLockedMonthInvoicesByAccess()
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  }
}
