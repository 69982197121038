<app-breadcrumb [title]="curentLang === 'en' ? 'Update Invoice and Receipt' : (curentLang === 'ar' ? ' تعديل فاتورة وايصال ' : 'ערוך חשבונית וקבלה')"
  [items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : [' לוח הבקרה '])"
  [active_item]="curentLang === 'en' ? 'Update Invoice and Receipt' : (curentLang === 'ar' ? ' تعديل فاتورة وايصال ' : 'ערוך חשבונית וקבלה')">
</app-breadcrumb>

<div class="container-fluid basic_table">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-block row">
          <div class="col-sm-12 col-lg-12 col-xl-12">
            <div>
              <form [formGroup]="invoiceReceiptForm" >
                <div class="row">
                  <div class="col-lg-12 row">
                    <div class="col-md-6">
                      <div class="form-group mx-2">
                        <label class="mt-2">{{ curentLang === 'en' ? 'Select Company Name' : (curentLang === 'ar' ? 'اختر اسم الشركة' :    'שם החברה      ')}}</label>
                        <span class="text-danger">*</span>
                        <ng-select formControlName="company_id" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                          <ng-option *ngFor="let item of mastercompany" [value]="item.company_id">
                            {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar' ? item.company_name_ar : item.company_name_he) }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mx-2">
                        <label class="mt-2">{{ curentLang === 'en' ? 'Select Customer Name' : (curentLang === 'ar' ? 'اختر اسم العميل' : '  לקוח     ')}}</label>
                        <span class="text-danger">*</span>
                        <ng-select formControlName="customer_id" (change)="getCompanyId($event);getCustomerById();" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'לקוח') }}">
                          <ng-option *ngFor="let item of mastercustomer" [value]="item.customer_id">
                            {{ curentLang === 'en' ? item.customer_name_en : (curentLang === 'ar' ? item.customer_name_ar : item.customer_name_he) }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 row">
                    <div class="col-md-4">
                        <div class="col-lg-12 ">
                            <div class="form-group mx-2 ">
                                <label class="mt-2"> {{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? 'تاريخ الفاتورة' : '  תאריך ')}}</label>
                                <input type="date"  (change)="filter($event); getVatPercentageByVatListIdAndDate()"
                                 formControlName="invoice_date" class="form-control w-100">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group mx-2">
                            <label class="mt-2"> {{ curentLang === 'en' ? 'Vat Type' : (curentLang === 'ar' ? 'نوع الضريبة' : '  סוג המע"מ ') }}</label>
                            <span class="text-danger">*</span>
          

                            <ng-select formControlName="vat_list_id"   (change)="getVatPercentageByVatListIdAndDate()"style="cursor: pointer;"
                             placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                <ng-option *ngFor="let item of vatTypeList; let i = index " [value]="item?.vat_list_id">
                                    {{ curentLang === 'en' ? item.vat_name_en : (curentLang === 'ar' ? item.vat_name_ar : item.vat_name_he) }}      
                                 </ng-option>
                              </ng-select>

                        </div> 
                    </div>

                    <div class="col-md-4">
                        <div class="form-group mx-2">
                            <label class="mt-2"> {{ curentLang === 'en' ? 'Document Language' : (curentLang === 'ar' ? 'لغة المستند  ' : '  שפת מסמך')}}</label>
                            <select formControlName="document_language" class="form-control form-select">
                                <option *ngFor="let lang of availableLanguages" [ngValue]="lang.code" [selected]="lang.code === curentLang">
                                    {{ lang.name }}
                                </option>
                            </select>

                            <!-- <ng-select formControlName="document_language"  style="cursor: pointer;"
                            placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                               <ng-option *ngFor="let lang of availableLanguages; let i = index "[ngValue]="lang.code" [selected]="lang.code === curentLang">
                                {{ lang.name }}     
                                </ng-option>
                             </ng-select> -->

                        </div>
                        </div>
                        
   
                </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive">
                            <table class="table table-bordered d-none d-lg-table" formArrayName="lines">
    
                                <thead >
                                    <tr>
                                        <th>{{ curentLang === 'en' ? 'Product Name' : (curentLang === 'ar' ? 'اسم المنتج' : 'שם מוצר ')}}</th>
                                        <th>{{ curentLang === 'en' ? 'Product Desc' : (curentLang === 'ar' ? 'وصف المنتج' : '    תיאור פרטי באנגלית')}}</th>
                                        <th>{{ curentLang === 'en' ? 'Price per unit' : (curentLang === 'ar' ? 'سعر الوحدة' : 'מחיר יחידה  ')}}</th>
                                        <th>{{ curentLang === 'en' ? 'Quantity' : (curentLang === 'ar' ? 'الكمية' : 'כמות')}}</th>
                                        <th>{{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'خصم' : 'הנחה')}}</th>
                                        <th>{{ curentLang === 'en' ? 'Vat Amount' : (curentLang === 'ar' ? 'قيمة الضريبة' : ' מע"מ' )}}</th>
                                        <th>{{ curentLang === 'en' ? 'Total Amount' : (curentLang === 'ar' ? 'المجموع' : '  סה"כ')}}</th>
                                        <th>{{ curentLang === 'en' ? 'Remove' : (curentLang === 'ar' ? 'حذف' : 'מחק')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of invproducts.controls; let i=index" [formGroupName]="i">
                                        <td style="width:20%">
                                            <ng-select formControlName="product_id" (change)="productchange(i)" style="cursor: pointer;">
                                                <ng-option *ngFor="let item of masterproduct; let i = index" [value]="item.product_id">
                                                    {{ curentLang === 'en' ? item.product_name_en : (curentLang === 'ar' ? item.product_name_ar : item.product_name_he) }}
                                                </ng-option>
                                            </ng-select>
                                        </td>
                                        <td style="width:20%">
                                            <textarea maxlength="250" cols="10" rows="1" class="form-control form-control-sm" formControlName="description"></textarea>
                                        </td>
                                        <td style="width:10%">
                                            <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="price_per_unit" />
                                        </td>
                                        <td style="width:9%">
                                            <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="quantity" />
                                        </td>
                                        <td style="width:10%">
                                            <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="discount" />
                                        </td>
                                        <td style="width:11%">
                                            <input class="form-control" disabled readonly formControlName="vat_amount" />
                                        </td>
                                        <td style="width:13%">
                                            <input class="form-control" type="number" min="0" formControlName="total_amount" (change)="onTotalAmountChange(i)" />
                                        </td>
                                        <td style="width:5%">
                                            <i (click)="Removeproduct(i)" class="fa fa-trash" style="font-size: 22px; cursor: pointer; color: red;"></i>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="6">
                                            <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addnewproduct()">
                                                <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                             {{ curentLang === 'en' ? 'Add Product' : (curentLang === 'ar' ? 'اضافة منتج  ' : '  הוספת פריט  ')}}    </button>
                                        </td>
                                    </tr>
                                </tfoot>
        
                            </table>
                        </div>


                         <!-- Mobile layout for smaller screens -->
<div class="d-lg-none" formArrayName="lines">


<div *ngFor="let item of invproducts.controls; let i=index" [formGroupName]="i" class="border p-3 my-2">
<div class="form-group">
    <label>{{ curentLang === 'en' ? 'Product Name' : (curentLang === 'ar' ? 'اسم المنتج' : 'שם מוצר ')}}</label>
    <ng-select formControlName="product_id" (change)="productchange(i)" style="cursor: pointer;">
        <ng-option *ngFor="let item of masterproduct; let i = index" [value]="item.product_id">
            {{ curentLang === 'en' ? item.product_name_en : (curentLang === 'ar' ? item.product_name_ar : item.product_name_he) }}
        </ng-option>
    </ng-select>
</div>
<div class="form-group">
    <label>{{ curentLang === 'en' ? 'Product Desc' : (curentLang === 'ar' ? 'وصف المنتج' : '    תיאור פרטי באנגלית')}}</label>
    <textarea maxlength="250" cols="10" rows="2" class="form-control form-control-sm" formControlName="description"></textarea>
</div>
<div class="form-group">
    <label>{{ curentLang === 'en' ? 'Price per unit' : (curentLang === 'ar' ? 'سعر الوحدة' : '  מחיר יחידה')}}</label>
    <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="price_per_unit" />
</div>
<div class="form-group">
    <label>{{ curentLang === 'en' ? 'Quantity' : (curentLang === 'ar' ? 'الكمية' : 'כמות')}}</label>
    <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="quantity" />
</div>
<div class="form-group">
    <label>{{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'خصم' : 'הנחה')}}</label>
    <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="discount" />
</div>
<div class="form-group">
    <label>{{ curentLang === 'en' ? 'Vat Amount' : (curentLang === 'ar' ? 'قيمة الضريبة' : '   מע"מ ')}}</label>
    <input class="form-control" disabled readonly  formControlName="vat_amount" />
</div>
<div class="form-group">
    <label>{{ curentLang === 'en' ? 'Total Amount' : (curentLang === 'ar' ? 'المجموع' : '  סה"כ ')}}</label>
    <input class="form-control" type="number" min="0" formControlName="total_amount" (change)="onTotalAmountChange(i)" />
</div>
<div class="text-center">
    <button (click)="Removeproduct(i)" class="btn btn-sm m-2" style="background-color: red; color: #fff;">
        <i class="fa fa-trash" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
        {{ curentLang === 'en' ? 'Delete' : (curentLang === 'ar' ? 'حذف' : 'מחק') }}
    </button>
</div>
</div>

<!-- Add button for small screens -->
<div class="text-center mt-3">
<button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addnewproduct()">
    <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
    {{ curentLang === 'en' ? 'Add Product' : (curentLang === 'ar' ? 'اضافة منتج' : '  הוספת פריט ') }}
</button>
</div>
</div>


                    
                        <div class="row d-flex justify-content-between">
    
                                    <div class="col-md-6 col-sm-12" style="color: black;">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="d-flex flex-column">
                                                    <div class="d-flex justify-content-between px-4 my-2">
                                                        <div style="width: 200px;"> {{ curentLang === 'en' ? 'Amount Before Discount' : (curentLang === 'ar' ? '   المبلغ قبل الخصم' : 'מחיר לפני הנחה')}}</div>
                                                        <input formControlName="amount_before_discount" class="form-control">
                                                    </div>
                                                    
                                                    <div class="d-flex justify-content-between px-4 my-2">
                                                        <div style="width: 200px;"> {{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'الخصم' : 'הנחה')}}</div> 
                                                        <input formControlName="all_discount" class="form-control">
                                                    </div>
                                                    
                                                    <div class="d-flex justify-content-between px-4 my-2">
                                                        <div style="width: 200px;"> {{ curentLang === 'en' ? 'Payment Amount' : (curentLang === 'ar' ? ' قيمة الدفع  ' : 'סה"כ   ')}}</div>
                                                        <input formControlName="payment_amount" class="form-control">
                                                    </div>
                                                    
                                                    <div class="d-flex justify-content-between px-4 my-2">
                                                        <div style="width: 200px;"> {{ curentLang === 'en' ? 'VAT Amount' : (curentLang === 'ar' ? '   قيمة الضريبة' : ' מע"מ    ')}} ( {{VatPercentage }} %)</div>
                                                        <input formControlName="all_vat_amount" class="form-control"  >

                                                    </div>
                                                    
                                                    <div class="d-flex justify-content-between px-4 my-2">
                                                        <div style="width: 200px;"> {{ curentLang === 'en' ? 'Payment Amount Including VAT' : (curentLang === 'ar' ? 'قيمة المبلغ شاملة الضريبة' : '    סה"כ לשתלום')}}</div>
                                                        <input formControlName="payment_amount_including_vat" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                           
                                        </div>
                                    </div>
    
                                   
            
                                    <div class="col-md-6 col-sm-12" style="color: black;">
                                        <div class="mt-8 px-4">
                                            <div>
                                                <label for="notes"> {{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? 'ملاحظة' : 'הערה')}}</label>
                                                <textarea
                                                    formControlName="invoice_note"
                                                    id="notes" 
                                                    name="notes"
                                                    class="form-textarea form-control my-2"
                                                    placeholder="{{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? 'ملاحظة' : 'הערה')}}"
                                                    name="notes"
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    
    
                                
     
                        </div>
                    </div>
    
                </div>

                <br>

            
                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive">
                            <!-- Main table for larger screens -->
                            <table class="table table-bordered d-none d-lg-table" formArrayName="linesChecks">
                                <!-- Table header -->
                                <thead class="text-black">
                                    <tr>
                                        <!-- Table headers with language support -->
                                        <th>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</th>
                                        <th>{{ curentLang === "en" ? "Reference" : (curentLang === "ar" ? "رقم الشيك" : " מס' שיק  ") }}</th>
                                        <th>{{ curentLang === "en" ? "Bank" : (curentLang === "ar" ? "البنك" : "בנק") }}</th>
                                        <th>{{ curentLang === "en" ? "Branch" : (curentLang === "ar" ? "الفرع" : "סניף") }}</th>
                                        <th>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ? "الحساب" : "חשבון") }}</th>
                                       <!--    <th>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</th> -->
                                        <th>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</th>
                                        <th>{{ curentLang === "en" ? "Remove" : (curentLang === "ar" ? "حذف" : "מחק") }}</th>
                                    </tr>
                                </thead>
                                <!-- Table body -->
                                <tbody>
                                    <tr *ngFor="let item of linesChecks.controls; let i = index" [formGroupName]="i">
                                        <td style="width:12%"><input class="form-control" formControlName="date" type="date" /></td>
                                        <td style="width:10%"><input class="form-control" formControlName="reference" type="number" /></td>
                                        <td style="width:20%">
                                            <ng-select formControlName="bank" (change)="getBranches($event ,i)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                <ng-option *ngFor="let item of masterBank" [value]="item?.bank_code">
                                                    {{ curentLang === 'en' ? item.bank_name_en : (curentLang === 'ar' ? item.bank_name_ar : item.bank_name_he) }}  /
                                                    {{ item.bank_code }} 
                                                </ng-option>
                                            </ng-select>
                                        </td>
                                        <td style="width:20%">
                                            <ng-select formControlName="branch" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                <ng-option *ngFor="let item of getBranchesForIndex(i)" [value]="item?.bank_branch_code">
                                                    {{ curentLang === 'en' ? item.bank_branch_name_en : (curentLang === 'ar' ? item.bank_branch_name_ar : item.bank_branch_name_he) }}  /
                                                    {{ item.bank_branch_code }}
                                                </ng-option>
                                            </ng-select>
                                        </td>
                                        <td style="width:15%"><input class="form-control" formControlName="account" type="number" /></td>
                                        <!--  <td><input class="form-control" formControlName="details" type="text" /></td> -->
                                        <td style="width:13%"><input class="form-control" formControlName="amount" type="number" /></td>
                                        <td style="width:10%">
                                            <i (click)="removeLineFromChecks(i)" class="fa fa-trash" style="font-size: 22px; cursor: pointer; color: red;" aria-hidden="true"></i>
                                        </td>
                                    </tr>
                                </tbody>
                                <!-- Table footer -->
                                <tfoot>
                                    <tr>
                                        <td>
                                            <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToChecks()">
                                                <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                {{ curentLang === 'en' ? ' Check' : (curentLang === 'ar' ? 'نقدي' : 'מזומן ') }}
                                            </button>
                                        </td>
                                        <td>
                                            <input type="number" min="0" readonly [value]="getTotalAmountChecksArray()" class="form-control" formControlName="checks_amount">
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                
                            <!-- Vertical form layout for small screens -->
                            <div class="d-lg-none" formArrayName="linesChecks">
                                <div *ngFor="let item of linesChecks.controls; let i = index" class="border mb-3 p-3" [formGroupName]="i">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</label>
                                            <input class="form-control" formControlName="date" type="date" />
                                        </div>
                                        <div class="col-12">
                                            <label>{{ curentLang === "en" ? "Reference" : (curentLang === "ar" ? "رقم الشيك" : " מס' שיק  ") }}</label>
                                            <input class="form-control" formControlName="reference" type="number" />
                                        </div>
                                        <div class="col-12">
                                            <label>{{ curentLang === "en" ? "Bank" : (curentLang === "ar" ? "البنك" : "בנק") }}</label>
                                            <ng-select formControlName="bank" (change)="getBranches($event, i)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                <ng-option *ngFor="let item of masterBank" [value]="item?.bank_code">
                                                    {{ curentLang === 'en' ? item.bank_name_en : (curentLang === 'ar' ? item.bank_name_ar : item.bank_name_he) }}   /
                                                    {{ item.bank_code }}

                                                </ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-12">
                                            <label>{{ curentLang === "en" ? "Branch" : (curentLang === "ar" ? "الفرع" : "סניף") }}</label>
                                            <ng-select formControlName="branch" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                <ng-option *ngFor="let item of getBranchesForIndex(i)" [value]="item?.bank_branch_code">
                                                    {{ curentLang === 'en' ? item.bank_branch_name_en : (curentLang === 'ar' ? item.bank_branch_name_ar : item.bank_branch_name_he) }}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-12">
                                            <label>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ? "الحساب" : "חשבון") }}</label>
                                            <input class="form-control" formControlName="account" type="number" />
                                        </div>
                                   <!--      <div class="col-12">
                                            <label>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</label>
                                            <input class="form-control" formControlName="details" type="text" />
                                        </div>
                                        -->
                                        <div class="col-12">
                                            <label>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</label>
                                            <input class="form-control" formControlName="amount" type="number" />
                                        </div>
                                        <div class="col-12 text-center">
                                            <button class="btn btn-sm m-2" (click)="removeLineFromChecks(i)" style="background-color: red; color: #fff;">
                                                <i  class="fa fa-trash mx1" style="font-size: 14px; cursor: pointer; color: #fff;" aria-hidden="true"></i>
                                                {{ curentLang === 'en' ? 'Delete' : (curentLang === 'ar' ? '  حذف' : '  מחק') }}
                                            </button>
                                            
                                        </div>
                                    </div>
                                </div>

                                   <!-- Total amount for small screens -->
                                   <div class="text-center m-3">
                                    <input type="number" min="0" readonly [value]="getTotalAmountChecksArray()" class="form-control" formControlName="checks_amount">
                                </div>
                                <!-- Add button for small screens -->
                                <div class="text-center">
                                    <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToChecks()">
                                        <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                        {{ curentLang === 'en' ? ' Check' : (curentLang === 'ar' ? 'نقدي' : 'מזומן ') }}
                                    </button>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </div>
                

                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive  ">
                            <table class="table table-bordered d-none d-lg-table" formArrayName="linesBankPayment">
                                <!-- Table header -->
                                <thead class="text-black">
                                    <tr>
                                        <th>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</th>
                                        <th>{{ curentLang === "en" ? "Bank" : (curentLang === "ar" ? "البنك" : "בנק") }}</th>
                                        <th>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ? "الحساب" : "חשבון") }}</th>
                                   <!--     <th>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</th> -->
                                        <th>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</th>
                                        <th>{{ curentLang === "en" ? "Remove" : (curentLang === "ar" ? "حذف" : "מחק") }}</th>
                                    </tr>
                                </thead>
                                <!-- Table body -->
                                <tbody>
                                    <tr *ngFor="let item of linesBankPayment.controls; let i = index" [formGroupName]="i">
                                        <td style="width:20%"><input class="form-control" formControlName="date" type="date" /></td>
                                        <td style="width:25%">
                                            <ng-select formControlName="bank" (change)="getBranches($event, i)" style="cursor: pointer;"
                                                placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                <ng-option *ngFor="let item of masterBank" [value]="item?.bank_code">
                                                    {{ curentLang === 'en' ? item.bank_name_en : (curentLang === 'ar' ? item.bank_name_ar : item.bank_name_he) }}  /
                                                    {{ item.bank_code }} 
                                                </ng-option>
                                            </ng-select>
                                        </td>
                                        <td style="width:25%"><input class="form-control" formControlName="account" type="number" /></td>
                                  <!--       <td><input class="form-control" formControlName="details" type="text" /></td> -->
                                        <td style="width:20%"><input class="form-control" formControlName="amount" type="number" /></td>
                                        <td style="width:10%">
                                            <i (click)="removeLineFromBankPayment(i)" class="fa fa-trash" style="font-size: 22px; cursor: pointer; color: red;" aria-hidden="true"></i>
                                        </td>
                                    </tr>
                                </tbody>
                                <!-- Table footer -->
                                <tfoot>
                                    <tr>
                                        <td >
                                            <!-- Add button to add new line -->
                                            <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToBankPayment()">
                                                <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                {{ curentLang === 'en' ? ' Transfer Bank' : (curentLang === 'ar' ? 'حوالة بنكية' : 'העברה בנקאית') }}
                                            </button>

                                            
                                        </td>
                                      
                                        <td>
                                            <input type="number" min="0" readonly [value]="getTotalAmountBankPaymentArray()" class="form-control" formControlName="bank_payment_amount">
                                        </td>
                                        
                                    </tr>
                                
                                </tfoot>
                            </table>
                        </div>
                
                        <!-- Mobile layout -->
                        <div class="d-lg-none" formArrayName="linesBankPayment">
                            <div *ngFor="let item of linesBankPayment.controls; let i = index" [formGroupName]="i" class="border p-3 my-2">
                                <div class="form-group">
                                    <label>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</label>
                                    <input class="form-control" formControlName="date" type="date" />
                                </div>
                                <div class="form-group">
                                    <label>{{ curentLang === "en" ? "Bank" : (curentLang === "ar" ? "البنك" : "בנק") }}</label>
                                    <ng-select formControlName="bank" (change)="getBranches($event, i)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                        <ng-option *ngFor="let item of masterBank" [value]="item?.bank_code">
                                            {{ curentLang === 'en' ? item.bank_name_en : (curentLang === 'ar' ? item.bank_name_ar : item.bank_name_he) }}  / 
                                            {{ item.bank_code }}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group">
                                    <label>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ? "الحساب" : "חשבון") }}</label>
                                    <input class="form-control" formControlName="account" type="number" />
                                </div>
                           <!--     <div class="form-group">
                                    <label>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</label>
                                    <input class="form-control" formControlName="details" type="text" />
                                </div>
                                -->
                                <div class="form-group">
                                    <label>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</label>
                                    <input class="form-control" formControlName="amount" type="number" />
                                </div>
                                <div class="text-center">
                                    <button (click)="removeLineFromBankPayment(i)" class="btn btn-sm m-2" style="background-color: red; color: #fff;">
                                        <i  class="fa fa-trash" style="font-size: 14px; cursor: pointer; color: #fff;" aria-hidden="true"></i>
                                        {{ curentLang === 'en' ? 'Delete' : (curentLang === 'ar' ? '  حذف' : '  מחק') }}
                                    </button>
                                  
                                </div>
                            </div>
                
                             <!-- Total amount for small screens -->
                             <div class="text-center m-3">
                                <input type="number" min="0" readonly [value]="getTotalAmountBankPaymentArray()" class="form-control" formControlName="bank_payment_amount">
                            </div>
                            <!-- Add button for small screens -->
                            <div class="text-center mt-3">
                                <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToBankPayment()">
                                    <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                    {{ curentLang === 'en' ? ' Transfer Bank' : (curentLang === 'ar' ? 'حوالة بنكية' : 'העברה בנקאית') }}
                                </button>
                            </div>
                
                           
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                      <!-- Desktop Table View -->
                      <div class="table-responsive ">
                        <table class="table d-none d-lg-block table-bordered" formArrayName="linesCredit">
                          <!-- Table header -->
                          <thead class="text-black">
                            <tr>
                                <th>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</th>
                                <th>{{ curentLang === "en" ? "Payment Type" : (curentLang === "ar" ? "نوع الدفع" : "שיטת תשלום  ") }}</th>
                                <th>{{ curentLang === "en" ? "Credit Type" : (curentLang === "ar" ? "نوع الأتمان" : "סוג אשראי  ") }}</th>
                                <th>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ? "الحساب" : "חשבון") }}</th>
                            <!--     <th>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</th> -->
                                <th>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</th>
                                <th>{{ curentLang === "en" ? "Remove" : (curentLang === "ar" ? "حذف" : "מחק") }}</th>
                            </tr>
                          </thead>
                          <!-- Table body -->
                          <tbody>
                            <tr *ngFor="let item of linesCredit.controls; let i = index" [formGroupName]="i">
                              <td style="width:20%">
                                <input class="form-control" formControlName="date" type="date" />
                              </td>
                              <td style="width:20%">
                                <select formControlName="credit_card_name" class="form-control form-select" (change)="filterPayment($event)">
                                  <option [value]="1">Normal</option>
                                  <option [value]="2">Payments</option>
                                  <option [value]="3">Credit</option>
                                  <option [value]="4">Deferred Payment</option>
                                  <option [value]="5">Others</option>
                                  <option [value]="0">Not Credit Card</option>
                                </select>
                              </td>
                              <td style="width:20%">
                                <select formControlName="credit_type" class="form-control form-select" (change)="filterCreidt($event, i)">
                                  <option [value]="1">Isracard</option>
                                  <option [value]="2">Cal</option>
                                  <option [value]="3">Diners</option>
                                  <option [value]="4">American Express</option>
                                  <option [value]="6">Leumi Card</option>
                                  <option [value]="0">Not Credit Card</option>
                                </select>
                              </td>
                              <td style="width:20%">
                                <input class="form-control" formControlName="account" type="number" />
                              </td>
                       <!--   <td>
                                <input class="form-control" formControlName="details" type="text" />
                              </td>
                              -->
                              <td style="width:15%">
                                <input class="form-control" formControlName="amount" type="number" />
                              </td>
                              <td style="width:5%">
                                <i (click)="removeLineFromCreidtPayment(i)" class="fa fa-trash" style="font-size: 22px; cursor: pointer; color: red;" aria-hidden="true"></i>
                              </td>
                            </tr>
                          </tbody>
                          <!-- Table footer -->
                          <tfoot>
                            <tr>
                              <td>
                                <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToCredit()">
                                  <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                  {{ curentLang === 'en' ? ' Credit' : (curentLang === 'ar' ? '  بطاقة ائتمان' : 'כרטיס אשראי') }}
                                </button>
                              </td>
                              <td>
                                <input type="number" min="0" readonly [value]="getTotalAmountCreidtArray()" class="form-control" formControlName="credit_amount">
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                  
                      <!-- Mobile-Friendly Layout -->
                      <div class="d-block d-lg-none" formArrayName="linesCredit">
                        <div *ngFor="let item of linesCredit.controls; let i = index" [formGroupName]="i" class="mb-3 border rounded p-2">
                          <div class="form-group">
                            <label>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</label>
                            <input class="form-control" formControlName="date" type="date" />
                          </div>
                  
                          <div class="form-group">
                            <label>{{ curentLang === "en" ? "Payment Type" : (curentLang === "ar" ? "نوع الدفع" : "  שיטת תשלום ") }}</label>
                            <select formControlName="credit_card_name" class="form-control form-select" (change)="filterPayment($event)">
                              <option [value]="1">Normal</option>
                              <option [value]="2">Payments</option>
                              <option [value]="3">Credit</option>
                              <option [value]="4">Deferred Payment</option>
                              <option [value]="5">Others</option>
                              <option [value]="0">Not Credit Card</option>
                            </select>
                          </div>
                  
                          <div class="form-group">
                            <label>{{ curentLang === "en" ? "Credit Type" : (curentLang === "ar" ? "نوع الأتمان" : "  סוג אשראי ") }}</label>
                            <select formControlName="credit_type" class="form-control form-select" (change)="filterCreidt($event, i)">
                              <option [value]="1">Isracard</option>
                              <option [value]="2">Cal</option>
                              <option [value]="3">Diners</option>
                              <option [value]="4">American Express</option>
                              <option [value]="6">Leumi Card</option>
                              <option [value]="0">Not Credit Card</option>
                            </select>
                          </div>
                  
                          <div class="form-group">
                            <label>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ? "الحساب" : "חשבון") }}</label>
                            <input class="form-control" formControlName="account" type="number" />
                          </div>
                  
                    <!--       <div class="form-group">
                            <label>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</label>
                            <input class="form-control" formControlName="details" type="text" />
                          </div>
                          -->
                  
                          <div class="form-group">
                            <label>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</label>
                            <input class="form-control" formControlName="amount" type="number" />
                          </div>
                  
                          <div class="text-center my-3">
                            <button class="btn btn-sm m-2" style="background-color: red; color: #fff;" (click)="removeLineFromCreidtPayment(i)">
                                <i  class="fa fa-trash mx1" style="font-size: 14px; cursor: pointer; color: #fff;" aria-hidden="true"></i>
                                {{ curentLang === 'en' ? 'Delete' : (curentLang === 'ar' ? '  حذف' : '  מחק') }}
                            </button>
                            
                          </div>
                        </div>

                        <div class="text-center m-3">
                            <input type="number" min="0" readonly [value]="getTotalAmountCreidtArray()" class="form-control" formControlName="credit_amount">
                        </div>
                        <!-- Add button for mobile layout -->
                        <div class="text-center">
                        <button type="button" class="btn my-2  " style="background-color: #141414; color: #fff;" (click)="addNewLineToCredit()">
                          <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                          {{ curentLang === 'en' ? 'Add Credit' : (curentLang === 'ar' ? 'إضافة فيزا' : '  הוספת כרטיס באשראי ') }}
                        </button>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                  

                  <div class="row">
                    <div class="col-lg-12">
                        <!-- Table for larger screens -->
                        <div class="table-responsive ">
                            <table class="table d-none d-lg-table table-bordered" formArrayName="linesCash">
                                <!-- Table header -->
                                <thead class="text-black">
                                    <tr>
                                        <th>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</th>
                                        <!--     <th>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</th> -->
                                           <th>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</th>
                                           <th>{{ curentLang === "en" ? "Remove" : (curentLang === "ar" ? "حذف" : "מחק") }}</th>
                                    </tr>
                                </thead>
                                <!-- Table body -->
                                <tbody>
                                    <tr *ngFor="let item of linesCash.controls; let i = index" [formGroupName]="i">
                                        <td style="width:45%">
                                            <input class="form-control" formControlName="date" type="date" />
                                        </td>
                                     <!--     <td>
                                            <input class="form-control" formControlName="details" type="text" />
                                        </td>
                                        -->
                                        <td style="width:45%">
                                            <input class="form-control" formControlName="amount" type="number" />
                                        </td>
                                        <td style="width:10%">
                                            <i (click)="removeLineFromCash(i)" class="fa fa-trash" style="font-size: 22px; cursor: pointer; color: red;" aria-hidden="true"></i>
                                        </td>
                                    </tr>
                                </tbody>
                                <!-- Table footer -->
                                <tfoot>
                                    <tr>
                                        <td >
                                            <!-- Add button to add new line -->
                                            <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToCash()">
                                                <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                {{ curentLang === 'en' ? '  Cash' : (curentLang === 'ar' ? 'نقدي' : 'מזומן ') }}
                                            </button>
                                        </td>
                                   
                                        <td  >
                                            <input type="number" min="0" readonly [value]="getTotalAmountCashArray()" class="form-control" formControlName="cash_amount">
                                        </td>
                                    </tr>
                                </tfoot>
                                
                            </table>
                        </div>
                
                        <!-- Mobile layout for smaller screens -->
                        <div class="d-lg-none" formArrayName="linesCash">
                            <div *ngFor="let item of linesCash.controls; let i = index" [formGroupName]="i" class="border p-3 my-2">
                                <div class="form-group">
                                    <label>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</label>
                                    <input class="form-control" formControlName="date" type="date" />
                                </div>
                                <!--   <div class="form-group">
                                    <label>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</label>
                                    <input class="form-control" formControlName="details" type="text" />
                                </div>
                                -->
                                <div class="form-group">
                                    <label>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</label>
                                    <input class="form-control" formControlName="amount" type="number" />
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-sm m-2" style="background-color: red; color: #fff;" (click)="removeLineFromCash(i)">
                                        <i  class="fa fa-trash" style="font-size: 14px; cursor: pointer; color: #fff;" aria-hidden="true"></i>
                                        {{ curentLang === 'en' ? 'Delete' : (curentLang === 'ar' ? '  حذف' : '  מחק') }}
                                    </button>
                                   
                                </div>
                            </div>
                

                              <!-- Total amount for small screens -->
                              <div class="text-center m-3">
                                <input type="number" min="0" readonly [value]="getTotalAmountCashArray()" class="form-control" formControlName="cash_amount">
                            </div>
                            <!-- Add button for small screens -->
                            <div class="text-center mt-3">
                                <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToCash()">
                                    <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                    {{ curentLang === 'en' ? '  Cash' : (curentLang === 'ar' ? 'نقدي' : 'מזומן ') }}
                                </button>
                            </div>
                
                          
                        </div>
                    </div>
                </div>

                <div class="row  d-flex justify-content-center">
                    <div class="col-md-3"></div>
                   
                    <div class="col-md-3 ">
                      <div class="col-lg-12  p-2">
                        <label for="" style="font-weight: 800;">
                          {{ curentLang === 'en' ? 'Deduction From Source' : curentLang === 'ar' ? ' الخصم من المصدر' : '    ניכוי במקור  ' }}
                        </label>
                        <input type="number" min="0" formControlName="deduction_from_source" class="form-control m-auto w-100">
                      </div>
                    </div>
                   
                    <div class="col-md-3  ">
                     
                      <div class="col-lg-12 p-2">
                        <label for="" style="font-weight: 800;">
                          {{ curentLang === 'en' ? 'Final Amount' : curentLang === 'ar' ? 'القيمة النهائية ' : '    סהכ לתשלום  ' }}
                        </label>
                        <input class="form-control   m-auto w-100" formControlName="total_amount" type="number">
                      </div>
                    </div>
  
                    <div class="col-md-3"></div>
  
                  </div>

                  <div class="row d-flex justify-content-between text-center">

                    <div class="col-4 col-lg-4 p-1">
                        <button class="btn  " style="background-color: #141414; color: #fff;" type="submit" (click)="confirmInvoice()">
                            <i class="fa fa-check mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                            {{ curentLang === 'en' ? 'Confirm ' : (curentLang === 'ar' ? 'تأكيد    ' : ' אישור    ') }}                        </button> 
                    </div>

                  <div class="col-4 col-lg-4 p-1">
                    <button class="btn  " style="background-color: #277d29; color: #fff" type="submit" (click)="saveInvoiceAndReceipt()">
                      <i class="fa fa-save m-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                      {{ curentLang === 'en' ? 'Save ' : (curentLang === 'ar' ? 'حفظ ' : ' שמירה ') }}
                    </button>
                  </div>

                  <div class="col-4 col-lg-4 p-1">
                    <button class="btn  btn-danger" routerLink="/dashboard/invoice-with-receipt">
                      <i class="fa fa-rotate-left mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}

                    </button>
                  </div>
                </div> 
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
