<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 footer-copyright text-center">
      <p class="mb-0">Copyright {{ year }} © THI INVOICES APP  </p>
    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="user_type === 100">
  <div class="row">
<div class="bottom-tab d-md-none"> <!-- فقط تظهر في الشاشات الصغيرة -->
  <div class="bottom-tab-item">
    <a routerLink="/dashboard/merchant-info">
      <i class="fa fa-user-o" style="font-size: 20px; "></i>
      <div style="color: #fff">{{ curentLang === 'en' ? 'Home' : (curentLang === 'ar' ? ' الرئيسية' : ' מסך ראשי ')}}</div>
    </a>
  </div>
  <div class="bottom-tab-item">
    <a routerLink="/dashboard/customers">
      <i class="icofont icofont-users" style="font-size: 20px; "></i>
      <div style="color: #fff">{{ curentLang === 'en' ? 'Customers' : (curentLang === 'ar' ? 'العملاء' : 'לקוחות')}}</div>
    </a>
  </div>

  <div class="bottom-tab-item">
    <a routerLink="/dashboard/accountants">
      <i class="icofont icofont-users" style="font-size: 20px; "></i>
      <div style="color: #fff">{{ curentLang === 'en' ? 'Accountants' : (curentLang === 'ar' ? ' المحاسبين' : ' מנהלי חשבוניות')}}</div>
    </a>
  </div>

  <div class="bottom-tab-item">
    <a routerLink="/dashboard/products">
      <i class="fa fa-shopping-cart" style="font-size: 20px;"></i>
      <div style="color: #fff">{{ curentLang === 'en' ? 'Products' : (curentLang === 'ar' ? 'المنتجات' : 'מוצרים')}}</div>
    </a>
  </div>

</div>

  </div>
</div>



 
