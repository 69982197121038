import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
 import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { map } from 'rxjs/operators';
import { HttpHelpService } from "src/app/Data/service/http-help.service";
@Component({
  selector: "app-history-details",
  templateUrl: "./history-details.component.html",
  styleUrls: ["./history-details.component.scss"]
})

export class HistoryDetailsComponent implements OnInit {
  
  curentLang: string = "";
  layoutVersion: any;
  userRegistId: any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term: string = "";
  p: any;
  TransactionsData: any[] = [];
  filteredTransactionsData: any[] = [];
  id:number

  constructor(
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private service: HttpHelpService,
    private modalService: NgbModal,
    private activatedroute:ActivatedRoute
  ) {
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.id = this.activatedroute.snapshot.params['id'];
    
  }

  getAllResponseLogsByLogId() {
    this.spinner.show();
    this.service.getAllResponseLogsByLogId(this.id).subscribe({
      next: res => {
        this.TransactionsData = res;
        this.filterTransactions(); // Apply filter after data is loaded
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  filterTransactions() {
    if (this.term) {
      this.filteredTransactionsData = this.TransactionsData.filter(transaction =>
        transaction.createdBy.user_name.toLowerCase().includes(this.term.toLowerCase()) ||
        transaction.company.company_name_en.toLowerCase().includes(this.term.toLowerCase()) ||
        transaction.company.merchant.merchant_name_en.toLowerCase().includes(this.term.toLowerCase()) ||
        transaction.Type_of_operation.toLowerCase().includes(this.term.toLowerCase()) ||
        transaction.success_count.toString().includes(this.term) ||
        transaction.failure_count.toString().includes(this.term) ||
        transaction.createdDate.toLowerCase().includes(this.term.toLowerCase())
      );
    } else {
      this.filteredTransactionsData = [...this.TransactionsData];
    }
  }

  ngOnInit() {
    this.getAllResponseLogsByLogId();
    this.layoutVersion = localStorage.getItem("layout_version");
    if (this.layoutVersion == null || this.layoutVersion == undefined) {
      localStorage.setItem("layout_version", "light-only");
      this.layoutVersion = "light-only";
    } else {
      this.layoutVersion = localStorage.getItem("layout_version");
      if (this.layoutVersion === "dark-only") {
        document.body.classList.add("dark-only");
      } else {
        document.body.classList.add("light-only");
      }
    }
    this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
  }
}
