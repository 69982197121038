<app-breadcrumb [title]="curentLang === 'en' ? 'Payment Method Info' : (curentLang === 'ar' ? ' معلومات طرق الدفع   ' : '   אמצעי תשלום   ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['   לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Payment Method Info' : (curentLang === 'ar' ? '    معلومات طرق الدفع    ' : '   אמצעי תשלום ')">
</app-breadcrumb>

<!--payment method info -->

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Payment Method Info' : (curentLang === 'ar' ? 'معلومات طرق الدفع ' : '  אמצעי תשלום  ')}} 
        </h5>

       <div>
        <button  class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
            {{ curentLang === 'en' ? 'Add Payment Method ' : (curentLang === 'ar' ? ' اضافة طريقة دفع   ' : ' הוסף אמצעי תשלום ')}} 
        </button>

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllPaymentMethod()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Payment Method Name En' : (curentLang === 'ar' ? '  أسم طريقة الدفع عربي  ' : 'שם אמצעי התשלום הוא ערבית ')}} </th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of paymentMethodData | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td> 
                    {{curentLang === 'en' ? item.payment_method_name_en.substring(0, 20) : (curentLang === 'ar' ? item.payment_method_name_ar.substring(0, 20) : item.payment_method_name_he.substring(0, 20))}}
                  </td>

                   <td>{{ item.createdDate | date: 'dd/MM/yyyy'}}</td>



                  <td >
                  <button  class=" btn" style="border: none;"
                   (click)="executeFunctions(  item?.payment_method_id,updatedData ) ">
                    <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                   </i>
                  </button>
                   </td>

                   <td >
                    <button  
                    [ngStyle]="{ 'pointer-events': item.bank_branch_status == 1 ? 'none' : 'auto', 'color': item.bank_branch_status == 1 ? 'gray' : 'red' }" 
                    class="btn" 
                    style="border: none;" 
                    (click)="getId(item?.payment_method_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                      </button>
                     </td>

                     

                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>


<!--add payment method  -->

<ng-template #content let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Add Payment Method ' : (curentLang === 'ar' ? ' اضافة طريقة دفع   ' : ' הוסף אמצעי תשלום ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="addPaymentMethod">
                        <div class="row">
                            <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Payment Method Name En' : (curentLang === 'ar' ? '  أسم طريقة الدفع انجليزي   ' : ' שם אמצעי התשלום באנגלית  ')}}
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="200" 
                                 type="text" formControlName="payment_method_name_en" 
                                  />
                              </div>
              
                              <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Payment Method Name Ar' : (curentLang === 'ar' ? '   أسم طريقة الدفع عربي   ' : ' שם אמצעי התשלום הוא ערבית')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="200"
                                    formControlName="payment_method_name_ar"
                                   />
                                </div>
              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Payment Method Name He' : (curentLang === 'ar' ? '   أسم طريقة الدفع عبري   ' : ' שם אמצעי התשלום הוא עברי ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="payment_method_name_he" 
                                   maxlength="200"
                                  />
                                   
                                </div>
              
                             

                    
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button" [disabled]="!addPaymentMethod.valid"
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="addedData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form> 
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--update payment method -->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
              {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : '    ערוך מידע ')}} 
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
      <div class="modal-body">
          <div class="container-fluid ">
              <div class="edit-profile">
                <div class="row d-flex justify-content-between">
                      
                    <form  [formGroup]="updatePaymentMethod">
                        <div class="row">
                            <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Payment Method Name En' : (curentLang === 'ar' ? '  أسم طريقة الدفع انجليزي   ' : ' שם אמצעי התשלום באנגלית  ')}}
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="200" 
                                 type="text" formControlName="payment_method_name_en" 
                                  />
                              </div>
              
                              <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Payment Method Name Ar' : (curentLang === 'ar' ? '   أسم طريقة الدفع عربي   ' : ' שם אמצעי התשלום הוא ערבית')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="200"
                                    formControlName="payment_method_name_ar"
                                   />
                                </div>
              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Payment Method Name He' : (curentLang === 'ar' ? '   أسم طريقة الدفع عبري   ' : ' שם אמצעי התשלום הוא עברי ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="payment_method_name_he" 
                                   maxlength="200"
                                  />
                                   
                                </div>
              
                             

                    
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button" [disabled]="!updatePaymentMethod.valid"
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updatePaymentMethodById()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form> 
                   
                 
              
                
              </div>
              
            </div>
          
          </div>
      </div>
     
  </ng-template> 

   <!--Delete payment method  -->

   <ng-template #dataDeleted let-modal>
    <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close btn-close"  style="color: grey;"  aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
          {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : ' هل تريد بالتأكيد حذفه؟') }}
        </p>
    </div>
    <div class="modal-footer">

      <button type="button" 
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="deletePaymentMethodById()"
       > 
        {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>
</ng-template>