import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from "../service/global.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  readonly VAPID_PUBLIC_KEY = 'BGNH8e-EiV9V7B9jjZuV2askzGselY6-QMPiYP_s814JAff5sydmi6aB6Ce6RdtrAJhdGwDd03ngKZ0QlkckvXI';

  constructor(private swPush: SwPush, private http: HttpClient) {}

  // دالة للتحقق من الإذن وطلبه إذا لم يكن مفعلًا
  async checkPermissionAndRequest(userId: number) {
    if (Notification.permission === 'granted') {
       this.checkAndUpdateSubscription(userId);
    } else if (Notification.permission === 'default') {
       const permission = await Notification.requestPermission();
      if (permission === 'granted') {
         this.checkAndUpdateSubscription(userId);
      } else {
       }
    } else {
       alert('Please enable notifications in your browser settings to receive updates.');
    }
  }

  // دالة لطلب الاشتراك في الإشعارات أو التحقق من الاشتراك الحالي
  async checkAndUpdateSubscription(userId: number) {
    this.swPush.subscription.subscribe(async subscription => {
      if (subscription) {
 
        // جلب الاشتراكات المخزنة للمستخدم من الباك إند
        const storedSubscriptions = await this.getStoredSubscriptionFromBackend(userId);

        // التحقق مما إذا كان الاشتراك الحالي موجودًا بالفعل
        const exists = storedSubscriptions?.some(
          (sub: any) => JSON.stringify(sub) === JSON.stringify(subscription)
        );

        if (!exists) {
           this.sendSubscriptionToBackend(userId, subscription);
        } else {
         }
      } else {
         this.requestSubscription(userId);
      }
    });
  }

  // دالة لطلب الاشتراك وطلب إذن الإشعارات
  requestSubscription(userId: number) {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })
    .then(subscription => {
       this.sendSubscriptionToBackend(userId, subscription);
    })
    .catch(err => console.error("Could not subscribe to notifications", err));
  }

  // دالة لجلب الاشتراكات المخزنة للمستخدم من الباك إند
  getStoredSubscriptionFromBackend(userId: number): Promise<PushSubscription[] | null> {
    return this.http.get<PushSubscription[] | null>(`${baseUrl}users/${userId}/subscriptions`)
      .toPromise()
      .catch(err => {
        console.error('Error fetching stored subscriptions:', err);
        return null;
      });
  }

  // دالة لإرسال بيانات الاشتراك إلى الباك إند
  sendSubscriptionToBackend(userId: number, subscription: PushSubscription) {
    this.http.post(`${baseUrl}users/${userId}/subscribe`, subscription).subscribe(
      () => console.log(''),
      error => console.error('Error saving subscription:', error)
    );
  }
}
