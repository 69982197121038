import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-more-info-company",
  templateUrl: "./more-info-company.component.html",
  styleUrls: ["./more-info-company.component.scss"]
})

export class MoreInfoCompanyComponent implements OnInit {
  @Input() isInsideModal: boolean = false;

  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  selectedOffice:any;

  term:any;
  p: any;
  companyData:any=[];
 

  merchantInfoData:any=[];
  merchantInfoCompanyData:any=[];
  vatInfoData:any=[];
  currentPage: number = 1;
  id: any ;
  CompanyId:any;
  MerchantID:any;
  redirectUrl:any;
  MerchantPhone:any;
   
  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
    private toastr: ToastrService ,
    activatedRoute:ActivatedRoute,

  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.id = activatedRoute.snapshot.params['id'];

  };


  getActivityInCompany() {
    this.spinner.show();
    this.service.getActivityInCompany(this.id).subscribe({
      next: res => {
        this.companyData = res;
  
        // تنسيق رقم الهاتف باستخدام formatPhoneNumber
        const rawPhone = this.companyData?.companyInfo?.merchant?.users?.[0]?.phone_number;
        this.MerchantPhone = this.formatPhoneNumber(rawPhone);
  
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }

  formatPhoneNumber(phoneNumber: string): string {
    // Remove any non-digit characters except '+'
    const cleaned = phoneNumber.replace(/[^ \d]/g, '');
  
    // Ensure the number ends with '+'
    if (!cleaned.endsWith('+')) {
      return `${cleaned}+`;
    }
  
    return cleaned;
  };

  ngOnInit()
   {
    this.getActivityInCompany();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   } else {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only") {
       document.body.classList.add("dark-only");
     } else {
       document.body.classList.add("light-only");

     }
   }
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));

  };
  }

