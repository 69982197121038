<app-breadcrumb [title]="curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? ' معلومات الشركة  ' : '  פרטי חברה  ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : [' הגדרות   '])" 
[active_item]="curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? ' معلومات الشركة  ' : '  פרטי חברה  ')">
</app-breadcrumb>


<div class="container-fluid ">
    <div class="edit-profile">
      <div class="row">
        <div class="col-xl-4 ">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0" style="color:  grey;"> 
                {{curentLang === 'en' ? 'Company Logo' : (curentLang === 'ar' ? ' لوجو الشركة  ' : '  לוגו החברה    ')}}
            </h5>
              <div class="card-options">
                <a class="card-options-collapse" href="javascript:void(0)" data-bs-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="javascript:void(0)" data-bs-toggle="card-remove"><i class="fe fe-x"></i></a>
              </div>
            </div>
            <div class="card-body">
              <form >
                <div class="row mb-2">
                  <div class="col-auto icon-container position-relative" style="background-color: #E9E9E9;">
                    <img class="rounded" style="width: 220px; height: 220px;" alt="company logo"
                         [src]="companyInfoData.logo_url ? companyInfoData.logo_url == null || companyInfoData.logo_url == ''  : 'assets/default-logo/WhatsApp Image 2024-07-09 at 21.30.21_fc2afcbb.jpg'" />
                    <i (click)="executeFunctions(companyInfoData?.company_id, uploadData);" *ngIf="user_type == 100"
                       class="fa fa-camera position-absolute"></i>
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
       
        <div class="col-xl-8 " *ngIf="user_type == 100  ||  user_type == 101">
          <div class="card ">
              <div class="card-header mx-4">
                  <h5 style="color: grey;"> 
                    {{curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? ' معلومات الشركة  ' : '   פרטי חברה   ')}}
                  </h5>
                </div>
              <div class="card-body">
           <div class="row">
             

            
            <div class="col-xxl-6 col-sm-6 box-col-6 " >
              <div class="card small-widget">
                 <div class="card-body {{ newOrders.colorClass }}" >
                  <div class="d-flex align-items-end gap-1">
                    <div class="mb-3 w-75">
                      <label class="form-label" style="color: grey;"> 
                        {{ curentLang === 'en' ? 'Company Name Ar' : (curentLang === 'ar' ? '  اسم الشركة عربي   ' : '  שם בערבית ')}}
                     </label>
                      <input style="color: grey;" class="form-control" 
                      type="text"  [(ngModel)]="companyNameAr"
                      readonly/>
                    </div>

                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div> 
              </div>
            </div>
            <!---->

            <div class="col-xxl-6 col-sm-6 box-col-6 " >
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}" >
                  <div class="d-flex align-items-end gap-1">
                    <div class="mb-3 w-75">
                      <label class="form-label" style="color: grey;"> 
                        {{ curentLang === 'en' ? 'Company Name En' : (curentLang === 'ar' ? '  اسم الشركة انجليزي   ' : 'שם באנגלית')}}
                     </label>
                      <input style="color: grey;" class="form-control" type="text"  [(ngModel)]="companyNameEn"
                      readonly/>
                    </div>

                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div> 
              </div>
            </div>
            <!---->

            <div class="col-xxl-6 col-sm-6 box-col-6 " >
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}" >
                  <div class="d-flex align-items-end gap-1">
                    <div class="mb-3 w-75">
                      <label class="form-label" style="color: grey;"> 
                        {{ curentLang === 'en' ? 'Company Name He ' : (curentLang === 'ar' ? '  اسم الشركة عبري    ' : 'שם החברה הוא עברי ')}}       
                     </label> 
                      <input style="color: grey;" class="form-control" type="text"  [(ngModel)]="companyNamehE"
                      readonly/>
                    </div>

                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

                  <!---->
                  <div class="col-xxl-6 col-sm-6 box-col-6 " >
                    <div class="card small-widget">
                      <div class="card-body {{ newOrders.colorClass }}" >
                        <div class="d-flex align-items-end gap-1">
                          <div class="mb-3 w-75">
                            <label class="form-label" style="color: grey;"> 
                              {{ curentLang === 'en' ? 'Company Phone' : (curentLang === 'ar' ? ' هاتف الشركة ' : 'טלפון של החברה')}}
                            </label> 
                            <input style="color: grey;" class="form-control" type="text"  [(ngModel)]="companyPhone" readonly/>
                          </div>
    
                        </div>
                        <div class="bg-gradient">
                          <svg class="stroke-icon svg-fill">
                            <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                      <!---->
         
                      <div class="col-xxl-6 col-sm-6 box-col-6 " >
                        <div class="card small-widget">
                          <div class="card-body {{ newOrders.colorClass }}" >
                            <div class="d-flex align-items-end gap-1">
                              <div class="mb-3 w-75">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company City He' : (curentLang === 'ar' ? ' مدينة الشركة عبري' : 'עיר חברה: איברי')}}
                                </label> 
                                <input style="color: grey;" class="form-control " type="text" readonly   [(ngModel)]="CompanyAddressCity"/>
                              </div>
        
                            </div>
                            <div class="bg-gradient">
                              <svg class="stroke-icon svg-fill">
                                <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!---->

                      <div class="col-xxl-6 col-sm-6 box-col-6 " >
                        <div class="card small-widget">
                          <div class="card-body {{ newOrders.colorClass }}" >
                            <div class="d-flex align-items-end gap-1">
                              <div class="mb-3 w-75">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company City Ar' : (curentLang === 'ar' ? ' مدينة الشركة عربي' : 'חברת עיר ערבית')}}
                                </label> 
                                <input style="color: grey;" class="form-control " type="text" readonly   [(ngModel)]="CompanyAddressCityAr"/>
                              </div>
        
                            </div>
                            <div class="bg-gradient">
                              <svg class="stroke-icon svg-fill">
                                <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!---->

                      <div class="col-xxl-6 col-sm-6 box-col-6 " >
                        <div class="card small-widget">
                          <div class="card-body {{ newOrders.colorClass }}" >
                            <div class="d-flex align-items-end gap-1">
                              <div class="mb-3 w-75">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company City En' : (curentLang === 'ar' ? ' مدينة الشركة انجليزي' : 'חברת עיר אנגלית')}}
                                </label> 
                                <input style="color: grey;" class="form-control " type="text" readonly   [(ngModel)]="CompanyAddressCityEn"/>
                              </div>
        
                            </div>
                            <div class="bg-gradient">
                              <svg class="stroke-icon svg-fill">
                                <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!---->

                      <div class="col-xxl-6 col-sm-4 box-col-4 " >
                        <div class="card small-widget">
                          <div class="card-body {{ newOrders.colorClass }}" >
                            <div class="d-flex align-items-end gap-1">
                              <div class="mb-3 w-75">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Street Name He' : (curentLang === 'ar' ? '  اسم شارع الشركة عبري' : 'שם הרחוב של החברה הוא עברי')}}
                                </label> 
                                <input style="color: grey;" class="form-control " type="text"   readonly  [(ngModel)]="CompanyAddressSTR"/>
                              </div>
        
                            </div>
                            <div class="bg-gradient">
                              <svg class="stroke-icon svg-fill">
                                <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!---->
                      <div class="col-xxl-6 col-sm-4 box-col-4 " >
                        <div class="card small-widget">
                          <div class="card-body {{ newOrders.colorClass }}" >
                            <div class="d-flex align-items-end gap-1">
                              <div class="mb-3 w-75">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Street Name Ar' : (curentLang === 'ar' ? '  اسم شارع الشركة عربي' : 'שם רחוב של החברה בערבית')}}
                                </label> 
                                <input style="color: grey;" class="form-control " type="text"  readonly  [(ngModel)]="CompanyAddressSTRAr"/>
                              </div>
        
                            </div>
                            <div class="bg-gradient">
                              <svg class="stroke-icon svg-fill">
                                <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!---->
                      <div class="col-xxl-6 col-sm-4 box-col-4 " >
                        <div class="card small-widget">
                          <div class="card-body {{ newOrders.colorClass }}" >
                            <div class="d-flex align-items-end gap-1">
                              <div class="mb-3 w-75">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Street Name En' : (curentLang === 'ar' ? '  اسم شارع الشركة انجليزي' : 'שם רחוב של החברה באנגלית')}}
                                </label> 
                                <input style="color: grey;" class="form-control " type="text"  readonly   [(ngModel)]="CompanyAddressSTREn"/>
                              </div>
        
                            </div>
                            <div class="bg-gradient">
                              <svg class="stroke-icon svg-fill">
                                <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!---->

                      <div class="col-xxl-6 col-sm-4 box-col-4 " >
                        <div class="card small-widget">
                          <div class="card-body {{ newOrders.colorClass }}" >
                            <div class="d-flex align-items-end gap-1">
                              <div class="mb-3 w-75">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Building Number' : (curentLang === 'ar' ? '  رقم مبني الشركة' : 'מס בניין')}}
                               </label> 
                                <input style="color: grey;" class="form-control " type="number" readonly   [(ngModel)]="CompanyBuildingNumber"/>
                              </div>
        
                            </div>
                            <div class="bg-gradient">
                              <svg class="stroke-icon svg-fill">
                                <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!---->
                      <div class="col-xxl-6 col-sm-4 box-col-4 " >
                        <div class="card small-widget">
                          <div class="card-body {{ newOrders.colorClass }}" >
                            <div class="d-flex align-items-end gap-1">
                              <div class="mb-3 w-75">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Post Number' : (curentLang === 'ar' ? ' الرقم البريدي     ' : '        מספר דירה    ')}}
                               </label> 
                                <input style="color: grey;" class="form-control " type="text" readonly   [(ngModel)]="CompanyAddressZipCode"/>
                              </div>
        
                            </div>
                            <div class="bg-gradient">
                              <svg class="stroke-icon svg-fill">
                                <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>



                         <!--back-->
        <div class=" d-flex justify-content-between"> 

          <!-- <button type="button" [disabled]="!updateCompanyInfo.valid"
          class="btn   m-2" 
         style="background-color: #141414; color: #fff;"
          (click)="updateCompanyById()"
          > 
           {{ curentLang === 'en' ? 'Update' : (curentLang === 'ar' ? '     تحديث ' : '     עדכן  ') }}
           <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
       </button> -->

          <a routerLink="/dashboard/companies">
            <button class="btn btn-danger m-2 " >
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
            </button>
          </a>

        </div>

           
          </div>
           </div>
        </div>
     

    </div>


    <div class="col-xl-8 "  *ngIf="user_type == 200 || user_type == 201">
      <div class="card ">
          <div class="card-header mx-4">
              <h5 style="color: grey;"> 
                {{curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? ' معلومات الشركة  ' : '  מידע על החברה   ')}}
              </h5>
            </div>
          <div class="card-body">
       <div class="row">

        <div class="col-xxl-6 col-sm-12 box-col-6 " >
          <div class="card small-widget">
             <div class="card-body {{ newOrders.colorClass }}" >
              <div class="d-flex align-items-end gap-1">
                <div class="mb-3 w-75">
                  <label class="form-label" style="color: grey;"> 
                    {{ curentLang === 'en' ? 'Company Name Ar' : (curentLang === 'ar' ? '  اسم الشركة عربي   ' : '  שמו של הסוחר הוא ערבי  ')}}
                 </label>
                  <input style="color: grey;" class="form-control" 
                  type="text"  [(ngModel)]="companyNameAr"
                  readonly/>
                </div>

              </div>
              <div class="bg-gradient">
                <svg class="stroke-icon svg-fill">
                  <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                </svg>
              </div>
            </div> 
          </div>
        </div>
        <!---->

        <div class="col-xxl-6 col-sm-12 box-col-6 " >
          <div class="card small-widget">
            <div class="card-body {{ newOrders.colorClass }}" >
              <div class="d-flex align-items-end gap-1">
                <div class="mb-3 w-75">
                  <label class="form-label" style="color: grey;"> 
                    {{ curentLang === 'en' ? 'Company Name En' : (curentLang === 'ar' ? '  اسم الشركة انجليزي   ' : ' שם החברה באנגלית')}}
                 </label>
                  <input style="color: grey;" class="form-control" type="text"  [(ngModel)]="companyNameEn"
                 readonly/>
                </div>

              </div>
              <div class="bg-gradient">
                <svg class="stroke-icon svg-fill">
                  <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                </svg>
              </div>
            </div> 
          </div>
        </div>
        <!---->

        <div class="col-xxl-6 col-sm-12 box-col-6 " >
          <div class="card small-widget">
            <div class="card-body {{ newOrders.colorClass }}" >
              <div class="d-flex align-items-end gap-1">
                <div class="mb-3 w-75">
                  <label class="form-label" style="color: grey;"> 
                    {{ curentLang === 'en' ? 'Company Name He ' : (curentLang === 'ar' ? '  اسم الشركة عبري    ' : 'שם החברה הוא עברי ')}}       
                 </label> 
                  <input style="color: grey;" class="form-control" type="text"  [(ngModel)]="companyNamehE"
                  readonly/>
                </div>

              </div>
              <div class="bg-gradient">
                <svg class="stroke-icon svg-fill">
                  <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>

                  <!---->
     
                  <div class="col-xxl-6 col-sm-12 box-col-6 " >
                    <div class="card small-widget">
                      <div class="card-body {{ newOrders.colorClass }}" >
                        <div class="d-flex align-items-end gap-1">
                          <div class="mb-3 w-75">
                            <label class="form-label" style="color: grey;"> 
                              {{ curentLang === 'en' ? 'Company City' : (curentLang === 'ar' ? ' مدينة الشركة' : '  עיר ')}}
                           </label> 
                            <input style="color: grey;" class="form-control " type="text" readonly   [(ngModel)]="CompanyAddressCity"/>
                          </div>
    
                        </div>
                        <div class="bg-gradient">
                          <svg class="stroke-icon svg-fill">
                            <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!---->

                  <div class="col-xxl-6 col-sm-12 box-col-4 " >
                    <div class="card small-widget">
                      <div class="card-body {{ newOrders.colorClass }}" >
                        <div class="d-flex align-items-end gap-1">
                          <div class="mb-3 w-75">
                            <label class="form-label" style="color: grey;"> 
                              {{ curentLang === 'en' ? 'Company Street Name' : (curentLang === 'ar' ? '  اسم شارع الشركة' : 'שם רחוב')}}
                           </label> 
                            <input style="color: grey;" class="form-control " type="text" readonly  [(ngModel)]="CompanyAddressSTR"/>
                          </div>
    
                        </div>
                        <div class="bg-gradient">
                          <svg class="stroke-icon svg-fill">
                            <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!---->

                  <div class="col-xxl-6 col-sm-12 box-col-4 " >
                    <div class="card small-widget">
                      <div class="card-body {{ newOrders.colorClass }}" >
                        <div class="d-flex align-items-end gap-1">
                          <div class="mb-3 w-75">
                            <label class="form-label" style="color: grey;"> 
                              {{ curentLang === 'en' ? 'Company Building Number' : (curentLang === 'ar' ? '  رقم مبني الشركة' : 'מס בניין')}}
                           </label> 
                            <input style="color: grey;" class="form-control " min="0" type="number" readonly   [(ngModel)]="CompanyBuildingNumber"/>
                          </div>
    
                        </div>
                        <div class="bg-gradient">
                          <svg class="stroke-icon svg-fill">
                            <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!---->
                  <div class="col-xxl-6 col-sm-12 box-col-4 " >
                    <div class="card small-widget">
                      <div class="card-body {{ newOrders.colorClass }}" >
                        <div class="d-flex align-items-end gap-1">
                          <div class="mb-3 w-75">
                            <label class="form-label" style="color: grey;"> 
                              {{ curentLang === 'en' ? ' Post Number' : (curentLang === 'ar' ? ' الرقم البريدي  ' : ' מיקוד דואר ')}}
                           </label> 
                            <input style="color: grey;" class="form-control " type="number" min="0" readonly  [(ngModel)]="CompanyAddressZipCode"/>
                          </div>
    
                        </div>
                        <div class="bg-gradient">
                          <svg class="stroke-icon svg-fill">
                            <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
         
          <div class="col-xxl-6 col-sm-12 box-col-6 " >
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}" >
                  <div class="d-flex align-items-end gap-1">
                    <div class="mb-3 w-75">
                      <label class="form-label" style="color: grey;"> 
                        {{ curentLang === 'en' ? 'Tax Register' : (curentLang === 'ar' ? '  رقم السجل الضريبي  ' : 'מס עוסק מורשה')}}
                     </label> 
                      <input style="color: grey;" class="form-control" type="number" min="0"   [(ngModel)]="TaxRegister"
                      readonly/>
                    </div>

                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
                 <!---->
         
                 <div class="col-xxl-6 col-sm-6 box-col-6 " >
                  <div class="card small-widget">
                    <div class="card-body {{ newOrders.colorClass }}" >
                      <div class="d-flex align-items-end gap-1">
                        <div class="mb-3 w-75">
                          <label class="form-label" style="color: grey;"> 
                            {{ curentLang === 'en' ? 'Commercial Register' : (curentLang === 'ar' ? '  رقم السجل التجاري  ' : 'מס עוסק מורשה')}}
                         </label> 
                          <input style="color: grey;" class="form-control" type="number" min="0"   [(ngModel)]="CommercialRegister"
                          readonly/>
                        </div>
  
                      </div>
                      <div class="bg-gradient">
                        <svg class="stroke-icon svg-fill">
                          <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                     <!--back-->
    <div class=" d-flex justify-content-between"> 

      <!-- <button type="button" [disabled]="!updateCompanyInfoByAdmin.valid"
      class="btn   m-2" 
     style="background-color: #141414; color: #fff;"
      (click)="updateCompanyByAdminByCompanyId()"
      > 
       {{ curentLang === 'en' ? 'Update' : (curentLang === 'ar' ? '     تحديث ' : '     עדכן  ') }}
       <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
   </button> -->

      <a routerLink="/dashboard/companies">
        <button class="btn btn-danger m-2 " >
          {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : 'חזרה') }}
          <i class="fa fa-rotate-left mx-1"></i>
        </button>
      </a>

    </div>

       
      </div>
  
      </div>
    </div>
 

</div>
      
    </div>
    
  </div>

</div>



