<app-breadcrumb [title]="curentLang === 'en' ? 'Office Info' : (curentLang === 'ar' ? ' معلومات المكتب  ' : '   מידע משרדי ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Office Info' : (curentLang === 'ar' ? ' معلومات المكتب  ' : '   מידע משרדי')">
</app-breadcrumb>

<!--office info -->

<div class="container-fluid ">
    <div class="edit-profile">
      <div class="row">
        <!-- <div class="col-xl-4 ">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0" style="color:  grey;"> 
                {{curentLang === 'en' ? 'Company Logo' : (curentLang === 'ar' ? ' لوجو الشركة  ' : '  לוגו חברה  ')}}
            </h5>
              <div class="card-options">
                <a class="card-options-collapse" href="javascript:void(0)" data-bs-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="javascript:void(0)" data-bs-toggle="card-remove"><i class="fe fe-x"></i></a>
              </div>
            </div>
            <div class="card-body">
              <form >
                <div class="row mb-2">
                  <div class="col-auto icon-container position-relative" style="background-color: #E9E9E9;">
                    <img class="rounded" style="width: 220px;height: 220px;" alt="company logo" [src]="companyInfoData.logo_url" />
                    <i (click)="executeFunctions(companyInfoData?.company_id, uploadData);"  *ngIf="user_type == 100"
				class="fa fa-camera position-absolute"></i> 
                </div>
                </div>
              </form>
            </div>
          </div>
        </div> -->
       
        <div class="col-xl-12" *ngIf="user_type == 300">
            <div class="card ">
                <div class="card-header mx-4">
                    <h5 style="color: grey;"> 
                      {{curentLang === 'en' ? 'Office Info' : (curentLang === 'ar' ? ' معلومات المكتب  ' : '  מידע משרדי  ')}}
                    </h5>
                  </div>
                <div class="card-body">
                    <form  [formGroup]="updateOfficeInfo">
            <div class="row">
               

              
              <div class="col-xxl-6 col-sm-12 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <div class="d-flex align-items-end gap-1">
                      <div class="mb-3 w-75">
                        <label class="form-label" style="color: grey;"> 
                          {{ curentLang === 'en' ? 'Office Name  ' : (curentLang === 'ar' ? '  اسم المكتب ' : ' שם המשרד ')}}
                       </label>
                        <input style="color: grey;" class="form-control" 
                        type="text"  [(ngModel)]="OfficeName"
                        formControlName="office_name"/>
                      </div>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <!---->

              <div class="col-xxl-6 col-sm-12 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <div class="d-flex align-items-end gap-1">
                      <div class="mb-3 w-75">
                        <label class="form-label" style="color: grey;"> 
                          {{ curentLang === 'en' ? 'Office Address  ' : (curentLang === 'ar' ? '  عنوان المكتب     ' : '   כתובת משרד   ')}}
                       </label>
                        <input style="color: grey;" class="form-control" type="text"  [(ngModel)]="OfficeAddress"
                        formControlName="office_address"/>
                      </div>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <!---->

              <div class="col-xxl-6 col-sm-12 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <div class="d-flex align-items-end gap-1">
                      <div class="mb-3 w-75">
                        <label class="form-label" style="color: grey;"> 
                          {{ curentLang === 'en' ? 'Office Phone' : (curentLang === 'ar' ? '  هاتف المكتب     ' : '    טלפון במשרד       ')}}
                       </label> 
                        <input style="color: grey;" class="form-control" type="text"  [(ngModel)]="OfficePhone"
                        formControlName="office_phone"/>
                      </div>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

                        <!---->

                           

                            <div class="col-xxl-6 col-sm-12 box-col-6 " >
                                <div class="card small-widget">
                                  <div class="card-body {{ newOrders.colorClass }}" >
                                    <div class="d-flex align-items-end gap-1">
                                      <div class="mb-3 w-75">
                                        <label class="form-label" style="color: grey;"> 
                                          {{ curentLang === 'en' ? '  Tax Number' : (curentLang === 'ar' ? '     الرقم الضريبي    ' : '     מס תעודת זהות    ')}}
                                       </label> 
                                        <input style="color: grey;" class="form-control " readonly type="text" formControlName ="tax_number"   [(ngModel)]="TaxNumber"/>
                                      </div>
                
                                    </div>
                                    <div class="bg-gradient">
                                      <svg class="stroke-icon svg-fill">
                                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                           <!---->
           
                        <div class="col-xxl-6 col-sm-12 box-col-6 " >
                          <div class="card small-widget">
                            <div class="card-body {{ newOrders.colorClass }}" >
                              <div class="d-flex align-items-end gap-1">
                                <div class="mb-3 w-75">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Email' : (curentLang === 'ar' ? '     البريد الالكتروني  ' : '      דואר אלקטרוני     ')}}
                                 </label> 
                                  <input style="color: grey;" class="form-control " type="email" formControlName ="email"   [(ngModel)]="Email"/>
                                </div>
          
                              </div>
                              <div class="bg-gradient">
                                <svg class="stroke-icon svg-fill">
                                  <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                    
                        
                           <!--back-->
          <div class=" d-flex justify-content-between"> 

            <button type="button" [disabled]="!updateOfficeInfo.valid"
            class="btn   m-2" 
           style="background-color: #141414; color: #fff;"
            (click)="updateOfficeById()"
            > 
             {{ curentLang === 'en' ? 'Update' : (curentLang === 'ar' ? '     تحديث ' : '     עדכן  ') }}
             <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
         </button>

            <!-- <a routerLink="/dashboard/companies">
              <button class="btn btn-danger m-2 " >
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                <i class="fa fa-rotate-left mx-1"></i>
              </button>
            </a> -->

          </div>

             
            </div>
        </form>
            </div>
          </div>
       

      </div>


      <div class="col-xl-8 "  *ngIf="user_type == 200">
        <div class="card ">
            <div class="card-header mx-4">
                <h5 style="color: grey;"> 
                  {{curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? ' معلومات الشركة  ' : '  מידע על החברה   ')}}
                </h5>
              </div>
            <div class="card-body">
                <form  [formGroup]="updateCompanyInfoByAdmin">
        <div class="row">
           
            <div class="col-xxl-6 col-sm-6 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <div class="d-flex align-items-end gap-1">
                      <div class="mb-3 w-75">
                        <label class="form-label" style="color: grey;"> 
                          {{ curentLang === 'en' ? 'Tax Register' : (curentLang === 'ar' ? '  رقم السجل الضريبي  ' : '  מספר רישום מס ')}}
                       </label> 
                        <input style="color: grey;" class="form-control" type="number"  [(ngModel)]="TaxRegister"
                        formControlName="tax_register"/>
                      </div>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
                   <!---->
           
                   <div class="col-xxl-6 col-sm-6 box-col-6 " >
                    <div class="card small-widget">
                      <div class="card-body {{ newOrders.colorClass }}" >
                        <div class="d-flex align-items-end gap-1">
                          <div class="mb-3 w-75">
                            <label class="form-label" style="color: grey;"> 
                              {{ curentLang === 'en' ? 'Commercial Register' : (curentLang === 'ar' ? '  رقم السجل التجاري  ' : '    מספר רישום מסחרי ')}}
                           </label> 
                            <input style="color: grey;" class="form-control" type="number"  [(ngModel)]="CommercialRegister"
                            formControlName="commercial_register"/>
                          </div>
    
                        </div>
                        <div class="bg-gradient">
                          <svg class="stroke-icon svg-fill">
                            <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>

                       <!--back-->
      <div class=" d-flex justify-content-between"> 

        <button type="button" [disabled]="!updateCompanyInfoByAdmin.valid"
        class="btn   m-2" 
       style="background-color: #141414; color: #fff;"
        (click)="updateCompanyByAdminByCompanyId()"
        > 
         {{ curentLang === 'en' ? 'Update' : (curentLang === 'ar' ? '     تحديث ' : '     עדכן  ') }}
         <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
     </button>

        <a routerLink="/dashboard/companies">
          <button class="btn btn-danger m-2 " >
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
          </button>
        </a>

      </div>

         
        </div>
    </form>
        </div>
      </div>
   

  </div>
      
    </div>
    
  </div>

</div>
