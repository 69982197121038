<!-- <app-breadcrumb 
  [title]="curentLang === 'en' ? 'Export Reports Info' : (curentLang === 'ar' ? ' معلومات تصدير التقارير   ' : '   ייצוא דוחות מידע   ')" 
  [items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
  [active_item]="curentLang === 'en' ? 'Export Reports Info' : (curentLang === 'ar' ? ' معلومات تصدير التقارير   ' : '   ייצוא דוחות מידע  ')">
</app-breadcrumb> -->


<div class="container-fluid basic_table my-2">
  <div class="row">
  
      
        
    <div class="col-sm-12">
      <div class="card">
        <!-- <div class="card-header d-flex justify-content-between">
          <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Export Reports Info To Company   ' : (curentLang === 'ar' ? '  معلومات تصدير التقارير لشركة    ' : '      ייצוא דוחות מידע לחברה  ')}}
            : 
            <span style="color: red;">
              {{curentLang === 'en' ? reportsIncomeData?.company_name_ar : (curentLang === 'ar' ? reportsIncomeData?.company_name_ar : reportsIncomeData?.company_name_he)}}
               </span>
          </h5>


        </div> -->

        <div class="modal-body">
          <div class="container-fluid">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                <form [formGroup]="reportsForm">
                  <div class="row">
                    <div class="mb-3 col-xl-4 col-md-6 col-sm-12">
                      <label class="form-label" style="color: grey;">
                        {{ curentLang === 'en' ? 'Report' : (curentLang === 'ar' ? ' التقرير  ' : '   הדו"ח') }}
                      </label>
                      <select class="form-select" style="color: black; cursor: pointer;" (change)="filter($event)" formControlName="document_type">
                        <option [value]="1">{{ curentLang === 'en' ? 'Income is detailed' : (curentLang === 'ar' ? ' الدخل مفصل ' : 'ההכנסה מפורטת') }}</option>
                        <option [value]="2">{{ curentLang === 'en' ? 'Total income received' : (curentLang === 'ar' ? '   مجموع الدخل والقبض ' : 'סך ההכנסות שהתקבלו  ') }}</option>
                        <option [value]="3">{{ curentLang === 'en' ? 'Document' : (curentLang === 'ar' ? ' مستند ' : 'מסמך') }}</option>
                        <option [value]="4">{{ curentLang === 'en' ? 'Item sales report' : (curentLang === 'ar' ? ' تقرير مبيعات الأصناف ' : 'דוח מכירת פריטים') }}</option>
                        <option [value]="5">{{ curentLang === 'en' ? 'Consolidated Item' : (curentLang === 'ar' ? ' البند الموحد ' : 'פריט מאוחד') }}</option>
                        <option [value]="6">{{ curentLang === 'en' ? 'Receipts' : (curentLang === 'ar' ? '  الأيصالات   ' : 'קבלות ') }}</option>
                        <option [value]="7">{{ curentLang === 'en' ? 'Dashboard' : (curentLang === 'ar' ? '  لوحة التحكم   ' : ' לוח הבקרה ') }}</option>
                      </select>
                    </div>
                    
                    

                    <!-- <div class="col-sm-2 mt-1">
                      <label class="form-label" style="color: grey;"> 
                          {{ curentLang === 'en' ? 'Period' : (curentLang === 'ar' ? 'الفترة' : 'פרק זמן')}}
                      </label>
                      <select class="form-control form-select"
                          style="cursor: pointer;"
                          (change)="filterMonth($event)">
                      >
                          <option [value]="1"> {{ curentLang === 'en' ? 'Current month' :(curentLang === 'ar' ? 'الشهر الحالي'  : 'החודש הנוכחי' )}} </option>
                          <option [value]="2"> {{ curentLang === 'en' ? 'Last month' :(curentLang === 'ar' ? 'الشهر الماضي'  : 'חודש שעבר ' )}} </option>
                          <option [value]="5"> {{ curentLang === 'en' ? 'Last Three month' :(curentLang === 'ar' ? '   أخر ثلاث شهور'  : '  שלושת החודשים האחרונים ' )}} </option>
                          <option [value]="6"> {{ curentLang === 'en' ? 'Last Six month' :(curentLang === 'ar' ? '   أخر ست شهور '  : '  ששת החודשים האחרונים ' )}} </option>
          
                          <option [value]="3"> {{ curentLang === 'en' ? 'Start of the year' :(curentLang === 'ar' ? 'من بداية العام'   : 'מתחילת השנה ' )}} </option>
                          <option [value]="4"> {{ curentLang === 'en' ? 'Last year' :(curentLang === 'ar' ? 'العام الماضي'  : 'שנה שעברה' )}} </option>
                      </select>
                  </div> -->
                  
                  <!-- <div class="mb-3 col-xl-3 col-md-6 col-sm-12">
                      <label class="form-label" style="color: grey;">
                         {{ curentLang === 'en' ? 'From Date' : (curentLang === 'ar' ? 'من تاريخ' : 'מהתאריך של') }}
                      </label>
                      <input style="color: grey;" class="form-control" type="date" formControlName="startDate" />
                  </div>
                  
                  <div class="mb-3 col-xl-3 col-md-6 col-sm-12">
                      <label class="form-label" style="color: grey;">
                         {{ curentLang === 'en' ? 'To Date' : (curentLang === 'ar' ? 'الى تاريخ' : 'עד היום') }}
                      </label>
                      <input style="color: grey;" class="form-control" type="date" formControlName="endDate" />
                  </div>
                   -->

                    



                  </div>

                  <div class="modal-footer">
                    <button type="button" [disabled]="!reportsForm.valid" class="btn btn-sm m-auto my-2" style="background-color: #141414; color: #fff;" (click)="displayExcelData()">
                      {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? ' تاكيد ' : ' אישור ') }}
                      <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                    </button>
                    
                    <button (click)="exportToExcel()" class="btn my-2" style="background-color: #141414; color: #fff;">
                      <i class="fa fa-download mx-1"></i>
                      {{ curentLang === 'en' ? 'Download Exel' : (curentLang === 'ar' ? ' تحميل اكسيل ' : ' הורד את אקסל') }}
                    </button>

                    <!-- <button type="button" class="btn btn-sm m-auto my-2 btn-danger" routerLink="/dashboard/company-documents/{{uuid}}">
                      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? ' رجوع ' : ' חזרה ') }}
                      <i class="fa fa-rotate-left mx-1"></i>
                    </button> -->
                    <button type="button" class="btn btn-sm m-auto my-2 btn-danger" routerLink="/dashboard/office-companies">
                      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? ' رجوع ' : ' חזרה ') }}
                      <i class="fa fa-rotate-left mx-1"></i>
                    </button>
                  </div>
                </form>

                <div *ngIf="rowData?.length == 0" class="alert text-danger mt-3">
                  <span> {{ curentLang === 'en' ? 'There are no reports yet... ' : (curentLang === 'ar' ? ' لا توجد تقارير حتي الأن ... ' : 'אין עדיין דיווחים') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-body">
          <div class="container-fluid">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                <div class="col-sm-12" [hidden]="rowData.length == 0">
                  <div class="card ">
                    <div class="row">
                      <div class="col-sm-5">
                        <!-- <div class="form-group search m-3">
                          <label for="seaarch" class="ml-2" style="color: grey;">{{ curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש') }}</label>
                          <input class="form-control w-100" type="text" name="search" [ngModelOptions]="{ standalone: true }" id="seaarch" [(ngModel)]="term" style="color: black;" placeholder="{{ curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש') }}" />
                        </div> -->
                      </div>

                      <div class="card-header col-sm-4"></div>

                      <div class="card-header col-sm-3">
                        <div class="d-flex justify-content-between">
                          <!-- <button (click)="generatePDF2()" class="btn mt-3" style="background-color: #141414; color: #fff;">
                            <i class="fa fa-file-pdf-o mx-1"></i>
    {{ curentLang === 'en' ? ' Print ' : (curentLang === 'ar' ? 'طباعة نسخة  ' : 'הפקת העתק') }}
                          </button>  -->

                          <!-- <button (click)="exportToExcel()" class="btn mt-3" style="background-color: #141414; color: #fff;">
                            <i class="fa fa-download mx-1"></i>
                            {{ curentLang === 'en' ? 'Download Exel' : (curentLang === 'ar' ? ' تحميل اكسيل ' : ' הורד את אקסל') }}
                          </button> -->
                        </div>
                      </div>
                    </div>

                    <div class="card-block row">
                      <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive page ">
                          <ag-grid-angular
                          style="width: 100%; height: 600px;"
                          class="ag-theme-alpine text-start ag-header-cell-comp-wrapper"
                          [columnDefs]="columnDefs"
                          [rowData]="rowData"
                          [gridOptions]="gridOptions"
                          [suppressRowClickSelection]="true"
                          [suppressCopyRowsToClipboard]="true"
                           [enableBrowserTooltips]="true"
                          [enableRangeSelection]="true"
                          
                          [rowMultiSelectWithClick]="true"
                          [suppressDragLeaveHidesColumns]="true"
                          [suppressMakeColumnVisibleAfterUnGroup]="true"
                          [suppressAggFuncInHeader]="true"
                          [suppressColumnVirtualisation]="true"
                          [enableCellTextSelection]="true"
                          [suppressContextMenu]="true"
                          [suppressExcelExport]="true"
                          [defaultColDef]="{
                            sortable: true,
                            filter: true,
                            flex: 1,
                            resizable: true,
                            minWidth: 100,
                            headerClass: 'ag-header-cell-comp-wrapper header-align-right',
                            cellStyle: { 'background-color': '#ffffff', 'text-align': 'right' },
                            suppressMenu: false,
                            cellRenderer: 'currencyCellRenderer'
                          }">
                        </ag-grid-angular>
                        

                          

                          <!-- <div class="pagination-container">
                            <pagination-controls (pageChange)="p = $event" class=" my-3 current"></pagination-controls>
                          </div> -->
                        </div>
                      </div>
                    </div>

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  
 

 
  </div>

</div>