import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpHelpService } from "../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { AddCompanyPermissionsEndPoient, AddOfficeAccountingByAdminEndPoient } from "../service/global.service";
import { israelPhoneNumberValidator } from './../../validators/israelPhoneNumberValidator';

@Component({
  selector: "app-accounting-offices",
  templateUrl: "./accounting-offices.component.html",
  styleUrls: ["./accounting-offices.component.scss"]
})

export class AccountingOfficesComponent implements OnInit {
  public show: boolean = false;
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addAccountingOffice:FormGroup;
  updateAccountingOffice:FormGroup;
  addCompanyAccountingOffice:FormGroup;
  customersData:any=[];
  customerDataMerchant:any=[];
  accountingOfficeData:any=[];
  activeCompanyData:any=[];
  officeId: number;
  updatedData: any;
  viewData: any;
  currentPage: number = 1;
  limit: number = 100;
  offset: number = 0;
  total: number = 0; 
  public validate = false;
  public updatevalidate = false;


  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));

   this.addAccountingOffice=new FormGroup({
    'user_name': new FormControl(null, [ Validators.required , Validators.maxLength(20)]),
    'phone_number': new FormControl('', [israelPhoneNumberValidator() , Validators.required]),

    password: new FormControl('', [ Validators.required , Validators.pattern(/^(?=.*[!@#$%^&*()_\-+=<>?])[A-Z][A-Za-z0-9!@#$%^&*()_\-+=<>?]{3,20}$/)]),
    office_name :new FormControl('', [Validators.required, Validators.maxLength(80)]),
    office_address :new FormControl(null, [Validators.maxLength(80)]),
   
    'office_phone': new FormControl(null),
    email :new FormControl(null, [  Validators.email]),
    tax_number :new FormControl(null, [Validators.maxLength(9)  ]),  
    language :new FormControl(this.curentLang),
    created_by:new FormControl(this.userRegistId),
    });

    this.addCompanyAccountingOffice = new FormGroup({
      companyMerchant: new FormControl([] , Validators.required),   
      office_id: new FormControl(this.officeId),
      created_by: new FormControl(this.userRegistId),
      permission_status: new FormControl('')
    });
    
   
    this.updateAccountingOffice=new FormGroup({
      'phone_number': new FormControl('', [israelPhoneNumberValidator() , Validators.required]),

       office_name :new FormControl('', [Validators.required,Validators.maxLength(80)]),
      office_address :new FormControl(null, [Validators.maxLength(80)]),
      'office_phone': new FormControl(null),
      email :new FormControl(null, [ Validators.email]),
      tax_number :new FormControl(null, [Validators.maxLength(9)  ]),  
     updated_by:new FormControl(this.userRegistId),
      });
  };

  showPassword() {
    this.show = !this.show;
  }
  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  onCompanyMerchantChange(event) {
    this.addCompanyAccountingOffice.patchValue({
      companyMerchant: event
    });
  }

  onPageChange(page: number) {
    this.currentPage = page;
    const offset = (page - 1) * this.limit;
    this.getAllAccountingOffice(this.limit, offset);
  }
  openXl2(content2:any) {
    this.modalService.open(content2, { size: 'lg'   });
  };

  isValidEmail(email: string): boolean {
    // Regular expression for basic email validation
     const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailRegex.test(email);
  }
  addedData() {
    let customerPhone = this.addAccountingOffice.value.phone_number;
    if (customerPhone.startsWith('0')) {
      customerPhone = customerPhone.substring(1);
    }
  
    if (this.addAccountingOffice.value.office_name === '' || 
      //  this.addAccountingOffice.value.email === '' ||
      this.addAccountingOffice.value.phone_number === '' ||
        this.addAccountingOffice.value.user_name === ''
      ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يجب ادخال اسم المستخدم و اسم المكتب  ورقم الهاتف وكلمة المرور  ' : 
               (this.curentLang === 'en' ? 'You must enter the user name office name , phone number, and password' : 
               (this.curentLang === 'he' ? 'יש להזין את שם המשתמש, שם המשרד, מספר הטלפון והסיסמה   ' : ''))
      });
      this.validate = !this.validate;
      return;
    } else {
      let merchantPhone = this.addAccountingOffice.value.phone_number;
      if (merchantPhone.length < 7) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'يجب ألا يقل رقم الهاتف عن 7 أرقام' : 
                 (this.curentLang === 'en' ? 'The phone number must be at least 7 digits long' : 
                 (this.curentLang === 'he' ? 'מספר הטלפון חייב להיות בן 7 ספרות לפחות' : ''))
        });
        return;
      }
  
      let merchantTaxNumber = this.addAccountingOffice.value.tax_number?.trim();
      if (merchantTaxNumber && merchantTaxNumber.length < 9) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'يجب ألا يقل الرقم الضريبي عن 9 أرقام' : 
                 (this.curentLang === 'en' ? 'The tax number must be at least 9 digits long' : 
                 (this.curentLang === 'he' ? 'מספר המס חייב להיות לפחות 9 מספרים' : ''))
        });
        return;
      }
  
      const validPrefixes = ['50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
      let validationStartPhoneNumber = customerPhone.substring(0, 2);
  
      if (!validPrefixes.includes(validationStartPhoneNumber)) {
        Swal.fire({
          icon: 'warning',
          title: this.curentLang === 'ar' ? 'يجب أن يبدأ رقم الهاتف بأي رقم من هذة الأرقام (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
                 (this.curentLang === 'en' ? 'Phone number must start with one of these numbers (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
                 (this.curentLang === 'he' ? 'צריך להתחיל עם אחד מהמספרים (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : ''))
        });
        return;
      }
  
      let password = this.addAccountingOffice.value.password;
      if (  password.length < 8 || password.length > 20) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'يجب أن تكون كلمة المرور أطول من أو تساوي 8 أحرف وأقل من أو تساوي 20 حرفًا' : 
                 (this.curentLang === 'en' ? 'Password must be longer than or equal to 8 characters and shorter than or equal to 20 characters' : 
                 (this.curentLang === 'he' ? 'הסיסמה חייבת להיות ארוכה מ-8 תווים וקצרה מ-20 תווים' : ''))
        });
        return;
      }
  
      let merchantEmail = this.addAccountingOffice.value.email?.trim();
      if (merchantEmail && !this.isValidEmail(this.addAccountingOffice.value.email)) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'الرجاء إدخال عنوان بريد إلكتروني صالح' : 
                 (this.curentLang === 'en' ? 'Please enter a valid email address' : 
                 (this.curentLang === 'he' ? 'אנא הזן כתובת דוא"ל חוקית' : ''))
        });
        return;
      }
  
      let customerPhoneFinal = this.addAccountingOffice.value.phone_number;
      if (customerPhoneFinal.startsWith('0')) {
        customerPhoneFinal = customerPhoneFinal.substring(1);
      }
      let model = {
        user_name: this.addAccountingOffice.value.user_name,
        phone_number: '+972' + customerPhoneFinal,
        password: this.addAccountingOffice.value.password,
        office_name: this.addAccountingOffice.value.office_name,
        office_address: this.addAccountingOffice.value.office_address,
        office_phone: this.addAccountingOffice.value.office_phone,
        email: this.addAccountingOffice.value.email ? this.addAccountingOffice.value.email : null,
        tax_number: this.addAccountingOffice.value.tax_number,
        language: this.curentLang,
        created_by: Number(this.userRegistId),
      };
  
      this.spinner.show();
      this.service.Post(AddOfficeAccountingByAdminEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : 
                   (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllAccountingOffice();
          this.addAccountingOffice.reset({
            phone_number: '',
            user_name: '',
            password: '',
            office_name: '',
            office_address: '',
            office_phone: '',
            email: '',
            tax_number: '',
          });
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
            case 403:
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                             (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : 
                             (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }
  };
  
   
  isNumber(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    } else {
      return true;
    }
  }
  getAllAccountingOffice(limit?: number, offset?: number) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
    this.spinner.show();
    this.service.getAllAccountingOffice(limit, offset).subscribe({
      next: res => {
        this.accountingOfficeData = res.data;
        this.total = res.total;
        this.spinner.hide();
         
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }

  getALLActiveCompany() {
    this.spinner.show();
    this.service.getALLActiveCompany().subscribe({
      next: res => {
        this.activeCompanyData = res;
          
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  addedAccountingOfficeCompany() {
    if (this.addCompanyAccountingOffice.invalid) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      return;
    } else {
      const companyMerchants = this.addCompanyAccountingOffice.value.companyMerchant;
      const models = companyMerchants.map(companyMerchant => ({
        office_id: Number(this.officeId),
        company_id: Number(companyMerchant.company_id),
        merchant_id: Number(companyMerchant.merchant_id),
        created_by: Number(this.userRegistId),
        permission_status: Number(16)
      }));
  
      this.spinner.show();
      this.service.Post(AddCompanyPermissionsEndPoient.POST, models).subscribe((res) => {
        this.spinner.hide();
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllAccountingOffice();
          this.addCompanyAccountingOffice.reset();
        } else {
          let errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
      });
    }
  }
  filterCompany(event:any)
{
  let x = event;
   
 };
  setValue(officeId: number) {
    for (let i = 0; i < this.accountingOfficeData.length; i++) {
      if (this.accountingOfficeData[i].office_id == this.officeId) {
        const selectedCategory = this.accountingOfficeData.find(item => item.office_id === officeId);
         this.updateAccountingOffice.controls.office_name.setValue(this.accountingOfficeData[i].office_name);
        this.updateAccountingOffice.controls.office_address.setValue(this.accountingOfficeData[i].office_address);
        this.updateAccountingOffice.controls.office_phone.setValue(this.accountingOfficeData[i].office_phone);
        this.updateAccountingOffice.controls.email.setValue(this.accountingOfficeData[i].email);
        this.updateAccountingOffice.controls.tax_number.setValue(this.accountingOfficeData[i].tax_number);
      }
    };
  };

  executeFunctions(CustomerID: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(CustomerID);
    this.setValue(CustomerID);
    this.updateMd(this.updatedData);
 
  };

  getId(id: number) {
    this.officeId = id;
     
  };

  executeFunctionsView(CustomerID: number, view: any) {
    this.viewData = view;
    this.getId(CustomerID);
    this.setValue(CustomerID);
    this.updateViewMd(this.viewData);
  };
  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  updateViewMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };
  updatedOfficeData() {
    if (this.updateAccountingOffice.value.office_name?.trim() === '') {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يجب ادخال اسم المكتب   ' : (this.curentLang === 'en' ? 'You must enter the office name  ' : (this.curentLang === 'he' ? ' עליך להזין את שם המשרד   ' : ''))
      });
      this.updatevalidate = !this.updatevalidate;

      return; 
      
    } 
    
    let merchantTaxNumber = this.updateAccountingOffice.value.tax_number?.trim();
    if (merchantTaxNumber && merchantTaxNumber.length < 9) {
      Swal.fire({
        icon: 'error',
        title: this.curentLang === 'ar' ? 'يجب ألا يقل الرقم الضريبي عن 9 أرقام' : 
               (this.curentLang === 'en' ? 'The tax number must be at least 9 digits long' : 
               (this.curentLang === 'he' ? 'מספר המס חייב להיות לפחות 9 מספרים' : ''))
      });
      return;
    }

    let merchantEmail = this.updateAccountingOffice.value.email?.trim();
    if (merchantEmail && !this.isValidEmail(this.updateAccountingOffice.value.email)) {
      Swal.fire({
        icon: 'error',
        title: this.curentLang === 'ar' ? 'الرجاء إدخال عنوان بريد إلكتروني صالح' : 
               (this.curentLang === 'en' ? 'Please enter a valid email address' : 
               (this.curentLang === 'he' ? 'אנא הזן כתובת דוא"ל חוקית' : ''))
      });
      return;
    }
    
    else {
      let model = {
        office_name: this.updateAccountingOffice.value.office_name?.trim(),
        office_address: this.updateAccountingOffice.value.office_address?.trim() ? this.updateAccountingOffice.value.office_address : null,
        office_phone: this.updateAccountingOffice.value.office_phone?.trim() ? this.updateAccountingOffice.value.office_phone : null,
        email: this.updateAccountingOffice.value.email ? this.updateAccountingOffice.value.email : null,
        tax_number: this.updateAccountingOffice.value.tax_number?.trim() ? this.updateAccountingOffice.value.tax_number : null,
        updated_by: Number(this.userRegistId),
      };
  
      this.spinner.show();
      this.service.updateOfficesById(this.officeId, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllAccountingOffice();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }
  };
  
  
  changeStatus(id:number, office_status:any){
    this.spinner.show();
  
    let model =
    {
      "office_status": office_status == 1 ? 59 : 1,
     "updated_by": this.userRegistId
    }
  
    this.service.changeAccountingOfficeStatus(id , model ).subscribe(({
      
      next:res =>{
       if (res.status ==200){

        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        })
          this.getAllAccountingOffice();
          this.spinner.hide();
       }
  
      },
      error:err =>{
        Swal.fire({
          icon:"error",
          title:err.error.message[0]
        });
        this.spinner.hide();
  
      }
      
    }))
  };

  ngOnInit() 
  {
   this.getAllAccountingOffice();
   this.getALLActiveCompany();
   this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
   this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
   
  };
}
