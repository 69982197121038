<app-breadcrumb [title]="curentLang === 'en' ? 'Company Documents Info' : (curentLang === 'ar' ? ' معلومات مستندات الشركات   ' : '   מידע על מסמכי תאגיד    ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Company Documents Info' : (curentLang === 'ar' ? ' معلومات مستندات الشركات   ' : '   מידע על מסמכי תאגיד   ')">
</app-breadcrumb>

<div class="container-fluid basic_table">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h5 style="color: grey;">
            {{ curentLang === 'en' ? 'Company Documents Info' : (curentLang === 'ar' ? '  معلومات مستندات الشركات  ' : '  מידע על מסמכי תאגיד   ')}}
          </h5>

          <div>
            <!-- <button [hidden]="user_type == 200" class="btn m-2" style="background-color: #141414; color: #fff;"
            routerLink="/dashboard/export-reports/{{uuid}}">
       <i class="fa fa-upload mx-1"></i>
      {{ curentLang === 'en' ? 'Export Reports' : (curentLang === 'ar' ? ' اصدار تقارير' : ' הוספת חברה')}}
    </button>
    
    <button (click)="downloadAllInvoicesZip()" class="btn m-2" style="background-color: #141414; color: #fff;">
      <i class="fa fa-download mx-1"></i>
      {{ curentLang === 'en' ? 'Download All Invoices ZIP' : (curentLang === 'ar' ? 'تحميل كل الفواتير ZIP' : 'הורד את כל החשבוניות ב-ZIP') }}
    </button> -->
    
    

            <!-- <button class="btn mx-1 btn-outline-warning" (click)="getAllCompanyDocumentsComponent()">
              <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
            </button> -->
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <div class="form-group search">
              <label for="search" style="color: grey;">
                {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
              </label>
              <div class="input-group">
                <input class="form-control form-control-search" type="text" name="search"
                       [ngModelOptions]="{standalone: true}" id="search" [(ngModel)]="term" style="color: black;"
                       placeholder="{{curentLang === 'en' ? 'Search by customer name or document number ' : (curentLang === 'ar' ? 'بحث بأسم العميل او رقم المستند' : 'חפש לפי שם הלקוח או מספר המסמך')}}"

                       >
                <!-- <div class="input-group-append">
                  <button class="btn" style="background-color: #141414; color: #fff;" type="button"
                          (click)="getAllCompanyDocumentsFilters(fromDate, toDate, limit, offset, term)">
                    <i class="fa fa-search mx-1"></i>
                    {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                  </button>
                  <button class="btn  btn-outline-secondary" type="button" (click)="term='';getAllCompanyDocumentsFilters(fromDate, toDate, limit, offset, term)">
                    <i class="fa fa-times"></i>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
          
        
          <div class="col-sm-3 ">
            <div class="form-group search">
              <label for="fromDate"  style="color: grey;">
                {{curentLang === 'en' ? 'From Date' : (curentLang === 'ar' ? 'من تاريخ' : 'מהתאריך של')}}
              </label>
              <input class="form-control" type="date" [(ngModel)]="fromDate" id="fromDate" />
            </div>
          </div>
        
          <div class="col-sm-5">
            <div class="row">
              <div class="col-md-6 ">
                <div class="form-group search">
                  <label for="toDate"  style="color: grey;">
                    {{curentLang === 'en' ? 'To Date' : (curentLang === 'ar' ? 'الي تاريخ' : ' עד היום')}}
                  </label>
                  <input class="form-control" type="date" [(ngModel)]="toDate" id="toDate" />
                </div>
              </div>

              <div class="col-md-6 ">

                <div class="form-group search " style="margin-top: 30px;">
                  <button class="btn mx-1" style="background-color: #141414; color: #fff;" [disabled]="!fromDate || !toDate"
                          (click)="getAllCompanyDocumentsComponent(fromDate, toDate)">
                    <i class="fa fa-search mx-1"></i>
                    {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                  </button>
                </div>
              </div>

            </div>
           

          </div>
      
        </div>
        
        
         
       
        

        <div class="card-block row">
          <div class="col-sm-12 col-lg-12 col-xl-12">
            <div class="table-responsive">
              <table class="table table-light text-start table-hover">
                <thead>
                  <tr>
                    <th scope="col">{{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? '  رقم المستند' : '  מספר מסמך') }}</th>
                    <th scope="col"> {{ curentLang === 'en' ? 'Invoice Type' : (curentLang === 'ar' ? '  نوع المستند  ' : ' סוג מסמך ')}} </th>
                    <th scope="col"> {{ curentLang === 'en' ? ' Customer Name ' : (curentLang === 'ar' ? ' اسم العميل' : 'שם הסוכן')}}</th>   
                    <th scope="col"> {{ curentLang === 'en' ? ' Customer Vat Number ' : (curentLang === 'ar' ? ' رقم مشغل العميل' : 'מספר מע"מ של לקוח  ')}}</th>     
  
                    <th scope="col"> {{ curentLang === 'en' ? 'Total Amount ' : (curentLang === 'ar' ? 'المبلغ الاجمالي' : '  סכום כולל')}}</th>
                    <th scope="col"> {{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? '  تاريخ الفاتورة' : 'תאריך חשבונית ')}}</th>
                    <th scope="col"> {{ curentLang === 'en' ? 'View ' : (curentLang === 'ar' ? ' معاينة المستند   ' : ' הצגה   ')}}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let item of companyDocumentsData | paginate: { itemsPerPage: limit, currentPage: currentPage, totalItems: total } | filter: term; let i = index"
                  [ngClass]="{'table-danger': selectedInvoiceUuid === item.invoice_uuid }"
                  (click)="getInvoiceId(item.invoice_uuid);executeFunctions(item.invoice_uuid , content4)">
                    <td scope="row">{{item?.document_number}}</td>
                    <td>{{ getInvoiceTypeDescription(item?.invoice_type) }}</td>
                    <td class="customer-name">{{item?.customer_name}}</td>
                    <td>{{item?.customer_vat_number}}</td>
                    <td>
                      <span *ngIf="item.invoice_type == 400">{{item?.total_amount | number:'1.2-2'}}</span>
                      <span *ngIf="item.invoice_type != 400">{{ item?.payment_amount_including_vat | number:'1.2-2'}}</span>
                    </td>
                    <td>{{ item?.invoice_date | date: 'dd/MM/yyyy' }}</td>


                    <td>
                      <a   rel="noopener noreferrer">
                          <i class="fa fa-file-pdf-o" 
                          style="font-size: 15px; cursor: pointer; color: #213FA6;"></i>
                      </a>
                  </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination-container">
                <pagination-controls (pageChange)="onPageChange($event)" (totalItems)="total" (currentPage)="currentPage">
                </pagination-controls>
                <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange()">
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <a routerLink="/dashboard/companies">
                  <button class="btn btn-danger m-2">
                    {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה ')}}
                    <i class="fa fa-rotate-left mx-1"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>









<ng-template #content4 let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
      {{ curentLangDocument === 'en' ? 'Document Info' : (curentLangDocument === 'ar' ? ' معلومات المستند  ' : '   מידע על מסמך   ')}}
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">

      <div class="row  ">
        <div class="col-md-4">
          <button type="button" (click)="downloadDocumentPdf();modal.close('Close click')" class="btn btn-sm mx-4 mt-4"
          style=" background-color: #141414; color: #fff;">
          <i class="fa fa-file-pdf-o mx-1"></i>
          {{ curentLang === 'en' ? ' Print ' : (curentLang === 'ar' ? 'طباعة نسخة  ' : 'הפקת העתק') }}
        </button>
        </div>

        <div class="col-md-4">

        </div>

        <div class="col-md-4">

          <div class="form-group mx-4" *ngIf="CopyVersion === 'نسخة مطبوعه' || CopyVersion === 'עותק משוחזר' || CopyVersion === 'version copy'">
            <label class="mt-2" style="color: #141414;">
              {{ curentLang === 'en' ? 'Change Document Language' : (curentLang === 'ar' ? 'تغيير لغة المستند' : 'שנה את שפת המסמך') }}
            </label>
            <select class="form-control" (change)="filterDocumentLanguage($event.target.value)">
              <option *ngFor="let lang of availableLanguages" [value]="lang.code">
                {{ lang.name }}
              </option>
            </select>
          </div>
          
          </div>
      </div>




<div class="container" [hidden]="receiptInfoData?.confirm == 0" >
    <div class="row m-auto">
        <div class="col-sm-12 ">

          <div class="page m-auto  invoice-content"  id="printDiv">

            <div class="card-2">

                <div class="row m-2 ">
                    <div class="col-lg-12">
                        <div class="invoice-inner" id="invoice_wrapper">
                            <div class="invoice-top">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="logo my-2"  *ngIf="invoiceInfoData?.logo">
                                            <img
                                                [src]="invoiceInfoData?.logo"
                                                alt="logo"
                                                style="width: 100px;height: 100px; border-radius: 5%;">
                                        </div>
                                    </div>

                                    <div [class.py-5]="invoiceInfoData?.logo" [class.py-2]="!invoiceInfoData?.logo" class="col-sm-4">
                                        <h2
                                            class="text-center water-mark ">{{invoiceInfoData?.copy_version}}</h2>
                                    </div>

                                    <div class="col-sm-4">
                                 
                                    </div>
                                </div>
                            </div>
                            <div class="invoice-info">
                                <div class="row">
                                   
                                    <div class="col-sm-6 mb-30">
                                        <div class="invoice-number ">
                                            <h4 class="inv-title-1" style="font-weight: bold; font-size: 14px;">{{ curentLangDocument === 'en' ? 'For ' : (curentLangDocument === 'ar' ? '   لحضرة' : 'עבור כבודו')}}</h4>
                                            <div class="invo-addr-1">
                                                <div style="font-weight: 400;">
                                                    <!-- <strong> {{ curentLangDocument === 'en' ? 'Customer Name' : (curentLangDocument === 'ar' ? 'اسم العميل' : 'שם הלקוח')}} / </strong> -->
                                                    <strong>{{ invoiceInfoData?.customer_name }}</strong>
                                                </div>
                                                
                                                <div *ngIf="invoiceInfoData?.customer_address; else noAddress" style="font-weight: 300;" class="m-0">
                                                    <strong>{{ invoiceInfoData?.customer_address }}</strong>
                                                </div>
                                                
                                                <ng-template #noAddress>
                                                    <div style="font-weight: 300;">
                                                        <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : 'מספר זיהוי עובד')}} </strong>
                                                        <div style="font-weight: 300;">
                                                            <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                
                                                <div *ngIf="invoiceInfoData?.customer_address" style="font-weight: 300;">
                                                    <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : 'מספר זיהוי עובד')}} </strong>
                                                    <div style="font-weight: 300;">
                                                        <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                    </div>
                                                </div>
                                                

                                                
                                                <div style="font-weight: 300;">
                                                    <!-- <strong>{{ curentLangDocument === 'en' ? 'Customer Id Number' : (curentLangDocument === 'ar' ? '  رقم الهوية للعميل' : '  מספר זיהוי לקוח ')}} / </strong> -->
                                                    <strong>{{ invoiceInfoData?.customer_id_number }}</strong>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div class="col-sm-6 mb-30">
                                        <div class="invoice-number text-end">
                                            <!-- <h4 class="inv-title-1"> {{ curentLangDocument === 'en' ? 'Invoice From' : (curentLangDocument === 'ar' ? 'فاتورة من' : 'חשבונית מ')}} </h4> -->
                                            <div class="invo-addr-1">
                                                <div style="font-weight: bold;">
                                                    <!-- <strong>{{ curentLangDocument === 'en' ? 'Company Name' : (curentLangDocument === 'ar' ? 'اسم الشركة' : 'שם החברה')}} / </strong>  -->
                                                    <strong>{{ invoiceInfoData?.company_name }}</strong>
                                                </div>
                                                
                                                <div style="font-weight: 300;">
                                                    <!-- <strong> {{ curentLangDocument === 'en' ? 'Company Address' : (curentLangDocument === 'ar' ? 'عنوان الشركة' : 'כתובת החברה')}} / </strong> -->
                                                    <strong>{{ invoiceInfoData?.company_address_str }} -</strong>
                                                    <strong>{{ invoiceInfoData?.company_Building_number }} -</strong>

                                                    
                                                    <strong>{{ invoiceInfoData?.company_address_city }}</strong>
                                                </div>
                                                
                                              
                                                <!-- <div style="font-weight: 400;">{{ invoiceInfoData?.company_address_zip_code }}</div> -->
                                                <!-- <div style="font-weight: 300;">
                                                    <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? '  الرقم الضريبي للشركة' : 'מספר מס חברה')}} / </strong>
                                                    <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                
                                                </div> -->

                                                <div  style="font-weight: 300;"> 
                                                    <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : ' מספר מס חברה    ')}}  </strong> 
                                               </div>
                                               <div  style="font-weight: 300;"> 
                                               <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                               </div>
                                              

                                              
                                                
                                            </div>
                                        </div>
                                        
                                        
                                        
                                    </div>
                                </div>
                                <div class="row  ">
                           
                                    <div class="col-sm-4"></div>
                                    <div class="invoice text-center   col-sm-4">
                                        <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Invoice Details' : (curentLangDocument === 'ar' ? 'تفاصيل الفاتورة' : ' פרטי החשבונית  ')}}</h4> -->

                                        <p class="mb-1"
                                            *ngIf="invoiceInfoData?.invoice_reference_number !== null">
                                            <!-- {{ curentLangDocument === 'en' ? 'Invoice Reference Number' : (curentLangDocument === 'ar' ? 'الرقم المرجعي للفاتورة' : ' מספר הפנייה לחשבונית ')}} -->
                                            <span>{{invoiceInfoData?.invoice_reference_number}}</span>
                                        </p>

                                        <div class="mb-1"*ngIf="invoiceInfoData?.document_number !== null" style="color: black;">
                                      <strong style="font-weight: bold;" class="mx-1">{{ curentLangDocument === 'en' ? 'Invoice And Receipt Number' : (curentLangDocument === 'ar' ? ' فاتورة مع ايصال رقم  ' : '   חשבונית עם מספר קבלה  ')}} :</strong>   
                                      <strong style="font-weight: 400; color: black;">{{invoiceInfoData?.document_number}}</strong>
                                    </div>
                                  

                                    <div [hidden]="invoiceInfoData?.confirmation_number == null"  style="font-weight: 300;color: black;">
                                        <strong style="font-weight: bold;" class="mx-1">{{ curentLangDocument === 'en' ? 'Confirmation Number' : (curentLangDocument === 'ar' ? ' رقم تخصيص' : 'מספר הקצאה')}} : </strong> 
                                       <strong>{{ invoiceInfoData?.confirmation_number }}  </strong>
                                   </div>
                                       
                                    </div>

                                    <div class="col-sm-4  text-end  ">
                                        <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' التاريخ  ' : ' תאריך  ')}}</h4> -->
                                       
                                              <div class="mb-0" style="font-weight: bold;color: black;">
                                                {{ curentLangDocument === 'en' ? '  Date' : (curentLangDocument === 'ar' ? 'تاريخ  ' : 'היסטוריה')}}
                                            </div>  
                                            <div style="font-weight: 400; color: black;">{{ invoiceInfoData?.invoice_date  }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="order-summary">
                                <div class="table-responsive">
                                    <table class="table invoice-table">
                                        <thead class="bg-active" style="font-weight: bold;">
                                            <tr>
                                                <th
                                                    class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Item Name' : (curentLangDocument === 'ar' ? 'اسم الصنف' : 'שם הפריט')}}</th>
                                                <th
                                                    class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Description' : (curentLangDocument === 'ar' ? 'الوصف' : 'תיאור')}}</th> 
                                                <!-- <th
                                                    class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Measure Unit' : (curentLangDocument === 'ar' ? 'وحدة القياس' : 'יחידת מידה')}}</th> -->
                                                <th
                                                    class="text-center" style="font-weight: bold;"> {{ curentLangDocument === 'en' ? 'Price' : (curentLangDocument === 'ar' ? 'السعر  ' : ' מחיר  ')}}</th>
                                                <th
                                                    class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Quantity' : (curentLangDocument === 'ar' ? 'الكمية' : 'כמות')}}</th>
                                                <th
                                                    class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Discount' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</th>
                                                <th class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor=" let item of invoiceInfoData?.lines">
                                                <td class="text-center" style="font-weight: 400; color: black;" >{{item?.item_name}}</td>  
                                                
                                               <td  class="text-center  description-column"style="font-weight: 400; color: black;">{{item?.description}}</td>

                                              <!--    <td class="text-center  description-column" style="font-weight: 400; color: black;">{{item?.measure_unit_description}}</td> -->

                                                <td class="text-center" style="font-weight: 400; color: black;" >{{item?.price_per_unit | number:'1.2-2'}}</td>

                                                <td class="text-center" style="font-weight: 400; color: black;" >{{item?.quantity}}</td>

                                                <td class="text-center" style="font-weight: 400; color: black;" >{{item?.discount}}</td>

                                                <td class="text-center" style="font-weight: 400; color: black;" >{{item?.sub_total | number:'1.2-2'}}</td>

                                            </tr>

                                        </tbody>
                                    </table>

                                  

                                </div>
                                <div class="small-table my-4">
                                    <div class="row">
                                        <div class="col-md-12  ">
                                          <div class="tabe table-responsive" style="background-color: #F1F1F1;">
                                            <div class="d-flex justify-content-between" style="background-color: #F1F1F1;">
                                                <div class="col-md-6  p-2 ">
                                                    <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Amount Before Discount:' : (curentLangDocument === 'ar' ? 'المبلغ قبل الخصم' : '   סכום לפני הנחה')}}</div>
                                                    <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Discount:' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</div>
                                                    <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Payment Amount:' : (curentLangDocument === 'ar' ? '   المبلغ المدفوع    ' :' סה"כ ')}}</div>
                                                    <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'VAT Amount:' : (curentLangDocument === 'ar' ? '       المبلغ الضريبي    ' : '           סכום עסקה   ')}}</div>
                                                    <div style="width: 300px; font-weight: bold;" class="m-1"> {{ curentLangDocument === 'en' ? 'Payment Amount Including VAT:' : (curentLangDocument === 'ar' ? ' المبلغ المدفوع شامل الضريبة ' : '  סכום תשלום כולל עסקה  ')}}</div>

                                                </div>

                                             

                                                <div class="col-md-6 text-end px-3 p-2">
                                                    <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.amount_before_discount | number:'1.2-2'}}</div>
                                                    <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.all_discount | number:'1.2-2'}}</div>
                                                    <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount | number:'1.2-2'}}</div>
                                                    <div style="font-weight: 400; color: black;" class="my-1">({{invoiceInfoData?.vat_percentage}} %)   {{invoiceInfoData?.all_vat_amount | number:'1.2-2'}}</div>
                                                    <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount_including_vat | number:'1.2-2'}}</div>

                                                </div>
                                               
                                             
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                
                                

                            </div>


                              <div class="order-summary  " *ngIf="invoiceInfoData?.invoice_type == 320">

            <div class="col-lg-12 " >
                <ng-container  >
                    <div class="mt-4 d-flex justify-content-between">
                        <span style="font-weight: bold; " class="mx-2">{{ curentLangDocument === 'en' ? 'Payment Info' : (curentLangDocument === 'ar' ? 'بيانات الدفع  ' : ' נתוני תשלום ')}} </span>
                        <!-- <span style="font-weight: 500;" class="mx-3">{{ receiptInfoData.checks_amount | number:'1.0-0' }}</span> -->
                    </div>  
            
                    <div class="table-responsive ">
                        <table class="table invoice-table">
                            <thead>
                            
                                <tr>
                                    <th
                                        class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Payment Method ' : (curentLangDocument === 'ar' ? 'طرق الدفع  ' : 'אמצעי תשלום ')}}
                                    </th>
                                    <!-- <th
                                        class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Ref' : (curentLangDocument === 'ar' ? 'مرجع  ' : 'רפ')}}</th> -->
                                    <!-- <th
                                        class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك  ' : 'בַּנק')}}
                                    </th> -->
                                    <!-- <th
                                        class="text-center" style="font-weight: bold;" > {{ curentLangDocument === 'en' ? 'Branch' : (curentLangDocument === 'ar' ? 'فرع  ' : 'סניף')}}
                                    </th>
                                    
                                    <th
                                        class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Account' : (curentLangDocument === 'ar' ? 'حساب  ' : 'חֶשְׁבּוֹן')}}
                                    </th> -->

                                    <th
                                        class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Details' : (curentLangDocument === 'ar' ? 'تفاصيل  ' : 'פרטים')}}</th>
                                        <th
                                        class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' تاريخ  ' : ' תאריך')}}</th>
                                    <th class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع  ' : 'סכום')}}</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor=" let item of invoiceInfoData?.receiptsDetails">
                                    <td class="text-center" style="font-weight: 400; color: black;">
                                        <span *ngIf=" item?.payment_type == 1"> {{ curentLangDocument === 'en' ? 'Cash' : (curentLangDocument === 'ar' ? 'كاش' : 'מְזוּמָנִים')}}</span>
                                        <span *ngIf=" item?.payment_type == 2"> {{ curentLangDocument === 'en' ? 'Check' : (curentLangDocument === 'ar' ? 'شيك' : 'לִבדוֹק')}}</span>
                                        <span *ngIf=" item?.payment_type == 3"> {{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك' : 'בַּנק')}}</span>
                                        <span *ngIf=" item?.payment_type == 4"> {{ curentLangDocument === 'en' ? 'Credit' : (curentLangDocument === 'ar' ? 'ائتمان' : 'אַשׁרַאי')}}</span>
                                    </td>
                                    <!-- <td class="text-center">{{ item.bank }}</td>
                                    <td class="text-center">{{ item.branch }}</td>
                                    <td class="text-center">{{ item.account }}</td>
                                     -->

                                    <td class="text-center description-column" style="font-weight: 400; color: black;">{{ item.details }}</td>
                                    <td class="text-center" style="font-weight: 400; color: black;">{{ item.date | date: 'yyyy-MM-dd' }}</td>
                                    <td class="text-center" style="font-weight: 400; color: black;">{{ item.amount | number:'1.2-2'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>


          

            <div class="small-table my-4">
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-between" >
                        <div class="col-md-6 mt-5">
                          <!-- <span>Weighted date: 05/05/24</span> -->
                         </div>
                         <div class="col-md-6">
                            <div class="row d-flex justify-content-between mx-1" style="background-color: #F1F1F1;">
                                <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                    <span class="py-1"> {{ curentLangDocument === 'en' ? 'Deduction at source' : (curentLangDocument === 'ar' ? 'الخصم من المصدر  ' : 'ניכוי במקור ')}} :  </span>
                                </div>

                                <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                    <span class="mx-1">{{invoiceInfoData.deduction_from_source | number:'1.0-0'}}</span>
                                </div>

                                <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                    <span>{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}  :  </span>
                                </div>

                                <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                    <span class="mx-1">{{ invoiceInfoData.total_amount | number:'1.2-2' }}</span>

                                </div>


                            </div>
                            
                         </div>

                         <div class="col-md-6">

                         </div>
                    </div>
                </div>
            </div>
            
            
            
            
            
        </div>
                                
                                
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            </div>


            
            <div class="modal-footer">
              <button type="button" class="btn btn-sm m-auto mt-2"   hidden
                      style="background-color: #141414; color: #fff;" 
                       > 
                   <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
              </button>
              <button type="button" class="btn btn-sm mt-2 btn-danger" 
                      (click)="modal.close('Close click')">
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                  <i class="fa fa-rotate-left mx-1"></i>
              </button>
          </div>

 
        </div>

        

    </div>




    <!--back-->

    </div>
  </div>
</ng-template>