<app-breadcrumb [title]="curentLang === 'en' ? 'Notifications Info' : (curentLang === 'ar' ? ' معلومات التحذيرات' : '   מידע על הודעה  ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['    לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Notifications Info' : (curentLang === 'ar' ? ' معلومات التحذيرات ' : '    מידע על הודעה  ')">
</app-breadcrumb>

<div class="row">
<div class="col-sm-12">
<div class="card">
  

    <div class="card-header card-no-border d-flex justify-content-between">
      <div class="header-top">
        <h5 class="m-0" style="color: black;">
            {{ curentLang === 'en' ? 'Warnings  ' : (curentLang === 'ar' ? ' التحذيرات' : '  אזהרות')}}
        </h5>
       
      </div>

      <div>

  

      </div>

    </div>
    <div class="card-body pt-0 campaign-table">
      <div class="recent-table table-responsive currency-table">
        <table class="table text-center table-hover">
          <thead style="background-color: grey; color: #141414;">
            <tr>
               <th scope="col">{{ curentLang === 'en' ? 'Details' : (curentLang === 'ar' ? ' التفاصيل   ' : 'הפרטים') }}</th>
              <th scope="col">{{ curentLang === 'en' ? 'View Invoice' : (curentLang === 'ar' ? 'عرض الفاتورة' : 'הצג חשבונית')}}</th>
              <th scope="col">  {{ curentLang === 'en' ?'Cancel warning' : (curentLang === 'ar' ? 'الغاء التحذير' : 'אזהרת ביטול')}}</th>
             </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of LockedMonthInvoicesData ; let i = index">
                 
           
              <td> {{ curentLang === 'en' ? item.messageEn : (curentLang === 'ar' ? item.messageAr  : item.messageHe)}}</td>
                
              <td>
                <a [href]="item.pdf_link" target="_blank" rel="noopener noreferrer">
                    <button style=" background-color: #141414; color: white;"
                   
                    class="btn  btn-sm"  ><i class="fa fa-check-circle-o text-white "></i> {{ curentLang === 'en' ? 'View Invoice' : (curentLang === 'ar' ? 'عرض الفاتورة' : 'הצג חשבונית')}}
                  </button>
                  </a>
              </td>

              <td>
                <button class="btn btn-sm" style="background-color: rgb(28, 80, 221); color: whitesmoke;"
                (click)="getId(item?.invoice_id);openVerticallyCenteredRejected(dataReject)">
                 <i class="fa fa-times-circle " style="color: #fff;"></i>
                {{ curentLang === 'en' ?'Cancel warning' : (curentLang === 'ar' ? 'الغاء التحذير' : 'אזהרת ביטול')}}
                </button>       
             </td>
           
            </tr>
          </tbody>
        </table>

        <div class="pagination-container">
          <pagination-controls 
          (pageChange)="p = $event" class="m-auto my-3 current" 
          >
          </pagination-controls>
 
        </div>  


      </div>

      <div class=" d-flex justify-content-between"> 
        <div></div>

     

       <div *ngIf="user_type == 300 || user_type == 301">
        <a routerLink="/dashboard/office-companies">
            <button class="btn btn-danger m-2 " >
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
            </button>
          </a>
       </div>

      </div>

    </div>
  </div>

 

</div>
</div>




  <!--cancle-->

  <ng-template #dataReject let-modal>
    <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body text-center">
      <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
        {{curentLang === 'en' ? 'Are you sure you want to cancel the warning on this bill?' : (curentLang === 'ar' ? 'هل تريد بالتأكيد الغاء التحذير علي هذة الفاتورة ؟' : 'האם אתה בטוח שאתה רוצה לבטל את האזהרה על החשבון הזה?') }}
      </p>
    </div>
  
    <div class="modal-footer">

      <button type="button" 
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="cancleAert()"
       > 
       {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تأكيد' : 'כדי להיות בטוח')}}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>


  </ng-template>