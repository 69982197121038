import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { of } from 'rxjs/internal/observable/of';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AddCustomerEndPoient, AddInvoiceEndPoient } from "../../service/global.service";
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { israelPhoneNumberValidator } from "src/app/validators/israelPhoneNumberValidator";

@Component({
  selector: 'app-add-receipts',
  templateUrl: './add-receipts.component.html',
  styleUrls: ['./add-receipts.component.scss']
})
export class AddReceiptsComponent implements OnInit {
  today: any;
  curentLang:string="";
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  companyDefault = JSON.parse(localStorage.getItem('companyDefault'));
  user_type = JSON.parse(localStorage.getItem('user_type'));

  selectedCustomer:any;
  addCustomer:FormGroup;
  public validate = false;
  customerTypeData:any=[];

  constructor(private builder: FormBuilder, private service: HttpHelpService, private router: Router, private alert: ToastrService,
    private activeoute: ActivatedRoute, private spinner: NgxSpinnerService,  private datePipe: DatePipe ,  private toastr: ToastrService ,
    private modalService: NgbModal
  )
   { 
    this.curentLang = localStorage.getItem('curentLang');
    this.companyDefault = localStorage.getItem('companyDefault');

    this.addCustomer=new FormGroup({
      customer_name_en :new FormControl(''),
      customer_name_ar:new FormControl(''),
      customer_name_he :new FormControl(''),  
      // customer_address_str :new FormControl(''),
      // customer_address_city :new FormControl(''),
      // customer_address_zip_code :new FormControl(''),
      // customer_Building_number :new FormControl(''),
      // customer_phone :new FormControl('', [israelPhoneNumberValidator()]),  
      customer_vat_number :new FormControl(null), 
      customer_vat_tax :new FormControl(null), 
      //id_number :new FormControl(null),  
      id_number :new FormControl(0),
      customer_type :new FormControl(''),  
      merchant_id :new FormControl(this.merchantId),  
       created_by:new FormControl(this.userRegistId),
      });
 
   }
  receiptDetails !: FormArray<any>;

  masterCustomer: any;
  masterCompany : any;
  masterPaymentMethod : any;
  masterBank : any;
  masterBranch : any;
  masterBranches: any[] = []; es
  allBranchesData:any;
  cashType = 1;
  checkType = 2;
  bankTransferType = 4;
  creditCardType = 3;
  creditFilter:any
  selectedCompany:any;
  companyNameAr :any;
  companyNameEn :any;
  companyNameHe :any;
  selectedMonth:any
    selectedYear:any
  ngOnInit(): void {
    this.curentLang = localStorage.getItem('curentLang');
    this.companyDefault = localStorage.getItem('companyDefault');
    this.companyNameAr = localStorage.getItem('company_name_ar');
    this.companyNameEn  = localStorage.getItem('company_name_en');
    this.companyNameHe  = localStorage.getItem('company_name_he');
 

    this.companyDefault = localStorage.getItem('companyDefault');
    if (this.companyDefault) {
      this.selectedCompany = this.companyDefault;
      
      if (this.curentLang == 'ar'){
        this.selectedCompany = this.companyNameAr;
        }
         else if (this.curentLang === 'he') {
          this.selectedCompany = this.companyNameHe;
        } 

        else if (this.curentLang === 'en') {
          this.selectedCompany = this.companyNameEn;
        } 

        else {
          this.curentLang = 'en';
        }
      

    }
  
    // تعيين اللغة الافتراضية استنادًا إلى لغة الموقع الحالية
    if (this.curentLang === 'ar') {
      this.curentLang = 'ar'; // لغة الموقع العربية
    } else if (this.curentLang === 'he') {
      this.curentLang = 'he'; // لغة الموقع العبرية
    } else {
      this.curentLang = 'en';
    }
  
    this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
    const today = new Date();
    this.selectedMonth = today.getMonth() + 1;  
    this.selectedYear = today.getFullYear();  
    this.checkClosedMonth(); 
    this.GetCustomers();
    this.GetCompany();
    this.GetBankList();
    this.getAllBankBranch();
    this.getAllCustomerType();
    if (this.companyDefault !== null && this.companyDefault !== 'null') {
      this.receiptForm.patchValue({
        company_id: Number(this.companyDefault),
      });
      this.checkSequenceExists(Number(this.companyDefault));
    }
  
    this.receiptForm.valueChanges.subscribe(() => {
      this.calculateTotalAmount();
    });
  
    this.receiptForm.get('company_id').valueChanges.subscribe(companyId => {
      if (companyId) {
        this.checkSequenceExists(companyId);
      }
    });

     const currentDate = new Date();
 

 //    this.receiptForm.patchValue({
   //   invoice_date: this.formatDate(currentDate), // تعيين تاريخ محدد
 //   });
  }
  
  checkClosedMonth() {
    this.spinner.show();
    this.service.checkClosedMonth(this.companyDefault, this.selectedMonth , this.selectedYear).subscribe({
      next: res => {
        this.spinner.hide();
        if (res.status == 200) {  
          this.toastr.warning(this.curentLang === 'en' 
                ? res.messageEn
                : this.curentLang === 'ar' 
                ? res.messageAr
                : res.messageHe);
        } else {
          this.toastr.info(this.curentLang === 'en' 
                ? 'The selected month and year are open.'
                : this.curentLang === 'ar' 
                ? 'الشهر والسنة المختارة مفتوحة.'
                : 'החודש והשנה שנבחרו פתוחים.');
        }
      },
      error: error => {
        this.spinner.hide();
        console.error('Error checking closed month:', error);
      }
    });
  }

  formatDate(date: Date): string {
    const day = ('0' + date?.getDate()).slice(-2);
    const month = ('0' + (date?.getMonth() + 1)).slice(-2);
    const year = date?.getFullYear();
    return `${day}/${month}/${year}`;
  }

  openXlCustomer(contentAddCustomer:any) {
    this.modalService.open(contentAddCustomer, { size: 'xl' , centered:true });
  };

  filterAddCustomer(event:any)
  {
    let x = event.target.value;
    };
  getAllCustomerType() {
    this.spinner.show();
    this.service.getAllCustomerType().subscribe({
      next: res => {
        this.customerTypeData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };
  addedData() {
    // إزالة الصفر الأولي من رقم الهاتف إذا كان موجودًا
    // let customerPhone = this.addCustomer.value.customer_phone;
    // if (customerPhone.startsWith('0')) {
    //   customerPhone = customerPhone.substring(1);
    // }
  
   
    const customerType = this.addCustomer.value.customer_type;

    // التحقق من القيم المطلوبة بناءً على نوع العميل
    if (
      this.addCustomer.value.customer_name_he.trim() === '' ||
      (customerType === 6 && this.addCustomer.value.customer_vat_number.trim() === '' || null) ||
   //   (customerType === 5 && this.addCustomer.value.id_number.trim() === '' || null) ||
      customerType == null
  ) {
      Swal.fire({
          icon: 'warning',
          title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة' : (this.curentLang === 'en' ? 'Please fill the required fields' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ' : ''))
      });
      this.validate = !this.validate;
      return;
  }
   else {
  
      let merchantTaxNumber = this.addCustomer.value.customer_vat_number;
      if (merchantTaxNumber !== '' && merchantTaxNumber?.length < 9) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'يجب ألا يقل الرقم الضريبي عن 9 أرقام' : 
                 (this.curentLang === 'en' ? 'The tax number must be at least 9 digits long' : 
                 (this.curentLang === 'he' ? 'מספר המס חייב להיות לפחות 9 מספרים' : ''))
        });
        return;
      }
  
      let merchantPhone = this.addCustomer.value.customer_phone;
      // if (merchantPhone !== '' && merchantPhone.length < 7) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: this.curentLang === 'ar' ? 'يجب ألا يقل رقم الهاتف عن 7 أرقام' : 
      //            (this.curentLang === 'en' ? 'The phone number must be at least 7 digits long' : 
      //            (this.curentLang === 'he' ? 'מספר הטלפון חייב להיות בן 7 ספרות לפחות' : ''))
      //   });
      //   return;
      // }
  
      // const validPrefixes = ['50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
      // let validationStartPhoneNumber = merchantPhone.substring(0, 2);
  
      // if (merchantPhone !== '' && !validPrefixes.includes(validationStartPhoneNumber)) {
      //   Swal.fire({
      //     icon: 'warning',
      //     title: this.curentLang === 'ar' ? 'يجب أن يبدأ رقم الهاتف بأي رقم من هذة الأرقام (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
      //            (this.curentLang === 'en' ? 'Phone number must start with one of these numbers (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
      //            (this.curentLang === 'he' ? 'צריך להתחיל עם אחד מהמספרים (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : ''))
      //   });
      //   return;
      // }
  
      // إزالة الصفر الأولي من رقم الهاتف إذا كان موجودًا
      // let customerPhoneFinal = this.addCustomer.value.customer_phone;
      // if (customerPhoneFinal.startsWith('0')) {
      //   customerPhoneFinal = customerPhoneFinal.substring(1);
      // }
  
      // تعيين قيمة customer_name_he إلى الحقول الأخرى
      const customerNameHe = this.addCustomer.value.customer_name_he;
      this.addCustomer.patchValue({
        customer_name_ar: customerNameHe,
        customer_name_en: customerNameHe
      });
  
      let model = {
        customer_name_en: this.addCustomer.value.customer_name_en,
        customer_name_ar: this.addCustomer.value.customer_name_ar,
        customer_name_he: this.addCustomer.value.customer_name_he,
        // customer_address_str: this.addCustomer.value.customer_address_str,
        // customer_address_city: this.addCustomer.value.customer_address_city,
        // customer_address_zip_code: this.addCustomer.value.customer_address_zip_code,
        // customer_Building_number: this.addCustomer.value.customer_Building_number,
        // customer_phone: customerPhoneFinal !== '' ? '+972' + customerPhoneFinal : '',
        customer_vat_number: this.addCustomer.value.customer_vat_number,
        customer_vat_tax: this.addCustomer.value.customer_vat_tax,
        id_number: this.addCustomer.value.id_number,
        customer_type: Number(this.addCustomer.value.customer_type),
        merchant_id: Number(this.merchantId),
        created_by: Number(this.userRegistId),
      };
  
      this.spinner.show();
      this.service.Post(AddCustomerEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
            this.GetCustomers();
          this.getAllCustomerType();
          this.addCustomer.reset({
            // customer_name_en: '',
            // customer_name_ar: '',
            // customer_name_he: '',
            // customer_address_str: '',
            // customer_address_city: '',
            // customer_address_zip_code: '',
            // customer_Building_number: '',
            // customer_phone: '',
            customer_vat_number: null,
            customer_vat_tax: null,
            id_number: null,
            customer_type: null,
          });
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
            case 403:
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }
  };

  isNumber(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    } else {
      return true;
    }
  }
  
  checkSequenceExists(companyId: number): void {
    const docType = '400'; // نوع المستند ثابت
    this.spinner.show();
    this.service.checkSequenceExists(companyId, docType).subscribe(res => {
      const sequenceExists = res;
   
      if (!sequenceExists) {
        Swal.fire({
          title: this.curentLang === 'en' ? 'Enter the starting number for the document sequence:' : this.curentLang === 'ar' ? 'أدخل الرقم الابتدائي لتسلسل المستند:' : 'הזן את המספר ההתחלתי של רצף המסמכים:',
          input: 'number',
          inputAttributes: {
            min: '1',
            step: '1'
          },
          inputValue: 1, // تعيين القيمة الافتراضية إلى 1
          showCancelButton: false,
          confirmButtonText: this.curentLang === 'en' ? 'Submit' : this.curentLang === 'ar' ? 'إرسال' : 'שלח',
          allowOutsideClick: false,
          allowEscapeKey: false,
          preConfirm: (value) => {
            if (!value || value < 1) {
              Swal.showValidationMessage(
                this.curentLang === 'en' ? 'Please enter a valid number greater than 0' : this.curentLang === 'ar' ? 'يرجى إدخال رقم صالح أكبر من 0' : 'אנא הזן מספר חוקי גדול מ-0'
              );
            }
          }
        }).then(result => {
          if (result.value) {
            this.createSequence(companyId, parseInt(result.value, 10));
          }
        });
      }
  
      this.spinner.hide();
    }, error => {
       this.spinner.hide();
    });
  }
  
  createSequence(companyId: number, startWith: number): void {
    this.spinner.show();
    const docType = '400'; // نوع المستند ثابت
  
    this.service.createSequence(companyId, docType, startWith).subscribe(res => {

      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        color: 'green',
        backdrop:true,
         
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'success',
        title: this.curentLang === 'ar' ? 'تم إنشاء التسلسل بنجاح' : (this.curentLang === 'en' ? 'Sequence created successfully' :'רצף נוצר בהצלחה')
      })

    }, error => {
     });
    this.spinner.hide();
  }
  
  filter(event:any)
  {
    let x = event ;
    
   };

   filterDate(event:any)
  {
    let x = new Date(event.target.value);    
    this.selectedMonth = x.getMonth() + 1; // Month is 0-based, so add 1
    this.selectedYear = x.getFullYear();
      
    this.checkClosedMonth();
    
  };

   filterPayment(event:any)
  {
    let x = event.target.value ;
    
   };

   filterCreidt(event: any, index: number) {
    const selectedValue = event.target.value;
    this.creditFilter = selectedValue;

    // Update the form control for credit_card_code at the specified index
    (this.receiptForm.get('linesCredit') as FormArray).at(index).get('credit_card_code')?.setValue(this.creditFilter);
}

   filterCustomer(event:any)
  {
      this.selectedCustomer = event;
    
   };


  availableLanguages = [
    { code: "he", name: "Hebrew" },
    { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    
];

  
  receiptForm = this.builder.group({
    invoice_date: this.builder.control(this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required),
    document_language: this.builder.control('he'),
    currency: this.builder.control('ILS' , Validators.required),
    cash_amount: this.builder.control(0, ),
    checks_amount : this.builder.control(null ),
    deduction_from_source : this.builder.control(0, ),
    bank_payment_amount : this.builder.control(0, ),
    credit_amount : this.builder.control(0, ),
    total_amount : this.builder.control(0, Validators.required),
    merchant_id : this.builder.control(this.merchantId),
    company_id : this.builder.control( null , Validators.required),
    customer_id : this.builder.control( '' , Validators.required),
    created_by : this.builder.control(this.userRegistId),
    linesCash: this.builder.array([] ),
    linesChecks: this.builder.array([] ),
    linesBankPayment: this.builder.array([] ),
    linesCredit: this.builder.array([] ),
  });
   
  get linesChecks() {
    return this.receiptForm.get("linesChecks") as FormArray;
  }
  
  get linesCash() {
    return this.receiptForm.get("linesCash") as FormArray;
  }

  get linesBankPayment() {
    return this.receiptForm.get("linesBankPayment") as FormArray;
  }
  
  get linesCredit() {
    return this.receiptForm.get("linesCredit") as FormArray;
  }
  

  SaveReceipt() {
    if (this.receiptForm.valid) {
        this.spinner.show(); // Start the spinner

        // Ensure cash_amount and checks_amount are set correctly
        const cashAmount = this.getTotalAmountCashArray();
        const checksAmount = this.getTotalAmountChecksArray();
        const bankPaymentAmount = this.getTotalAmountBankPaymentArray();
        const creditAmount = this.getTotalAmountCreidtArray();

        this.receiptForm.get('cash_amount').setValue(cashAmount);
        this.receiptForm.get('checks_amount').setValue(checksAmount);
        this.receiptForm.get('bank_payment_amount').setValue(bankPaymentAmount);
        this.receiptForm.get('credit_amount').setValue(creditAmount);

        const linesCash = this.receiptForm.get('linesCash') as FormArray;
        const linesChecks = this.receiptForm.get('linesChecks') as FormArray;
        const linesBankPayment = this.receiptForm.get('linesBankPayment') as FormArray;
        const linesCredit = this.receiptForm.get('linesCredit') as FormArray;
        if (linesCash?.length === 0 && linesChecks?.length === 0 && linesBankPayment?.length === 0 && linesCredit?.length === 0) {
          Swal.fire({
              icon: 'warning',
              title: this.curentLang === 'ar' ? 'يجب أن يكون هناك بيانات على الأقل في واحدة من وسائل الدفع' :
                      this.curentLang === 'he' ? '   חייבים להיות נתונים לפחות באחד מאמצעי התשלום    ' :
                      'There must be data in at least one of the payment methods'
          });
          this.spinner.hide();
          return; // Stop the execution if the condition is not met
          
        }

        this.service.SaveReceipt(this.receiptForm.getRawValue()).subscribe(res => {
            let result: any;
            result = res;

            if (result.status === 200) {
              this.router.navigate(['/dashboard/receipts']);
             

                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  color: 'green',
                  backdrop:true,
                   
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })
                
                Toast.fire({
                  icon: 'success',
                  title: this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe)
                })

                this.receiptForm.reset();
                
            } else if (result.status === 400 || result.status === 403 || result.status === 500) {
                let errorMessage;
                switch (result.status) {
                    case 400:
                        errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                        break;
                    case 403:
                        errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                        break;
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                        break;
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }
              
                this.toastr.error(errorMessage)
            }
            this.spinner.hide(); // Stop the spinner after the subscription completes
        },
        error => {
            // Handle error
            this.spinner.hide(); // Make sure to stop the spinner in case of error
        });
    } else {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'נא למלא כל השדות' : ''))
        });
    }
    this.spinner.hide()

}


  
  addNewLineToCash() {
    let linesCashArray = this.receiptForm.get("linesCash") as FormArray;

    let customerId = this.receiptForm.get("customer_id")?.value;
    let companyId = this.receiptForm.get("company_id")?.value;
    
    if (
      (customerId != null && customerId != '') &&
      (companyId != null && companyId != '') ) {
      linesCashArray.push(this.GenerateRowCash());

      // Update cash_amount
      this.receiptForm.get('cash_amount').setValue(this.getTotalAmountCashArray());
      this.calculateTotalAmount(); // Update total_amount
    } else {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'נא למלא כל השדות' : ''))
      });
    }
}

addNewLineToBankPayment() {
  let linesBankPaymentArray = this.receiptForm.get("linesBankPayment") as FormArray;

  let customerId = this.receiptForm.get("customer_id")?.value;
  let companyId = this.receiptForm.get("company_id")?.value;
  
  if (
    (customerId != null && customerId != '') &&
    (companyId != null && companyId != '') ) {
      linesBankPaymentArray.push(this.GenerateRowBankPayment());

    // Update cash_amount
    this.receiptForm.get('cash_amount').setValue(this.getTotalAmountBankPaymentArray());
    this.calculateTotalAmount(); // Update total_amount
  } else {
    Swal.fire({
      icon: 'warning',
      title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'נא למלא כל השדות' : ''))
    });
  }
}

addNewLineToChecks() {
  let linesChecksArray = this.receiptForm.get("linesChecks") as FormArray;

  let customerId = this.receiptForm.get("customer_id")?.value;
  let companyId = this.receiptForm.get("company_id")?.value;
  
  if (
      (customerId != null && customerId != '') &&
      (companyId != null && companyId != '') ) {
      linesChecksArray.push(this.GenerateRowCheck());

      // Update checks_amount
      this.receiptForm.get('checks_amount').setValue(this.getTotalAmountChecksArray());
      this.calculateTotalAmount(); // Update total_amount
  } else {
      Swal.fire({
          icon: 'warning',
          title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'נא למלא כל השדות' : ''))
      });
  }
}

addNewLineToCredit() {
  let linesChecksArray = this.receiptForm.get("linesCredit") as FormArray;

  let customerId = this.receiptForm.get("customer_id")?.value;
  let companyId = this.receiptForm.get("company_id")?.value;
  
  if (
      (customerId != null && customerId != '') &&
      (companyId != null && companyId != '') ) {
      linesChecksArray.push(this.GenerateRowCreidt());

      // Update checks_amount
      this.receiptForm.get('checks_amount').setValue(this.getTotalAmountCreidtArray());
      this.calculateTotalAmount(); // Update total_amount
  } else {
      Swal.fire({
          icon: 'warning',
          title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'נא למלא כל השדות' : ''))
      });
  }
}
 // GENERATE ROWS
  GenerateRowCheck() {
    return this.builder.group({
        date: this.builder.control('', Validators.required),
        reference: this.builder.control(1 , Validators.required),
        bank: this.builder.control( '' , Validators.required),
        branch: this.builder.control('' , Validators.required),
        account: this.builder.control({ value: 0, disabled: false } , Validators.required),
     //   details: this.builder.control(null),
        amount: this.builder.control({ value: 0, disabled: false } , Validators.required),
        payment_type: this.builder.control({ value: this.checkType, disabled: true }),
    });

    

  }

  GenerateRowCash() {
    return this.builder.group({
        date: this.builder.control('', Validators.required),
      //  details: this.builder.control(null ),
        amount: this.builder.control('', Validators.required),
        payment_type: this.builder.control({ value: this.cashType, disabled: true }),
    });
  }

  GenerateRowBankPayment() {
    return this.builder.group({
        date: this.builder.control('', Validators.required),
       // details: this.builder.control(null ),
        amount: this.builder.control('', Validators.required),
        account: this.builder.control({ value: 0, disabled: false } ),
        bank: this.builder.control('' ),
        payment_type: this.builder.control({ value: this.bankTransferType, disabled: true }),
    });
  }

 

  GenerateRowCreidt() {
    return this.builder.group({
        date: this.builder.control('', Validators.required),
      //  details: this.builder.control(null),
        amount: this.builder.control('', Validators.required),
        credit_card_code: this.builder.control('', Validators.required),
        credit_card_name: this.builder.control('', Validators.required),
        account: this.builder.control({ value: 0, disabled: false } , Validators.required),

        credit_type: this.builder.control('', Validators.required),
        payment_type: this.builder.control({ value: this.creditCardType, disabled: true }),
    });
}
  

   // Remove Cash Row
   removeLineFromCash(index: number) {
    const linesCashArray = this.receiptForm.get("linesCash") as FormArray;
    linesCashArray.removeAt(index);
    this.calculateTotalAmount();
  }

  // Remove Check Row
  removeLineFromChecks(index: number) {
    const linesChecksArray = this.receiptForm.get("linesChecks") as FormArray;
    linesChecksArray.removeAt(index);
    this.calculateTotalAmount();
  }

  removeLineFromBankPayment(index: number) {
    const linesChecksArray = this.receiptForm.get("linesBankPayment") as FormArray;
    linesChecksArray.removeAt(index);
    this.calculateTotalAmount();
  }

  removeLineFromCreidtPayment(index: number) {
    const linesChecksArray = this.receiptForm.get("linesCredit") as FormArray;
    linesChecksArray.removeAt(index);
    this.calculateTotalAmount();
  }

  getTotalAmountChecksArray(): number {
    let totalAmount = 0;
    const linesChecksArray = this.receiptForm.get("linesChecks") as FormArray;
    linesChecksArray.controls.forEach((control: FormGroup) => {
        totalAmount += control.get('amount').value || 0;
    });

    return totalAmount;
}

getTotalAmountCashArray(): number {
  let totalAmount = 0;
  const linesCashArray = this.receiptForm.get("linesCash") as FormArray;
  linesCashArray.controls.forEach((control: FormGroup) => {
      totalAmount += control.get('amount').value || 0;
      
  });
  return totalAmount;
}
getTotalAmountBankPaymentArray(): number {
  let totalAmount = 0;
  let linesBankPaymentArray = this.receiptForm.get("linesBankPayment") as FormArray;
  linesBankPaymentArray.controls.forEach((control: FormGroup) => {
      totalAmount += control.get('amount').value || 0;
      
  });
  return totalAmount;
}

getTotalAmountCreidtArray(): number {
  let totalAmount = 0;
  const linesChecksArray = this.receiptForm.get("linesCredit") as FormArray;
  linesChecksArray.controls.forEach((control: FormGroup) => {
      totalAmount += control.get('amount').value || 0;
  });

  return totalAmount;
}


calculateTotalAmount() {
  const cashAmount = this.getTotalAmountCashArray();
  const checksAmount = this.getTotalAmountChecksArray();
  const bankPayment = this.getTotalAmountBankPaymentArray();
  const creditAmount = this.getTotalAmountCreidtArray();
  const oldTotalAmount = this.receiptForm.get('total_amount').value; // قيمة total_amount السابقة
   const deductionFromSource = this.receiptForm.get('deduction_from_source').value || 0
  const newTotalAmount = (cashAmount + checksAmount + bankPayment + creditAmount + Number(deductionFromSource)) ;
  
  if (oldTotalAmount !== newTotalAmount) { // فقط في حالة تغير القيمة
    this.receiptForm.get('total_amount').setValue(newTotalAmount);
  }
}



getTotalAmount(): number {
  return this.receiptForm.get('total_amount').value;
}


  // SELECT OPTIONS
  GetCompany() {
    this.service.GetCompany().subscribe(res => {
      this.masterCompany = res;
    });
  }

  GetCustomers() {
    this.spinner.show();
    this.service.getAllActiveCustomersBasedOnMerchantId().subscribe(res => {
      this.masterCustomer = res;
      this.spinner.hide();
    });
  }

  getAllBankBranch() {
    this.spinner.show();
    this.service.getAllBankBranch().subscribe(res => {
      this.allBranchesData = res;
      
      this.spinner.hide();
    });
  }

  GetBankList() {
    this.spinner.show();
    this.service.GetBankList().subscribe(res => {
      this.masterBank = res;
      
      this.spinner.hide();
    });
  }
  

 
 
  getBranches(bankCode: string, index: number) {
    // التحقق إذا كانت القيم غير موجودة (undefined أو فارغة)
    if (!bankCode || index === undefined || index === null) {
        console.warn('bankCode or index is missing');
        // تعيين قائمة فارغة للفروع لهذا السطر
        this.masterBranches[index] = [];
        // إعادة تعيين حقل الفرع في الفورم
        const branchControl = (this.receiptForm.get('linesChecks') as FormArray).at(index).get('branch');
        branchControl.reset(); // إعادة تعيين حقل الفرع
        return;
    }

    // إذا كانت القيم موجودة، نتابع استدعاء الـ API
    const selectedBank = this.masterBank.find(bank => bank.bank_code === bankCode);
    if (selectedBank) {
        const bankId = selectedBank.bank_id;
        this.spinner.show(); // عرض الـ spinner أثناء التحميل
        this.service.getAllBranchById(bankId).subscribe({
            next: res => {
                // تخزين البيانات الخاصة بالفروع لهذا السطر
                this.masterBranches[index] = res;
                
                // التحقق من وجود الفرع المختار ضمن الفروع المحملة
                const branchControl = (this.receiptForm.get('linesChecks') as FormArray).at(index).get('branch');
                const branchValue = branchControl.value;
                const branchExists = res.some(branch => branch.bank_branch_code === branchValue);
                if (!branchExists) {
                    // إعادة تعيين حقل الفرع إذا لم يكن موجودًا
                    branchControl.reset();
                }

                this.spinner.hide(); // إخفاء الـ spinner بعد نجاح الاستدعاء
            }, 
            error: error => {
                console.error('Error fetching branches:', error);
                this.spinner.hide(); // إخفاء الـ spinner عند حدوث خطأ
            }
        });
    }
}

getBranches2(bankCode: string, index: number) {
  // التحقق إذا كانت القيم غير موجودة (undefined أو فارغة)
  if (!bankCode || index === undefined || index === null) {
      console.warn('bankCode or index is missing');
      // تعيين قائمة فارغة للفروع لهذا السطر
      this.masterBranches[index] = [];
      // إعادة تعيين حقل الفرع في الفورم
      const branchControl = (this.receiptForm.get('linesBankPayment') as FormArray).at(index).get('branch');
      branchControl.reset(); // إعادة تعيين حقل الفرع
      return;
  }

 
}

onCancelSelection(event: MouseEvent) {
  event.stopPropagation(); // منع انتشار الحدث إلى العناصر الأخرى داخل ng-select
  // تنفيذ الأكواد الخاصة بك هنا
}


getBranchesForIndex(index: number) {
    // Return the branches data for the corresponding row index
    return this.masterBranches[index] || [];
}

 


validateNumberInput(event: any) {
  console.log(event.target.value , 'event.target.value');
  
  const arabicToEnglishMap = {
    '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
    '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9'
  };

  let inputValue = event.target.value;

  // تحويل الأرقام العربية إلى أرقام إنجليزية
  inputValue = inputValue.replace(/[\u0660-\u0669]/g, (match) => arabicToEnglishMap[match]);

  // تحديث قيمة الحقل بالقيمة المعدلة
  event.target.value = inputValue;

  // إذا كنت تستخدم Reactive Forms، يجب تحديث قيمة الـ form control أيضًا
  const formControl = this.receiptForm.get('deduction_from_source');
  if (formControl) {
    formControl.setValue(inputValue, { emitEvent: false }); // تحديث القيمة بدون إطلاق حدث التغيير
  }
}




}
