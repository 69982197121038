<app-breadcrumb [title]="curentLang === 'en' ? 'Additional information about the company' : (curentLang === 'ar' ? ' معلومات اضافية عن الشركة ' : '   מידע נוסף על החברה ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Additional information about the company' : (curentLang === 'ar' ? ' معلومات اضافية عن الشركة ' : '   מידע נוסף על החברה')">
</app-breadcrumb>

<!--company info -->

<div class="container-fluid basic_table">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Additional information about the company' : (curentLang === 'ar' ? '  معلومات اضافية عن الشركة' : '  מידע נוסף על החברה')}}
        </h5>
           
       <div>
   

   


      <button class="btn mx-1 btn-outline-warning"  (click)="getActivityInCompany()">
        <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}</button>
       </div>
     
      </div>

      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                   <th scope="col"> {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? '  أسم الشركة  ' : '    שם החברה  ')}} </th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Tax Registration Number' : (curentLang === 'ar' ? '   رقم السجل الضريبي  ' : 'מספר רישום מס')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Commercial Register' : (curentLang === 'ar' ? ' رقم السجل التجاري  ' : '     מרשם מסחרי ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Last Invoice Date' : (curentLang === 'ar' ? '  تاريخ أخر فاتورة  ' : '  תאריך החשבונית האחרונה')}}</th>  
               
                <th scope="col"> {{ curentLang === 'en' ? 'Company Accountant Office ' : (curentLang === 'ar' ? '  مكتب محاسب الشركة' : ' משרד מנהל החשבונות')}}</th>
                <th scope="col"> {{ curentLang === 'en' ? 'Phone Company Accountant' : (curentLang === 'ar' ? '    هاتف محاسب الشركة' : 'מס טלפון מנהל החשובנות')}}</th>

                <th scope="col"> {{ curentLang === 'en' ? 'Office Address ' : (curentLang === 'ar' ? '   عنوان المكتب  ' : '   כתובת המשרד   ')}}</th>
 
                   <th scope="col"> {{ curentLang === 'en' ? 'Merchant Name' : (curentLang === 'ar' ? ' أسم التاجر' : 'שם סוחר')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Merchant Phone' : (curentLang === 'ar' ? ' هاتف التاجر' : '   טלפון סוחר   ')}}</th>

                  

  
 
                </tr>
              </thead>

              <tbody>
                <tr>
                   <td> 
                    {{curentLang === 'en' ? companyData?.companyInfo?.company_name_en : (curentLang === 'ar' ? companyData?.companyInfo?.company_name_ar : companyData?.companyInfo?.company_name_he)}}
                  </td>

                   <td>{{companyData?.companyInfo?.tax_register}}</td>
                   <td>{{ companyData?.companyInfo?.commercial_register}}  </td>
                   <td>{{ companyData?.lastInvoice?.invoice_date | date:'shortDate'}}</td> 

                 
                  
                  <td>{{ companyData?.officeCompany?.office_name}}</td>
                  <td>{{ companyData?.officeCompany?.office_phone}}</td>
                  
                  <td>{{ companyData?.officeCompany?.office_address}}</td>

                  
                  <td> 
                    {{curentLang === 'en' ? companyData?.companyInfo?.merchant?.merchant_name_en : (curentLang === 'ar' ? companyData?.companyInfo?.merchant?.merchant_name_ar : companyData?.companyInfo?.merchant?.merchant_name_he)}}
                  </td>
 

                  <td>{{ companyData?.companyInfo?.merchant?.users?.[0]?.phone_number}}</td>

                 

               
 

                </tr>
          
              </tbody>
            </table>
            <!-- <div class="pagination-container">
                <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

            </div> -->
          </div>
        </div>

        <div class=" d-flex justify-content-between"> 
          <div></div>

         <div *ngIf="user_type == 100 || user_type == 101">
          <a routerLink="/dashboard/merchant-info">
              <button class="btn btn-danger m-2 " *ngIf="!isInsideModal">
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                <i class="fa fa-rotate-left mx-1"></i>
              </button>
            </a>
         </div>

         <div *ngIf="user_type == 200 || user_type == 201">
          <a routerLink="/dashboard/company">
              <button class="btn btn-danger m-2 " >
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                <i class="fa fa-rotate-left mx-1"></i>
              </button>
            </a>
         </div>

        </div>

      </div>
    </div>
  </div>

    </div>
</div>




  