<app-breadcrumb [title]="curentLang === 'en' ? 'Details History Permanent File ' : (curentLang === 'ar' ? '  تفاصيل رفع الملف الموحد' : '    פרטים על העלאת הקובץ המאוחד      ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['    לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Details History Permanent File ' : (curentLang === 'ar' ? '  تفاصيل رفع الملف الموحد ' : '   פרטים על העלאת הקובץ המאוחד      ')">
</app-breadcrumb>

<!--Details History Permanent File info -->

<div class="container-fluid basic_table" >
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <h5 style="color: grey;"> 
                        {{ curentLang === 'en' ? 'History Permanent File ' : (curentLang === 'ar' ? ' تفاصيل رفع الملف الموحد ' : ' פרטים על העלאת הקובץ המאוחד      ')}}
                    </h5>
                    <div>
                        <button class="btn m-1  btn-outline-warning" (click)="getAllResponseLogsByLogId()">
                            <i class="fa fa-refresh mx-1 "></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
                        </button>

                            <button style="background-color: red; color: #fff;" class="btn" routerLink="/dashboard/history">Back</button>
                          
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5">
                        <div class="form-group search m-3">
                            <label for="seaarch" class="ml-2" style="color: grey;"> 
                                {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  
                            </label>
                            <input class="form-control  w-100" type="text" name="search"
                                [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" 
                                (ngModelChange)="filterTransactions()"
                                placeholder="{{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}" />
                        </div>
                    </div>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table class="table table-light text-center table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">{{ curentLang === 'en' ? 'Status' : (curentLang === 'ar' ? 'الحالة' : ' המצב  ')}}</th>
                                        <th scope="col">{{ curentLang === 'en' ? 'Details' : (curentLang === 'ar' ? ' التفاصيل  ' : ' הפרטים  ')}}</th>
                                      
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let transaction of filteredTransactionsData | paginate: { itemsPerPage: 400, currentPage: p }; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ transaction.status }}</td>
                                        <td>{{ transaction.reason }}</td>
                                         
                                    </tr>
                                </tbody>
                            </table>
                            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
