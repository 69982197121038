import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from 'sweetalert2';

@Component({
  selector: "app-update-company",
  templateUrl: "./update-company.component.html",
  styleUrls: ["./update-company.component.scss"]
})
export class UpdateCompanyComponent implements OnInit {
  
  curentLang: string = "";
  layoutVersion: any;
  userRegistId: any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));

  companyInfoData: any = [];
  id: number;
  uuid: string;
  companyNameAr: string = '';
  companyNameEn: string = '';
  companyNamehE: string = '';
  logoUrl: string = '';
  TaxRegister: string = '';
  CommercialRegister: string = '';
  startInvoiceNumber: string = '';
  VatListId: number;
  VATNameAR: string = '';
  VATNameEN: string = '';
  VATNameHE: string = '';
  CompanyAddressSTR: string = '';
  CompanyAddressSTRAr: string = '';
  CompanyAddressSTREn: string = '';
  CompanyBuildingNumber: string = '';
  CompanyAddressCity: string = '';
  CompanyAddressCityAr: string = '';
  CompanyAddressCityEn: string = '';
  CompanyAddressZipCode: string = '';
  CompanyId: number;
  companyPhone: any 

  public file: any;
  url: any; 
  files: File[] = [];
  currentImageUrl: any;
  updateCompanyInfo: FormGroup;
  updateCompanyInfoByAdmin: FormGroup;
  vatInfoData: any = [];
  public validate = false;

  newOrders = {
    colorClass: "primary",
    icon: "new-order",
    colorClass2: "warning",
    icon2: "customers",
    colorClass3: "success",
    icon4: "invoice",
    colorClass4: "secondary",
    icon3: "profit"
  };

  uploadImage = new FormGroup({
    file: new FormControl(''),  
    id: new FormControl(''),
  });

  constructor(  
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private service: HttpHelpService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal, 
    private cdRef: ChangeDetectorRef,
  ) { 
    this.curentLang = localStorage.getItem('curentLang');
    this.uuid = this.activatedRoute.snapshot.params['id'];
     

    this.updateCompanyInfo = new FormGroup({
      company_name_ar: new FormControl(''),  
      company_name_en: new FormControl(''),
      company_name_he: new FormControl(''),
      company_address_str: new FormControl(''),
     company_address_str_ar: new FormControl(''),
     company_address_str_en: new FormControl(''),
      company_Building_number: new FormControl(''),
      company_phone: new FormControl(''),
      company_address_city: new FormControl(''),
      company_address_city_ar: new FormControl(''),
      company_address_city_en: new FormControl(''),
      company_address_zip_code: new FormControl(''),
      updated_by: new FormControl(this.merchantId),
    });

    this.updateCompanyInfoByAdmin = new FormGroup({
      tax_register :new FormControl('' , [Validators.required, Validators.pattern('^[0-9]{9}$')] ),  
      commercial_register :new FormControl('' , [Validators.required, Validators.pattern('^[0-9]')] ),  
      company_name_ar: new FormControl(''),  
      company_name_en: new FormControl(''),
      company_phone: new FormControl(''),
      company_name_he :new FormControl('' , Validators.required),  
      company_address_str :new FormControl('' ,Validators.required ),
      company_address_str_ar :new FormControl('' , Validators.required ),
      company_address_str_en :new FormControl('' , Validators.required ),
      company_Building_number :new FormControl('' , Validators.required),
      company_address_city :new FormControl('' , Validators.required ),
      company_address_city_ar :new FormControl(''),
      company_address_city_en :new FormControl(''),
      company_address_zip_code :new FormControl('', Validators.required ),
      updated_by: new FormControl(this.userRegistId),
    });
  };

  openVerticallyCentered(dataDeleted:any) {
    this.modalService.open(dataDeleted, { centered: true });
  };

  deleteCompanyLogoByCompanyId() {
    this.spinner.show();
    this.service.deleteCompanyLogoByCompanyId(this.CompanyId).subscribe({
      next: (res) => {
        if (res.statusCode == 400 ||  res.statusCode == 500 || res.statusCode == 404) { 

          Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.spinner.hide();
          return;
        } else {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.getCompanyInfoById();
           this.modalService.dismissAll();
          this.spinner.hide();
        }
      },
      error: (err) => {
        Swal.fire(err.messageAr);
        this.spinner.hide(); 
      }
    });
  };

  getCompanyInfoById() {
    this.spinner.show();
    this.service.getCompanyInfoById(this.id).subscribe({
      next: res => {
        this.companyInfoData = res;   
         
        this.companyPhone =  this.companyInfoData?.company_phone;
        this.companyNameAr = this.companyInfoData?.company_name_ar;
        this.companyNameEn = this.companyInfoData?.company_name_en;
        this.companyNamehE = this.companyInfoData?.company_name_he;
        this.logoUrl = this.companyInfoData?.logo_url;
        this.CompanyId = this.companyInfoData?.company_id;      
        this.TaxRegister = this.companyInfoData?.tax_register;
        this.CommercialRegister = this.companyInfoData?.commercial_register;
        this.startInvoiceNumber = this.companyInfoData?.start_invoice_number;
        this.VatListId = this.companyInfoData?.vat_list_id;
        this.VATNameAR = this.companyInfoData?.vatList?.vat_name_ar;
        this.VATNameEN = this.companyInfoData?.vatList?.vat_name_en;
        this.VATNameHE = this.companyInfoData?.vatList?.vat_name_he;
        this.CompanyAddressSTR = this.companyInfoData?.company_address_str;
        this.CompanyAddressSTRAr = this.companyInfoData?.company_address_str_ar;
        this.CompanyAddressSTREn = this.companyInfoData?.company_address_str_en;

        this.CompanyAddressCity = this.companyInfoData?.company_address_city;
        this.CompanyAddressCityAr = this.companyInfoData?.company_address_city_ar;
        this.CompanyAddressCityEn = this.companyInfoData?.company_address_city_en;

        this.CompanyAddressZipCode = this.companyInfoData?.company_address_zip_code;
        this.CompanyBuildingNumber = this.companyInfoData?.company_Building_number;
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getCompanyIdByUuid() {
    this.service.getCompanyIdByUuid(this.uuid).subscribe({
      next: res => {
        this.id = res?.company_id; 
        this.getCompanyInfoById();
      },
      error: error => {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'فشل في الحصول على معلومات الشركة' : (this.curentLang === 'en' ? 'Failed to get company information' : 'נכשל בהשגת מידע על החברה')
        });
      }
    });
  }

  ngOnInit() {
    this.getCompanyIdByUuid();
    this.getAllActiveVatList();
    this.layoutVersion = localStorage.getItem("layout_version");
    if (this.layoutVersion == null || this.layoutVersion == undefined) {
      localStorage.setItem("layout_version", "light-only");
      this.layoutVersion = "light-only"; 
    } else {
      this.layoutVersion = localStorage.getItem("layout_version"); 
      if (this.layoutVersion === "dark-only") {
        document.body.classList.add("dark-only");
      } else {
        document.body.classList.add("light-only");
      }
    }
    this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
  };

  getAllActiveVatList() {
    this.spinner.show();
    this.service.getAllActiveVatList().subscribe({
      next: res => {
        this.vatInfoData = res;
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  setValue(userId: number) {
    const userToUpdate = this.companyInfoData; 
    if (userToUpdate) {
      this.uploadImage?.get('id')?.setValue(userToUpdate.company_id);
      this.uploadImage?.get('file')?.setValue(this.currentImageUrl);
    }
  };

  executeFunctions(userId: number, uploadData: any) {
    this.getId(userId);
    this.openVerticallyCentered2(uploadData , userId);
    this.setValue(userId);
    const selectedProfile = this.companyInfoData;
    if (selectedProfile) {
      this.currentImageUrl = selectedProfile?.logo_url; 
    } 
  };
  
  uploadFile() {
    const formData = new FormData();
    if(this.file != null){
      formData.append("file", this.file, this.file.name);
    }
    this.spinner.show();
    this.service.uploadCompanyImage(this.id, formData).subscribe(({
      next: response => {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? response.messageAr : (this.curentLang === 'en' ? response.messageEn : response.messageHe)
        });
        this.modalService.dismissAll();
        this.getCompanyInfoById();
        this.uploadImage.reset();
        this.spinner.hide();
        if(response.status == 400) {
          Swal.fire(response.message);
          this.spinner.hide();
        }
      },
      error: error => {
        Swal.fire(error.message);
        this.spinner.hide();
      }
    }));
  };

  onFileChanged(event: any) {
    this.file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.currentImageUrl = event.target.result; 
        this.cdRef.detectChanges();
      }
      reader.readAsDataURL(event.target.files[0]);
    };
  };
  
  openVerticallyCentered2(uploadData: any, userId: any) {
    this.modalService.open(uploadData, { centered: true });
    userId = this.id;
  };

  getId(id: number) {
    this.id = id;
  };

  updateCompanyById() {
  //   const companyNameHe = this.updateCompanyInfo.value.company_name_he;
  // this.updateCompanyInfo.patchValue({
  //   company_name_ar: companyNameHe,
  //   company_name_en: companyNameHe
  // });
    let model = {
      company_name_en: this.updateCompanyInfo.value.company_name_en,
      company_name_ar: this.updateCompanyInfo.value.company_name_ar,
      company_name_he: this.updateCompanyInfo.value.company_name_he,
      company_Building_number: this.updateCompanyInfo.value.company_Building_number,
      company_address_str: this.updateCompanyInfo.value.company_address_str,
      company_address_city: this.updateCompanyInfo.value.company_address_city,
      company_address_str_ar: this.updateCompanyInfo.value.company_address_str_ar,
      company_address_str_en: this.updateCompanyInfo.value.company_address_str_en,
      company_address_city_ar: this.updateCompanyInfo.value.company_address_city_ar,
      company_address_city_en: this.updateCompanyInfo.value.company_address_city_en,
      company_address_zip_code: this.updateCompanyInfo.value.company_address_zip_code,
      company_phone: this.updateCompanyInfo.value.company_phone,
      updated_by: Number(this.merchantId),
    }; 
  
    this.spinner.show();
    this.service.updateCompanyById(this.id, model).subscribe((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.modalService.dismissAll();
        this.getCompanyInfoById();
      } else if (res.status === 400 || res.status === 403 || res.status === 500) {
        let errorMessage;
        switch (res.status) {
          case 400:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 403:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 500:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            break;
          default:
            errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
        }
        Swal.fire({
          icon: 'error',
          title: errorMessage
        });
      }
      this.spinner.hide();
    });
  };

  updateCompanyByAdminByCompanyId() {

    if (this.updateCompanyInfoByAdmin.value.company_name_he == '' || this.updateCompanyInfoByAdmin.value.company_address_str == '' ||
      this.updateCompanyInfoByAdmin.value.company_Building_number == '' || this.updateCompanyInfoByAdmin.value.company_address_city == '' ||
      this.updateCompanyInfoByAdmin.value.company_address_str_ar == '' ||  this.updateCompanyInfoByAdmin.value.company_address_str_en == '' ||
      this.updateCompanyInfoByAdmin.value.company_address_city_ar == '' ||  this.updateCompanyInfoByAdmin.value.company_address_city_en == '' ||
      this.updateCompanyInfoByAdmin.value.company_Building_number == '' || this.updateCompanyInfoByAdmin.value.company_address_zip_code == '' ||
      this.updateCompanyInfoByAdmin.value.commercial_register == '' || this.updateCompanyInfoByAdmin.value.tax_register == '' 
     ) 
     {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة' : (this.curentLang === 'en' ? 'Please fill out the required fields' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים' : ''))
      });
  
      // Mark all controls as touched to show validation errors
      this.updateCompanyInfoByAdmin.markAllAsTouched();
      this.validate = !this.validate;

      return;
     }

     else
     {
      const companyNameHe = this.updateCompanyInfoByAdmin.value.company_name_he;
      this.updateCompanyInfoByAdmin.patchValue({
        company_name_ar: companyNameHe,
        company_name_en: companyNameHe
      });


      let model = {
        tax_register: this.updateCompanyInfoByAdmin.value.tax_register,
        commercial_register: this.updateCompanyInfoByAdmin.value.commercial_register,
        company_name_en: this.updateCompanyInfoByAdmin.value.company_name_en,
        company_name_ar: this.updateCompanyInfoByAdmin.value.company_name_ar,
        company_name_he: this.updateCompanyInfoByAdmin.value.company_name_he,
        company_phone: this.updateCompanyInfoByAdmin.value.company_phone,
        company_address_str: this.updateCompanyInfoByAdmin.value.company_address_str,
        company_address_str_ar: this.updateCompanyInfoByAdmin.value.company_address_str_ar,
        company_address_str_en: this.updateCompanyInfoByAdmin.value.company_address_str_en,
        company_Building_number: this.updateCompanyInfoByAdmin.value.company_Building_number,
        company_address_city: this.updateCompanyInfoByAdmin.value.company_address_city,
        company_address_city_ar: this.updateCompanyInfoByAdmin.value.company_address_city_ar,
        company_address_city_en: this.updateCompanyInfoByAdmin.value.company_address_city_en,
        company_address_zip_code: this.updateCompanyInfoByAdmin.value.company_address_zip_code,
        updated_by: Number(this.userRegistId),
      };
  
      this.spinner.show();
      this.service.updateCompanyByAdminByCompanyId(this.id, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getCompanyInfoById();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });

     }

 
  };

 

  isNumber(event: KeyboardEvent): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
