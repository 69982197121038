import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHelpService } from 'src/app/Data/service/http-help.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { NavService } from 'src/app/shared/services/nav.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public openNotification: boolean = false;
  public openInvoiceNotification: boolean = false;
  
  permissionId:number;

  public elem: any;
  curentLang:string="";
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  notificationsData:any [] =[];
  currentPage: number = 1;
  limit: number = 100000;
  offset: number = 0;
  total: number = 0; 
  term:any;
  p: any
  activeCompanyData:any

  constructor(
    public layout: LayoutService, 
    public navServices: NavService,
     @Inject(DOCUMENT) private document: any,
     public translate:TranslateService ,   private router: Router,  private service:HttpHelpService  ,
     private spinner: NgxSpinnerService ,private modalService: NgbModal,
     ) 
  {
     //Change Lang:
     this.curentLang = localStorage.getItem('curentLang');
     this.changeLanguage(this.curentLang)
     const layoutVersion = localStorage.getItem("layout_version");
  if (layoutVersion === "dark-only") {
    document.body.classList.add("dark-only");
  } else {
    document.body.classList.add("light-only");
  }
  this.curentLang = localStorage.getItem('curentLang');
  this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  this.user_type = JSON.parse(localStorage.getItem('user_type'));
  this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  }

  getMessage(item: any, curentLang: string): string {
    if (!item?.company) return '';
    const nameEn = item.company.company_name_en;
    const nameAr = item.company.company_name_ar;
    const nameHe = item.company.company_name_he;
  
    switch (curentLang) {
      case 'en':
        return `You have been chosen by ${nameEn} company to be its personal accountant`;
      case 'ar':
        return `لقد تم اختيارك من قبل شركة ${nameAr} لتكون المحاسب الشخصي لها`;
      case 'he':
        return `נבחרת על ידי ${nameHe} חברה להיות רואה החשבון האישי שלה`;
      default:
        return '';
    }
  }
  

  ngOnInit() {
    this.getAllPendingPermissionsByOffice();
    this.getAllActiveAgreedPermissionsByOfficeId();
    this.elem = document.documentElement;
    this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  }

  onPageChange(page: number) {
    this.currentPage = page;
    const offset = (page - 1) * this.limit;
    this.getAllPendingPermissionsByOffice(this.limit, offset);
  }
  

    getAllPendingPermissionsByOffice( limit?: number, offset?: number ) {
      this.spinner.show();
      limit = limit || this.limit;
      offset = offset || (this.currentPage - 1) * this.limit;
      this.service.getAllPendingPermissionsByOffice(limit, offset).subscribe({
        next: res => {
          this.notificationsData = res.data;
           
          this.total = res.count;
           
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
    };
  
  switchDirection(lang:string , dir:string)
	{
		document.body.setAttribute('direction', dir);
		this.document.getElementsByTagName('html')[0].lang = lang; 
        document.getElementsByTagName('html')[0].setAttribute('dir', dir);
		document.getElementsByTagName('html')[0].setAttribute('class',dir);
	}
  changeLanguage(lang: string) {
    localStorage.setItem('curentLang', lang);
    this.translate.use(lang);
    
    if (lang == "en") {
        this.switchDirection('en', 'ltr');
    } else if (lang == "ar") {
        this.switchDirection('ar', 'rtl');
    } else if (lang == "he") {
        this.switchDirection('he', 'rtl');
    }

    // Navigate to the current URL without full refresh
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
    });
}

 getAllActiveAgreedPermissionsByOfficeId( ) {
   
  this.spinner.show();
  this.service.getAllActiveAgreedPermissionsByOfficeId(this.officeIdNumber).subscribe({
    next: res => {
      this.activeCompanyData = res;
      this.spinner.hide();
       
    },
    error: () => {
      this.spinner.hide();
    }
  });
}

changeCompanyPermissionStatus(id:number, permission_status:any){
  this.spinner.show();

  let model =
  {
    "permission_status": permission_status == 16 ? 17 : 16,
   "updated_by": this.userRegistId
  }

  this.service.changeCompanyPermissionStatus(this.permissionId , model ).subscribe(({
    
    next:res =>{
     if (res.status ==200){

      Swal.fire({
        icon: 'success',
        title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
      })
        this.getAllPendingPermissionsByOffice();
        this.getAllActiveAgreedPermissionsByOfficeId();
        window.location.reload();
        this.modalService.dismissAll();
        this.spinner.hide();
     }

    },
    error:err =>{
      Swal.fire({
        icon:"error",
        title:err.error.message[0]
      });
      this.spinner.hide();

    }
    
  }))
};

getId(id: number) {
  this.permissionId = id;
 };

openVerticallyCenteredConfirmed(dataConfirm:any) {
  this.modalService.open(dataConfirm, { centered: true });
};

openVerticallyCenteredRejected(dataReject:any) {
  this.modalService.open(dataReject, { centered: true });
};

changeCompanyPermissionStatusReject(id:number, permission_status:any){
  this.spinner.show();

  let model =
  {
    "permission_status": permission_status == 17 ? 16 : 17,
   "updated_by": this.userRegistId
  }

  this.service.changeCompanyPermissionStatus(this.permissionId , model ).subscribe(({
    
    next:res =>{
     if (res.status ==200){

      Swal.fire({
        icon: 'success',
        title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
      })
        this.getAllPendingPermissionsByOffice();
        this.getAllActiveAgreedPermissionsByOfficeId();
        window.location.reload();
        this.modalService.dismissAll();
        this.spinner.hide();
     }

    },
    error:err =>{
      Swal.fire({
        icon:"error",
        title:err.error.message[0]
      });
      this.spinner.hide();

    }
    
  }))
};


  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
 
  }

  toggleInvoiceNotificationMobile() {
    this.openInvoiceNotification = !this.openInvoiceNotification;
 
  }

}
