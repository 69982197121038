import { OrderComponent } from './Data/order/order.component';
import { DocumentsComponent } from './Data/company/documents/documents.component';
import { UsersComponent } from './Data/users/users.component';
import { InvoicesTypesComponent } from './Data/invoices-types/invoices-types.component';
import { ProductsComponent } from './Data/products/products.component';
import { CustomersComponent } from './Data/customers/customers.component';
import { PlansInfoComponent } from './Data/plans-info/plans-info.component';
import { ViewMerchantComponent } from './Data/merchant/view-merchant/view-merchant.component';
import { FirstPageComponent } from './components/simple-page/first-page/first-page.component';
import { HttpConfigInterceptor } from './interciptor/jwt.interceptor';
import { RegisterComponent } from './Auth/register/register.component';
import { AboutComponent } from './landing-pages/about/about.component';
import { HomeheaderComponent } from './landing-pages/homeheader/homeheader.component';
import { HomeComponent } from './landing-pages/home/home.component';
import { NavigathionComponent } from './landing-pages/navigathion/navigathion.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

// // for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// // for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// // for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from '@ng-select/ng-select';

import { OverlayModule } from '@angular/cdk/overlay';
import { TermsAndConditionsComponent } from './landing-pages/terms-and-conditions/terms-and-conditions.component';
import { LoginComponent } from './Auth/login/login.component';
import { ForgotPasswordComponent } from './Auth/forgot-password/forgot-password.component';
import { SubPlansComponent } from './landing-pages/sub-plans/sub-plans.component';
import { Error404Component } from './Auth/error404/error404.component';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { OtpComponent } from './Auth/otp/otp.component';
import { ResendotpComponent } from './Auth/resendotp/resendotp.component';
import { PlansComponent } from './Auth/plans/plans.component';
import { CompanyComponent } from './Data/company/company.component';
import { DashboardComponent } from './Data/dashboard/dashboard.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { PlaneDetailsComponent } from './Data/dashboard/plane-details/plane-details.component';
import { MerchantComponent } from './Data/merchant/merchant.component';
import { UpdateMerchantComponent } from './Data/merchant/update-merchant/update-merchant.component';
import { ViewCompanyComponent } from './Data/company/view-company/view-company.component';
import { UpdateCompanyComponent } from './Data/company/update-company/update-company.component';
import { VatListComponent } from './Data/vat-list/vat-list.component';
import { CategoriesComponent } from './Data/categories/categories.component';
import { MeasurementUnitsComponent } from './Data/measurement-units/measurement-units.component';
import { UsersPrivilegesComponent } from './Data/users-privileges/users-privileges.component';
import { ReceiptsComponent } from './Data/receipts/receipts.component';
import { VatListDetailsComponent } from './Data/vat-list/vat-list-details/vat-list-details.component';
import { BanksComponent } from './Data/banks/banks.component';
import { BanksBranchesComponent } from './Data/banks/banks-branches/banks-branches.component';
import { PaymentMethodComponent } from './Data/payment-method/payment-method.component';
import { InvoicesComponent } from './Data/invoices/invoices.component';
import { AddInvoiceComponent } from './Data/invoices/add-invoice/add-invoice.component';
import {AddFinaicalRequestComponent} from './Data/finaical-request/add-finaical-request/add-finaical-request.component';
import { UpdateInvoiceComponent } from './Data/invoices/update-invoice/update-invoice.component';
import { ExportPdfComponent } from './Data/invoices/export-pdf/export-pdf.component';
import { AddReceiptsComponent } from './Data/receipts/add-receipts/add-receipts.component';
import { ExportReceiptPdfComponent } from './Data/receipts/export-receipt-pdf/export-receipt-pdf.component';
import { PermanentFileComponent } from './Data/permanent-file/permanent-file.component';
import { AppInfoComponent } from './Data/app-info/app-info.component';
import { FinaicalRequestComponent } from './Data/finaical-request/finaical-request.component';
import { UpdateFinaicalRequestComponent } from './Data/finaical-request/update-finaical-request/update-finaical-request.component';
import { TermsComponent } from './Data/terms/terms.component';
import { InvoiceAndReceiptComponent } from './Data/invoice-and-receipt/invoice-and-receipt.component';
import { AddInvoiceWithReceiptComponent } from './Data/invoice-and-receipt/add-invoice-with-receipt/add-invoice-with-receipt.component';
import { UpdateInvoiceWithReceiptComponent } from './Data/invoice-and-receipt/update-invoice-with-receipt/update-invoice-with-receipt.component';
import { ExportInvoiceWithReceiptPdfComponent } from './Data/invoice-and-receipt/export-invoice-with-receipt-pdf/export-invoice-with-receipt-pdf.component';
import { ExportFinaicalRequestPdfComponent } from './Data/finaical-request/export-finaical-request-pdf/export-finaical-request-pdf.component';
import { AccountingOfficesComponent } from './Data/accounting-offices/accounting-offices.component';
import { AccountingOfficesCompaniesComponent } from './Data/accounting-offices/accounting-offices-companies/accounting-offices-companies.component';
import { OfficeComponent } from './Data/Accountant/office/office.component';
import { OfficeUsersComponent } from './Data/Accountant/office-users/office-users.component';
import { OfficeUsersPrivilegesComponent } from './Data/Accountant/office-users-privileges/office-users-privileges.component';
import { OfficeCompaniesComponent } from './Data/Accountant/office-companies/office-companies.component';
import { AccountantUserCompaniesComponent } from './Data/Accountant/accountant-user-companies/accountant-user-companies.component';
import { AccountantsComponent } from './Data/accountants/accountants.component';
import { CompanyDocumentsComponent } from './Data/Accountant/company-documents/company-documents.component';
import { DocumentPdfComponent } from './Data/Accountant/document-pdf/document-pdf.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ExportReportsComponent } from './Data/Accountant/export-reports/export-reports.component';
import { AgGridModule } from 'ag-grid-angular';
import { UploadPermanentFileComponent } from './Data/upload-permanent-file/upload-permanent-file.component';
import { ReverceInvoiceComponent } from './Data/reverce-invoice/reverce-invoice.component';
import { UpdateReverceInvoiceComponent } from './Data/reverce-invoice/update-reverce-invoice/update-reverce-invoice.component';
import { ExportReverceInvoicePdfComponent } from './Data/reverce-invoice/export-reverce-invoice-pdf/export-reverce-invoice-pdf.component';
import { AddReverceInvoiceComponent } from './Data/reverce-invoice/add-reverce-invoice/add-reverce-invoice.component';
import { MoreInfoCompanyComponent } from './Data/company/more-info-company/more-info-company.component';
import { UploadAllocationNumberComponent } from './Data/upload-allocation-number/uploadAllocationNumber.component';
import { AllUsersOfficeComponent } from './Data/accounting-offices/all-users-office/all-users-office.component';
import { AllMerchantUsersComponent } from './Data/merchant/all-merchant-users/all-merchant-users.component';
import { PasswordModule } from 'primeng/password';
import { HistoryComponent } from './Data/upload-permanent-file/history/history.component';
import { CompanyEmployeeComponent } from './Data/Accountant/office-companies/company-employee/company-employee.component';
import { HistoryDetailsComponent } from './Data/upload-permanent-file/history/history-details/history-details.component';
import { CalendarModule } from 'primeng/calendar';
import { DataTableModule } from '@bhplugin/ng-datatable';
import { MissionComponent } from './Data/Mission/Mission.component';
import { AddMissionComponent } from './Data/Mission/add-mission/add-mission.component';
import { UpdateMissionComponent } from './Data/Mission/update-mission/update-mission.component';
import { AddOrderComponent } from './Data/order/add-order/add-order.component';
import { UpdateOrderComponent } from './Data/order/update-order/update-order.component';
import { OfferPriceComponent } from './Data/offer-price/offer-price.component';
import { AddOfferPriceComponent } from './Data/offer-price/add-offer-price/add-offer-price.component';
import { UpdateOfferPriceComponent } from './Data/offer-price/update-offer-price/update-offer-price.component';
import { SkeletonModule } from 'primeng/skeleton';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeheaderComponent,
    AboutComponent,
    SubPlansComponent,
    NavigathionComponent,
    TermsAndConditionsComponent,
    RegisterComponent,
    LoginComponent,
    OtpComponent,
    ResendotpComponent,
    ForgotPasswordComponent,
    Error404Component,
    PlansComponent,
    InvoiceAndReceiptComponent,
    AddInvoiceWithReceiptComponent,
    UpdateInvoiceWithReceiptComponent,
    FirstPageComponent,
    DashboardComponent,
    PlaneDetailsComponent,
    MerchantComponent,
    ViewMerchantComponent,
    UpdateMerchantComponent,
    CompanyComponent,
    ViewCompanyComponent,
    UpdateCompanyComponent,
    VatListComponent,
    CategoriesComponent,
    PlansInfoComponent,
    CustomersComponent,
    ProductsComponent,
    MeasurementUnitsComponent,
    UsersPrivilegesComponent,
    InvoicesTypesComponent,
    ReceiptsComponent,
    AddReceiptsComponent,
    UsersComponent,
    VatListDetailsComponent,
    BanksComponent,
    BanksBranchesComponent,
    PaymentMethodComponent,
    InvoicesComponent,
    AddInvoiceComponent,
    AddFinaicalRequestComponent,
    UpdateInvoiceComponent,
    ExportPdfComponent,
    ExportReceiptPdfComponent,
    PermanentFileComponent,
    AppInfoComponent,
    FinaicalRequestComponent,
    UpdateFinaicalRequestComponent,
    TermsComponent,
    ExportInvoiceWithReceiptPdfComponent,
    ExportFinaicalRequestPdfComponent,
    AccountingOfficesComponent,
    AccountingOfficesCompaniesComponent,
    OfficeComponent,
    OfficeUsersComponent,
    OfficeUsersPrivilegesComponent,
    OfficeCompaniesComponent,
    AccountantUserCompaniesComponent,
    AccountantsComponent,
    CompanyDocumentsComponent,
    DocumentPdfComponent,
    ExportReportsComponent,
    UploadPermanentFileComponent,
    ReverceInvoiceComponent,
    UpdateReverceInvoiceComponent,
    ExportReverceInvoicePdfComponent,
    AddReverceInvoiceComponent,
    MoreInfoCompanyComponent,
    UploadAllocationNumberComponent,
    DocumentsComponent,
    AllUsersOfficeComponent,
    AllMerchantUsersComponent,
    HistoryComponent,
    CompanyEmployeeComponent,
    HistoryDetailsComponent,
    MissionComponent,
    AddMissionComponent,
    UpdateMissionComponent,
    HomeComponent,
    OrderComponent,
    AddOrderComponent,
    UpdateOrderComponent,
    OfferPriceComponent,
    AddOfferPriceComponent,
    UpdateOfferPriceComponent,
     ],
  imports: [
  BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OverlayModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    NgxSpinnerModule,
     NgSelectModule,
     AgGridModule,
     PasswordModule,
     CalendarModule,
     DataTableModule,
     SkeletonModule,
    
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    
//     // for HttpClient use:
    LoadingBarHttpClientModule,
//     // for Router use:
    LoadingBarRouterModule,
//     // for Core use:
    LoadingBarModule,
ServiceWorkerModule.register('custom-sw.js', {
  enabled: true,
  // Register the ServiceWorker as soon as the application is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
})
  ],
  providers: [ CookieService , 
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    DatePipe 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
