<app-breadcrumb [title]="curentLang === 'en' ? 'History Permanent File ' : (curentLang === 'ar' ? '  سجلات رفع الملف الموحد' : '    רשומות העלאת קבצים מאוחדות        ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['    לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'History Permanent File ' : (curentLang === 'ar' ? '  سجلات رفع الملف الموحد ' : '   רשומות העלאת קבצים מאוחדות        ')">
</app-breadcrumb>

<!--History Permanent File info -->

<div class="container-fluid basic_table" >
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <h5 style="color: grey;"> 
                        {{ curentLang === 'en' ? 'History Permanent File ' : (curentLang === 'ar' ? ' سجلات رفع الملف الموحد ' : ' רשומות העלאת קבצים מאוחדות        ')}}
                    </h5>
                    <div>
                        <button class="btn m-1  btn-outline-warning" (click)="getAllTransactionsHistoryZipFile()">
                            <i class="fa fa-refresh mx-1 "></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
                        </button>

                            <button style="background-color: red; color: #fff;" class="btn" routerLink="/dashboard/upload-permanent-file">Back</button>
                          
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5">
                        <div class="form-group search m-3">
                            <label for="seaarch" class="ml-2" style="color: grey;"> 
                                {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  
                            </label>
                            <input class="form-control  w-100" type="text" name="search"
                                [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" 
                                (ngModelChange)="filterTransactions()"
                                placeholder="{{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}" />
                        </div>
                    </div>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table class="table table-light text-center table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">{{ curentLang === 'en' ? 'Action Performed By' : (curentLang === 'ar' ? 'اسم منفذ العملية' : ' שם המבצע')}}</th>
                                        <th scope="col">{{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה')}}</th>
                                        <!-- <th scope="col">{{ curentLang === 'en' ? 'Merchant Name' : (curentLang === 'ar' ? 'اسم التاجر' : 'שם הסוחר')}}</th> -->
                                        <th scope="col">{{ curentLang === 'en' ? 'Company Tax Number' : (curentLang === 'ar' ? 'الرقم الضريبي للشركة' : 'מספר מס של החברה')}}</th>
                                        <th scope="col">{{ curentLang === 'en' ? 'Type of Operation' : (curentLang === 'ar' ? 'نوع العملية' : 'סוג הפעולה')}}</th>
                                        <th scope="col">{{ curentLang === 'en' ? 'Successful' : (curentLang === 'ar' ? ' الناجحة' : 'הצליח')}}</th>
                                        <th scope="col">{{ curentLang === 'en' ? 'Failed' : (curentLang === 'ar' ? 'الفاشلة' : 'נכשל')}}</th>
                                        <th scope="col">{{ curentLang === 'en' ? 'note' : (curentLang === 'ar' ? 'ملاحظات' : 'הערות')}}</th>
                                        <th scope="col">{{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? 'تاريخ الإنشاء' : 'תאריך יצירה')}}</th>
                                        <th scope="col">{{ curentLang === 'en' ? 'Created Time' : (curentLang === 'ar' ? 'وقت الإنشاء' : 'זמן יצירה')}}</th>
                                        <th scope="col">{{ curentLang === 'en' ? 'Details' : (curentLang === 'ar' ? ' التفاصيل ' : 'הפרטים')}}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let transaction of filteredTransactionsData | paginate: { itemsPerPage: 400, currentPage: p }">
                                        <td>{{ transaction?.createdBy?.user_name }}</td>
                                        <td>{{ transaction?.company?.company_name_en }}</td>
                                        <!-- <td>{{ transaction?.company?.merchant?.merchant_name_en || '' }}</td> -->
                                        <td>{{ transaction?.company?.tax_register || '' }}</td>
                                        <td>{{ transaction.Type_of_operation }}</td>
                                        <td>{{ transaction.success_count }}</td>
                                        <td>{{ transaction.failure_count }}</td>
                                        <td>{{ transaction.note }}</td>
                                        <td>{{ transaction.createdDate | date:'dd/MM/yyyy' }}</td>
                                        <td>{{ transaction.createdDate | date:'HH:mm:ss' }}</td>
                                        <td><i class="fa fa-info-circle" 
                                            style="color: rgb(180, 180, 57); cursor: pointer;"
                                            routerLink="/dashboard/history-details/{{transaction.id}}">

                                        </i></td>

                                    </tr>
                                </tbody>
                            </table>
                            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
