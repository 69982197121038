import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { HttpHelpService } from "../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddPaymeentMethodEndPoient } from "../service/global.service";
import Swal from 'sweetalert2';

@Component({
  selector: "app-payment-method",
  templateUrl: "./payment-method.component.html",
  styleUrls: ["./payment-method.component.scss"]
})

export class PaymentMethodComponent implements OnInit {
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addPaymentMethod:FormGroup;
  updatePaymentMethod:FormGroup;
  paymentMethodData:any=[];
  paymentMethodId: number;
  updatedData: any;
  viewData: any;
  id:any;

  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
    activatedRoute:ActivatedRoute,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.id = activatedRoute.snapshot.params['id'];

   this.addPaymentMethod=new FormGroup({
    payment_method_name_ar :new FormControl('' , Validators.required),
    payment_method_name_en:new FormControl('' ,Validators.required),
    payment_method_name_he :new FormControl('' , Validators.required),  
    created_by:new FormControl(this.userRegistId),
    });

    this.updatePaymentMethod=new FormGroup({
      payment_method_name_ar :new FormControl(''),
      payment_method_name_en:new FormControl(''),
      payment_method_name_he :new FormControl(''),  
      updated_by:new FormControl(this.userRegistId),
      });
  };

  getAllPaymentMethod() {
    this.spinner.show();
    this.service.getAllPaymentMethod().subscribe({
      next: res => {
        this.paymentMethodData = res;
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  addedData() {
    if (
      this.addPaymentMethod.value.payment_method_name_ar == ''||
      this.addPaymentMethod.value.payment_method_name_en == ''||
      this.addPaymentMethod.value.payment_method_name_he == ''
        ) 
      {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      
      return; 
    }
      
    else
    {
      let model = {
        payment_method_name_ar: this.addPaymentMethod.value.payment_method_name_ar,
        payment_method_name_en: this.addPaymentMethod.value.payment_method_name_en,
        payment_method_name_he: this.addPaymentMethod.value.payment_method_name_he,
        created_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.Post(AddPaymeentMethodEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllPaymentMethod();
          this.addPaymentMethod.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }

  };

  setValue(paymentMethodId: number) {
    for (let i = 0; i < this.paymentMethodData.length; i++) {
      if (this.paymentMethodData[i].payment_method_id == this.paymentMethodId) {
        const selectedCategory = this.paymentMethodData.find(item => item.payment_method_id === paymentMethodId);

        this.updatePaymentMethod.controls.payment_method_name_ar.setValue(this.paymentMethodData[i].payment_method_name_ar);
        this.updatePaymentMethod.controls.payment_method_name_en.setValue(this.paymentMethodData[i].payment_method_name_en);
        this.updatePaymentMethod.controls.payment_method_name_he.setValue(this.paymentMethodData[i].payment_method_name_he);
      }
    };
  };

  getId(id: number) {
    this.paymentMethodId = id;
  };

  executeFunctions(BankID: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(BankID);
    this.setValue(BankID);
    this.updateMd(this.updatedData);
  };

  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  updatePaymentMethodById() {

    let model = {
        payment_method_name_ar: this.updatePaymentMethod.value.payment_method_name_ar,
        payment_method_name_en: this.updatePaymentMethod.value.payment_method_name_en,
        payment_method_name_he: this.updatePaymentMethod.value.payment_method_name_he,
        updated_by: Number(this.userRegistId),
    };
  
    this.spinner.show();
    this.service.updatePaymentMethodById( this.paymentMethodId, model).subscribe((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.modalService.dismissAll();
        this.getAllPaymentMethod();
      } else if (res.status === 400 || res.status === 403 || res.status === 500) {
        let errorMessage;
        switch (res.status) {
          case 400:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 403:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 500:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          default:
            errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
        }
        Swal.fire({
          icon: 'error',
          title: errorMessage
        });
      }
      this.spinner.hide();
    });


};

openVerticallyCentered(dataDeleted:any) {
  this.modalService.open(dataDeleted, { centered: true });
};

deletePaymentMethodById() {
  this.spinner.show();
  this.service.deletePaymentMethodById(this.paymentMethodId).subscribe({
    next: (res) => {
      if (res.status == 400) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.spinner.hide();
        return;
      } else {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.getAllPaymentMethod();
        this.modalService.dismissAll();
        this.spinner.hide();
      }
    },
    error: (err) => {
      Swal.fire(err.messageAr);
      this.spinner.hide(); 
    }
  });
};


  ngOnInit() 
  {
  this.getAllPaymentMethod();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
  };
}
