<app-breadcrumb [title]="curentLang === 'en' ? 'Plans Info' : (curentLang === 'ar' ? ' معلومات الخطط ' : '   מידע על תוכניות')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Plans Info' : (curentLang === 'ar' ? ' معلومات الخطط ' : ' מידע על תוכניות  ')">
</app-breadcrumb>

<!--plans info -->

<div class="container-fluid basic_table">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Plans Info' : (curentLang === 'ar' ? 'معلومات الخطط'  : '  מידע על תוכניות   ')}}
        </h5>

       <div>
        <button class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add Plan' : (curentLang === 'ar' ? '  اضافة خطة ' : '   הוסף תוכנית ')}}
        </button>

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllPans()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>
       </div>
     
      </div>
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Plan Name En' : (curentLang === 'ar' ? '  أسم الخطة عربي  ' : '    שם התוכנית הוא ערבי')}} </th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Description En' : (curentLang === 'ar' ? '  وصف الخطة عربي  ' : ' תיאור התכנית הוא בעברית ')}} </th>

                   <th scope="col"> {{ curentLang === 'en' ? 'Price' : (curentLang === 'ar' ? ' سعرالخطة  ' : 'המחיר')}}</th>

                   <th scope="col"> {{ curentLang === 'en' ? 'Number Of Documents' : (curentLang === 'ar' ? ' عدد المستندات  ' : 'מספר מסמכים')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Number Of Companies' : (curentLang === 'ar' ? ' عدد الشركات  ' : '   מספר חברות')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Number Of Users' : (curentLang === 'ar' ? ' عدد المستخدمين  ' : '   מספר משתמשים  ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Purchasable' : (curentLang === 'ar' ? '  قابلة للشراء    ' : ' ניתן לרכישה ')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  לצפות   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of planData | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td> 
                    {{curentLang === 'en' ? item.plan_name_en : (curentLang === 'ar' ? item.plan_name_ar : item.plan_name_he)}}
                  </td>

                  <td>
                    {{ curentLang === 'en' ? item.description_en.substring(0, 30) : (curentLang === 'ar' ? item.description_ar.substring(0, 30) : item.description_he.substring(0, 30)) }}
                </td>
                

                   <td>{{item.price}}</td>
                   <td>{{item.number_of_documents}}</td>
                   <td>{{item.number_of_company}}</td>
                   <td>{{item.number_of_users}}</td>
                   
                   <td *ngIf="item.repeatable==1" style="color:#54BA4A;" > 
                      {{ curentLang === 'en' ? 'Active' : (curentLang === 'ar' ? 'نشط' : ' פעיל ')}}
                    </td>

                    <td *ngIf="item.repeatable==59" style="color:#FC4438;" > 
                        {{ curentLang === 'en' ? 'Inactive' : (curentLang === 'ar' ? 'غير نشط' : '  לא פעיל ')}}
                      </td>

                      <td>
                        <button 
                         *ngIf="item.status_plan==1" class=" btn" style="border: none;" 
                         (click)="changeStatus(item?.plan_id, item.status_plan)">
                            <i class="fa solid fa-solid fa-toggle-on " style=" color: #213FA6;" ></i>
                        </button> 
        
                        <button 
                         *ngIf="item.status_plan==59" class=" btn" 
                        (click)="changeStatus(item?.plan_id, item.status_plan)">
                            <i class="fa solid fa-solid fa-toggle-off " style=" color: red;"></i>
                        </button> 
        
                      </td>


                   <td>{{ item.createdDate | date: 'dd/MM/yyyy'}}</td>

                  <td>
                    <i class="fa fa-eye" style="color: grey;   cursor: pointer;"
                    (click)="executeFunctionsView(  item?.plan_id,viewData ) "></i>
                  </td>

                   <td>
                     <i class="fa fa-edit" style=" cursor: pointer; color: green;"
                     (click)="executeFunctions(  item?.plan_id,updatedData ) ">
                    </i>
                   </td>

                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>


<!--add plan-->

<ng-template #content let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Add Plan ' : (curentLang === 'ar' ? ' اضافة خطة ' : '  הוסף תוכנית ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]=" addPlan">
                        <div class="row">
                            <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Plan Name Ar' : (curentLang === 'ar' ? '  اسم الخطة عربي   ' : ' שם התוכנית הוא ערבי   ')}}
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="plan_name_ar" 
                                  />
                              </div>
              
                              <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Plan Name En' : (curentLang === 'ar' ? '  اسم الخطة انجليزي   ' : '   שם התוכנית הוא באנגלית    ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="plan_name_en"
                                   />
                                </div>
              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Plan Name He' : (curentLang === 'ar' ? '  اسم الخطة عبري   ' : '   שם התוכנית הוא עברי    ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="plan_name_he"  
                                   maxlength="80"
                                  />
                                   
                                </div>

                                <div class="mb-3 col-xl-6 col-md-6">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Plan Type' : (curentLang === 'ar' ? ' نوع الخطة ' : ' סוג תוכנית   ')}}
                                  </label>
                                  <select class="form-select form-select-sm" style="color: #141414;"
                                          (change)="onPlanTypeChange($event)" formControlName="is_unlimited_documents">
                                    <option [value]="true" style="color: black; cursor: pointer;">
                                      {{ curentLang === 'en' ? 'Unlimited' : (curentLang === 'ar' ? 'غير محدود' : 'ללא מספר מסמכים')}}
                                    </option>
                                    <option [value]="false" style="color: black; cursor: pointer;">
                                      {{ curentLang === 'en' ? 'Limited' : (curentLang === 'ar' ? 'محدود' : 'מספר מסמכים')}}
                                    </option>
                                  </select>
                                </div>


                                <div class="mb-3 col-xl-6 col-md-6  ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Purchasable' : (curentLang === 'ar' ? '  قابلة للشراء    ' : ' ניתן לרכישה ')}}
                                 </label>

                                   <select class="form-select form-select-sm" style="color: #141414;"
                                   (change)="filter($event)" formControlName="repeatable">
                               
                                      <option *ngFor='let item of lookupData; let i = index '
                                      [value]="item?.lookup_detail_id">
                {{ curentLang === 'en' ?  item?.lookupDetailEnName :(curentLang === 'ar' ? item?.lookupDetailArName  :  item?.lookupDetailHeName )}}
                                  </option>
                                
                                  </select>
                               
                                </div>

              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Price' : (curentLang === 'ar' ? ' سعرالخطة  ' : 'המחיר')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control" type="number" 
                                   formControlName="price"
                                   
                                     />
                                </div>

                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Duration' : (curentLang === 'ar' ? '  المدة' : '  מֶשֶׁך')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control" type="number" 
                                   formControlName="duration"
                                   
                                     />
                                </div>

                                <div class="mb-3 col-xl-4 col-md-4" *ngIf="!addPlan.get('is_unlimited_documents')?.value">
                                  <label class="form-label" style="color: grey;">
                                    {{ curentLang === 'en' ? 'Number Of Documents' : (curentLang === 'ar' ? 'عدد المستندات' : 'מספר מסמכים')}}
                                  </label>
                                  <input style="color: grey;" class="form-control" type="number"
                                         formControlName="number_of_documents"/>
                                </div>

                             
                                 

                                  <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Number Of Users' : (curentLang === 'ar' ? ' عدد المستخدمين  ' : '   מספר משתמשים  ')}}                                   </label>
                                    <input style="color: grey;" 
                                    class="form-control" type="number" 
                                     formControlName="number_of_users"
                                     
                                       />
                                  </div>

                                  <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Number Of Companies' : (curentLang === 'ar' ? ' عدد الشركات  ' : '   מספר חברות')}}
                                   </label>
                                    <input style="color: grey;" 
                                    class="form-control" type="number" 
                                     formControlName="number_of_company"
                                     
                                       />
                                  </div>
                                 
                                  <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description Ar' : (curentLang === 'ar' ? '  وصف الخطة عربي  ' : '   תיאור התוכנית בערבית')}}   
                                   </label>
                                        <textarea
                                         maxlength="600" name=""
                                          id="description_ar" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="description_ar">
                                   </textarea>
                                  </div>

                                  <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description En' : (curentLang === 'ar' ? '  وصف الخطة انجليزي  ' : '  תיאור התוכנית באנגלית')}} 
                                   </label>
                                        <textarea
                                         maxlength="600" name=""
                                          id="description_ar" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="description_en">


                                   </textarea>
                                  </div>

                            
              
                                  <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description He' : (curentLang === 'ar' ? '  وصف الخطة عبري  ' : '   תיאור התוכנית בעברית')}} 
                                   </label>
                                        <textarea
                                         maxlength="600" name=""
                                          id="description_ar" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="description_he">
                                   </textarea>
                                  </div>
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button" 
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="addedData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--update plan-->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : ' ערוך מידע  ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="updatePlan">
                        <div class="row">
                          <div class="mb-3 col-xl-4 col-md-4 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Plan Name Ar' : (curentLang === 'ar' ? '  اسم الخطة عربي   ' : ' שם התוכנית הוא ערבי   ')}}
                             </label>
                              <input style="color: grey;" 
                               class="form-control" maxlength="80" 
                               type="text" formControlName="plan_name_ar" 
                                />
                            </div>
            
                            <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Plan Name En' : (curentLang === 'ar' ? '  اسم الخطة انجليزي   ' : '   שם התוכנית הוא באנגלית    ')}}
                               </label>
                                <input style="color: grey;" 
                                class="form-control"
                                 type="text"
                                 maxlength="80"
                                  formControlName="plan_name_en"
                                 />
                              </div>
            
                              <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Plan Name He' : (curentLang === 'ar' ? '  اسم الخطة عبري   ' : '   שם התוכנית הוא עברי    ')}}
                               </label>
                                <input style="color: grey;" class="form-control"
                                 type="text" 
                                 formControlName="plan_name_he"  
                                 maxlength="80"
                                />
                                 
                              </div>
                              <div class="mb-3 col-xl-6 col-md-6">
                                <label class="form-label" style="color: grey;">
                                  {{ curentLang === 'en' ? 'Plan Type' : (curentLang === 'ar' ? ' نوع الخطة ' : ' סוג תוכנית   ')}}
                                </label>
                                <input type="text" style="color: grey;" [value]="getPlanTypeLabel(updatePlan.get('is_unlimited_documents')?.value)" class="form-control" readonly>
                              </div>
                              

                              <div class="mb-3 col-xl-6 col-md-6  ">
                                <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Purchasable' : (curentLang === 'ar' ? '  قابلة للشراء    ' : ' ניתן לרכישה ')}}
                               </label>

                                 <select class="form-select form-select-sm" style="color: #141414;"
                                 (change)="filter($event)" formControlName="repeatable">
                             
                                    <option *ngFor='let item of lookupData; let i = index '
                                    [value]="item?.lookup_detail_id">
                                    {{ curentLang === 'en' ?  item?.lookupDetailEnName :(curentLang === 'ar' ? item?.lookupDetailArName  :  item?.lookupDetailHeName )}}
                                </option>
                              
                                </select>
                             
                              </div>
            
                              <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Price' : (curentLang === 'ar' ? ' سعرالخطة  ' : 'המחיר')}}
                               </label>
                                <input style="color: grey;" 
                                class="form-control" type="number" 
                                 formControlName="price"
                                 
                                   />
                              </div>

                              <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Duration' : (curentLang === 'ar' ? '  المدة' : '  מֶשֶׁך')}}
                               </label>
                                <input style="color: grey;" 
                                class="form-control" type="number" 
                                 formControlName="duration"
                                 
                                   />
                              </div>

                              <div class="mb-3 col-xl-4 col-md-4" *ngIf="!updatePlan.get('is_unlimited_documents')?.value">
                                <label class="form-label" style="color: grey;">
                                  {{ curentLang === 'en' ? 'Number Of Documents' : (curentLang === 'ar' ? 'عدد المستندات' : 'מספר מסמכים')}}
                                </label>
                                <input style="color: grey;" class="form-control" type="number"
                                       formControlName="number_of_documents"/>
                              </div>

                         

                               

                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Number Of Users' : (curentLang === 'ar' ? ' عدد المستخدمين  ' : '   מספר משתמשים  ')}}                                   </label>
                                  <input style="color: grey;" 
                                  class="form-control" type="number" 
                                   formControlName="number_of_users"
                                   
                                     />
                                </div>

                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Number Of Companies' : (curentLang === 'ar' ? ' عدد الشركات  ' : '   מספר חברות')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control" type="number" 
                                   formControlName="number_of_company"
                                   
                                     />
                                </div>
                               
                                
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Description Ar' : (curentLang === 'ar' ? '  وصف الخطة عربي  ' : '   תיאור התוכנית בערבית')}}   
                                 </label>
                                      <textarea
                                       maxlength="600" name=""
                                        id="description_ar" cols="10" rows="5" type="text"
                                        class="form-control form-control-sm"
                                         formControlName="description_ar">
                                 </textarea>
                                </div>

                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Description En' : (curentLang === 'ar' ? '  وصف الخطة انجليزي  ' : '  תיאור התוכנית באנגלית')}} 
                                 </label>
                                      <textarea
                                       maxlength="600" name=""
                                        id="description_ar" cols="10" rows="5" type="text"
                                        class="form-control form-control-sm"
                                         formControlName="description_en">


                                 </textarea>
                                </div>

            
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Description He' : (curentLang === 'ar' ? '  وصف الخطة عبري  ' : '   תיאור התוכנית בעברית')}} 
                                 </label>
                                      <textarea
                                       maxlength="600" name=""
                                        id="description_ar" cols="10" rows="5" type="text"
                                        class="form-control form-control-sm"
                                         formControlName="description_he">
                                 </textarea>
                                </div>
                      </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button" [disabled]="!updatePlan.valid"
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updatedPlanData()"> 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--view plan-->

<ng-template #viewData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'View Info ' : (curentLang === 'ar' ? ' مشاهدة المعلومات ' : '     הצג מידע      ')}} 
    </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                <form  [formGroup]="updatePlan">
                  <div class="row">
                    <div class="mb-3 col-xl-4 col-md-4 ">
                        <label class="form-label" style="color: grey;"> 
                          {{ curentLang === 'en' ? 'Plan Name Ar' : (curentLang === 'ar' ? '  اسم الخطة عربي   ' : ' שם התוכנית הוא ערבי   ')}}
                       </label>
                        <input style="color: grey;" readonly
                         class="form-control" maxlength="80" 
                         type="text" formControlName="plan_name_ar" 
                          />
                      </div>
      
                      <div class="mb-3 col-xl-4 col-md-4 ">
                          <label class="form-label" style="color: grey;"> 
                            {{ curentLang === 'en' ? 'Plan Name En' : (curentLang === 'ar' ? '  اسم الخطة انجليزي   ' : '   שם התוכנית הוא באנגלית    ')}}
                         </label>
                          <input  readonly style="color: grey;" 
                          class="form-control"
                           type="text"
                           maxlength="80"
                            formControlName="plan_name_en"
                           />
                        </div>
      
                        <div class="mb-3 col-xl-4 col-md-4 ">
                          <label class="form-label" style="color: grey;"> 
                            {{ curentLang === 'en' ? 'Plan Name He' : (curentLang === 'ar' ? '  اسم الخطة عبري   ' : '   שם התוכנית הוא עברי    ')}}
                         </label>
                          <input  readonly style="color: grey;" class="form-control"
                           type="text" 
                           formControlName="plan_name_he"  
                           maxlength="80"
                          />
                           
                        </div>
                        <div class="mb-3 col-xl-6 col-md-6">
                          <label class="form-label" style="color: grey;">
                            {{ curentLang === 'en' ? 'Plan Type' : (curentLang === 'ar' ? ' نوع الخطة ' : ' סוג תוכנית   ')}}
                          </label>
                          <input  readonly type="text" style="color: grey;" [value]="getPlanTypeLabel(updatePlan.get('is_unlimited_documents')?.value)" class="form-control" readonly>
                        </div>
                        

                        <div class="mb-3 col-xl-6 col-md-6  ">
                          <label class="form-label" style="color: grey;"> 
                              {{ curentLang === 'en' ? 'Purchasable' : (curentLang === 'ar' ? '  قابلة للشراء    ' : ' ניתן לרכישה ')}}
                         </label>

                           <select class="form-select form-select-sm" style="color: #141414;"
                           (change)="filter($event)" formControlName="repeatable">
                       
                              <option *ngFor='let item of lookupData; let i = index '
                              [value]="item?.lookup_detail_id">
                              {{ curentLang === 'en' ?  item?.lookupDetailEnName :(curentLang === 'ar' ? item?.lookupDetailArName  :  item?.lookupDetailHeName )}}
                          </option>
                        
                          </select>
                       
                        </div>
      
                        <div class="mb-3 col-xl-4 col-md-4 ">
                          <label class="form-label" style="color: grey;"> 
                            {{ curentLang === 'en' ? 'Price' : (curentLang === 'ar' ? ' سعرالخطة  ' : 'המחיר')}}
                         </label>
                          <input  readonly style="color: grey;" 
                          class="form-control" type="number" 
                           formControlName="price"
                           
                             />
                        </div>

                        <div class="mb-3 col-xl-4 col-md-4 ">
                          <label class="form-label" style="color: grey;"> 
                            {{ curentLang === 'en' ? 'Duration' : (curentLang === 'ar' ? '  المدة' : '  מֶשֶׁך')}}
                         </label>
                          <input  readonly style="color: grey;" 
                          class="form-control" type="number" 
                           formControlName="duration"
                           
                             />
                        </div>

                        <div class="mb-3 col-xl-4 col-md-4" *ngIf="!updatePlan.get('is_unlimited_documents')?.value">
                          <label class="form-label" style="color: grey;">
                            {{ curentLang === 'en' ? 'Number Of Documents' : (curentLang === 'ar' ? 'عدد المستندات' : 'מספר מסמכים')}}
                          </label>
                          <input  readonly style="color: grey;" class="form-control" type="number"
                                 formControlName="number_of_documents"/>
                        </div>

                   

                         

                          <div class="mb-3 col-xl-6 col-md-6 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Number Of Users' : (curentLang === 'ar' ? ' عدد المستخدمين  ' : '   מספר משתמשים  ')}}                                   </label>
                            <input  readonly style="color: grey;" 
                            class="form-control" type="number" 
                             formControlName="number_of_users"
                             
                               />
                          </div>

                          <div class="mb-3 col-xl-6 col-md-6 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Number Of Companies' : (curentLang === 'ar' ? ' عدد الشركات  ' : '   מספר חברות')}}
                           </label>
                            <input  readonly style="color: grey;" 
                            class="form-control" type="number" 
                             formControlName="number_of_company"
                             
                               />
                          </div>
                         
                          
                          <div class="mb-3 col-xl-4 col-md-4 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Description Ar' : (curentLang === 'ar' ? '  وصف الخطة عربي  ' : '   תיאור התוכנית בערבית')}}   
                           </label>
                                <textarea readonly
                                 maxlength="600" name=""
                                  id="description_ar" cols="10" rows="5" type="text"
                                  class="form-control form-control-sm"
                                   formControlName="description_ar">
                           </textarea>
                          </div>

                          <div class="mb-3 col-xl-4 col-md-4 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Description En' : (curentLang === 'ar' ? '  وصف الخطة انجليزي  ' : '  תיאור התוכנית באנגלית')}} 
                           </label> 
                                <textarea  readonly
                                 maxlength="600" name=""
                                  id="description_ar" cols="10" rows="5" type="text"
                                  class="form-control form-control-sm"
                                   formControlName="description_en">


                           </textarea>
                          </div>

      
                          <div class="mb-3 col-xl-4 col-md-4 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Description He' : (curentLang === 'ar' ? '  وصف الخطة عبري  ' : '   תיאור התוכנית בעברית')}} 
                           </label>
                                <textarea   readonly
                                 maxlength="600" name=""
                                  id="description_ar" cols="10" rows="5" type="text"
                                  class="form-control form-control-sm"
                                   formControlName="description_he">
                           </textarea>
                          </div>
                </div>
            
              
  
                  
                </form>
          
                
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>