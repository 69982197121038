import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  private timeoutId: any;
 //  private readonly TIMEOUT = 8 * 60 * 60 * 1000; // 8 ساعات بالميلي ثانية
 //  private readonly TIMEOUT = 89 * 24 * 60 * 60 * 1000; 
  private curentLang: string ='';
  private readonly TIMEOUT_LIMIT = 24 * 60 * 60 * 1000; // 1 يوم بالميلي ثانية
private remainingTimeout = 89 * 24 * 60 * 60 * 1000; // 89 يومًا بالميلي ثانية

  constructor(private toastr: ToastrService) {
    this.curentLang = localStorage.getItem('curentLang');
    this.initEventListeners();
    this.checkInactivity();
  }

  private initEventListeners() {
    window.addEventListener('mousemove', () => this.checkTokenAndResetTimer());
    window.addEventListener('mousedown', () => this.checkTokenAndResetTimer());
    window.addEventListener('keypress', () => this.checkTokenAndResetTimer());
    window.addEventListener('touchmove', () => this.checkTokenAndResetTimer());
    window.addEventListener('beforeunload', () => this.setLastActivityTime());
  }

  private checkTokenAndResetTimer() {
    if (localStorage.getItem('token')) {
      this.resetTimer();
    }
  }

 

  private setLastActivityTime() {
    localStorage.setItem('lastActivityTime', Date.now().toString());
  }

  private resetTimer() {
    this.setLastActivityTime();
  
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  
    if (this.remainingTimeout > this.TIMEOUT_LIMIT) {
      this.timeoutId = setTimeout(() => {
        this.remainingTimeout -= this.TIMEOUT_LIMIT;
        this.resetTimer();
      }, this.TIMEOUT_LIMIT);
    } else {
      this.timeoutId = setTimeout(() => this.logout(), this.remainingTimeout);
    }
  }

  private logout() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      color: 'red',
      backdrop: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
      willClose: () => {
        // localStorage.clear();
        localStorage.removeItem('user_id')
        localStorage.removeItem('token')
        localStorage.removeItem('user_type')
        localStorage.removeItem('merchant_id')
        localStorage.removeItem('allPrivilige')
        localStorage.removeItem('userType_en')
        localStorage.removeItem('routeId')
        localStorage.removeItem('office_id')
        localStorage.removeItem('companyDefault')
        localStorage.removeItem('selectedCompanyLogo')
        window.location.href = '/login';
      }
    });

    // let message;
    // switch (this.curentLang) {
    //   case 'ar':
    //     message = 'تم مسح بيانات الجلسة بسبب عدم النشاط.';
    //     break;
    //   case 'he':
    //     message = 'נמחקו נתוני המושב עקב חוסר פעילות.';
    //     break;
    //   case 'en':
    //   default:
    //     message = 'Session data cleared due to inactivity.';
    //     break;
    // }

    Toast.fire({
      icon: 'warning',
     title: this.curentLang === 'ar' ? 'تم مسح بيانات الجلسة بسبب عدم النشاط.': (this.curentLang === 'en' ? 'Session data cleared due to inactivity.' :'נמחקו נתוני המושב עקב חוסר פעילות.' )
    });
  }

  public checkInactivity() {
    const lastActivityTime = localStorage.getItem('lastActivityTime');
    if (lastActivityTime && localStorage.getItem('token')) {
      const currentTime = Date.now();
      const timeDiff = currentTime - parseInt(lastActivityTime, 10);
      if (timeDiff >= this.remainingTimeout) {
        this.logout();
      }
    }
  }
}
