import { HttpHelpService } from '../../src/app/Data/service/http-help.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { extractKeys } from './helpers';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor( private router: Router , private service: HttpHelpService) {
  
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const userType = localStorage.getItem('userType_en');
    const privilege = localStorage.getItem('allPrivilige');
    const privilegeArray = privilege ? JSON.parse(privilege) : [];
    const children = privilegeArray?.children;
    const keys = extractKeys(children);
    if (keys.includes((route.url[0].path))) {      
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
  
}
