import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddCompanyEndPoient, AddCompanyPermissionsEndPoient } from "../service/global.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"]
})

export class CompanyComponent implements OnInit {
  @Input() isInsideModal: boolean = false;

  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  selectedOffice:any;

  term:any;
  p: any;
  companyData:any=[];
  addCompany:FormGroup;
  addCompanyByAdmin:FormGroup;
  addPermissionForm:FormGroup;

  merchantInfoData:any=[];
  merchantInfoCompanyData:any=[];
  vatInfoData:any=[];
  currentPage: number = 1;
  limit: number = 400;
  customLimit: number = 400;
  offset: number = 0;
  total: number = 0; 
  activeAccountantOfficeData:any=[];
  CompanyId:any;
  MerchantID:any;
  redirectUrl:any;
  public validate = false;
  public addvalidateCompanyMerchant = false;
  CompanyIdRow:any;
  documentForm: FormGroup ;
  selectedPeriod: number = 1;
  fromDate: string | null = null;
  toDate: string | null = null;
  ReportDataFilters: any = [];
  reportsForm: FormGroup ;
  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
    private toastr: ToastrService ,
    private fb: FormBuilder,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));

   this.addCompany=new FormGroup({
      company_name_ar :new FormControl(''),
      company_name_en:new FormControl(''),
      company_phone:new FormControl(''),
      company_name_he :new FormControl('' , Validators.required),  
      tax_register :new FormControl('' , Validators.required ),  
      commercial_register :new FormControl('' , Validators.required ),  
      company_address_str :new FormControl('' ,Validators.required  ),
      company_Building_number :new FormControl('' , Validators.required),
      company_address_city :new FormControl('' , Validators.required ),
      company_address_zip_code :new FormControl('', Validators.required ),
      merchant_id :new FormControl(this.merchantId ),  
      vat_list_id :new FormControl(''),
      created_by:new FormControl(this.userRegistId),
    });

    this.addCompanyByAdmin=new FormGroup({
      company_name_ar :new FormControl(''),
      company_name_en:new FormControl(''),
      company_phone:new FormControl(''),
      company_name_he :new FormControl('' , Validators.required),  
      tax_register :new FormControl('' , Validators.required ),  
      commercial_register :new FormControl('' , Validators.required ),  
      company_address_str :new FormControl('' ,Validators.required ),
      company_Building_number :new FormControl('' , Validators.required),
      company_address_city :new FormControl('' , Validators.required ),
      company_address_zip_code :new FormControl('', Validators.required ),
      merchant_id :new FormControl('' , Validators.required),  
      vat_list_id :new FormControl(''),
      created_by:new FormControl(this.userRegistId),
    });


    this.addPermissionForm = new FormGroup({
      companyUser: new FormControl([], Validators.required),   
      office_id: new FormControl(),
      merchant_id: new FormControl(),
      created_by: new FormControl(this.userRegistId),
    });


    this.reportsForm = new FormGroup({
      companyId: new FormControl(this.CompanyId),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      lang  : new FormControl(this.curentLang),
    });

    
   
  
  };
  isNumber(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    } else {
      return true;
    }
  }
   
  addedPermission() {
    if (this.addPermissionForm.invalid) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      return;
    } else {
      const companyUsers = this.addPermissionForm.value.companyUser;
      
      const models = companyUsers?.map(companyUser => ({
        office_id: Number(companyUser.office_id),
        company_id: Number(this.CompanyId), // تأكد من أنك تحصل على معرف الشركة بشكل صحيح
        merchant_id: Number(this.MerchantID),
        created_by: Number(this.userRegistId),
      }));

       
    
      this.spinner.show();
      this.service.Post(AddCompanyPermissionsEndPoient.POST, models).subscribe((res) => {
        this.spinner.hide();
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllActiveAccountingOffices();
          this.getAllCompaniesInfo();
          this.getAllMerchantInfo();
          this.addPermissionForm.reset();
        } else {
          let errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
      });
    }
  };

  filterSelected(event: any) {
    this.selectedOffice = event;
    this.addPermissionForm.patchValue({ office_id: this.selectedOffice });

  };

  getIdToSend(id:number)
  {
    this.CompanyId = id ;
  };
  openVerticallyCenteredConfirmed(content4: any) {
    this.modalService.open(content4, { centered: true });
  };

  openXl4(content4:any) {
    this.modalService.open(content4, { size: 'md' , centered:true });
  };

  ConnectWithTaxGovernment() {
    this.spinner.show();
  
    const connectFunc = this.service.ConnectWithTaxGovernment.bind(this.service);
  
    connectFunc(this.CompanyId).subscribe((res) => {
      if (res && res.url) {
        const redirectUrl = res.url;
  
        // Show toast
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          color: 'green',
          backdrop: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          }
        });
  
        Toast.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? 'برجاء الأنتظار سيتم تحويلك الأن الي موقع مصلحة الضرائب' : 
                 (this.curentLang === 'en' ? 'Please wait. You will now be transferred to the Tax Authority website' : 
                 'אנא המתן כעת תועבר לאתר רשות המסים')
        });
  
        this.modalService.dismissAll();
  
        // Open redirect URL in the same tab
        window.location.href = redirectUrl;
      } else {
        this.toastr.error('No redirect URL found');
      }
      this.spinner.hide();
    }, (error) => {
      this.toastr.error('An error occurred');
      this.spinner.hide();
    });
  }
  
  
  isTokenExpired(expirationDate: string): boolean {
    const expiration = new Date(expirationDate);
    const now = new Date();
    return now >= expiration;
  }
  handleClick(companyId: number, content4: any) {
      this.getIdToSend(companyId);
     
  };

  // handleClick(companyId: number, content4: any, expirationDate: string) {
  //   if (this.isTokenExpired(expirationDate)) {
  //     this.getIdToSend(companyId);
  //     this.openXl4(content4);
  //   }
  // }
  
  getCompanyId(id:number , merchantid:number)
  {
     this.CompanyId = id ;
     this.MerchantID = merchantid

  };

  getAllCompaniesInfo(limit?: number, offset?: number  ,keyword?: any) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
    this.spinner.show();
    this.service.getAllCompaniesInfo(limit, offset , keyword).subscribe({
      next: res => {
        this.companyData = res.data;
        this.total = res.total;
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
      }
    });
  };

  getAllActiveAccountingOffices( ) {
   
    this.spinner.show();
    this.service.getAllActiveAccountingOffices().subscribe({
      next: res => {
        this.activeAccountantOfficeData = res;
        this.spinner.hide(); 
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }

  onPageChange(page: number) {
    this.currentPage = page;
    const offset = (page - 1) * this.limit;
    this.getAllCompaniesInfo(this.limit, offset, this.term);
  }
  
  onLimitChange() {
    this.limit = this.customLimit;
    this.offset = (this.currentPage - 1) * this.limit;
    this.getAllCompaniesInfo(this.limit, this.offset, this.term);
  }

  getAllMerchantInfo() {
    this.spinner.show();
    this.service.getAllMerchantInfo().subscribe({
      next: res => {
        this.merchantInfoData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  filterMerchant(event:any)
{
  let x = event;
 };

  filter(event:any)
{
  let x = event.target.value;
 };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  openXl3(content3:any) {
    this.modalService.open(content3, { size: 'md'  });
  };

  openXl5(content5:any) {
    this.modalService.open(content5, { size: 'lg'  });
  };

  openXl2(contentAdmin:any) {
    this.modalService.open(contentAdmin, { size: 'xl' , centered:true });
  };

  addedData() {
    // التحقق من صحة النموذج باستخدام formControl
    if (this.addCompany.invalid) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
  
      // Mark all controls as touched to show validation errors
      this.addCompany.markAllAsTouched();
      return;
    }

    const companyNameHe = this.addCompany.value.company_name_he;
    this.addCompany.patchValue({
      company_name_ar: companyNameHe,
      company_name_en: companyNameHe
    });
  
    let model = {
      company_name_ar: this.addCompany.value.company_name_ar,
      company_name_en: this.addCompany.value.company_name_en,
      company_name_he: this.addCompany.value.company_name_he,
      company_phone: this.addCompany.value.company_phone,
      company_address_str: this.addCompany.value.company_address_str,
      company_Building_number: this.addCompany.value.company_Building_number,
      company_address_city: this.addCompany.value.company_address_city,
      company_address_zip_code: this.addCompany.value.company_address_zip_code,
      tax_register: this.addCompany.value.tax_register,
      commercial_register: this.addCompany.value.commercial_register,
      vat_list_id: Number(this.addCompany.value.vat_list_id),
      merchant_id: Number(this.merchantId),
      created_by: Number(this.userRegistId),
    };
  
    this.spinner.show();
    this.service.Post(AddCompanyEndPoient.POST, model).subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllCompaniesInfo();
          this.getAllMerchantInfo();
          // إعادة تعيين النموذج مع القيم الافتراضية
          this.resetForm();
        } else {
          this.handleErrorResponse(res);
        }
      },
      (err) => {
        this.spinner.hide();
        this.handleErrorResponse(err);
      }
    );
  }

  resetForm() {
    this.addCompany.reset({
      company_name_ar: '',
      company_name_en: '',
      company_name_he: '',
      company_phone: '',
      company_address_str: '',
      company_Building_number: '',
      company_address_city: '',
      company_address_zip_code: '',
      tax_register: '',
      commercial_register: '',
      vat_list_id: null,
    });
  }

  resetFormAdmin() {
    this.addCompanyByAdmin.reset({
      company_name_ar: '',
      company_name_en: '',
      company_name_he: '',
      company_phone: '',
      company_address_str: '',
      company_Building_number: '',
      company_address_city: '',
      company_address_zip_code: '',
      tax_register: '',
      commercial_register: '',
      merchant_id: null,
      vat_list_id: null,
    });
  }

  handleErrorResponse(response: any) {
    let errorMessage;
    switch (response.status) {
      case 400:
      case 403:
      case 406:
        errorMessage = this.curentLang === 'ar' ? response.messageAr : (this.curentLang === 'en' ? response.messageEn : response.messageHe);
        break;
      default:
        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
    }
    Swal.fire({
      icon: 'error',
      title: errorMessage
    });
  }

  handleErrorResponseA(response: any) {
    let errorMessage;
    switch (response.status) {
      case 400:
      case 403:
      case 406:
        errorMessage = this.curentLang === 'ar' ? response.messageAr : (this.curentLang === 'en' ? response.messageEn : response.messageHe);
        break;
      default:
        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
    }
    Swal.fire({
      icon: 'error',
      title: errorMessage
    });
  }
  addedDataByAadmin() {

    if (this.addCompanyByAdmin.value.company_name_he == '' || this.addCompanyByAdmin.value.company_address_str == '' ||
      this.addCompanyByAdmin.value.company_Building_number == '' || this.addCompanyByAdmin.value.company_address_city == '' ||
      this.addCompanyByAdmin.value.company_Building_number == '' || this.addCompanyByAdmin.value.company_address_zip_code == '' ||
      this.addCompanyByAdmin.value.commercial_register == '' || this.addCompanyByAdmin.value.tax_register == '' 
     ) 
     {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة' : (this.curentLang === 'en' ? 'Please fill out the required fields' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים' : ''))
      });
  
      // Mark all controls as touched to show validation errors
      this.addCompanyByAdmin.markAllAsTouched();
      this.validate = !this.validate;

      return;
     }
     
     else
     {
      const companyNameHe = this.addCompanyByAdmin.value.company_name_he;
      this.addCompanyByAdmin.patchValue({
        company_name_ar: companyNameHe,
        company_name_en: companyNameHe
      });
    
  
      let model = {
        company_name_ar: this.addCompanyByAdmin.value.company_name_ar,
        company_name_en: this.addCompanyByAdmin.value.company_name_en,
        company_name_he: this.addCompanyByAdmin.value.company_name_he,
        company_phone: this.addCompanyByAdmin.value.company_phone,
        company_address_str: this.addCompanyByAdmin.value.company_address_str,
        company_Building_number: this.addCompanyByAdmin.value.company_Building_number,
        company_address_city: this.addCompanyByAdmin.value.company_address_city,
        company_address_zip_code: this.addCompanyByAdmin.value.company_address_zip_code,
        tax_register: this.addCompanyByAdmin.value.tax_register,
        commercial_register: this.addCompanyByAdmin.value.commercial_register,
        vat_list_id: Number(this.addCompanyByAdmin.value.vat_list_id),
        merchant_id: Number(this.addCompanyByAdmin.value.merchant_id),
        created_by: Number(this.userRegistId),
      };
    
       this.spinner.show();
      this.service.Post(AddCompanyEndPoient.POST, model).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
            this.getAllCompaniesInfo();
            this.getAllMerchantInfo();
            // إعادة تعيين النموذج مع القيم الافتراضية
            this.resetFormAdmin();
          } else {
            this.handleErrorResponse(res);
          }
        },
        (err) => {
          this.spinner.hide();
          this.handleErrorResponse(err);
        }
      );
     }
  

  
  };
  
  getId(id:number)
{
   this.merchantId = id ;
   this.CompanyIdRow = id;
};

openVerticallyCentered(dataDeleted:any) {
  this.modalService.open(dataDeleted, { centered: true });
};

deleteCompanyByCompanyId() {
  this.spinner.show();
  this.service.deleteCompanyByCompanyId(this.CompanyIdRow).subscribe({
    next: (res) => {
      if (res.status == 400) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.spinner.hide();
        return;
      } else {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.getAllCompaniesInfo();
        this.getAllMerchantInfo();
        // this.getAllCompaniesInfoByMerchantId();
        this.getAllActiveAccountingOffices();
        this.modalService.dismissAll();
        this.spinner.hide();
      }
    },
    error: (err) => {
      Swal.fire(err.messageAr);
      this.spinner.hide(); 
    }
  });
};


  ngOnInit() {
    this.getAllCompaniesInfo();
    this.getAllMerchantInfo();
    // this.getAllCompaniesInfoByMerchantId();
    this.getAllActiveAccountingOffices();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   } else {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only") {
       document.body.classList.add("dark-only");
     } else {
       document.body.classList.add("light-only");

     }
   }
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
   this.reportsForm = this.fb.group({
    companyId: [null],
    startDate: [''],
    endDate: [''],
    document_type: [1],
    lang: this.curentLang
  });

 
  const currentDate = new Date();
  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  this.reportsForm.patchValue({
    startDate: this.formatDate(startDate),
    endDate: this.formatDate(endDate)
  });

  this.fromDate = this.formatDate(startDate);
  this.toDate = this.formatDate(endDate);
  };



  filterMonth(event: any) {
    const selectedValue = parseInt(event.target.value, 10);
    const currentDate = new Date();
  
    let fromDate: Date;
    let toDate: Date;
  
    switch (selectedValue) {
      case 1: // Current month
        fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        break;
      case 2: // Last month
        fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        toDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        break;
      case 3: // Start of the year to the end of the current month
        fromDate = new Date(currentDate.getFullYear(), 0, 1);
        toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        break;
      case 4: // Last year
        fromDate = new Date(currentDate.getFullYear() - 1, 0, 1);
        toDate = new Date(currentDate.getFullYear() - 1, 11, 31);
        break;
      case 5: // Last two full months (excluding the current month)
        fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1);
        toDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        break;
      default:
        return;
    }
  
    this.fromDate = this.formatDate(fromDate);
    this.toDate = this.formatDate(toDate);
    this.selectedPeriod = selectedValue;
  
    this.documentForm.patchValue({
      startDate: this.fromDate,
      endDate: this.toDate,
      period: selectedValue
    });
}

formatDate(date: Date): string {
  const day = ('0' + date?.getDate()).slice(-2);
  const month = ('0' + (date?.getMonth() + 1)).slice(-2);
  const year = date?.getFullYear();
  return `${day}/${month}/${year}`;
}
onFromDateChange(event: Date): void {
  this.fromDate = this.formatDate(event);
}

onToDateChange(event: Date): void {
  this.toDate = this.formatDate(event);
  }

confirmAction() {
  this.getReportFilters(this.CompanyId , this.fromDate, this.toDate,  this.curentLang);
}




getReportFilters(CompanyId?:any ,  fromDate?: string, toDate?: string, lang ?: any, ) {
  
    this.spinner.show();

  this.service.getFinanicalReport( CompanyId, fromDate, toDate, lang).subscribe({
    next: (pdfBlob) => {
      // إنشاء كائن Blob لملف PDF
      const blob = new Blob([pdfBlob], { type: 'application/pdf' });
      const downloadUrl = window.URL.createObjectURL(blob);

      // إنشاء عنصر <a> لتفعيل التحميل
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `Income_Report_${CompanyId}_${fromDate}_${toDate}.pdf`;
      document.body.appendChild(a);
      a.click();

      // تنظيف الرابط والذاكرة
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);
      this.spinner.hide();
    },
    error: () => {
      this.spinner.hide();
    }
  });
}
}
