<app-breadcrumb [title]="curentLang === 'en' ? 'Receipt Info' : (curentLang === 'ar' ? ' معلومات الايصالات' : ' פרטי הקבלות' )" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['    הגדרות   '])" 
[active_item]="curentLang === 'en' ? 'Receipt Info' : (curentLang === 'ar' ? ' معلومات الايصالات ' : 'פרטי הקבלות')">
</app-breadcrumb>

<!--Receipt info -->

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Receipt Info' : (curentLang === 'ar' ? 'معلومات الايصالات ' : '  פרטי הקבלות    ')}}
        </h5>

       <div>
        <!-- <button  class="btn" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
            {{ curentLang === 'en' ? 'Add receipt' : (curentLang === 'ar' ? ' اضافة ايصال ' : 'מידע קבלה')}}
        </button> -->


        <button [hidden]="!isValidMerchant" type="button" class="btn" style="background-color: #141414; color: #fff;" aria-label="Close" [routerLink]="['/dashboard/add-receipts']">
          <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add receipt' : (curentLang === 'ar' ? ' اضافة ايصال ' : '  הוספת קבלה')}}
      </button>

     
      

        <!-- <button class="btn mx-1 btn-outline-warning"   >
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button> -->

         </div>
     
      </div>

      <div class="container-fluid basic_table" >
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group search px-1">
            <label for="seaarch" class="ml-2" style="color: grey;">
              {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
            </label>
            <div class="input-group">
              <input class="form-control w-100" type="text" name="search"
                     [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term"
                     placeholder="{{curentLang === 'en' ? 'Search by customer name or document number ' : (curentLang === 'ar' ? 'بحث بأسم العميل او رقم المستند' : 'חפש לפי שם הלקוח או מספר המסמך')}}"
                     />
              <!-- <div class="input-group-append m-auto">
                <button class="btn" style="background-color: #141414; color: #fff;" type="button"
                        (click)="getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                  <i class="fa fa-search mx-1"></i>
                  {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                </button>
                <button class="btn btn-outline-secondary" type="button" (click)="term='';getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                  <i class="fa fa-times"></i>
                </button>
              </div> -->
            </div>
          </div>
        </div>
       
        <div class="col-sm-3 mt-2" >
          <label class="form-label px-1" style="color: grey;">
            {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה') }}
          </label>
          <ng-select [(ngModel)]="selectedCompany" (change)="filter($event)" class="px-1"
                     style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
            <ng-option [value]="allCompaniesOption">
              {{ curentLang === 'en' ? 'All Companies' : (curentLang === 'ar' ? 'الكل' : 'הכל') }}
            </ng-option>
            <ng-option *ngFor="let item of companyData" [value]="item">
              {{ curentLang === 'en' ? item?.company_name_en : (curentLang === 'ar' ? item?.company_name_ar : item?.company_name_he)}}
            </ng-option>
          </ng-select>
          <ng-template *ngIf="companyData.length === 0" class="px-1">
            <span style="color: red;">
              {{ curentLang === 'en' ? 'There are no invoices for this company yet' : (curentLang === 'ar' ? "لا توجد فواتير لهذه الشركة حتى الآن" : " עדין לא הופקו קבלות        ")}}
            </span>
          </ng-template>
        </div>
       
        <form [formGroup]="documentForm"   >

      
<div class="row d-flex justify-content-between ">
  <div class="col-md-3 mt-2 "  >
    <h6 class="form-label px-1 " style="color: grey;"> 
      {{ curentLang === 'en' ? 'Period' : (curentLang === 'ar' ? 'الفترة' : '  תקופה  ')}}
    </h6>
    <div class="px-1">
      <select class="form-control  px-1  form-select" style="cursor: pointer;" (change)="filterMonth($event)" (click)="confirmAction()">
        <option [value]="1">{{ curentLang === 'en' ? 'Current month' : (curentLang === 'ar' ? 'الشهر الحالي' : '  חודש נוכחי') }}</option>
        <option [value]="2">{{ curentLang === 'en' ? 'Last month' : (curentLang === 'ar' ? 'الشهر الماضي' : '  חודש קודם') }}</option>
        <option [value]="5">{{ curentLang === 'en' ? 'Last two month' : (curentLang === 'ar' ? ' أخر شهران ' : '  שני חודשים קודמים') }}</option>
        <option [value]="3">{{ curentLang === 'en' ? 'Start of the year' : (curentLang === 'ar' ? 'من بداية العام' : 'מתחילת שנה  ') }}</option>
        <option [value]="4">{{ curentLang === 'en' ? 'Last year' : (curentLang === 'ar' ? 'العام الماضي' : '  שנה קודמת') }}</option>
      </select>
    </div>
 
  </div>

  <div class="col-md-6 ">
    <div class="row d-flex justify-content-between">

    
      <div class="col-md-6 mt-2">
      <h6 for="fromDate" class="ml-2" style="color: grey;">
        {{curentLang === 'en' ? 'From Date' : (curentLang === 'ar' ? 'من تاريخ' : 'מתאריך')}}
      </h6>
      <div  >
        <p-calendar [(ngModel)]="fromDate" style="display: block; width: 100%;"
         showIcon="true" (ngModelChange)="onFromDateChange($event)"
            formControlName="startDate" 
            dateFormat="dd/mm/yy"
             placeholder="DD/MM/YYYY"
              >
            </p-calendar>

      </div>
    </div>

      <div class="col-md-6 " >
        <h6 for="toDate"  style="color: grey;">
         {{curentLang === 'en' ? 'To Date' : (curentLang === 'ar' ? 'الي تاريخ' : ' עד תאריך  ')}}
       </h6>
       <div class="">
       <p-calendar 
       style="display: block; width: 100%;"
         [(ngModel)]="toDate" 
         showIcon="true" 
         (ngModelChange)="onToDateChange($event)" 
         formControlName="endDate" 
         dateFormat="dd/mm/yy" 
         placeholder="DD/MM/YYYY">
       </p-calendar>
      </div>
   </div>

    </div>
  </div>
  
 
  
   
  
  <div class="col-md-3 mt-4 " style="padding-top: 6px;">
    <div class="form-group search  ">
      <button class="btn btn-sm mx-1  mt-2 " style="background-color: #141414; color: #fff;" 
      [disabled]="!fromDate || !toDate"
      (click)="getInvoicesFilters(fromDate, toDate)">
        <i class="fa fa-refresh mx-1"></i>
        {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
      </button>
    </div>
  </div>
</div>
   
   
  
    </form>
  
    </div>
      </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12  ">


          <div class="table-responsive">
            <table class="table table-light d-none d-lg-table text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col>">{{ curentLang === 'en' ? 'Receipt Number' : (curentLang === 'ar' ? 'رقم الايصال' : 'ספר קבלה')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'From' : (curentLang === 'ar' ? ' من ' : ' מ')}} </th>
                   <th scope="col">  {{ curentLang === 'en' ? 'To' : (curentLang === 'ar' ? '  الي  ' : ' ל ')}}</th>
                   <th scope="col">  {{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ ' : ' תאריך  ')}}</th>
                   <th scope="col>">{{ curentLang === 'en' ? 'Cash Amount' : (curentLang === 'ar' ? 'مبلغ نقدي' : '   סכום מזומן  ')}}</th>
                   <th scope="col>">{{ curentLang === 'en' ? 'Checks Amount' : (curentLang === 'ar' ? 'مبلغ الشيكات' : '     סכום שקים ')}}</th>
                   <th scope="col>">{{ curentLang === 'en' ? 'Deduction From Source ' : (curentLang === 'ar' ? 'الخصم من المصدر' : '       ניכוי במקור  ')}}</th>
                   <th scope="col>">{{ curentLang === 'en' ? 'Final Total' : (curentLang === 'ar' ? 'المجموع النهائي' : '  סה"כ ')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'PDF' : (curentLang === 'ar' ? '   PDF' : '  PDF   ')}}</th>
                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  לצפות   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th> -->

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of invoicesDataFilters | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total}; let i = index"
                [ngClass]="{'table-danger': selectedInvoiceUuid === item.invoice_uuid }"
                 
                
                >
                  <td scope="row">{{i+1}}</td>
                  <td>{{item.document_number}}</td>

                  <td>{{item?.company?.company_name_en}} </td>
                   <td>{{item?.customer_name}}</td>
                   <td>{{ item?.invoice_date | date: 'dd/MM/yyyy' }}</td>
                   <td>{{item?.cash_amount}}</td>
                   <td>{{item?.checks_amount}}</td>
                   <td>{{item?.deduction_from_source}}</td>
                   <td>{{item?.total_amount}}</td>


                   <td>
                    <i class="fa fa-file-pdf-o"  
                       *ngIf="item.document_source != 20 && item.pdf_link !=  null "
                       [ngStyle]="{
                           'pointer-events': item.confirm == 0 ? 'none' : 'auto',
                           'opacity': item.confirm == 0 ? 0.6 : 1,
                           'color': '#213FA6',
                           'font-size': '15px',
                           'cursor': 'pointer'
                       }"
                       (click)="executeFunctions(item.invoice_uuid ); openPdfModal(item, content4)">
                    </i>
                
                  

                    <i *ngIf="item.pdf_link === null && item.document_source == 20" 
                    class="fa fa-file-pdf-o"
                    style="color: #213FA6; font-size: 15px; cursor: pointer;"
                    (click)="executeFunctions(item.invoice_uuid); openInvoiceXl2(content5 )">
                 </i>
                </td>
                


                  <!-- <td>
                    <button class=" btn" style="border: none;" (click)="executeFunctionsView(  item?.invoice_id,viewData ) ">
                        <i class="fa fa-eye" style="color: grey;   cursor: pointer;"></i>
                    </button>
                  </td> 

                  <td >
                  <button  class=" btn" style="border: none;"
                   (click)="executeFunctions(  item?.invoice_id,updatedData ) ">
                    <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                   </i>
                  </button>
                   </td>

                   <td >
                    <button 
                    [ngStyle]="{ 'pointer-events': item.status == 1 ? 'none' : 'auto', 'color': item.status == 1 ? 'gray' : 'red' }" 
                    class="btn" 
                    style="border: none;" 
                    (click)="getId(item?.invoice_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                      </button>
            
                     </td> -->

                </tr>
          
              </tbody>
            
            </table>


<!-- Receipt Info on phone -->
 

  <div class="container-fluid basic_table d-lg-none my-2" >

    


    <div class="row">
<div class="col-sm-12">
   
    <div class="card"  id="headingTwo"
     *ngFor="let item of invoicesDataFilters  | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} |filter:term ; let i = index ">
    
            <div   
            
            (click)="toggleCollapse(i)" 
            [attr.aria-expanded]="openedIndex === i" 
            aria-controls="collapse"
            style="cursor: pointer; background-color: #F6F6F9 " 
            class="py-1">
            <h6>
              <i class="fa fa-file mx-1"  style="color: #000; font-size: 15px;" > </i>
              <span style="color: #000; font-size: 15px;" class="mx-2">
                {{ curentLang === 'en' ? 'Receipt Number' : (curentLang === 'ar' ? 'رقم الايصال' : '  ספר קבלה')}} :
              </span>
              <span style="color: #000; font-size: 15px;" class="mx-2">{{item.document_number}}</span>
            </h6>
              <h6>
                <i class="icofont icofont-businessman mx-1"  style="color: #000; font-size: 15px;" > </i>
                <span style="color: #000; font-size: 15px;" class="mx-2">
                  {{ curentLang === 'en' ? 'Customer Name ' : (curentLang === 'ar' ? 'أسم الزبون ' : 'שם הלקוח')}} :
                </span>
                <span style="color: #000; font-size: 15px;" class="mx-2">{{item.customer_name}}</span>
              </h6>
        
            </div>
      
             
            <div class="row d-flex justify-content-between">

              <div class="col-md-12  col-sm-12 ">
                <div class="row collapse   show" id="collapseTwo" [ngbCollapse]="openedIndex !== i">

               
                <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'From' : (curentLang === 'ar' ? ' من ' : ' מ')}}
                  </span>
                    <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>
                    <span class="col-sm-4   text-end ">
                      <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item?.company?.company_name_en}}</span>
                    </span>
                 </div>
                 <hr style="border: solid 1px #141414;" >
                 <!---->
      
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'To' : (curentLang === 'ar' ? '  الي  ' : ' ל ')}}
                  </span>

                  <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>

                  <span class="col-sm-4   text-end ">
                    <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item?.customer_name}}</span>
                  </span>
                  
                     
     
                 </div>
                 <hr style="border: solid 1px #141414;" >

                 <!---->
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                    {{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ ' : ' תאריך  ')}}
                  </span>
                  
                  <div class="text-center col-sm-4">  </div>

                    <span style="color: #141414;   " class="col-sm-4   text-end "  >
                      <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ item?.invoice_date | date: 'dd/MM/yyyy' }}</span>
                  </span>

                
                 </div>
                 <hr style="border: solid 1px #141414;" >

                   <!---->

                   <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                      {{ curentLang === 'en' ? 'Cash Amount' : (curentLang === 'ar' ? 'مبلغ نقدي' : 'סכום מזומן')}} 

                    </span>
                    
                    <div class="text-center col-sm-4">  </div>
  
                      <span style="color: #141414;   " class="col-sm-4   text-end "  >
                        <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > {{item?.cash_amount}}</span>
                    </span>
  
                  
                   </div>
                   <hr style="border: solid 1px #141414;" >
                    <!---->

                    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                        {{ curentLang === 'en' ? 'Checks Amount' : (curentLang === 'ar' ? 'مبلغ الشيكات' : 'סכום שקים ')}} 
  
                      </span>
                      
                      <div class="text-center col-sm-4">  </div>
    
                        <span style="color: #141414;   " class="col-sm-4   text-end "  >
                          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >  {{item?.checks_amount}}</span>
                      </span>
    
                    
                     </div>
         
                   <hr style="border: solid 1px #141414;" >
                    <!---->

                    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                        {{ curentLang === 'en' ? 'Deduction From Source ' : (curentLang === 'ar' ? 'الخصم من المصدر' : 'ניכוי במקור')}}
                
                      </span>
                      
                      <div class="text-center col-sm-4">  </div>
                
                        <span style="color: #141414;   " class="col-sm-4   text-end "  >
                          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item?.deduction_from_source}}</span>
                      </span>
                
                    
                     </div>
                 
 
                 <hr style="border: solid 1px #141414;" >


                    <!---->

    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
        {{ curentLang === 'en' ? 'Final Total' : (curentLang === 'ar' ? 'المجموع النهائي' : 'סה"כ')}}

      </span>
      
      <div class="text-center col-sm-4">  </div>

        <span style="color: #141414;   " class="col-sm-4   text-end "  >
          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >   {{item?.total_amount}}</span>
      </span>

    
     </div>

                 
    
   <hr style="border: solid 1px #141414;" >

       <!---->

       <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

        <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
          {{ curentLang === 'en' ? 'PDF' : (curentLang === 'ar' ? '   PDF' : '  PDF   ')}}
  
        </span>
        
        <div class="text-center col-sm-4">  </div>
  
          <span style="color: #141414;   " class="col-sm-4   text-end "  >

            <span style="color: #141414;   " class="text-end col-sm-4  " >
              <span   rel="noopener noreferrer" class="px-5">
                <i class="fa fa-file-pdf-o"    *ngIf="item.document_source != 20 && item.pdf_link !=  null "
                (click)="executeFunctions(item.invoice_uuid  );openPdfModal(item , content4 )" style="cursor: pointer;"
                [ngStyle]="{ 'pointer-events': item.confirm == 0 ? 'none' : 'auto', 'opacity': item.confirm == 0 ? 0.6 : 1 }"
                style="color: grey; font-size: 15px; cursor: pointer; color: #213FA6;"></i>

                <i *ngIf="item.pdf_link === null && item.document_source == 20" 
                    class="fa fa-file-pdf-o"
                    style="color: #213FA6; font-size: 15px; cursor: pointer;"
                    (click)="executeFunctions(item.invoice_uuid); openInvoiceXl2(content5 )">
                 </i>
            </span>
          </span>
        </span>
  
      
       </div>

                 
       
     <hr style="border: solid 1px #141414;" >

                </div>
              </div>

            </div>
            <hr style="color: #141414; border: solid 1px;">
    </div>

 



  </div>

    </div>
</div>
 



            <div *ngIf="invoicesDataFilters?.length == 0" class="alert text-danger mt-3">
              <span> {{ curentLang === 'en' ? 'There are no receipts for this company yet... ' : (curentLang === 'ar' ? ' لا توجد ايصالات لهذة الشركة حتي الأن ... ' : 'אין עדיין קבלות עבור חברה זו... ')}}</span>
          </div>
            <div class="pagination-container">
              <pagination-controls 
                (pageChange)="onPageChange($event)" 
                (totalItems)="total" 
                (currentPage)="currentPage">
              </pagination-controls>
              <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange()">

            </div>
          </div>
          <div class=" d-flex justify-content-between"> 
            <div></div>

           <div *ngIf="user_type == 100 || user_type == 101">
            <a routerLink="/dashboard/merchant-info">
                <button class="btn btn-danger m-2 " *ngIf="!isInsideModal">
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

           <div *ngIf="user_type == 200 || user_type == 201">
            <a routerLink="/dashboard/users">
                <button class="btn btn-danger m-2 " >
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

          </div>

        </div>
      </div>

    </div>
  </div>

    </div>
</div>


<!--add Receipt  -->



<!--update Receipt  -->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Update receipt' : (curentLang === 'ar' ? ' تعديل الايصال ' : '  שנה את הקבלה')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="updateReceipt">
                        <div class="row">
                            <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'From' : (curentLang === 'ar' ? ' من ' : ' מ')}} 
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="from" 
                                  />
                              </div>
                            <!---->
                              <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'To' : (curentLang === 'ar' ? '  الي  ' : ' ל ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="to"
                                   />
                                </div>
                                  <!---->
                                <div class="mb-3  col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ ' : ' תאריך  ')}}
                                   </label>
                                    <input style="color: grey;" class="form-control"
                                     type="date" 
                                     formControlName="date" 
                                     maxlength="80"
                                    />
                                     
                                  </div>
                           
                                  <!---->
                                  <div class="mb-3  col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Receipt Number' : (curentLang === 'ar' ? 'رقم الايصال' : '   ספר קבלה')}}
                                   </label>
                                    <input style="color: grey;" class="form-control"
                                     type="text" 
                                     formControlName="receipt_number" 
                                     maxlength="50"
                                    />
                                     
                                  </div>

                                   <!---->
                                   <div class="mb-3  col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Amount' : (curentLang === 'ar' ? 'الكمية' : '     כמות  ')}}                                   </label>
                                    <input style="color: grey;" class="form-control"
                                     type="text" 
                                     formControlName="amount" 
                                     maxlength="50"
                                    />
                                     
                                  </div>
                                         <!---->

                                <div class="mb-3  col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Content' : (curentLang === 'ar' ? ' المحتوي ' : ' תוֹכֶן  ')}}
                                   </label>
                                 
                                    <textarea
                                    style="color: grey;" class="form-control"
                                     type="text" 
                                     formControlName="content" 
                                    name="" id="" cols="30" rows="2">
  
                                    </textarea>
                                     
                                  </div>
                             
                                      <!---->
                                <div class="mb-3  col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? ' ملاحظة ' : ' הערות  ')}}
                                   </label>
                                 
                                    <textarea
                                    style="color: grey;" class="form-control"
                                     type="text" 
                                     formControlName="note" 
                                    name="" id="" cols="30" rows="2">
  
                                    </textarea>
                                     
                                  </div>

                          
                        </div>
                  
                          <div class="modal-footer">

                            <button type="button" 
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updateReceiptById()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form> 
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--view Receipt  -->

<ng-template #viewData let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'View Info ' : (curentLang === 'ar' ? ' مشاهدة المعلومات ' : '     הצג מידע      ')}} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
                    <form  [formGroup]="updateReceipt">
                      <div class="row">
                          <div class="mb-3 col-xl-6 col-md-6 ">
                              <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'From' : (curentLang === 'ar' ? ' من ' : ' מ')}} 
                             </label>
                              <input style="color: grey;" 
                               class="form-control" maxlength="80" 
                               type="text" formControlName="from" 
                                />
                            </div>
                          <!---->
                            <div class="mb-3  col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'To' : (curentLang === 'ar' ? '  الي  ' : ' ל ')}}
                               </label>
                                <input style="color: grey;" 
                                class="form-control"
                                 type="text"
                                 maxlength="80"
                                  formControlName="to"
                                 />
                              </div>
                                <!---->
                              <div class="mb-3  col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ ' : ' תאריך  ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="date" 
                                   formControlName="date" 
                                   maxlength="80"
                                  />
                                   
                                </div>
                         
                                <!---->
                                <div class="mb-3  col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Receipt Number' : (curentLang === 'ar' ? 'رقم الايصال' : 'ספר קבלה  ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="receipt_number" 
                                   maxlength="50"
                                  />
                                   
                                </div>

                                 <!---->
                                 <div class="mb-3  col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Amount' : (curentLang === 'ar' ? 'الكمية' : '     כמות  ')}}                                   </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="amount" 
                                   maxlength="50"
                                  />
                                   
                                </div>
                                       <!---->

                              <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Content' : (curentLang === 'ar' ? ' المحتوي ' : ' תוֹכֶן  ')}}
                                 </label>
                               
                                  <textarea
                                  style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="content" 
                                  name="" id="" cols="30" rows="2">

                                  </textarea>
                                   
                                </div>
                           
                                    <!---->
                              <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? ' ملاحظة ' : ' הערות  ')}}
                                 </label>
                               
                                  <textarea
                                  style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="note" 
                                  name="" id="" cols="30" rows="2">

                                  </textarea>
                                   
                                </div>

                        
                      </div>
                
                     
                    </form> 
            
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>


  <!--Delete Receipt-->

  <ng-template #dataDeleted let-modal>
    <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
          {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : 'האם אתה בטוח שברצונך למחוק אותו ?') }}
        </p>
    </div>
    <div class="modal-footer">

      <button type="button"
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="deleteReceiptById()"
       > 
        {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>
</ng-template>




<ng-template #content4 let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
      {{curentLang === 'en' ? 'Receipt Details' : (curentLang === 'ar' ? 'تفاصيل الايصال ' : ' פרטי קבלה  ')}} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 mt-4">
          <button type="button" (click)="downloadDocumentPdf(); modal.close('Close click')" class="btn btn-sm mx-4 mt-4"
            style="background-color: #141414; color: #fff;">
            <i class="fa fa-file-pdf-o mx-1"></i>
            {{ curentLang === 'en' ? 'Print' : (curentLang === 'ar' ? 'طباعة نسخة' : 'הפקת העתק') }}
          </button>
        </div>

        <div class="col-md-4 mt-4">
          <div class="dropdown mx-4 mt-4">
            <button class="btn dropdown-toggle w-100" type="button" id="shareInvoiceDropdown" data-bs-toggle="dropdown"
              aria-expanded="false" style="background-color: #141414; color: #fff;">
              {{ curentLang === 'en' ? 'Share Original Version' : (curentLang === 'ar' ? 'مشاركة النسخة الأصلية' : 'שתף את המקור') }}
              <i class="fa fa-share-alt mx-1 text-white" style="font-size: 15px;"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="shareInvoiceDropdown">
              <li style="border-bottom: 1px solid #000;">
                <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" (click)="downloadOriginalDocPdfFromLink()">
                  <i class="fa fa-download mx-1" style="font-size: 15px; color: rgb(37, 16, 160);"></i>
                  {{ curentLang === 'en' ? 'Download original version': (curentLang === 'ar' ? 'تحميل النسخة الأصلية  ' : 'הורד את הגרסה המקורית') }}
                </a>
              </li>
              <li style="border-bottom: 1px solid #000;">
                <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" (click)="copyLinkToClipboard(rawPdfUrl)">
                  <i class="fa fa-copy mx-1" style="font-size: 15px; color: rgb(158, 40, 40);"></i>
                  {{ curentLang === 'en' ? 'Copy Link' : (curentLang === 'ar' ? 'نسخ الرابط' : 'העתק קישור') }}
                </a>
              </li>
              <li style="border-bottom: 1px solid #000;">
                <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" href="https://wa.me/?text={{ rawPdfUrl }}" target="_blank">
                    <i class="fa fa-whatsapp mx-1" style="font-size: 15px; color: rgb(32, 92, 32);"></i>
                    {{ curentLang === 'en' ? 'Share via WhatsApp' : (curentLang === 'ar' ? 'مشاركة عبر واتساب' : 'שתף בוואטסאפ') }}
                </a>
            </li>
              <li>
                <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" href="mailto:?subject=Invoice&body={{ rawPdfUrl }}">
                  <i class="fa fa-envelope mx-1" style="font-size: 15px; color: rgb(137, 137, 16);"></i>
                  {{ curentLang === 'en' ? 'Share via Email' : (curentLang === 'ar' ? 'مشاركة عبر البريد الإلكتروني' : 'שתף באימייל') }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-4 my-2">
          <div class="form-group mx-4">
            <label class="mt-2" style="color: #141414;">
              {{ curentLang === 'en' ? 'Change Document Language' : (curentLang === 'ar' ? 'تغيير لغة المستند' : 'שינוי שפת המסמך') }}
            </label>
            <select class="form-control" (change)="filterDocumentLanguage($event.target.value)">
              <option *ngFor="let lang of availableLanguages" [value]="lang.code">
                {{ lang.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <!-- عرض رسالة جاري تجهيز الملف أثناء عرض الهيكل العظمي -->
        <div *ngIf="isSkelton">
          <p class="text-center" style="color: grey;">{{ curentLang === 'en' ? 'The file is being prepared...' : (curentLang === 'ar' ? 'جاري تجهيز الملف' : 'הקובץ בהכנה') }}</p>
          <div class="skeleton-loader">
            <div class="border-round border-1 surface-border p-4 surface-card">
              <div class="flex mb-3">
                <div>
                  <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
                  <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
                  <p-skeleton height=".5rem"></p-skeleton>
                </div>
              </div>
              <p-skeleton width="100%" height="150px"></p-skeleton>
              <div class="flex justify-content-between mt-3">
                <p-skeleton width="4rem" height="2rem"></p-skeleton>
              </div>
            </div>
          </div>
        </div>

        <!-- عرض الـ PDF عند انتهاء الهيكل العظمي -->
        <iframe *ngIf="!isSkelton && pdfUrl" [src]="pdfUrl" width="100%" height="500px" frameborder="0"></iframe>
      </div>
    </div>

    <div class="modal-footer my-2">
      <button type="button" class="btn btn-sm mt-2 btn-danger" (click)="modal.close('Close click')">
        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
        <i class="fa fa-rotate-left mx-1"></i>
      </button>
    </div>
  </div>
</ng-template>



<ng-template #content5 let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
      {{curentLang === 'en' ? 'Receipt Details' : (curentLang === 'ar' ? 'تفاصيل الايصال ' : ' פרטי קבלה  ')}} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <button type="button" (click)="downloadDocumentPdf();modal.close('Close click')" class="btn btn-sm my-3"
    style=" background-color: #141414; color: #fff;">
    <i class="fa fa-file-pdf-o mx-1"></i>
    {{ curentLang === 'en' ? ' Print ' : (curentLang === 'ar' ? 'طباعة نسخة  ' : 'הפקת העתק') }}
  </button>
<div class="container" [hidden]="receiptInfoData?.confirm == 0" >
    <div class="row m-auto">
        <div class="col-sm-12 ">

            <div class="page m-auto  invoice-content"  id="printDiv">

                <div class="card-2" style="background-color: #fff; box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);">

                    <div class="row m-2 ">
                        <div class="col-lg-12">
                            <div class="invoice-inner" id="invoice_wrapper">
                                <div class="invoice-top">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="logo my-2"  *ngIf="receiptInfoData?.logo" >
                                                <img 
                                                    [src]="receiptInfoData?.logo"
                                                     title="company logo"
                                                    style="width: 100px;height: 100px; border-radius: 5%;">
                                            </div>
                                        </div>

                                        <div [class.py-5]="receiptInfoData?.logo" [class.py-2]="!receiptInfoData?.logo" class="col-sm-4" *ngIf="user_type == 300 || user_type == 301">
                                            <h2 class="text-center water-mark">
                                                {{ curentLangDocument === 'en' ? ' Accounting Copy' : (curentLangDocument === 'ar' ? 'نسخة المحاسب' : ' עותק רואה חשבון')}}
                                            </h2>
                                        </div>

                                        <div [class.py-5]="receiptInfoData?.logo" [class.py-2]="!receiptInfoData?.logo" class="col-sm-4" *ngIf="user_type == 200 || user_type == 201">
                                            <h2 class="text-center water-mark">{{receiptInfoData?.copy_version}}</h2>
                                        </div>
                                        
                                        <div class="col-sm-4">
                                     
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice-info py-2">
                                    <div class="row">
                                       
                                        <div class="col-sm-6 mb-30">
                                            <div class="invoice-number ">
                                                <h4 class="inv-title-1" style="font-weight: bold; font-size: 14px;">{{ curentLangDocument === 'en' ? 'For ' : (curentLangDocument === 'ar' ? '   لحضرة' : 'לכבוד  ')}}</h4>
                                                <div class="invo-addr-1">
                                                    <div style="font-weight: 400;">
                                                        <!-- <strong> {{ curentLangDocument === 'en' ? 'Customer Name' : (curentLangDocument === 'ar' ? 'اسم العميل' : 'שם הלקוח')}} / </strong> -->
                                                        <strong>{{ receiptInfoData?.customer_name }}</strong>
                                                    </div>
                                                    
                                                    <div *ngIf="receiptInfoData?.customer_address; else noAddress" style="font-weight: 300;" class="m-0">
                                                        <strong>{{ receiptInfoData?.customer_address }}</strong>
                                                    </div>
                                                    
                                                    <ng-template #noAddress>
                                                        <div style="font-weight: 300;">
                                                            <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : 'מס עוסק מורשה    ')}} </strong>
                                                            <div style="font-weight: 300;">
                                                                <strong>{{ receiptInfoData?.customer_vat_number }}</strong>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    
                                                    <div *ngIf="receiptInfoData?.customer_address" style="font-weight: 300;">
                                                        <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : '    מס עוסק מורשה ')}} </strong>
                                                        <div style="font-weight: 300;">
                                                            <strong>{{ receiptInfoData?.customer_vat_number }}</strong>
                                                        </div>
                                                    </div>
                                                    

                                                    
                                                    <div style="font-weight: 300;">
                                                        <!-- <strong>{{ curentLangDocument === 'en' ? 'Customer Id Number' : (curentLangDocument === 'ar' ? '  رقم الهوية للعميل' : '  מספר זיהוי לקוח ')}} / </strong> -->
                                                        <strong>{{ receiptInfoData?.customer_id_number }}</strong>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div class="col-sm-6 mb-30">
                                            <div class="invoice-number text-end">
                                                <!-- <h4 class="inv-title-1"> {{ curentLangDocument === 'en' ? 'Invoice From' : (curentLangDocument === 'ar' ? 'فاتورة من' : 'חשבונית מ')}} </h4> -->
                                                <div class="invo-addr-1">
                                                    <div style="font-weight: bold;">
                                                        <!-- <strong>{{ curentLangDocument === 'en' ? 'Company Name' : (curentLangDocument === 'ar' ? 'اسم الشركة' : 'שם החברה')}} / </strong>  -->
                                                        <strong>{{ receiptInfoData?.company_name }}</strong>
                                                    </div>
                                                    
                                                    <div style="font-weight: 300;">
                                                        <!-- <strong> {{ curentLangDocument === 'en' ? 'Company Address' : (curentLangDocument === 'ar' ? 'عنوان الشركة' : 'כתובת החברה')}} / </strong> -->
                                                        <strong>{{ receiptInfoData?.company_address_str }} -</strong>
                                                        <strong>{{ receiptInfoData?.company_Building_number }} -</strong>

                                                        
                                                        <strong>{{ receiptInfoData?.company_address_city }}</strong>
                                                    </div>
                                                    
                                                  
                                                    <!-- <div style="font-weight: 400;">{{ receiptInfoData?.company_address_zip_code }}</div> -->
                                                    <!-- <div style="font-weight: 300;">
                                                        <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? '  الرقم الضريبي للشركة' : 'מספר מס חברה')}} / </strong>
                                                        <strong>{{ receiptInfoData?.company_vat }}</strong>
                                                    
                                                    </div> -->

                                                    <div  style="font-weight: 300;"> 
                                                        <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : '     מס עוסק מורשה     ')}}  </strong> 
                                                   </div>
                                                   <div  style="font-weight: 300;"> 
                                                   <strong>{{ receiptInfoData?.company_vat }}</strong>
                                                   </div>
                                                  

                                                  
                                                    
                                                </div>
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    </div>
                                    <div class="row  ">
                                        <div class="col-sm-4"></div>

                                        

                                        <div class="invoice text-center   col-sm-4">
                                            <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Invoice Details' : (curentLangDocument === 'ar' ? 'تفاصيل الفاتورة' : ' פרטי החשבונית  ')}}</h4> -->

                                       

                                            <div class="mb-1"*ngIf="receiptInfoData?.document_number !== null">
                                           <strong *ngIf="receiptInfoData?.invoice_type == 305" style="font-weight: bold;" class="mx-1">
                                            {{curentLangDocument === 'en' ? 'Tax Invoice Number' : (curentLangDocument === 'ar' ? 'فاتورة ضريبية رقم' : '  חשבונית מס מספר       ') }}
                                          </strong> 

                                          <strong *ngIf="receiptInfoData?.invoice_type == 320" style="font-weight: bold;" class="mx-1">
                                            {{curentLangDocument === 'en' ? 'Invoice With Receipt Number' : (curentLangDocument === 'ar' ? 'فاتورة مع  ايصال رقم' : 'חשבונית עם מספר קבלה') }}
                                         </strong> 

                                         <strong *ngIf="receiptInfoData?.invoice_type == 332" style="font-weight: bold;" class="mx-1">
                                            {{curentLangDocument === 'en' ? ' Financial Request Number' : (curentLangDocument === 'ar' ? 'مطالبة مالية رقم' : ' תביעה כספית מס  ') }}
                                         </strong> 

                                         <strong *ngIf="receiptInfoData?.invoice_type == 400" style="font-weight: bold;" class="mx-1">
                                            {{curentLangDocument === 'en' ? 'Receipt Number' : (curentLangDocument === 'ar' ? ' ايصال رقم' : ' מס קבלה  ') }}
                                         </strong> 

                                         <strong *ngIf="receiptInfoData?.invoice_type == 330" style="font-weight: bold;" class="mx-1">
                                            {{curentLangDocument === 'en' ? 'Reverce Invoice Number' : (curentLangDocument === 'ar' ? '  فاتورة عكسية رقم  ' : '      חשבונית זיכוי קבלה ') }}
                                         </strong> 

                                         <strong *ngIf="receiptInfoData?.invoice_type == 100" style="font-weight: bold;" class="mx-1">
                                          {{curentLang === 'en' ? '    order' : (curentLang === 'ar' ? 'طلبية    ' : '   לְהַזמִין   ') }}
                                       </strong> 

                                       <strong *ngIf="receiptInfoData?.invoice_type == 200" style="font-weight: bold;" class="mx-1">
                                          {{curentLang === 'en' ? '    mission' : (curentLang === 'ar' ? 'ارسالية    ' : '   תעודת משלוח   ') }}
                                       </strong> 

                                       <strong *ngIf="receiptInfoData?.invoice_type == 000" style="font-weight: bold;" class="mx-1">
                                          {{curentLang === 'en' ? '    Offer Price' : (curentLang === 'ar' ? 'عرض سعر     ' : ' מחיר הצעה') }}
                                       </strong> 

                                         <strong style="font-weight: 400; color: black;">{{receiptInfoData?.document_number}}</strong>


                                            
                                        </div>
                                        <div [hidden]="receiptInfoData?.confirmation_number == null"  style="font-weight: 300;color: black;">
                                            <strong style="font-weight: bold;" class="mx-1">{{ curentLangDocument === 'en' ? 'Confirmation Number' : (curentLangDocument === 'ar' ? ' رقم تخصيص' : ' מספר הקצאה  ')}} : </strong> 
                                           <strong>{{ receiptInfoData?.confirmation_number }}  </strong>
                                       </div>
                                           
                                           
                                        </div>

                                        <div class="col-sm-4    text-end">
                                            <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' التاريخ  ' : ' תאריך  ')}}</h4> -->
                                           
                                                 <div class="mb-0" style="font-weight: bold;">
                                                    {{ curentLangDocument === 'en' ? ' Date' : (curentLangDocument === 'ar' ? 'تاريخ ' : 'תאריך ')}}
                                                </div> 
                                                <div style="font-weight: 400; color: black;">{{ receiptInfoData?.invoice_date| date:'dd/MM/yyyy' }}</div>
                                        </div>


                                    </div>
                                </div>
                                <div class="order-summary" *ngIf="receiptInfoData?.invoice_type !== 400" >
                                    <div class="table-responsive">
                                        <table class="table invoice-table">
                                            <thead class="bg-active" style="font-weight: bold;   ">
                                                <tr>
                                                    <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Item Name' : (curentLangDocument === 'ar' ? 'اسم الصنف' : 'פריט  ')}}</th>
                                                     <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Description' : (curentLangDocument === 'ar' ? 'الوصف' : 'תיאור')}}</th> 
                                                    <!-- <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Measure Unit' : (curentLangDocument === 'ar' ? 'وحدة القياس' : 'יחידת מידה')}}</th> -->
                                                    <th
                                                        class="text-center" style="font-weight: bold;"> {{ curentLangDocument === 'en' ? 'Price' : (curentLangDocument === 'ar' ? 'السعر  ' : ' מחיר  ')}}</th>
                                                    <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Quantity' : (curentLangDocument === 'ar' ? 'الكمية' : 'כמות')}}</th>
                                                    <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Discount' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</th>
                                                    <th class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor=" let item of receiptInfoData?.lines">
                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.item_name}}</td>  
                                                    
                                                    <td  class="text-center  description-column"style="font-weight: 400; color: black;">{{item?.description}}</td>

                                                  <!--   <td class="text-center  description-column" style="font-weight: 400; color: black;">{{item?.measure_unit_description}}</td> -->

                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.price_per_unit | number:'1.2-2'}}</td>

                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.quantity}}</td>

                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.discount}}</td>

                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.sub_total | number:'1.2-2'}}</td>

                                                </tr>

                                            </tbody>
                                        </table>

                                      

                                    </div>
                                    <div class="small-table my-4">
                                        <div class="row">
                                            <div class="col-md-12  ">
                                                <div class="d-flex justify-content-between" style="background-color: #F1F1F1;">
                                                    <div class="col-md-6  p-2 ">
                                                        <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Amount Before Discount:' : (curentLangDocument === 'ar' ? 'المبلغ قبل الخصم' : '   מחיר לפני הנחה    ')}}</div>
                                                        <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Discount:' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</div>
                                                        <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Payment Amount:' : (curentLangDocument === 'ar' ? '   المبلغ المدفوع    ' : '      סה"כ      ')}}</div>
                                                        <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'VAT Amount:' : (curentLangDocument === 'ar' ? '       المبلغ الضريبي    ' : 'מע"מ')}}</div>
                                                        <div style="width: 300px; font-weight: bold;" class="m-1"> {{ curentLangDocument === 'en' ? 'Payment Amount Including VAT:' : (curentLangDocument === 'ar' ? ' المبلغ المدفوع شامل الضريبة ' : 'סה"כ לשתלום')}}</div>

                                                    </div>

                                                 

                                                    <div class="col-md-6 text-end px-3 p-2">
                                                        <div style="font-weight: 400; color: black;" class="my-1">{{receiptInfoData?.amount_before_discount | number:'1.2-2'}}</div>
                                                        <div style="font-weight: 400; color: black;" class="my-1">{{receiptInfoData?.all_discount | number:'1.2-2'}}</div>
                                                        <div style="font-weight: 400; color: black;" class="my-1">{{receiptInfoData?.payment_amount | number:'1.2-2'}}</div>
                                                        <div style="font-weight: 400; color: black;" class="my-1">({{receiptInfoData?.vat_percentage}} %)   {{receiptInfoData?.all_vat_amount | number:'1.2-2'}}</div>
                                                        <div style="font-weight: 400; color: black;" class="my-1">{{receiptInfoData?.payment_amount_including_vat | number:'1.2-2'}}</div>

                                                    </div>
                                                   
                                                 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    

                                </div>


                                  <div class="order-summary  " *ngIf="receiptInfoData?.invoice_type == 320 || receiptInfoData?.invoice_type == 400">

                                    <div class="col-lg-12 " >
                                        <ng-container  >
                                            <div class="mt-4 d-flex justify-content-between">
                                                <span style="font-weight: bold; " class="mx-2">{{ curentLangDocument === 'en' ? 'Payment Info' : (curentLangDocument === 'ar' ? 'بيانات الدفع  ' : ' נתוני תשלום')}} </span>
                                                <!-- <span style="font-weight: 500;" class="mx-3">{{ receiptInfoData.checks_amount | number:'1.0-0' }}</span> -->
                                            </div>  
                                    
                                            <div class="table-responsive ">
                                                <table class="table invoice-table">
                                                    <thead>
                                                    
                                                        <tr>
                                                            <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Payment Method ' : (curentLangDocument === 'ar' ? 'طرق الدفع  ' : 'דרכי תשלום')}}
                                                            </th>
                                                            <!-- <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Ref' : (curentLangDocument === 'ar' ? 'مرجع  ' : 'רפ')}}</th> -->
                                                            <!-- <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك  ' : 'בַּנק')}}
                                                            </th> -->
                                                            <!-- <th
                                                                class="text-center" style="font-weight: bold;" > {{ curentLangDocument === 'en' ? 'Branch' : (curentLangDocument === 'ar' ? 'فرع  ' : 'סניף')}}
                                                            </th>
                                                            
                                                            <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Account' : (curentLangDocument === 'ar' ? 'حساب  ' : 'חֶשְׁבּוֹן')}}
                                                            </th> -->
                    
                                                            <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Details' : (curentLangDocument === 'ar' ? 'تفاصيل  ' : 'פרטים')}}</th>
                                                                <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' تاريخ  ' : ' תאריך')}}</th>
                                                            <th class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع  ' : 'סכום')}}</th>
                    
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor=" let item of receiptInfoData?.receiptsDetails">
                                                            <td class="text-center" style="font-weight: 400; color: black;">
                                                                <span *ngIf=" item?.payment_type == 1"> {{ curentLangDocument === 'en' ? 'Cash' : (curentLangDocument === 'ar' ? 'كاش' : 'מְזוּמָנִים')}}</span>
                                                                <span *ngIf=" item?.payment_type == 2"> {{ curentLangDocument === 'en' ? 'Check' : (curentLangDocument === 'ar' ? 'شيك' : 'לִבדוֹק')}}</span>
                                                                <span *ngIf=" item?.payment_type == 3"> {{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك' : 'בַּנק')}}</span>
                                                                <span *ngIf=" item?.payment_type == 4"> {{ curentLangDocument === 'en' ? 'Credit' : (curentLangDocument === 'ar' ? 'ائتمان' : 'אַשׁרַאי')}}</span>
                                                            </td>
                                                            <!-- <td class="text-center">{{ item.bank }}</td>
                                                            <td class="text-center">{{ item.branch }}</td>
                                                            <td class="text-center">{{ item.account }}</td>
                                                             -->
                    
                                                            <td class="text-center description-column" style="font-weight: 400; color: black;">{{ item.details }}</td>
                                                            <td class="text-center" style="font-weight: 400; color: black;">{{ item.date | date: 'yyyy-MM-dd' }}</td>
                                                            <td class="text-center" style="font-weight: 400; color: black;">{{ item.amount | number:'1.2-2'}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </ng-container>
                                    </div>


              

                <div class="small-table my-4" >
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-between" >
                            <div class="col-md-6 mt-5">
                              <!-- <span>Weighted date: 05/05/24</span> -->
                             </div>
                             <div class="col-md-6">
                                <div class="row d-flex justify-content-between mx-1" style="background-color: #F1F1F1;">
                                    <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                        <span class="py-1"> {{ curentLangDocument === 'en' ? 'Deduction at source' : (curentLangDocument === 'ar' ? 'الخصم من المصدر  ' : '  ניכוי במקור')}} :  </span>
                                    </div>

                                    <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                        <span class="mx-1">{{receiptInfoData.deduction_from_source | number:'1.0-0'}}</span>
                                    </div>

                                    <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                        <span>{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}  :  </span>
                                    </div>

                                    <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                        <span class="mx-1">{{ receiptInfoData.total_amount | number:'1.2-2' }}</span>

                                    </div>


                                </div>
                                
                             </div>

                             <div class="col-md-6">

                             </div>
                        </div>
                    </div>
                </div>
                
                
                
                <!-- <div class=" d-flex justify-content-between"> 

                    <button type="button"  hidden
                    class="btn   m-2" 
                   style="background-color: #141414; color: #fff;"
                    
                    > 
                     {{ curentLangDocument === 'en' ? 'Update' : (curentLangDocument === 'ar' ? '     تحديث ' : '     עדכן  ') }}
                     <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                 </button>
            
                   

                        <img src="../../../../assets/images/logo/thi.png"  class="  m-2 "  alt="">
                  
                      
                    
            
                  </div> -->
                
            </div>
                                    
                                    
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


            



        </div>

        

    </div>




    <!--back-->
    <div class="modal-footer my-2">
      <div class=" d-flex justify-content-between"> 

        <button type="button"  hidden
        class="btn   m-2" 
       style="background-color: #141414; color: #fff;"
        
        > 
         {{ curentLangDocument === 'en' ? 'Update' : (curentLangDocument === 'ar' ? '     تحديث ' : '     עדכן  ') }}
         <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
     </button>

    <!--    <a routerLink="/dashboard/company-documents/{{invoiceInfoData?.company_uuid}}" *ngIf="user_type == 300 || user_type == 301">
          <button class="btn btn-danger m-2 " >
            {{ curentLangDocument === 'en' ? 'Back' : (curentLangDocument === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
          </button>
        </a>
-->
        <a routerLink="/dashboard/documents/{{invoiceInfoData?.company_uuid}}" *ngIf="user_type == 200 || user_type == 201">
            <button class="btn btn-danger m-2 " >
              {{ curentLangDocument === 'en' ? 'Back' : (curentLangDocument === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
            </button>
          </a>

          <button type="button" class="btn btn-sm mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
            <i class="fa fa-rotate-left mx-1"></i>
          </button>

 

      </div>
    </div>
    
    </div>
  </div>
</ng-template>