<app-breadcrumb [title]="curentLang === 'en' ? 'Vat Details' : (curentLang === 'ar' ? ' تفاصيل ضريبة القيمة المضافة  ' : '  פירוט מע״מ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  הגדרות  '])" 
[active_item]="curentLang === 'en' ? 'Vat Details' : (curentLang === 'ar' ? ' تفاصيل ضريبة القيمة المضافة  ' : '   פירוט מע״מ    ')">
</app-breadcrumb>

<!--vat details info -->

<div class="container-fluid basic_table">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Vat Details' : (curentLang === 'ar' ? '  تفاصيل ضريبة القيمة المضافة ' : 'פירוט מע״מ')}}
        </h5>

       <div>
        <button class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add Vat Details' : (curentLang === 'ar' ? '  اضافة تفاصيل الضريبة    ' : '  הוספת פירוט     ')}}
        </button>

       

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllVatDetailsByVatListId()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Vat Percentage' : (curentLang === 'ar' ? '  نسبة الضريبة   ' : 'שיעור מס')}} </th>
                   <th scope="col"> {{ curentLang === 'en' ? 'From Date  ' : (curentLang === 'ar' ? ' من تاريخ    ' : '   מתאריך   ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'To Date  ' : (curentLang === 'ar' ? ' الي تاريخ    ' : '    עד תאריך  ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמה  ')}}</th>
                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th> -->
                  <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחיקה ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of vatInfoDetailsData | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td> {{item.vat_percentage}}%</td>
                  <td> {{item.start_date}} </td>
                  <td> {{item.end_date}} </td>
                  <td >{{item.createdDate | date: 'dd/MM/yyyy'}}</td>

                  <td >
                    <button 
                    class="btn" 
                    style="border: none;" 
                    (click)="getId(item?.vat_details_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style=" cursor: pointer; color: red;"></i>
                      </button>
            
                     </td>

                   <!-- <td>
                     <i class="fa fa-edit" style=" cursor: pointer; color: green;"
                     (click)="executeFunctions(  item?.vat_list_id,updatedData ) ">
                    </i>
                   </td> -->

                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>

        <div class=" d-flex justify-content-between"> 
            <div></div>

           <div>
            <a routerLink="/dashboard/vat-list">
                <button class="btn btn-danger m-2 " >
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

          

          </div>

      </div>
    </div>
  </div>

    </div>
</div>


<!--add vat details-->

<ng-template #content let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Add Vat Details' : (curentLang === 'ar' ? '  اضافة تفاصيل الضريبة    ' : '  הוספת פירוט     ')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]=" addVatDetails">
                        <div class="row">
                            <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'From Date  ' : (curentLang === 'ar' ? ' من تاريخ    ' : '   מתאריך   ')}}                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="date" formControlName="start_date" 
                                  />
                              </div>
              
                              <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'To Date  ' : (curentLang === 'ar' ? ' الي تاريخ    ' : '  עד היום  ')}}                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="date"
                                   maxlength="80"
                                    formControlName="end_date"
                                   />
                                </div>
              
                        
              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Vat Percentage' : (curentLang === 'ar' ? '      نسبة ضريبة القيمة المضافة    ' : ' אחוז המע״מ  ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control" type="number" 
                                   formControlName="vat_percentage"
                                   maxlength="20"
                                   min="0"
                                     />
                                </div>

                             

              
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button" [disabled]="!addVatDetails.valid"
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="addedData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--update vat details-->

<!-- <ng-template #updatedData let-modal>
    
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : '     ערוך מידע    ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                <form  [formGroup]="updateVatDetails">
                    <div class="row">
                        <div class="mb-3 col-xl-4 col-md-4 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'From Date  ' : (curentLang === 'ar' ? ' من تاريخ    ' : '   מתאריך   ')}}                               </label>
                            <input style="color: grey;" 
                             class="form-control" maxlength="80" 
                             type="date" formControlName="start_date" 
                              />
                          </div>
          
                          <div class="mb-3 col-xl-4 col-md-4 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'To Date  ' : (curentLang === 'ar' ? ' الي تاريخ    ' : '  עד היום  ')}}                                 </label>
                              <input style="color: grey;" 
                              class="form-control"
                               type="date"
                               maxlength="80"
                                formControlName="end_date"
                               />
                            </div>
          
                    
          
                            <div class="mb-3 col-xl-4 col-md-4 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Vat Percentage' : (curentLang === 'ar' ? '      نسبة ضريبة القيمة المضافة    ' : ' אחוזי מע"מ ')}}
                             </label>
                              <input style="color: grey;" 
                              class="form-control" type="number" 
                               formControlName="vat_percentage"
                               maxlength="20"
                                 />
                            </div>

                           

          
                    </div>
              
                
    
                      <div class="modal-footer">

                        <button type="button" [disabled]="!updateVatDetails.valid"
                         class="btn btn-sm m-auto mt-2" 
                        style="background-color: #213FA6; color: #fff;"
                         (click)="updatedVatDetailsData()"
                         > 
                          {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                      </button>
                  
                          <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                              <i class="fa fa-rotate-left mx-1"></i>
                          </button>
                  
                        
                  
                      </div>
                  </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template> -->


 <!--Delete vat Details-->

 <ng-template #dataDeleted let-modal>
    <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
          {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : 'אתב בטח שברוצנך למחוק?') }}
        </p>
    </div>
    <div class="modal-footer">

      <button type="button" 
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="deleteVatDetailsByID()"
       > 
        {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>
</ng-template>