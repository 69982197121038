<app-breadcrumb [title]="curentLang === 'en' ? 'Receipt Info' : (curentLang === 'ar' ? ' معلومات الايصالات' : ' פרטי הקבלות' )" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['    הגדרות   '])" 
[active_item]="curentLang === 'en' ? 'Receipt Info' : (curentLang === 'ar' ? ' معلومات الايصالات ' : 'פרטי הקבלות')">
</app-breadcrumb>

<!--Receipt info -->

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Receipt Info' : (curentLang === 'ar' ? 'معلومات الايصالات ' : '  פרטי הקבלות    ')}}
        </h5>

       <div>
        <!-- <button  class="btn" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
            {{ curentLang === 'en' ? 'Add receipt' : (curentLang === 'ar' ? ' اضافة ايصال ' : 'מידע קבלה')}}
        </button> -->


        <button [hidden]="!isValidMerchant" type="button" class="btn" style="background-color: #141414; color: #fff;" aria-label="Close" [routerLink]="['/dashboard/add-receipts']">
          <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add receipt' : (curentLang === 'ar' ? ' اضافة ايصال ' : '  הוספת קבלה')}}
      </button>

     
      

        <!-- <button class="btn mx-1 btn-outline-warning"   >
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button> -->

         </div>
     
      </div>

      <div class="container-fluid basic_table" >
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group search px-1">
            <label for="seaarch" class="ml-2" style="color: grey;">
              {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
            </label>
            <div class="input-group">
              <input class="form-control w-100" type="text" name="search"
                     [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term"
                     placeholder="{{curentLang === 'en' ? 'Search by customer name or document number ' : (curentLang === 'ar' ? 'بحث بأسم العميل او رقم المستند' : 'חפש לפי שם הלקוח או מספר המסמך')}}"
                     />
              <!-- <div class="input-group-append m-auto">
                <button class="btn" style="background-color: #141414; color: #fff;" type="button"
                        (click)="getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                  <i class="fa fa-search mx-1"></i>
                  {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                </button>
                <button class="btn btn-outline-secondary" type="button" (click)="term='';getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                  <i class="fa fa-times"></i>
                </button>
              </div> -->
            </div>
          </div>
        </div>
       
        <div class="col-sm-3 mt-2" >
          <label class="form-label px-1" style="color: grey;">
            {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה') }}
          </label>
          <ng-select [(ngModel)]="selectedCompany" (change)="filter($event)" class="px-1"
                     style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
            <ng-option [value]="allCompaniesOption">
              {{ curentLang === 'en' ? 'All Companies' : (curentLang === 'ar' ? 'الكل' : 'הכל') }}
            </ng-option>
            <ng-option *ngFor="let item of companyData" [value]="item">
              {{ curentLang === 'en' ? item?.company_name_en : (curentLang === 'ar' ? item?.company_name_ar : item?.company_name_he)}}
            </ng-option>
          </ng-select>
          <ng-template *ngIf="companyData.length === 0" class="px-1">
            <span style="color: red;">
              {{ curentLang === 'en' ? 'There are no invoices for this company yet' : (curentLang === 'ar' ? "لا توجد فواتير لهذه الشركة حتى الآن" : " עדין לא הופקו קבלות        ")}}
            </span>
          </ng-template>
        </div>
       
        <form [formGroup]="documentForm"   >

      
<div class="row d-flex justify-content-between ">
  <div class="col-md-3 mt-2 "  >
    <h6 class="form-label px-1 " style="color: grey;"> 
      {{ curentLang === 'en' ? 'Period' : (curentLang === 'ar' ? 'الفترة' : '  תקופה  ')}}
    </h6>
    <div class="px-1">
      <select class="form-control  px-1  form-select" style="cursor: pointer;" (change)="filterMonth($event)" (click)="confirmAction()">
        <option [value]="1">{{ curentLang === 'en' ? 'Current month' : (curentLang === 'ar' ? 'الشهر الحالي' : '  חודש נוכחי') }}</option>
        <option [value]="2">{{ curentLang === 'en' ? 'Last month' : (curentLang === 'ar' ? 'الشهر الماضي' : '  חודש קודם') }}</option>
        <option [value]="5">{{ curentLang === 'en' ? 'Last two month' : (curentLang === 'ar' ? ' أخر شهران ' : '  שני חודשים קודמים') }}</option>
        <option [value]="3">{{ curentLang === 'en' ? 'Start of the year' : (curentLang === 'ar' ? 'من بداية العام' : 'מתחילת שנה  ') }}</option>
        <option [value]="4">{{ curentLang === 'en' ? 'Last year' : (curentLang === 'ar' ? 'العام الماضي' : '  שנה קודמת') }}</option>
      </select>
    </div>
 
  </div>

  <div class="col-md-6 ">
    <div class="row d-flex justify-content-between">

    
      <div class="col-md-6 mt-2">
      <h6 for="fromDate" class="ml-2" style="color: grey;">
        {{curentLang === 'en' ? 'From Date' : (curentLang === 'ar' ? 'من تاريخ' : 'מתאריך')}}
      </h6>
      <div  >
        <p-calendar [(ngModel)]="fromDate" style="display: block; width: 100%;"
         showIcon="true" (ngModelChange)="onFromDateChange($event)"
            formControlName="startDate" 
            dateFormat="dd/mm/yy"
             placeholder="DD/MM/YYYY"
              >
            </p-calendar>

      </div>
    </div>

      <div class="col-md-6 " >
        <h6 for="toDate"  style="color: grey;">
         {{curentLang === 'en' ? 'To Date' : (curentLang === 'ar' ? 'الي تاريخ' : ' עד תאריך  ')}}
       </h6>
       <div class="">
       <p-calendar 
       style="display: block; width: 100%;"
         [(ngModel)]="toDate" 
         showIcon="true" 
         (ngModelChange)="onToDateChange($event)" 
         formControlName="endDate" 
         dateFormat="dd/mm/yy" 
         placeholder="DD/MM/YYYY">
       </p-calendar>
      </div>
   </div>

    </div>
  </div>
  
 
  
   
  
  <div class="col-md-3 mt-4 " style="padding-top: 6px;">
    <div class="form-group search  ">
      <button class="btn btn-sm mx-1  mt-2 " style="background-color: #141414; color: #fff;" 
      [disabled]="!fromDate || !toDate"
      (click)="getInvoicesFilters(fromDate, toDate)">
        <i class="fa fa-refresh mx-1"></i>
        {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
      </button>
    </div>
  </div>
</div>
   
   
  
    </form>
  
    </div>
      </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12  ">


          <div class="table-responsive">
            <table class="table table-light d-none d-lg-table text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col>">{{ curentLang === 'en' ? 'Receipt Number' : (curentLang === 'ar' ? 'رقم الايصال' : 'ספר קבלה')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'From' : (curentLang === 'ar' ? ' من ' : ' מ')}} </th>
                   <th scope="col">  {{ curentLang === 'en' ? 'To' : (curentLang === 'ar' ? '  الي  ' : ' ל ')}}</th>
                   <th scope="col">  {{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ ' : ' תאריך  ')}}</th>
                   <th scope="col>">{{ curentLang === 'en' ? 'Cash Amount' : (curentLang === 'ar' ? 'مبلغ نقدي' : '   סכום מזומן  ')}}</th>
                   <th scope="col>">{{ curentLang === 'en' ? 'Checks Amount' : (curentLang === 'ar' ? 'مبلغ الشيكات' : '     סכום שקים ')}}</th>
                   <th scope="col>">{{ curentLang === 'en' ? 'Deduction From Source ' : (curentLang === 'ar' ? 'الخصم من المصدر' : '       ניכוי במקור  ')}}</th>
                   <th scope="col>">{{ curentLang === 'en' ? 'Final Total' : (curentLang === 'ar' ? 'المجموع النهائي' : '  סה"כ ')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'PDF' : (curentLang === 'ar' ? '   PDF' : '  PDF   ')}}</th>
                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  לצפות   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th> -->

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of invoicesDataFilters | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total}; let i = index"
                [ngClass]="{'table-danger': selectedInvoiceUuid === item.invoice_uuid }"
                 
                
                >
                  <td scope="row">{{i+1}}</td>
                  <td>{{item.document_number}}</td>

                  <td>{{item?.company?.company_name_en}} </td>
                   <td>{{item?.customer_name}}</td>
                   <td>{{ item?.invoice_date | date: 'dd/MM/yyyy' }}</td>
                   <td>{{item?.cash_amount}}</td>
                   <td>{{item?.checks_amount}}</td>
                   <td>{{item?.deduction_from_source}}</td>
                   <td>{{item?.total_amount}}</td>


                   <td>
                    <i class="fa fa-file-pdf-o"  
                       *ngIf="item.document_source != 20"
                       [ngStyle]="{
                           'pointer-events': item.confirm == 0 ? 'none' : 'auto',
                           'opacity': item.confirm == 0 ? 0.6 : 1,
                           'color': '#213FA6',
                           'font-size': '15px',
                           'cursor': 'pointer'
                       }"
                       (click)="executeFunctions(item.invoice_uuid); openPdfModal(item, content4)">
                    </i>
                
                    <i *ngIf="item.document_source == 20" 
                       class="fa fa-close"
                       style="color: gray; font-size: 15px;">
                    </i>
                </td>
                


                  <!-- <td>
                    <button class=" btn" style="border: none;" (click)="executeFunctionsView(  item?.invoice_id,viewData ) ">
                        <i class="fa fa-eye" style="color: grey;   cursor: pointer;"></i>
                    </button>
                  </td> 

                  <td >
                  <button  class=" btn" style="border: none;"
                   (click)="executeFunctions(  item?.invoice_id,updatedData ) ">
                    <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                   </i>
                  </button>
                   </td>

                   <td >
                    <button 
                    [ngStyle]="{ 'pointer-events': item.status == 1 ? 'none' : 'auto', 'color': item.status == 1 ? 'gray' : 'red' }" 
                    class="btn" 
                    style="border: none;" 
                    (click)="getId(item?.invoice_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                      </button>
            
                     </td> -->

                </tr>
          
              </tbody>
            
            </table>


<!-- Receipt Info on phone -->
 

  <div class="container-fluid basic_table d-lg-none my-2" >

    


    <div class="row">
<div class="col-sm-12">
   
    <div class="card"  id="headingTwo"
     *ngFor="let item of invoicesDataFilters  | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} |filter:term ; let i = index ">
    
            <div   
            
            (click)="toggleCollapse(i)" 
            [attr.aria-expanded]="openedIndex === i" 
            aria-controls="collapse"
            style="cursor: pointer; background-color: #F6F6F9 " 
            class="py-1">
            <h6>
              <i class="fa fa-file mx-1"  style="color: #000; font-size: 15px;" > </i>
              <span style="color: #000; font-size: 15px;" class="mx-2">
                {{ curentLang === 'en' ? 'Receipt Number' : (curentLang === 'ar' ? 'رقم الايصال' : '  ספר קבלה')}} :
              </span>
              <span style="color: #000; font-size: 15px;" class="mx-2">{{item.document_number}}</span>
            </h6>
              <h6>
                <i class="icofont icofont-businessman mx-1"  style="color: #000; font-size: 15px;" > </i>
                <span style="color: #000; font-size: 15px;" class="mx-2">
                  {{ curentLang === 'en' ? 'Customer Name ' : (curentLang === 'ar' ? 'أسم الزبون ' : 'שם הלקוח')}} :
                </span>
                <span style="color: #000; font-size: 15px;" class="mx-2">{{item.customer_name}}</span>
              </h6>
        
            </div>
      
             
            <div class="row d-flex justify-content-between">

              <div class="col-md-12  col-sm-12 ">
                <div class="row collapse   show" id="collapseTwo" [ngbCollapse]="openedIndex !== i">

               
                <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'From' : (curentLang === 'ar' ? ' من ' : ' מ')}}
                  </span>
                    <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>
                    <span class="col-sm-4   text-end ">
                      <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item?.company?.company_name_en}}</span>
                    </span>
                 </div>
                 <hr style="border: solid 1px #141414;" >
                 <!---->
      
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'To' : (curentLang === 'ar' ? '  الي  ' : ' ל ')}}
                  </span>

                  <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>

                  <span class="col-sm-4   text-end ">
                    <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item?.customer_name}}</span>
                  </span>
                  
                     
     
                 </div>
                 <hr style="border: solid 1px #141414;" >

                 <!---->
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                    {{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ ' : ' תאריך  ')}}
                  </span>
                  
                  <div class="text-center col-sm-4">  </div>

                    <span style="color: #141414;   " class="col-sm-4   text-end "  >
                      <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ item?.invoice_date | date: 'dd/MM/yyyy' }}</span>
                  </span>

                
                 </div>
                 <hr style="border: solid 1px #141414;" >

                   <!---->

                   <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                      {{ curentLang === 'en' ? 'Cash Amount' : (curentLang === 'ar' ? 'مبلغ نقدي' : 'סכום מזומן')}} 

                    </span>
                    
                    <div class="text-center col-sm-4">  </div>
  
                      <span style="color: #141414;   " class="col-sm-4   text-end "  >
                        <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > {{item?.cash_amount}}</span>
                    </span>
  
                  
                   </div>
                   <hr style="border: solid 1px #141414;" >
                    <!---->

                    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                        {{ curentLang === 'en' ? 'Checks Amount' : (curentLang === 'ar' ? 'مبلغ الشيكات' : 'סכום שקים ')}} 
  
                      </span>
                      
                      <div class="text-center col-sm-4">  </div>
    
                        <span style="color: #141414;   " class="col-sm-4   text-end "  >
                          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >  {{item?.checks_amount}}</span>
                      </span>
    
                    
                     </div>
         
                   <hr style="border: solid 1px #141414;" >
                    <!---->

                    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                        {{ curentLang === 'en' ? 'Deduction From Source ' : (curentLang === 'ar' ? 'الخصم من المصدر' : 'ניכוי במקור')}}
                
                      </span>
                      
                      <div class="text-center col-sm-4">  </div>
                
                        <span style="color: #141414;   " class="col-sm-4   text-end "  >
                          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item?.deduction_from_source}}</span>
                      </span>
                
                    
                     </div>
                 
 
                 <hr style="border: solid 1px #141414;" >


                    <!---->

    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
        {{ curentLang === 'en' ? 'Final Total' : (curentLang === 'ar' ? 'المجموع النهائي' : 'סה"כ')}}

      </span>
      
      <div class="text-center col-sm-4">  </div>

        <span style="color: #141414;   " class="col-sm-4   text-end "  >
          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >   {{item?.total_amount}}</span>
      </span>

    
     </div>

                 
    
   <hr style="border: solid 1px #141414;" >

       <!---->

       <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

        <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
          {{ curentLang === 'en' ? 'PDF' : (curentLang === 'ar' ? '   PDF' : '  PDF   ')}}
  
        </span>
        
        <div class="text-center col-sm-4">  </div>
  
          <span style="color: #141414;   " class="col-sm-4   text-end "  >

            <span style="color: #141414;   " class="text-end col-sm-4  " >
              <span   rel="noopener noreferrer" class="px-5">
                <i class="fa fa-file-pdf-o" *ngIf="item.document_source != 20"
                (click)="executeFunctions(item.invoice_uuid  );openPdfModal(item , content4 )" style="cursor: pointer;"
                [ngStyle]="{ 'pointer-events': item.confirm == 0 ? 'none' : 'auto', 'opacity': item.confirm == 0 ? 0.6 : 1 }"
                style="color: grey; font-size: 15px; cursor: pointer; color: #213FA6;"></i>

                <i *ngIf="item.document_source == 20" 
                class="fa fa-close"
                style="color: gray; font-size: 15px;">
                 </i>
            </span>
          </span>
        </span>
  
      
       </div>

                 
       
     <hr style="border: solid 1px #141414;" >

                </div>
              </div>

            </div>
            <hr style="color: #141414; border: solid 1px;">
    </div>

 



  </div>

    </div>
</div>
 



            <div *ngIf="invoicesDataFilters?.length == 0" class="alert text-danger mt-3">
              <span> {{ curentLang === 'en' ? 'There are no receipts for this company yet... ' : (curentLang === 'ar' ? ' لا توجد ايصالات لهذة الشركة حتي الأن ... ' : 'אין עדיין קבלות עבור חברה זו... ')}}</span>
          </div>
            <div class="pagination-container">
              <pagination-controls 
                (pageChange)="onPageChange($event)" 
                (totalItems)="total" 
                (currentPage)="currentPage">
              </pagination-controls>
              <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange()">

            </div>
          </div>
          <div class=" d-flex justify-content-between"> 
            <div></div>

           <div *ngIf="user_type == 100 || user_type == 101">
            <a routerLink="/dashboard/merchant-info">
                <button class="btn btn-danger m-2 " *ngIf="!isInsideModal">
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

           <div *ngIf="user_type == 200 || user_type == 201">
            <a routerLink="/dashboard/users">
                <button class="btn btn-danger m-2 " >
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

          </div>

        </div>
      </div>

    </div>
  </div>

    </div>
</div>


<!--add Receipt  -->



<!--update Receipt  -->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Update receipt' : (curentLang === 'ar' ? ' تعديل الايصال ' : '  שנה את הקבלה')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="updateReceipt">
                        <div class="row">
                            <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'From' : (curentLang === 'ar' ? ' من ' : ' מ')}} 
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="from" 
                                  />
                              </div>
                            <!---->
                              <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'To' : (curentLang === 'ar' ? '  الي  ' : ' ל ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="to"
                                   />
                                </div>
                                  <!---->
                                <div class="mb-3  col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ ' : ' תאריך  ')}}
                                   </label>
                                    <input style="color: grey;" class="form-control"
                                     type="date" 
                                     formControlName="date" 
                                     maxlength="80"
                                    />
                                     
                                  </div>
                           
                                  <!---->
                                  <div class="mb-3  col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Receipt Number' : (curentLang === 'ar' ? 'رقم الايصال' : '   ספר קבלה')}}
                                   </label>
                                    <input style="color: grey;" class="form-control"
                                     type="text" 
                                     formControlName="receipt_number" 
                                     maxlength="50"
                                    />
                                     
                                  </div>

                                   <!---->
                                   <div class="mb-3  col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Amount' : (curentLang === 'ar' ? 'الكمية' : '     כמות  ')}}                                   </label>
                                    <input style="color: grey;" class="form-control"
                                     type="text" 
                                     formControlName="amount" 
                                     maxlength="50"
                                    />
                                     
                                  </div>
                                         <!---->

                                <div class="mb-3  col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Content' : (curentLang === 'ar' ? ' المحتوي ' : ' תוֹכֶן  ')}}
                                   </label>
                                 
                                    <textarea
                                    style="color: grey;" class="form-control"
                                     type="text" 
                                     formControlName="content" 
                                    name="" id="" cols="30" rows="2">
  
                                    </textarea>
                                     
                                  </div>
                             
                                      <!---->
                                <div class="mb-3  col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? ' ملاحظة ' : ' הערות  ')}}
                                   </label>
                                 
                                    <textarea
                                    style="color: grey;" class="form-control"
                                     type="text" 
                                     formControlName="note" 
                                    name="" id="" cols="30" rows="2">
  
                                    </textarea>
                                     
                                  </div>

                          
                        </div>
                  
                          <div class="modal-footer">

                            <button type="button" 
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updateReceiptById()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form> 
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--view Receipt  -->

<ng-template #viewData let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'View Info ' : (curentLang === 'ar' ? ' مشاهدة المعلومات ' : '     הצג מידע      ')}} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
                    <form  [formGroup]="updateReceipt">
                      <div class="row">
                          <div class="mb-3 col-xl-6 col-md-6 ">
                              <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'From' : (curentLang === 'ar' ? ' من ' : ' מ')}} 
                             </label>
                              <input style="color: grey;" 
                               class="form-control" maxlength="80" 
                               type="text" formControlName="from" 
                                />
                            </div>
                          <!---->
                            <div class="mb-3  col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'To' : (curentLang === 'ar' ? '  الي  ' : ' ל ')}}
                               </label>
                                <input style="color: grey;" 
                                class="form-control"
                                 type="text"
                                 maxlength="80"
                                  formControlName="to"
                                 />
                              </div>
                                <!---->
                              <div class="mb-3  col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ ' : ' תאריך  ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="date" 
                                   formControlName="date" 
                                   maxlength="80"
                                  />
                                   
                                </div>
                         
                                <!---->
                                <div class="mb-3  col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Receipt Number' : (curentLang === 'ar' ? 'رقم الايصال' : 'ספר קבלה  ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="receipt_number" 
                                   maxlength="50"
                                  />
                                   
                                </div>

                                 <!---->
                                 <div class="mb-3  col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Amount' : (curentLang === 'ar' ? 'الكمية' : '     כמות  ')}}                                   </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="amount" 
                                   maxlength="50"
                                  />
                                   
                                </div>
                                       <!---->

                              <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Content' : (curentLang === 'ar' ? ' المحتوي ' : ' תוֹכֶן  ')}}
                                 </label>
                               
                                  <textarea
                                  style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="content" 
                                  name="" id="" cols="30" rows="2">

                                  </textarea>
                                   
                                </div>
                           
                                    <!---->
                              <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? ' ملاحظة ' : ' הערות  ')}}
                                 </label>
                               
                                  <textarea
                                  style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="note" 
                                  name="" id="" cols="30" rows="2">

                                  </textarea>
                                   
                                </div>

                        
                      </div>
                
                     
                    </form> 
            
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>


  <!--Delete Receipt-->

  <ng-template #dataDeleted let-modal>
    <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
          {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : 'האם אתה בטוח שברצונך למחוק אותו ?') }}
        </p>
    </div>
    <div class="modal-footer">

      <button type="button"
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="deleteReceiptById()"
       > 
        {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>
</ng-template>




<ng-template #content4 let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
      {{curentLang === 'en' ? 'Receipt Details' : (curentLang === 'ar' ? 'تفاصيل الايصال ' : ' פרטי קבלה  ')}} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 mt-4">
          <button type="button" (click)="downloadDocumentPdf(); modal.close('Close click')" class="btn btn-sm mx-4 mt-4"
            style="background-color: #141414; color: #fff;">
            <i class="fa fa-file-pdf-o mx-1"></i>
            {{ curentLang === 'en' ? 'Print' : (curentLang === 'ar' ? 'طباعة نسخة' : 'הפקת העתק') }}
          </button>
        </div>

        <div class="col-md-4 mt-4">
          <div class="dropdown mx-4 mt-4">
            <button class="btn dropdown-toggle w-100" type="button" id="shareInvoiceDropdown" data-bs-toggle="dropdown"
              aria-expanded="false" style="background-color: #141414; color: #fff;">
              {{ curentLang === 'en' ? 'Share Original Version' : (curentLang === 'ar' ? 'مشاركة النسخة الأصلية' : 'שתף את המקור') }}
              <i class="fa fa-share-alt mx-1 text-white" style="font-size: 15px;"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="shareInvoiceDropdown">
              <li style="border-bottom: 1px solid #000;">
                <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" (click)="copyLinkToClipboard(rawPdfUrl)">
                  <i class="fa fa-copy mx-1" style="font-size: 15px; color: rgb(158, 40, 40);"></i>
                  {{ curentLang === 'en' ? 'Copy Link' : (curentLang === 'ar' ? 'نسخ الرابط' : 'העתק קישור') }}
                </a>
              </li>
              <li style="border-bottom: 1px solid #000;">
                <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" href="https://wa.me/?text={{ rawPdfUrl }}" target="_blank">
                    <i class="fa fa-whatsapp mx-1" style="font-size: 15px; color: rgb(32, 92, 32);"></i>
                    {{ curentLang === 'en' ? 'Share via WhatsApp' : (curentLang === 'ar' ? 'مشاركة عبر واتساب' : 'שתף בוואטסאפ') }}
                </a>
            </li>
              <li>
                <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" href="mailto:?subject=Invoice&body={{ rawPdfUrl }}">
                  <i class="fa fa-envelope mx-1" style="font-size: 15px; color: rgb(137, 137, 16);"></i>
                  {{ curentLang === 'en' ? 'Share via Email' : (curentLang === 'ar' ? 'مشاركة عبر البريد الإلكتروني' : 'שתף באימייל') }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-4 my-2">
          <div class="form-group mx-4">
            <label class="mt-2" style="color: #141414;">
              {{ curentLang === 'en' ? 'Change Document Language' : (curentLang === 'ar' ? 'تغيير لغة المستند' : 'שינוי שפת המסמך') }}
            </label>
            <select class="form-control" (change)="filterDocumentLanguage($event.target.value)">
              <option *ngFor="let lang of availableLanguages" [value]="lang.code">
                {{ lang.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <!-- عرض رسالة جاري تجهيز الملف أثناء عرض الهيكل العظمي -->
        <div *ngIf="isSkelton">
          <p class="text-center" style="color: grey;">{{ curentLang === 'en' ? 'The file is being prepared...' : (curentLang === 'ar' ? 'جاري تجهيز الملف' : 'הקובץ בהכנה') }}</p>
          <div class="skeleton-loader">
            <div class="border-round border-1 surface-border p-4 surface-card">
              <div class="flex mb-3">
                <div>
                  <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
                  <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
                  <p-skeleton height=".5rem"></p-skeleton>
                </div>
              </div>
              <p-skeleton width="100%" height="150px"></p-skeleton>
              <div class="flex justify-content-between mt-3">
                <p-skeleton width="4rem" height="2rem"></p-skeleton>
              </div>
            </div>
          </div>
        </div>

        <!-- عرض الـ PDF عند انتهاء الهيكل العظمي -->
        <iframe *ngIf="!isSkelton && pdfUrl" [src]="pdfUrl" width="100%" height="500px" frameborder="0"></iframe>
      </div>
    </div>

    <div class="modal-footer my-2">
      <button type="button" class="btn btn-sm mt-2 btn-danger" (click)="modal.close('Close click')">
        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
        <i class="fa fa-rotate-left mx-1"></i>
      </button>
    </div>
  </div>
</ng-template>
