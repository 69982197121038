<app-breadcrumb [title]="curentLang === 'en' ? 'Terms And Conditions Info' : (curentLang === 'ar' ? ' معلومات الشروط والأحكام' : '     תנאים   ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['    לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Terms And Conditions Info' : (curentLang === 'ar' ? ' معلومات الشروط والأحكام ' : '    תנאים   ')">
</app-breadcrumb>

<!--terms and conditions info -->

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Terms And Conditions Info' : (curentLang === 'ar' ? ' معلومات الشروط والأحكام' : ' תנאים ')}}
        </h5>

       <div>
        <button  class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)" *ngIf="user_type == 200">
            <i class="fa fa-plus-circle mx-1"></i>
            {{ curentLang === 'en' ? 'Add Terms And Conditions ' : (curentLang === 'ar' ? ' اضافة شروط وأحكام ' : 'הוסף תנאים והגבלות    ')}}
        </button>

       
        

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllTermsAndConditions()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Question' : (curentLang === 'ar' ? '    السؤال ' : ' השאלה  ')}} </th>
                   <th scope="col">  {{ curentLang === 'en' ? 'Answer  ' : (curentLang === 'ar' ? '  الاجابة  ' : '   התשובה')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>

                   <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th>

 
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of termsData  | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td> {{ curentLang === 'en' ? item.question_en.slice(0,20) : (curentLang === 'ar' ? item.question_ar.slice(0,20) :  item.question_he.slice(0,20))}} </td>
                  <td> {{ curentLang === 'en' ? item.answer_en.slice(0,20) : (curentLang === 'ar' ? item.answer_ar.slice(0,20) :  item.answer_he.slice(0,20))}} </td>
                  <td>{{ item.createdDate | date:'dd/MM/yyyy'}}</td>

 
           
                

                 <td>
                    <button   *ngIf="item.status == 1" class="btn" style="border: none;" (click)="changeTermsStatus(item?.id, item.status)">
                      <i class="fa solid fa-solid fa-toggle-on" style=" color: #213FA6;"></i>
                    </button> 
                    <button *ngIf="item.status == 59" class="btn" style="border: none;" (click)="changeTermsStatus(item?.id, item.status)">
                      <i class="fa solid fa-solid fa-toggle-off" style=" color: red;"></i>
                    </button> 
                  </td>
                  
                 
                  <td >
                    <button [disabled]="user_type == 100" class=" btn" style="border: none;"
                     (click)="executeFunctions(item?.id,updatedData ) ">
                      <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                     </i>
                    </button>
                     </td> 


                  <td >
                    <button [disabled]="user_type == 100" 
                    [ngStyle]="{ 'pointer-events': item.status == 1 ? 'none' : 'auto', 'color': item.status == 1 ? 'gray' : 'red' }" 
                    class="btn" 
                    style="border: none;" 
                    (click)="getId(item?.id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                      </button>
                     </td>

                


                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>



<!--add terms and conditions  -->

<ng-template #content let-modal >
    <div class="modal-header d-flex justify-content-between" >
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Add Terms And Conditions' : (curentLang === 'ar' ? ' اضافة شروط وأحكام    ' : '   הוסף תנאים והגבלות    ')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                   
                <form  [formGroup]="addTerms" novalidate [ngClass]="{ 'was-validated': validate }">
                    <div class="row">

                        <div class="mb-3 col-xl-12 col-md-12 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Question Ar  ' : (curentLang === 'ar' ? ' السؤال عربي   ' : '   השאלה היא בערבית  ')}} 
                                <span class="text-danger">*</span>
                           </label>
                            <input style="color: grey;"  onlyAlphabets required=""
                             class="form-control" maxlength="80" 
                             type="text" formControlName="question_ar" 
                              />
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter a question' : (curentLang === 'ar' ? '   الرجاء ادخال السؤال ' : ' אנא הכנס את שאלתך')}}  </div>

                          </div>
                        <!---->
                        <div class="mb-3 col-xl-12 col-md-12 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Answer Ar' : (curentLang === 'ar' ? ' الاجابة عربي' : ' התשובה היא ערבית')}} 
                                <span class="text-danger">*</span>
                           </label>
                           <textarea
                           formControlName="answer_ar"
                           onlyAlphabets required=""
                           class="form-textarea form-control my-2"
                           placeholder="{{ curentLang === 'en' ? 'Answer Ar' : (curentLang === 'ar' ? ' الاجابة عربي' : ' התשובה היא ערבית')}}"
                          
                       >
                      </textarea>
                      <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter an answer' : (curentLang === 'ar' ? '   الرجاء ادخال الاجابة ' : '   נא להזין את התשובה    ')}}  </div>

                          </div>

                         
                       <hr>
                         <!---->

                        <div class="mb-3 col-xl-12 col-md-12 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Question En  ' : (curentLang === 'ar' ? ' السؤال انجليزي   ' : ' השאלה היא עברית  ')}} 
                                <span class="text-danger">*</span>
                           </label>
                            <input style="color: grey;" onlyAlphabets required=""
                             class="form-control" maxlength="80" 
                             type="text" formControlName="question_en" 
                              />
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter a question' : (curentLang === 'ar' ? '   الرجاء ادخال السؤال ' : ' אנא הכנס את שאלתך')}}  </div>

                          </div>
                        <!---->
                        <div class="mb-3 col-xl-12 col-md-12 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Answer En  ' : (curentLang === 'ar' ? ' الاجابة انجليزي   ' : ' התשובה היא עברית      ')}} 
                                <span class="text-danger">*</span>
                           </label>
                          

                              <textarea
                              formControlName="answer_en"
                              onlyAlphabets required=""
                              class="form-textarea form-control my-2"
                              placeholder="{{ curentLang === 'en' ? 'Answer En' : (curentLang === 'ar' ? 'الاجابة انجليزي ' : ' התשובה היא עברית ')}}  "
                             
                          >
                        </textarea>
                        <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter an answer' : (curentLang === 'ar' ? '   الرجاء ادخال الاجابة ' : '   נא להזין את התשובה    ')}}  </div>

                          </div>

                          <hr>
                                   
                                          <!---->
                                     <div class="mb-3 col-xl-12 col-md-12 ">
                                        <label class="form-label" style="color: grey;"> 
                                            {{ curentLang === 'en' ? 'Question He  ' : (curentLang === 'ar' ? ' السؤال عبري' : '  השאלה היא עברית')}} 
                                            <span class="text-danger">*</span>
                                       </label>
                                        <input style="color: grey;" onlyAlphabets required=""
                                         class="form-control" maxlength="80" 
                                         type="text" formControlName="question_he" 
                                          />
                                          <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter a question' : (curentLang === 'ar' ? '   الرجاء ادخال السؤال ' : ' אנא הכנס את שאלתך')}}  </div>

                                      </div>
                                    <!---->
                                    <div class="mb-3 col-xl-12 col-md-12 ">
                                        <label class="form-label" style="color: grey;"> 
                                            {{ curentLang === 'en' ? 'Answer He' : (curentLang === 'ar' ? ' الاجابة عبري' : ' התשובה היא עברית  ')}} 
                                            <span class="text-danger">*</span>
                                       </label>
                                          <textarea
                                          formControlName="answer_he" onlyAlphabets required=""
                                          class="form-textarea form-control my-2"
                                          placeholder="{{ curentLang === 'en' ? 'Answer He' : (curentLang === 'ar' ? ' الاجابة عبري' : ' התשובה היא עברית  ')}} "
                                      >

                                    </textarea>
                                    <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter an answer' : (curentLang === 'ar' ? '   الرجاء ادخال الاجابة ' : '   נא להזין את התשובה    ')}}  </div>

                                      </div>

                                      <hr>
                      
                    </div>
              
                      <div class="modal-footer">

                        <button type="button" 
                         class="btn btn-sm m-auto mt-2" 
                        style="background-color: #141414; color: #fff;"
                         (click)="addedData()"
                         > 
                          {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                      </button>
                  
                          <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                              <i class="fa fa-rotate-left mx-1"></i>
                          </button>
                  
                        
                  
                      </div>
                  </form> 
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--update terms and conditions -->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
              {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : '    ערוך מידע ')}} 
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
      <div class="modal-body">
          <div class="container-fluid ">
              <div class="edit-profile">
                <div class="row d-flex justify-content-between">
                       
                    <form  [formGroup]="updateTerms" novalidate [ngClass]="{ 'was-validated': validateUpdate }">
                        <div class="row">
    
                          <div class="mb-3 col-xl-12 col-md-12 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Question Ar  ' : (curentLang === 'ar' ? ' السؤال عربي   ' : '   השאלה היא בערבית  ')}} 
                                <span class="text-danger">*</span>
                           </label>
                            <input style="color: grey;"  onlyAlphabets required=""
                             class="form-control" maxlength="80" 
                             type="text" formControlName="question_ar" 
                              />
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter a question' : (curentLang === 'ar' ? '   الرجاء ادخال السؤال ' : ' אנא הכנס את שאלתך')}}  </div>

                          </div>
                        <!---->
                        <div class="mb-3 col-xl-12 col-md-12 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Answer Ar' : (curentLang === 'ar' ? ' الاجابة عربي' : ' התשובה היא ערבית')}} 
                                <span class="text-danger">*</span>
                           </label>
                           <textarea
                           formControlName="answer_ar"
                           onlyAlphabets required=""
                           class="form-textarea form-control my-2"
                           placeholder="{{ curentLang === 'en' ? 'Answer Ar' : (curentLang === 'ar' ? ' الاجابة عربي' : ' התשובה היא ערבית')}}"
                          
                       >
                      </textarea>
                      <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter an answer' : (curentLang === 'ar' ? '   الرجاء ادخال الاجابة ' : '   נא להזין את התשובה    ')}}  </div>

                          </div>

                         
                       <hr>
                         <!---->

                        <div class="mb-3 col-xl-12 col-md-12 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Question En  ' : (curentLang === 'ar' ? ' السؤال انجليزي   ' : ' השאלה היא עברית  ')}} 
                                <span class="text-danger">*</span>
                           </label>
                            <input style="color: grey;" onlyAlphabets required=""
                             class="form-control" maxlength="80" 
                             type="text" formControlName="question_en" 
                              />
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter a question' : (curentLang === 'ar' ? '   الرجاء ادخال السؤال ' : ' אנא הכנס את שאלתך')}}  </div>

                          </div>
                        <!---->
                        <div class="mb-3 col-xl-12 col-md-12 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Answer En  ' : (curentLang === 'ar' ? ' الاجابة انجليزي   ' : ' התשובה היא עברית      ')}} 
                                <span class="text-danger">*</span>
                           </label>
                          

                              <textarea
                              formControlName="answer_en"
                              onlyAlphabets required=""
                              class="form-textarea form-control my-2"
                              placeholder="{{ curentLang === 'en' ? 'Answer En' : (curentLang === 'ar' ? 'الاجابة انجليزي ' : ' התשובה היא עברית ')}}  "
                             
                          >
                        </textarea>
                        <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter an answer' : (curentLang === 'ar' ? '   الرجاء ادخال الاجابة ' : '   נא להזין את התשובה    ')}}  </div>

                          </div>

                          <hr>
                                   
                                          <!---->
                                     <div class="mb-3 col-xl-12 col-md-12 ">
                                        <label class="form-label" style="color: grey;"> 
                                            {{ curentLang === 'en' ? 'Question He  ' : (curentLang === 'ar' ? ' السؤال عبري' : '  השאלה היא עברית')}} 
                                            <span class="text-danger">*</span>
                                       </label>
                                        <input style="color: grey;" onlyAlphabets required=""
                                         class="form-control" maxlength="80" 
                                         type="text" formControlName="question_he" 
                                          />
                                          <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter a question' : (curentLang === 'ar' ? '   الرجاء ادخال السؤال ' : ' אנא הכנס את שאלתך')}}  </div>

                                      </div>
                                    <!---->
                                    <div class="mb-3 col-xl-12 col-md-12 ">
                                        <label class="form-label" style="color: grey;"> 
                                            {{ curentLang === 'en' ? 'Answer He' : (curentLang === 'ar' ? ' الاجابة عبري' : ' התשובה היא עברית  ')}} 
                                            <span class="text-danger">*</span>
                                       </label>
                                          <textarea
                                          formControlName="answer_he" onlyAlphabets required=""
                                          class="form-textarea form-control my-2"
                                          placeholder="{{ curentLang === 'en' ? 'Answer He' : (curentLang === 'ar' ? ' الاجابة عبري' : ' התשובה היא עברית  ')}} "
                                      >

                                    </textarea>
                                    <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter an answer' : (curentLang === 'ar' ? '   الرجاء ادخال الاجابة ' : '   נא להזין את התשובה    ')}}  </div>

                                      </div>

                                      <hr>
                          
                        </div>
                  
                          <div class="modal-footer">
    
                            <button type="button" 
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updateTermsData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form> 
                
              </div>
              
            </div>
          
          </div>
      </div>
     
  </ng-template>


     <!--Delete terms and conditions-->

     <ng-template #dataDeleted let-modal>
        <div class="modal-header">
            <h4 class="modal-title"></h4>
            <button type="button" class="close btn-close"  style="color: grey;"  aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
              {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : '  האם אתה בטוח שברצונך למחוק   ') }}
            </p>
        </div>
        <div class="modal-footer">
  
          <button type="button" 
           class="btn btn-sm m-auto mt-2" 
          style="background-color: #141414; color: #fff;"
           (click)="deleteTermsById()"
           > 
            {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
            <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
        </button>
    
            <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                <i class="fa fa-rotate-left mx-1"></i>
            </button>
    
          
    
        </div>
    </ng-template>