import { Component, Inject, OnInit } from "@angular/core";
import * as accordion from '../../landing-pages/terms-and-conditions/accordion'
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from "@angular/common";
import { HttpHelpService } from "src/app/Data/service/http-help.service";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./terms-and-conditions.component.html",
  styleUrls: ["./terms-and-conditions.component.scss"]
})

export class TermsAndConditionsComponent implements OnInit {
  curentLang:string="";
  public accordion2 = accordion.accordion2;
  public accordion3 = accordion.accordion3;
  public accordion4 = accordion.accordion4;

  public term: string
  termsData:any=[];

  constructor(
    private spinner: NgxSpinnerService,  
    public translate:TranslateService , 
    @Inject(DOCUMENT) private document: Document,
    private service:HttpHelpService 
   )
   {
    this.curentLang = localStorage.getItem('curentLang');
   }

  getAllActiveTermsAndConditions() {
    this.spinner.show();
    this.service.getAllActiveTermsAndConditions().subscribe({
      next: res => {
        this.termsData = res;
          
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  ngOnInit() {
    this.spinner.show();
    this.getAllActiveTermsAndConditions();
    setTimeout(() => {
       this.spinner.hide();
    }, 1000);
  }
}
