<ng-container *ngIf="!isInsideModal">

<app-breadcrumb [title]="curentLang === 'en' ? 'Accountants Info' : (curentLang === 'ar' ? ' معلومات المحاسبين ' : ' מידע על רואי חשבון  ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['מידע על רואי חשבון'])" 
[active_item]="curentLang === 'en' ? 'Accountants Info' : (curentLang === 'ar' ? ' معلومات المحاسبين ' : 'מידע על רואי חשבון  ')">
</app-breadcrumb>

</ng-container>


<!--Accountants info -->
<ng-container *ngIf="!isInsideModal">

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Accountants Info' : (curentLang === 'ar' ? 'معلومات المحاسبين ' : 'מידע על סוגי חשבוניות')}}
        </h5>

       <div>
        <button  class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)" *ngIf="user_type == 100">
            <i class="fa fa-plus-circle mx-1"></i>
            {{ curentLang === 'en' ? 'Add Permissions' : (curentLang === 'ar' ? ' اضافة اذونات ' : '  הוסף הרשאות ')}}
        </button>

     
        

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllPermissionsByMerchantId()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Office Name' : (curentLang === 'ar' ? ' اسم المكتب ' : ' שם המשרד')}} </th>
                   <th scope="col">  {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? '  اسم الشركة  ' : 'שם החברה')}}</th>
                
                   <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
               
                   <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th> 

                 


                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of accountantsData  | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td>{{item?.office?.office_name}} </td>
                   <td>{{curentLang === 'en' ? item?.company?.company_name_en : (curentLang === 'ar' ? item?.company?.company_name_ar : item?.company?.company_name_he)}}</td>
                   <td>{{ item.createdDate | date: 'dd/MM/yyyy'}}</td>
                   <td>{{curentLang === 'en' ? item?.lookupDetailsOfficeStatus?.lookupDetailEnName : (curentLang === 'ar' ? item?.lookupDetailsOfficeStatus?.lookupDetailArName : item?.lookupDetailsOfficeStatus?.lookupDetailHeName)}}</td>

                   <td >
                    <button   
                     
                    class="btn" 
                    style="border: none;" 
                    (click)="getRowId(item?.permission_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style=" cursor: pointer; color: red;"></i>
                      </button>
                     </td> 
 

                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>
</ng-container>



<!--add Permissions-->

<ng-template #content let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
          {{ curentLang === 'en' ? 'Adding permissions to accounting companies' : (curentLang === 'ar' ? 'اضافة اذونات لشركات المحاسبة' : 'הוספת הרשאות לחברות רואי חשבון')}}
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
               <form [formGroup]="addPermissionForm">
                <div class="row">

                  <div class="mb-3 col-xl-6 col-md-6">
                    <label class="form-label" style="color: grey;">
                      {{ curentLang === 'en' ? 'Office Name' : (curentLang === 'ar' ? 'اسم المكتب' : '  שם המשרד') }}
                    </label>
                    <ng-select formControlName="office_id" [(ngModel)]="selectedOffice" (change)="filter(selectedOffice)"
                    style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                     <ng-option *ngFor="let item of activeAccountantOfficeData; let i = index " [value]="item?.office_id">
                       {{item?.office_name }}
                       <span style="color: transparent;">{{item.office_phone}}</span>
                     </ng-option>
                   </ng-select>
                  </div>

                  



                  <div class="mb-3 col-xl-6 col-md-6">
                    <label class="form-label" style="color: grey;">
                      {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה') }}
                    </label>
                    <ng-select formControlName="companyUser" (change)="onCompanyMerchantChange($event)" style="cursor: pointer;" [multiple]="true"
                               placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                      <ng-option *ngFor="let item of activeCompanyData" [value]="item" >
                        {{ curentLang === 'en' ? item?.company_name_en : (curentLang === 'ar' ? item?.company_name_ar : item?.company_name_he) }} 
                       </ng-option>
                    </ng-select>
                  </div>



                </div>



                
                <div class="modal-footer">
                  <button type="button" class="btn btn-sm m-auto mt-2" style="background-color: #141414; color: #fff;" 
                    [disabled]="addPermissionForm.invalid"  
                    (click)="addedPermission()"> 
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                  </button>
                  
                  <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                    {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                    <i class="fa fa-rotate-left mx-1"></i>
                  </button>
                </div>
              </form> 
                 
              
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>


<!--Delete accountant-->

              
<ng-template #dataDeleted let-modal>
  <div class="modal-header">
      <h4 class="modal-title"></h4>
      <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
        {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : 'האם אתה בטוח שברצונך למחוק אותו?') }}

      </p>
  </div>
  <div class="modal-footer">

    <button type="button" 
     class="btn btn-sm m-auto mt-2" 
    style="background-color: #141414; color: #fff;"
     (click)="deleteRelatedOfficeToCompany()"
     > 
     {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
      <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
  </button>

      <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
          <i class="fa fa-rotate-left mx-1"></i>
      </button>

  </div>
</ng-template>








<!-- accountant modal Info -->
<ng-container *ngIf="isInsideModal">

  <div class="container-fluid basic_table" >

    <div class="card-header d-flex justify-content-between">
      <h5 style="color: grey;"> 
          {{ curentLang === 'en' ? 'Accountants Info' : (curentLang === 'ar' ? 'معلومات المحاسبين ' : 'מידע על סוגי חשבוניות')}}
      </h5>

     <div>
      <button  class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)" *ngIf="user_type == 100">
          <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add Permissions' : (curentLang === 'ar' ? ' اضافة اذونات ' : '  הוסף הרשאות ')}}
      </button>

   
      

      <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllPermissionsByMerchantId()">
        <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
    </button>

       </div>
   
    </div>
    <div class="row">
      <div class="col-sm-5">
          <div class="form-group search m-3">
            <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
               <input class="form-control  w-100" type="text" name="search"
                [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
          </div>
      </div>
  </div>


    <div class="row">
<div class="col-sm-12">
   
    <div class="card"  id="headingTwo"
     *ngFor="let item of accountantsData  | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
    
            <p   
            
            (click)="toggleCollapse(i)" 
            [attr.aria-expanded]="openedIndex === i" 
            aria-controls="collapse"
            style="cursor: pointer; background-color: #FB4438;" 
            class="py-1">
              <i class="fa fa-user-circle mx-1"  style="color: #fff; font-size: 16px;" > </i>
              <span style="color: #fff; font-size: 16px;" class="mx-2">{{item?.office?.office_name}}</span>
            </p>
      
             
            <div class="row d-flex justify-content-between">

              <div class="col-md-12  col-sm-12 ">
                <div class="row collapse   show" id="collapseTwo" [ngbCollapse]="openedIndex !== i">

               
                <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'Office Name' : (curentLang === 'ar' ? ' اسم المكتب ' : ' שם המשרד')}}
                  </span>
                    <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >{{item?.office?.office_name}}</span>
                    <span class="col-sm-4   text-end ">
                      <i class="fa fa-building px-5 "  style="color: #141414; font-size: 16px ;" ></i>
                    </span>
                 </div>
                 <hr style="border: solid 1px #141414;" >
                 <!---->
      
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? '  اسم الشركة  ' : 'שם החברה')}}
                  </span>
                    <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
                      {{curentLang === 'en' ? item?.company?.company_name_en : (curentLang === 'ar' ? item?.company?.company_name_ar : item?.company?.company_name_he)}}
                    </span>
                    <span class="col-sm-4   text-end ">
                      <i class="fa fa-building-o px-5 "  style="color: #141414; font-size: 16px ;" ></i>
                    </span>
                 </div>
                 <hr style="border: solid 1px #141414;" >

                 <!---->
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}
                  </span>
                    <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
                      {{ item.createdDate | date: 'dd/MM/yyyy'}}
                    </span>
                    <span class="col-sm-4   text-end ">
                      <i class="fa fa-history px-5 "  style="color: #141414; font-size: 16px ;" ></i>
                    </span>
                 </div>
                 <hr style="border: solid 1px #141414;" >

                   <!---->

                   <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                      {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}
                    </span>
                      <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
                        {{curentLang === 'en' ? item?.lookupDetailsOfficeStatus?.lookupDetailEnName : (curentLang === 'ar' ? item?.lookupDetailsOfficeStatus?.lookupDetailArName : item?.lookupDetailsOfficeStatus?.lookupDetailHeName)}}

                      </span>
                      <span class="col-sm-4   text-end ">
                        <i class="fa fa-lightbulb-o px-5 "  style="color: #141414; font-size: 16px ;" ></i>
                      </span>
                   </div>
                   <hr style="border: solid 1px #141414;" >
                      
         
                 
                    <!---->
                    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                        {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}
                      </span>
                      <div class="text-center col-sm-4   ">  
                    
                      </div>
                        <span style="color: #141414; font-size: 14px; " class=" text-end col-sm-4" >
                          <button   
                     
                          class="btn px-5" 
                          style="border: none;" 
                          (click)="getRowId(item?.permission_id);openVerticallyCentered(dataDeleted)">
                            <i class="fa fa-trash-o   " style=" cursor: pointer; color: red;"></i>
                            </button>  
                        </span>
                   
                     </div>
                     <hr style="border: solid 1px #141414;" >
        

                  



                </div>
              </div>

            </div>
            <hr style="color: #141414; border: solid 1px;">
    </div>

    <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>    




  </div>

    </div>
</div>
</ng-container>



