import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { HttpHelpService } from "../../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { israelPhoneNumberValidator } from "src/app/validators/israelPhoneNumberValidator";
import { AddAccountantUserEndPoient, AddMerchantUserEndPoient, AddUserEndPoient } from "../../service/global.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-all-users-office",
  templateUrl: "./all-users-office.component.html",
  styleUrls: ["./all-users-office.component.scss"]
})

export class AllUsersOfficeComponent implements OnInit {
  
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  updateUserForm:FormGroup;
  officeId: number;
  updatedData: any;
 
  viewData: any; 
  updatePassword:FormGroup;
  accountantUser:FormGroup;

  userData:any=[];
  userTypeData:any=[];
  rowId:any;
  public show: boolean = false;
  public validate = false;
  public updatevalidate = false;

  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
    activatedRoute:ActivatedRoute,

  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeId = activatedRoute.snapshot.params['id'];

   this.updateUserForm=new FormGroup({
    'phone_number': new FormControl('', [Validators.required, israelPhoneNumberValidator()]),
    user_name:new FormControl(''),
    created_by:new FormControl(this.userRegistId),
    });

    this.updatePassword=new FormGroup({
      password: new FormControl('' , [Validators.required , Validators.pattern(/^(?=.*[!@#$%^&*()_\-+=<>?])[A-Z][A-Za-z0-9!@#$%^&*()_\-+=<>?]{3,20}$/)]),
      user_id :new FormControl(this.rowId),   
      });

      this.accountantUser=new FormGroup({
        'phone_number': new FormControl('', [Validators.required, israelPhoneNumberValidator()]),
  
        user_name:new FormControl(''),
        password: new FormControl('' , [Validators.required , Validators.pattern(/^(?=.*[!@#$%^&*()_\-+=<>?])[A-Z][A-Za-z0-9!@#$%^&*()_\-+=<>?]{3,20}$/)]),
        office_id: new FormControl(this.officeId),
        created_by:new FormControl(this.userRegistId),
        });
   
  };

  addAccountantUser() {
    if (
        this.accountantUser.value.phone_number == ''||
        this.accountantUser.value.user_name == ''||
        this.accountantUser.value.password == ''
    ) {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
        });
        this.validate = !this.validate;
        return; 
    } else {
        let password = this.accountantUser.value.password;
        if (password.length < 8 || password.length > 20) {
            Swal.fire({
                icon: 'error',
                title: this.curentLang === 'ar' ? 'يجب أن تكون كلمة المرور أطول من أو تساوي 8 أحرف وأقل من أو تساوي 20 حرفًا' : 
                       (this.curentLang === 'en' ? 'Password must be longer than or equal to 8 characters and shorter than or equal to 20 characters' : 
                       (this.curentLang === 'he' ? 'הסיסמה חייבת להיות ארוכה מ-8 תווים וקצרה מ-20 תווים' : ''))
            });
            return;
        }
        let merchantPhone = this.accountantUser.value.phone_number;
        if (merchantPhone.length <  7) {
          Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? 'يجب ألا يقل رقم الهاتف عن 7 أرقام' : 
                   (this.curentLang === 'en' ? 'The phone number must be at least 7 digits long ' : 
                   (this.curentLang === 'he' ? 'מספר הטלפון חייב להיות בן 7 ספרות לפחות' : ''))
          });
          return;
        }

        let userPhone = this.accountantUser.value.phone_number;
        if (userPhone.startsWith('0')) {
            userPhone = userPhone.substring(1);
        }
        let model = {
            "phone_number": '+972' + userPhone,
            user_name: this.accountantUser.value.user_name,
            password: this.accountantUser.value.password,
            office_id: Number(this.officeId),
            created_by: Number(this.userRegistId),
        };

        this.spinner.show();
        this.service.Post(AddAccountantUserEndPoient.POST, model).subscribe((res) => {
            if (res.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
                });
                this.modalService.dismissAll();
                this.getAllUserByOfficeId();
                this.accountantUser.reset();
            } else if (res.status === 400 || res.status === 403 || res.status === 500) {
                let errorMessage;
                switch (res.status) {
                    case 400:
                        errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                        break;
                    case 403:
                        errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                        break;
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                        break;
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }
                Swal.fire({
                    icon: 'error',
                    title: errorMessage
                });
            }
            this.spinner.hide();
        });
    }
}

openAddAccountantUserModel(data:any) {
  this.modalService.open(data, { size: 'md' , centered:true });
  };

  getId(id: number) {
    this.rowId = id;
    
  };
  getAllUserByOfficeId() {
    this.spinner.show();
    this.service.getAllUserByOfficeId(this.officeId).subscribe({
      next: res => {
        this.userData = res;
         
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };
 
  setValue(rowId: number) {
    for (let i = 0; i < this.userData.length; i++) {
      if (this.userData[i].user_id == this.rowId) {
        const selectedCategory = this.userData.find(item => item.user_id === rowId);

 
        let phone = this.userData[i].phone_number;
        if (phone.startsWith('+972')) {
          phone = phone.slice(4);  
        }
        this.updateUserForm.controls.phone_number.setValue(phone);
        this.updateUserForm.controls.user_name.setValue(this.userData[i].user_name);
      }
    };
  };

  executeFunctions(userId: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(userId);
    this.setValue(userId);
    this.updateMd(this.updatedData);
 
  };
 

  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'md'});
  };

  

  filter(event:any)
  {
    let x = event.target.value;
   };
   showPassword() {
    this.show = !this.show;
  }

  updateUserInfo() {
    let customerPhone = this.updateUserForm.value.phone_number;
    if (customerPhone.startsWith('0')) {
        customerPhone = customerPhone.substring(1);
    }
 

    // التحقق من الحقول المطلوبة
    const requiredFields = [
        'phone_number', 
        'user_name', 
    ];
    
    for (const field of requiredFields) {
        if (!this.updateUserForm.get(field).value) {
            Swal.fire({
                icon: 'warning',
                title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة' : 
                       (this.curentLang === 'en' ? 'Please fill the required fields' : 
                       (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ' : ''))
            });
            this.updatevalidate = !this.updatevalidate;
            return;
        }
    }

   

    // التحقق من صحة رقم الهاتف
    let merchantPhone = this.updateUserForm.value.phone_number;
    if (merchantPhone !== '' && merchantPhone.length < 7) {
        Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? 'يجب ألا يقل رقم الهاتف عن 7 أرقام' : 
                   (this.curentLang === 'en' ? 'The phone number must be at least 7 digits long' : 
                   (this.curentLang === 'he' ? 'מספר הטלפון חייב להיות בן 7 ספרות לפחות' : ''))
        });
        return;
    }

    const validPrefixes = ['50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
    let validationStartPhoneNumber = merchantPhone.substring(0, 2);

    if (merchantPhone !== '' && !validPrefixes.includes(validationStartPhoneNumber)) {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يجب أن يبدأ رقم الهاتف بأي رقم من هذة الأرقام (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
                   (this.curentLang === 'en' ? 'Phone number must start with one of these numbers (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
                   (this.curentLang === 'he' ? 'צריך להתחיל עם אחד מהמספרים (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : ''))
        });
        return;
    }

    // إزالة الصفر الأولي من رقم الهاتف إذا كان موجودًا
    let customerPhoneFinal = this.updateUserForm.value.phone_number;
    if (customerPhoneFinal.startsWith('0')) {
        customerPhoneFinal = customerPhoneFinal.substring(1);
    }

  

    let model = {
        user_name: this.updateUserForm.value.user_name,
         
        phone_number: '+972' + customerPhone,
         
        updated_by: Number(this.userRegistId),
    };

    this.spinner.show();
    this.service.updateUserInfo(this.rowId, model).subscribe((res) => {
        if (res.status === 200) {
            Swal.fire({
                icon: 'success',
                title: this.curentLang === 'ar' ? res.messageAr : 
                       (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
            this.getAllUserByOfficeId();
       
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
            let errorMessage;
            switch (res.status) {
                case 400:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                case 403:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                case 500:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                default:
                    errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : 
                                   (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            }
            Swal.fire({
                icon: 'error',
                title: errorMessage
            });
        }
        this.spinner.hide();
    });
}
  
 
  // changeUserStatus(id:number, user_status:any){
  //   this.spinner.show();
  
  //   let model =
  //   {
  //     "user_status": user_status == 1 ? 59 : 1,
  //    "updated_by": this.userRegistId
  //   }
  
  //   this.service.changeUserStatus(id , model ).subscribe(({
      
  //     next:res =>{
  //      if (res.status ==200){

  //       Swal.fire({
  //         icon: 'success',
  //         title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
  //       })
  //         this.getAllUserByOfficeId();
  //         this.spinner.hide();
  //      }
  
  //     },
  //     error:err =>{
  //       Swal.fire({
  //         icon:"error",
  //         title:err.error.message[0]
  //       });
  //       this.spinner.hide();
  
  //     }
      
  //   }))
  // };

  openXl(content:any) {
		this.modalService.open(content, { size: 'md' , centered:true });
	  };

    ChangeUserPasswordByAdmin() {
      let password = this.updatePassword.value.password;
      if (password.length < 8 || password.length > 20) {
        Swal.fire({
        icon: 'error',
        title: this.curentLang === 'ar' ? 'يجب أن تكون كلمة المرور أطول من أو تساوي 8 أحرف وأقل من أو تساوي 20 حرفًا' : 
             (this.curentLang === 'en' ? 'Password must be longer than or equal to 8 characters and shorter than or equal to 20 characters' : 
             (this.curentLang === 'he' ? 'הסיסמה חייבת להיות ארוכה מ-8 תווים וקצרה מ-20 תווים' : ''))
        });
        this.validate = !this.validate;
        return;
      }
      let model = {
         password: this.updatePassword.value.password,
        user_id: Number(this.rowId),
      };
      
      this.spinner.show();
      this.service.ChangeUserPasswordByAdmin( model).subscribe((res) => {
        if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.modalService.dismissAll();
        this.getAllUserByOfficeId();
        this.updatePassword.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
        let errorMessage;
        switch (res.status) {
          case 400:
          errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          break;
          case 403:
          errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          break;
          case 500:
          errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          break;
          default:
          errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
        }
        Swal.fire({
          icon: 'error',
          title: errorMessage
        });
        }
        this.spinner.hide();
      });
    
    
    };

  ngOnInit() 
  {
  this.getAllUserByOfficeId();
 
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
  };
}
