import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2';
import { AddVatDetailsEndPoient } from "../../service/global.service";

@Component({
  selector: "app-vat-list-details",
  templateUrl: "./vat-list-details.component.html",
  styleUrls: ["./vat-list-details.component.scss"]
})

export class VatListDetailsComponent implements OnInit {
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addVatDetails:FormGroup;
  updateVatDetails:FormGroup;
  vatInfoData:any=[];
  vatInfoDetailsData:any=[];
  vatId: number;
  updatedData: any;
  viewData: any;
  id:any;
  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
    activatedRoute:ActivatedRoute,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.id = activatedRoute.snapshot.params['id'];

   this.addVatDetails=new FormGroup({
    start_date :new FormControl('' , Validators.required),
    end_date:new FormControl('', Validators.required),
    vat_list_id :new FormControl(''),  
    vat_percentage :new FormControl('', Validators.required),  
     created_by:new FormControl(this.userRegistId),
    });

    this.updateVatDetails=new FormGroup({
      start_date :new FormControl(''),
      end_date:new FormControl(''),
      vat_list_id :new FormControl(''),  
      vat_percentage :new FormControl(''),  
      updated_by:new FormControl(this.userRegistId),
      });
  };

  getAllVatDetailsByVatListId() {
    this.spinner.show();
    this.service.getAllVatDetailsByVatListId(this.id).subscribe({
      next: res => {
        this.vatInfoDetailsData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getAllActiveVatList() {
    this.spinner.show();
    this.service.getAllActiveVatList().subscribe({
      next: res => {
        this.vatInfoData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  getId(id: number) {
    this.vatId = id;
   };

  filter(event:any)
  {
    let x = event.target.value;
   };

  addedData() {
    if (
      this.addVatDetails.value.start_date == ''||
      this.addVatDetails.value.vat_percentage == ''||
      this.addVatDetails.value.end_date == ''
        ) 
      {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      
      return; 
    }
      
    else
    {
      let model = {
        start_date: this.addVatDetails.value.start_date,
        end_date: this.addVatDetails.value.end_date,
        vat_list_id: Number(this.id),
        vat_percentage: this.addVatDetails.value.vat_percentage,
        created_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.Post(AddVatDetailsEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllActiveVatList();
          this.getAllVatDetailsByVatListId();
          this.addVatDetails.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }

  };

  setValue(vatId: number) {
    for (let i = 0; i < this.vatInfoDetailsData.length; i++) {
      if (this.vatInfoDetailsData[i].vat_list_id == this.vatId) {
        const selectedCategory = this.vatInfoDetailsData.find(item => item.vat_list_id === vatId);

        this.updateVatDetails.controls.start_date.setValue(this.vatInfoDetailsData[i].start_date);
        this.updateVatDetails.controls.end_date.setValue(this.vatInfoDetailsData[i].end_date);
        this.updateVatDetails.controls.vat_percentage.setValue(this.vatInfoDetailsData[i].vat_percentage);
      }
    };
  };

  executeFunctions(vatPerscentgeId: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(vatPerscentgeId);
    this.setValue(vatPerscentgeId);
    this.updateMd(this.updatedData);
  };

  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  updatedVatDetailsData() {

    let model = {
      start_date: this.updateVatDetails.value.start_date,
      end_date: this.updateVatDetails.value.end_date,
      vat_percentage: this.updateVatDetails.value.vat_percentage,
      vat_list_id: Number(this.id),
      updated_by: Number(this.userRegistId),
    };
  
    this.spinner.show();
    this.service.updateVatDetailsByID( this.vatId, model).subscribe((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.modalService.dismissAll();
        this.getAllActiveVatList();
        this.getAllVatDetailsByVatListId();
      } else if (res.status === 400 || res.status === 403 || res.status === 500) {
        let errorMessage;
        switch (res.status) {
          case 400:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 403:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 500:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          default:
            errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
        }
        Swal.fire({
          icon: 'error',
          title: errorMessage
        });
      }
      this.spinner.hide();
    });


};

openVerticallyCentered(dataDeleted:any) {
  this.modalService.open(dataDeleted, { centered: true });
};

deleteVatDetailsByID() {
  this.spinner.show();
  this.service.deleteVatDetailsByID(this.vatId).subscribe({
    next: (res) => {
      if (res.status == 400) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.spinner.hide();
        return;
      } else {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.getAllActiveVatList();
        this.getAllVatDetailsByVatListId();
        this.modalService.dismissAll();
        this.spinner.hide();
      }
    },
    error: (err) => {
      Swal.fire(err.messageAr);
      this.spinner.hide(); 
    }
  });
};

  ngOnInit() 
  {

    this.getAllVatDetailsByVatListId();
    this.getAllActiveVatList();
    this.layoutVersion = localStorage.getItem("layout_version");
     if (this.layoutVersion == null || this.layoutVersion == undefined) {
       localStorage.setItem("layout_version", "light-only");
       this.layoutVersion= "light-only"; 
     } else {
       this.layoutVersion = localStorage.getItem("layout_version"); 
       if (this.layoutVersion === "dark-only") {
         document.body.classList.add("dark-only");
       } else {
         document.body.classList.add("light-only");
  
       }
     }
     this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
     this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
     this.user_type = JSON.parse(localStorage.getItem('user_type'));

  }
}
