<app-breadcrumb [title]="curentLang === 'en' ? 'Categories Info' : (curentLang === 'ar' ? ' معلومات الأقسام ' : 'פרטי מחלקטת ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['הגדרות  '])" 
[active_item]="curentLang === 'en' ? 'Categories Info' : (curentLang === 'ar' ? ' معلومات الأقسام' : 'פרטי מחלקטת')">
</app-breadcrumb>

<!--categories info -->

<div class="container-fluid basic_table">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Categories Info' : (curentLang === 'ar' ? '  معلومات الأقسام ' : '  פרטי מחלקטת    ')}}
        </h5>

        <div>
            <button class="btn m-1 mx-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
                <i class="fa fa-plus-circle mx-1"></i>
              {{ curentLang === 'en' ? 'Add Category' : (curentLang === 'ar' ? '  اضافة قسم    ' : 'הוספת מחלקה')}}
            </button>
               
            <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllCategories()">
                <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
            </button>
        
            
          
        </div>
     
      </div>
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Section Name English' : (curentLang === 'ar' ? '  أسم القسم عربي  ' : ' שם בעברית    ')}} </th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Category Code' : (curentLang === 'ar' ? ' كود القسم    ' : '   קוד מחלקה   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטטוס ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : 'תאריך הרשמה')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  הצגה   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  עריכה ')}}</th>

                </tr>
              </thead>

              <tbody  >
                <tr *ngFor="let item of categoriesData | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td> 
                    {{curentLang === 'en' ? item.categories_name_en : (curentLang === 'ar' ? item.categories_name_ar : item.categories_name_he)}}
                  </td>

                   <td>{{item.categories_code}}</td>

                   <td  >
                    <button *ngIf="item.categories_status==1" class=" btn" (click)="changeStatus(item?.categories_id, item.categories_status)">
                        <i class="fa solid fa-solid fa-toggle-on " style=" color: #213FA6;" ></i>
                    </button> 

                    <button *ngIf="item.categories_status==59" class=" btn" (click)="changeStatus(item?.categories_id, item.categories_status)">
                        <i class="fa solid fa-solid fa-toggle-off " style=" color: red;"></i>
                    </button> 

                  </td>

                   <td>{{ item.createdDate | date: 'dd/MM/yyyy'}}</td>

                  <td>
                    <i class="fa fa-eye" style="color: grey;   cursor: pointer;"
                    (click)="executeFunctionsView(  item?.categories_id,viewData ) "></i>
                  </td>

                   <td>
                     <i class="fa fa-edit" style=" cursor: pointer; color: green;"
                     (click)="executeFunctions(  item?.categories_id,updatedData ) ">
                    </i>
                   </td>

                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>


<!--add category-->

<ng-template #content let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Add Category ' : (curentLang === 'ar' ? ' اضافة قسم ' : '  הוספת מחלקה  ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]=" addCategory" novalidate [ngClass]="{ 'was-validated': validate }">
                        <div class="row">
                            <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Section Name Arabic' : (curentLang === 'ar' ? '  اسم القسم عربي   ' : ' שם המחלקה הוא ערבית   ')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;"  onlyAlphabets required=""
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="categories_name_ar" 
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter category name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم القسم ' : ' נא להזין את שם המחלקה ')}}  </div>

                              </div>
              
                              <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Section Name English' : (curentLang === 'ar' ? '  اسم القسم انجليزي   ' : '  שם באנגלית ')}}
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;"  onlyAlphabets required=""
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="categories_name_en"
                                   />
                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter category name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم القسم ' : ' נא להזין את שם המחלקה ')}}  </div>

                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Section Name Hebrew' : (curentLang === 'ar' ? '  اسم القسم عبري   ' : '  שם בעברית   ')}}
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;" class="form-control" onlyAlphabets required=""
                                   type="text" 
                                   formControlName="categories_name_he"  
                                   maxlength="80"
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter category name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم القسم ' : ' נא להזין את שם המחלקה ')}}  </div>

                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Category Code' : (curentLang === 'ar' ? '  كود القسم   ' : ' קוד מחלקה  ')}}
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;" onlyAlphabets required=""
                                  class="form-control" type="text" 
                                   formControlName="categories_code"
                                   maxlength="20"
                                     />
                                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter category code' : (curentLang === 'ar' ? ' الرجاء ادخال كود القسم ' : ' נא להזין את קוד המחלקה')}}  </div>

                                </div>

              
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button"  
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="addedData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>

<!--update category-->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : '     עריכה      ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]=" updateCategory" novalidate [ngClass]="{ 'was-validated': validateUpdate }">
                        <div class="row">
                            <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Category Name Ar' : (curentLang === 'ar' ? '  اسم القسم عربي   ' : '  שם המחלקה הוא ערבית   ')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;" onlyAlphabets required=""
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="categories_name_ar" 
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter category name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم القسم ' : ' נא להזין את שם המחלקה ')}}  </div>

                              </div>
              
                              <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Section Name English' : (curentLang === 'ar' ? '  اسم القسم انجليزي   ' : '  שם המחלקה באנגלית  ')}}
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;" onlyAlphabets required=""
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="categories_name_en"
                                   />
                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter category name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم القسم ' : ' נא להזין את שם המחלקה ')}}  </div>

                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Section Name Hebrew' : (curentLang === 'ar' ? '  اسم القسم عبري   ' : 'שם בעברית')}}
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;" class="form-control" onlyAlphabets required=""
                                   type="text" 
                                   formControlName="categories_name_he"  
                                   maxlength="80"
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter category name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم القسم ' : ' נא להזין את שם המחלקה ')}}  </div>

                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Category Code' : (curentLang === 'ar' ? ' كود القسم' : ' קוד מחלקה ')}}
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;" onlyAlphabets required=""
                                  class="form-control" type="text" 
                                   formControlName="categories_code"
                                   maxlength="20"
                                     />
                                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter category code' : (curentLang === 'ar' ? ' الرجاء ادخال كود القسم ' : ' נא להזין את קוד המחלקה')}}  </div>

                                </div>

              
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button"  
                             class="btn btn-sm m-auto my-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updatedCategoryData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto my-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--view category-->

<ng-template #viewData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'View Info ' : (curentLang === 'ar' ? ' مشاهدة المعلومات ' : '  צפיה    ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="updateCategory" >
                        <div class="row">
                            <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Category Name Ar' : (curentLang === 'ar' ? '  اسم القسم عربي   ' : '  שם החברה בערבית    ')}}
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" readonly formControlName="categories_name_ar"
                                  />
                              </div>
              
                              <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Category Name En' : (curentLang === 'ar' ? '  اسم القسم انجليزي   ' : '    שם באנגלית  ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                    readonly
                                    formControlName="categories_name_en"
                                   />
                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Section Name Hebrew' : (curentLang === 'ar' ? '  اسم القسم عبري   ' : '  שם בעברית  ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="categories_name_he"  
                                  readonly
                                  />
                                   
                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Category Code' : (curentLang === 'ar' ? '  كود القسم  ' : ' קוד מחלקה ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control" type="text" 
                                   formControlName="categories_code"
                                 readonly
                                     />
                                </div>

              
                        </div>
                  
                    
        
                       
                      </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>