import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { OtpEndPoient } from "src/app/Data/service/global.service";
import { AuthService } from "../service/auth.service";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';

@Component({
  selector: "app-otp",
  templateUrl: "./otp.component.html",
  styleUrls: ["./otp.component.scss"]
})
export class OtpComponent implements OnInit {
  curentLang: string = "";
  public show: boolean = false;
  error: string = '';
  userRegistId: any;
  otpCode: string; // تعديل النوع إلى string

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private Router: Router,
  ) {
    this.curentLang = localStorage.getItem('curentLang') || 'en';
  }

  otp() {
    let model = {
      "user_id": Number(this.userRegistId),
      "otp": this.otpCode
    };
    this.spinner.show();
    this.authService.Otp(OtpEndPoient.POST, model).subscribe((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        // localStorage.setItem('token', res.accessToken);
        localStorage.setItem('user_type', res.userData.user_type);
        localStorage.setItem('userType_en',res.userData.userType_en) ;  
        localStorage.setItem('user_id', res.userData.user_id);
        localStorage.setItem('office_id', res.userData.office_id);
        localStorage.setItem('merchant_id', res.userData.merchant_id);
        localStorage.setItem('curentLang', res.userData.language);
        this.authService.saveCurrentUser();
        this.Router.navigate(['/dashboard/dashboard']);
        this.authService.isLogin.next(true)
        // this.Router.navigate(['/login'])
        // localStorage.removeItem('user_id')
      } else if (res.status === 400 || res.status === 403 || res.status === 500 || res.status === 404) {
        let errorMessage;
        switch (res.status) {
          case 400:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 403:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 500:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 404:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          default:
            errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
        }
        Swal.fire({
          icon: 'error',
          title: errorMessage
        });
      }
      this.spinner.hide();
    });
  };

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);

    this.userRegistId = JSON.parse(localStorage.getItem('user_id'))
  }

  showPassword() {
    this.show = !this.show;
  }

  isNumber(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    } else {
      return true;
    }
  }
}
