import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  curentLang:string="";
  user_type = JSON.parse(localStorage.getItem('user_type'));
  public today: number = Date.now();
  year: number = new Date().getFullYear();

  constructor(
    private modalService: NgbModal,

  ) 
  {  this.curentLang = localStorage.getItem('curentLang');
    this.user_type = JSON.parse(localStorage.getItem('user_type'));

  }

  ngOnInit(): void
 {
  this.user_type = JSON.parse(localStorage.getItem('user_type'));

  }

  openXl4(content4:any) {
    this.modalService.open(content4, { size: 'lg'  });
  };

}
