import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { HttpHelpService } from "../../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddAccountantUserEndPoient, AddAccountantUsersCompanyEndPoient } from "../../service/global.service";
import { israelPhoneNumberValidator } from '../../../validators/israelPhoneNumberValidator';

@Component({
  selector: "app-office-users",
  templateUrl: "./office-users.component.html",
  styleUrls: ["./office-users.component.scss"]
})

export class OfficeUsersComponent implements OnInit {
  
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  officeIdNumber =JSON.parse(localStorage.getItem('office_id'));
  term:any;
  p: any;
   accountantUser:FormGroup;
   addAccountantUserCompanyForm:FormGroup;
   activeCompanyData:any
   receiptId: number;
  updatedData: any;
  viewData: any;
  userData:any=[];
  userTypeData:any=[];
  userId:number;
  currentPage: number = 1;
  limit: number = 10;
  offset: number = 0;
  total: number = 0; 
  notificationsData: any;
  public show: boolean = false;
  updateUser:FormGroup;
  public validate = false;
  PhoneNumber:any;
  allNumbers:any
  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));    

    this.updateUser=new FormGroup({
      user_name :new FormControl('' , Validators.required),
      user_id : new FormControl(this.userId),
      updated_by:new FormControl(this.userRegistId),
        });
  

    this.accountantUser=new FormGroup({
      'phone_number': new FormControl('', [Validators.required, israelPhoneNumberValidator()]),

      user_name:new FormControl(''),
      password: new FormControl('' , [Validators.required , Validators.pattern(/^(?=.*[!@#$%^&*()_\-+=<>?])[A-Z][A-Za-z0-9!@#$%^&*()_\-+=<>?]{3,20}$/)]),
      office_id: new FormControl(this.officeIdNumber),
      created_by:new FormControl(this.userRegistId),
      });

      this.addAccountantUserCompanyForm = new FormGroup({
        companyUser: new FormControl([] , Validators.required),   
        office_id: new FormControl(this.officeIdNumber),
        user_id: new FormControl(this.userId),
        created_by: new FormControl(this.userRegistId),
      });
  };

  formatPhoneNumber(phoneNumber: string): string {
    // Remove any non-digit characters except '+'
    const cleaned = phoneNumber.replace(/[^ \d]/g, '');
  
    // Ensure the number ends with '+'
    if (!cleaned.endsWith('+')) {
      return `${cleaned}+`;
    }
  
    return cleaned;
  };
    getAllActiveAgreedPermissionsByOfficeId( ) {
   
    this.spinner.show();
    this.service.getAllActiveAgreedPermissionsByOfficeId(this.officeIdNumber).subscribe({
      next: res => {
        this.activeCompanyData = res;
        this.spinner.hide();
         this.getAllPendingPermissionsByOffice(); 

      },
      error: () => {
        this.spinner.hide();
      }
    });
  };

  getAllPendingPermissionsByOffice(limit?: number, offset?: number) {
    this.spinner.show();
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
    this.service.getAllPendingPermissionsByOffice(limit, offset).subscribe({
      next: res => {
        this.notificationsData = res.data;
         this.total = res.count;
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }

  getALLUsersByBasedToken() {
    this.spinner.show();
    this.service.getALLUsersByBasedToken().subscribe({
      next: res => {
        this.userData = res.map(user => {
          return {
            ...user,
            formattedPhoneNumber: this.formatPhoneNumber(user.phone_number)
          };
        });
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }

  openXl(content:any) {
    this.modalService.open(content, { size: 'md' , centered:true });
  };

  getId(id: number) {
    this.userId = id;
  };

  filter(event:any)
  {
    let x = event.target.value;
   };
  
   showPassword() {
    this.show = !this.show;
  }
  addAccountantUser() {
    if (
        this.accountantUser.value.phone_number == ''||
        this.accountantUser.value.user_name == ''||
        this.accountantUser.value.password == ''
    ) {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
        });
        this.validate = !this.validate;
        return; 
    } else {
        let password = this.accountantUser.value.password;
        if (password.length < 8 || password.length > 20) {
            Swal.fire({
                icon: 'error',
                title: this.curentLang === 'ar' ? 'يجب أن تكون كلمة المرور أطول من أو تساوي 8 أحرف وأقل من أو تساوي 20 حرفًا' : 
                       (this.curentLang === 'en' ? 'Password must be longer than or equal to 8 characters and shorter than or equal to 20 characters' : 
                       (this.curentLang === 'he' ? 'הסיסמה חייבת להיות ארוכה מ-8 תווים וקצרה מ-20 תווים' : ''))
            });
            return;
        }
        let merchantPhone = this.accountantUser.value.phone_number;
        if (merchantPhone.length <  7) {
          Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? 'يجب ألا يقل رقم الهاتف عن 7 أرقام' : 
                   (this.curentLang === 'en' ? 'The phone number must be at least 7 digits long ' : 
                   (this.curentLang === 'he' ? 'מספר הטלפון חייב להיות בן 7 ספרות לפחות' : ''))
          });
          return;
        }

        let customerPhone = this.accountantUser.value.phone_number;
        if (customerPhone.startsWith('0')) {
            customerPhone = customerPhone.substring(1);
        }
        let model = {
            "phone_number": '+972' + customerPhone,
            user_name: this.accountantUser.value.user_name,
            password: this.accountantUser.value.password,
            office_id: Number(this.officeIdNumber),
            created_by: Number(this.userRegistId),
        };

        this.spinner.show();
        this.service.Post(AddAccountantUserEndPoient.POST, model).subscribe((res) => {
            if (res.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
                });
                this.modalService.dismissAll();
                this.getALLUsersByBasedToken();
                this.accountantUser.reset();
            } else if (res.status === 400 || res.status === 403 || res.status === 500) {
                let errorMessage;
                switch (res.status) {
                    case 400:
                        errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                        break;
                    case 403:
                        errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                        break;
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                        break;
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }
                Swal.fire({
                    icon: 'error',
                    title: errorMessage
                });
            }
            this.spinner.hide();
        });
    }
}


  changeUserStatus(id:number, user_status:any){
    this.spinner.show();
  
    let model =
    {
      "user_status": user_status == 1 ? 59 : 1,
     "updated_by": this.userRegistId
    }
  
    this.service.changeUserStatus(id , model ).subscribe(({
      
      next:res =>{
       if (res.status ==200){

        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        })
          this.getALLUsersByBasedToken();
          this.spinner.hide();
       }
  
      },
      error:err =>{
        Swal.fire({
          icon:"error",
          title:err.error.message[0]
        });
        this.spinner.hide();
  
      }
      
    }))
  };

  openXl2(content2:any) {
    this.modalService.open(content2, { size: 'lg' });
  };

  onCompanyMerchantChange(event) {
    this.addAccountantUserCompanyForm.patchValue({
      companyUser: event
    });
  }
  addedAccountingUserCompany() {
    if (this.addAccountantUserCompanyForm.invalid) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      return;
    } else {
      const companyUsers = this.addAccountantUserCompanyForm.value.companyUser;
       
      const models = companyUsers.map(companyUser => ({
        office_id: Number(this.officeIdNumber),
        company_id: Number(companyUser.company_id),
        permission_id: Number(companyUser.permission_id),
        user_id: Number(this.userId),
        created_by: Number(this.userRegistId),
      }));
  
      this.spinner.show();
      this.service.Post(AddAccountantUsersCompanyEndPoient.POST, models).subscribe((res) => {
        this.spinner.hide();
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getALLUsersByBasedToken();
          
          this.addAccountantUserCompanyForm.reset();
        } else {
          let errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
      });
    }
  }

  filterCompany(event:any)
  {
    let x = event;
     
   };

   setValue(userId: number) {
    for (let i = 0; i < this.userData.length; i++) {
      if (this.userData[i].user_id == this.userId) {
        const selectedCategory = this.userData.find(item => item.user_id === userId);
        this.updateUser.controls.user_name.setValue(this.userData[i].user_name);
      }
    };
  };

  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'md' , centered:true});
  };

   executeFunctions(userId: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(userId);
    this?.setValue(userId);
    this.updateMd(this.updatedData);
 
  };

  updateUserName() {

    let model = {
      user_name: this.updateUser.value.user_name,
      updated_by: Number(this.userRegistId),
      user_id: Number(this.userId)
    };
  
    this.spinner.show();
    this.service.updateUserName( model).subscribe((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.modalService.dismissAll();
        this.getALLUsersByBasedToken();
        this.getAllActiveAgreedPermissionsByOfficeId();
        this.getAllPendingPermissionsByOffice();
      } else if (res.status === 400 || res.status === 403 || res.status === 500) {
        let errorMessage;
        switch (res.status) {
          case 400:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 403:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 500:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          default:
            errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
        }
        Swal.fire({
          icon: 'error',
          title: errorMessage
        });
      }
      this.spinner.hide();
    });
  }

  ngOnInit() 
  {
    this.getAllActiveAgreedPermissionsByOfficeId();
  this.getALLUsersByBasedToken();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));  
  };
}
