<app-breadcrumb [title]="curentLang === 'en' ? 'Users Info' : (curentLang === 'ar' ? ' معلومات المستخدمين' : '   מידע על משתמשים ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['    לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Users Info' : (curentLang === 'ar' ? ' معلومات المستخدمين ' : '  מידע על משתמשים ')">
</app-breadcrumb>

<!--Users info -->

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Users Info' : (curentLang === 'ar' ? 'معلومات المستخدمين ' : 'מידע על סוגי חשבוניות')}}
            
        </h5>

       <div>
        <button  class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)" *ngIf="user_type == 300">
            <i class="fa fa-plus-circle mx-1  "></i>
            {{ curentLang === 'en' ? 'Add User' : (curentLang === 'ar' ? ' اضافة مستخدم ' : 'הוסף משתמש  ')}}
        </button>

     
        

        <button class="btn m-1  btn-outline-warning"  (click)="getALLUsersByBasedToken()">
          <i class="fa fa-refresh mx-1 "></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'User Name' : (curentLang === 'ar' ? ' اسم المستخدم ' : ' שם המשתמש')}} </th>
                   <th scope="col">  {{ curentLang === 'en' ? 'Phone Number' : (curentLang === 'ar' ? '  رقم الهاتف  ' : ' מספר הטלפון')}}</th>
                   <th scope="col">  {{ curentLang === 'en' ? 'User Type' : (curentLang === 'ar' ? '  نوع المستخدم ' : ' סוג המשתמש')}}</th> 
                   <th scope="col">  {{ curentLang === 'en' ? 'Mverified' : (curentLang === 'ar' ? ' تم التحقق ' : ' מְאוּמָת  ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Add Company  ' : (curentLang === 'ar' ? ' اضافة شركة ' : 'הוסף חברה ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'View Companies  ' : (curentLang === 'ar' ? 'عرض الشركات ' : ' לצפות בחברות ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>

                 

                  <th scope="col"> {{ curentLang === 'en' ? 'Privileges  ' : (curentLang === 'ar' ? '   الصلاحيات  ' : '  סמכויות ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of userData  | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td>{{item?.user_name}} </td>
                  <td>{{item?.formattedPhoneNumber}}</td>

               

                   <td *ngIf="item?.user_type==300" class="text-dark" style="font-weight: 600;">
                    {{curentLang === 'en' ? 'Accountant' : (curentLang === 'ar' ? 'محاسب' : 'חשבונאי ')}}
                  </td>

                   <td *ngIf="item?.user_type==301" class="text-danger" style="font-weight: 600;">
                    {{curentLang === 'en' ? 'Accountant User' : (curentLang === 'ar' ? 'مستخدم لدي المحاسب ' : 'אני מועסק על ידי רואה חשבון     ')}}
                   </td>

                   

                   <td *ngIf="item?.mverified == 1">
                      <i class="fa fa-check-circle" style="font-size: 20px; color: green;"></i>
                   </td>
                   
                   <td *ngIf="item?.mverified == 0">
                    <i class="fa fa-close" style="font-size: 20px; color: red;"></i>
                 </td>
                
                 <td>{{ item.createdDate | date: 'dd/MM/yyyy'}}</td>

                 <td>
                  <i *ngIf="item.user_type === 301 " class="fa fa-plus-circle" style="color: rgb(0, 0, 0);   cursor: pointer;"
                  (click)="getId(item.user_id);openXl2(content2)"></i>

                  <i *ngIf="item.user_type === 300 " class="fa fa-close" style="color: rgb(0, 0, 0);   cursor: pointer;"
                  ></i>
                </td>

                <td >
                  <i *ngIf="item.user_type === 301 "  class="fa fa-building-o" style="color: rgb(0, 0, 0);   cursor: pointer;"
                  routerLink="/dashboard/accountant-user-companies/{{item.user_id}}"></i>

                  <i *ngIf="item.user_type === 300 "  class="fa fa-close" style="color: rgb(0, 0, 0);   cursor: pointer;"
                 ></i>
                </td>

                


                 <td>
                    <button [disabled]="item.user_type === 300  " *ngIf="item.user_status == 1" 
                    class="btn"
                     style="border: none;" 
                    (click)="changeUserStatus(item?.user_id, item.user_status)">
                      <i class="fa solid fa-solid fa-toggle-on" style=" color: #213FA6;"></i>
                    </button> 
                    
                    <button *ngIf="item.user_status == 59" class="btn" style="border: none;" (click)="changeUserStatus(item?.user_id, item.user_status)">
                      <i class="fa solid fa-solid fa-toggle-off" style=" color: red;"></i>
                    </button> 
                  </td>
                  


                  <td >
                    <button [disabled]="user_type == 200" class=" btn" style="border: none;"
                     (click)="executeFunctions(item?.user_id,updatedData ) ">
                      <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                     </i>
                    </button>
                     </td>

                     



                   <td >
                    <i *ngIf="item.user_type == 301  " 
                    routerLink="/dashboard/office-users-privileges/{{item?.user_id}}"
                     class="fa fa-file-archive-o"
                      style="font-size: 18px; cursor: pointer;"></i>

                      <i *ngIf="item.user_type == 300  " 
                      
                       class="fa fa-lock"
                        style=" cursor: pointer; color: grey;"></i>

                     </td>


                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>



<!--add user by accountant  -->

<ng-template #content let-modal >
    <div class="modal-header d-flex justify-content-between" >
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Add user' : (curentLang === 'ar' ? ' اضافة مستخدم ' : '  הוסף משתמש')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                   
                <form  [formGroup]="accountantUser" class="needs-validation" novalidate [ngClass]="{ 'was-validated': validate }">
                    <div class="row">
                        <div class="mb-3 col-xl-12 col-md-12 ">
                            <label class="form-label" style="color: grey;" > 
                                {{ curentLang === 'en' ? 'User Name' : (curentLang === 'ar' ? ' اسم المستخدم ' : ' שם המשתמש')}} 
                           </label>
                            <input style="color: grey;" onlyAlphabets 
                             class="form-control" maxlength="80"  
                             type="text" formControlName="user_name" required="" 
                              />
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter user name' : (curentLang === 'ar' ? '  ادخل اسم المستخدم ' : '  הזן את שם המשתמש  ')}}  </div>
                          </div>
                        <!---->
                          <div class="mb-3  col-xl-12 col-md-12 ">
                            <label class="col-form-label pt-0" style="color: #141414;">{{'Register.phone'|translate}}</label>
                            <div class="input-group">
                              <span style="color: #141414;" class="input-group-text">+972</span>
                              <input class="form-control" type="text" maxlength="10" required="" onlyAlphabets
                               placeholder="{{'Register.phone'|translate}}" formControlName="phone_number">
                            </div>
                            <div *ngIf="accountantUser.get('phone_number')?.errors != null && accountantUser.get('phone_number')?.touched" class="alert text-danger my-1">
                              <p *ngIf="accountantUser.get('phone_number')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p>
                              <p *ngIf="accountantUser.get('phone_number')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{accountantUser.get('phone_number')?.errors?.invalidMobilePrefix.message}}</p>
                              <p *ngIf="accountantUser.get('phone_number')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{accountantUser.get('phone_number')?.errors?.invalidPhoneNumber.message}}</p>
                            </div>
                            <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter phone number' : (curentLang === 'ar' ? '  ادخل رقم الهاتف' : '   הזן את מספר הטלפון')}}  </div>

                            </div>

                               <!---->
                               <div class="mb-3  col-xl-12 col-md-12 ">
                                <div class="form-group">
                                    <label class="form-label"style="color: grey;">{{'Login.password'|translate}}</label>
                                    <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" 
                                    onlyAlphabets formControlName="password" required="" placeholder="*********">
                                    <div *ngIf="accountantUser?.get('password')?.errors != null && accountantUser?.get('password')?.touched" class=" alert text-danger my-1">
                                      <p  *ngIf="accountantUser?.get('password')?.errors?.required" class=" "> {{'Login.error18'|translate}}</p>
                                              <ul  *ngIf="accountantUser?.get('password')?.errors?.pattern && accountantUser.get('password')?.touched" class="text-start"> 
                                                  <li>{{'Login.error19'|translate}}</li>
                                                  <li>{{'Login.error20'|translate}}</li>
                                                  <li>{{'Login.error21'|translate}}</li>
                                                  <li>{{ curentLang === 'en' ? 'Password does not match the criteria' : (curentLang === 'ar' ? 'كلمة المرور غير مطابقة للمعايير' : 'הסיסמה אינה תואמת את הקריטריונים') }}

                                              </ul>
                                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter password' : (curentLang === 'ar' ? '   ادخل كلمة المرور    ' : '    הזן את הסיסמה      ')}}  </div>

                                       </div>
                                    <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                                    <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>  
                                    
                                  </div>
                                 
                              </div>


                      
                    </div>
              
                      <div class="modal-footer">

                        <button type="button" 
                         class="btn btn-sm m-auto mt-2" 
                        style="background-color: #141414; color: #fff;"
                        [disabled]="accountantUser.get('password').invalid"
                         (click)="addAccountantUser()"
                        
                         > 
                          {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                      </button>
                  
                          <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                              <i class="fa fa-rotate-left mx-1"></i>
                          </button>
                  
                        
                  
                      </div>
                  </form> 
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>



<!--add Company to users accountant-->

<ng-template #content2 let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
          {{ curentLang === 'en' ? 'Add a company to an accountant user' : (curentLang === 'ar' ? '  اضافة شركة لمستخدم لدي المحاسب' : 'הוסף חברה למשתמש רואה חשבון')}}
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
               <form [formGroup]="addAccountantUserCompanyForm">
                <div class="row">
                  <div class="mb-3 col-xl-12 col-md-12">
                    <label class="form-label" style="color: grey;">
                      {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה') }}
                    </label>
                    <ng-select formControlName="companyUser" (change)="onCompanyMerchantChange($event)" style="cursor: pointer;" [multiple]="true"
                               placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                      <ng-option *ngFor="let item of activeCompanyData" [value]="item" >
                        {{ curentLang === 'en' ? item?.company?.company_name_en : (curentLang === 'ar' ? item?.company?.company_name_ar : item?.company?.company_name_he) }} /
                        {{ curentLang === 'en' ? item.merchant.merchant_name_en : (curentLang === 'ar' ? item.merchant.merchant_name_ar : item.merchant.merchant_name_he) }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                
                <div class="modal-footer">
                  <button type="button" class="btn btn-sm m-auto mt-2" style="background-color: #141414; color: #fff;" 
                  [disabled]="addAccountantUserCompanyForm.invalid"
                          (click)="addedAccountingUserCompany()">
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                  </button>
                  
                  <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                    {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                    <i class="fa fa-rotate-left mx-1"></i>
                  </button>
                </div>
              </form>
                 
              
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>


<!--update user-->

<ng-template #updatedData let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : '    ערוך מידע ')}} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="updateUser" novalidate [ngClass]="{ 'was-validated': validate }">
                        <div class="row">
 
                          <div class="mb-3 col-xl-12 col-md-12 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'User Name ' : (curentLang === 'ar' ? ' اسم العميل ' : 'שם הסוכן')}}
                             </label>
                              <input style="color: grey;" onlyAlphabets
                               class="form-control" maxlength="80" 
                               type="text" formControlName="user_name"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter user name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم المستخدم ' : ' אנא הזן שם משתמש ')}}  </div>

                            </div> 
   
                      </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button" [disabled]="!updateUser.valid"
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updateUserName()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>