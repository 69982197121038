import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpHelpService } from "../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { AddBankEndPoient } from "../service/global.service";

@Component({
  selector: "app-banks",
  templateUrl: "./banks.component.html",
  styleUrls: ["./banks.component.scss"]
})

export class BanksComponent implements OnInit {
  
   
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addBank:FormGroup;
  updateBank:FormGroup;
  BankData:any=[];
  bankId: number;
  updatedData: any;
  viewData: any;
  public validate = false;
  public validateUpdate = false;
  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));

   this.addBank=new FormGroup({
    bank_name_ar :new FormControl('' , Validators.required),
    bank_name_en:new FormControl('' ,Validators.required),
    bank_name_he :new FormControl('' , Validators.required),  
    bank_code :new FormControl('' , Validators.required),
    created_by:new FormControl(this.userRegistId),
    });

    this.updateBank=new FormGroup({
      bank_name_ar :new FormControl(''),
      bank_name_en:new FormControl(''),
      bank_name_he :new FormControl(''),  
      bank_code :new FormControl(''),
      updated_by:new FormControl(this.userRegistId),
      });
  };

  getAllBanks() {
    this.spinner.show();
    this.service.getAllBanks().subscribe({
      next: res => {
        this.BankData = res;
          
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  addedData() {
    const bank_name_ar = this.addBank.value.bank_name_ar.trim();
    const bank_name_en = this.addBank.value.bank_name_en.trim();
    const bank_name_he = this.addBank.value.bank_name_he.trim();
    const bank_code = this.addBank.value.bank_code.trim();
  
    if (
      bank_name_ar === '' ||
      bank_name_en === '' ||
      bank_name_he === '' ||
      bank_code === ''
    ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
      });
  
      this.addBank.markAllAsTouched();
      this.validate = !this.validate;
      return;
    }
      
    else
    {
      let model = {
        bank_name_ar: this.addBank.value.bank_name_ar,
        bank_name_en: this.addBank.value.bank_name_en,
        bank_name_he: this.addBank.value.bank_name_he,
        bank_code: this.addBank.value.bank_code,
        created_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.Post(AddBankEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllBanks();
          this.addBank.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }

  };

  changeBankStatus(id:number, bank_status:any){
    this.spinner.show();
  
    let model =
    {
      "bank_status": bank_status == 1 ? 59 : 1,
     "updated_by": this.userRegistId
    }
  
    this.service.changeBankStatus(id , model ).subscribe(({
      
      next:res =>{
       if (res.status ==200){

        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        })
          this.getAllBanks();
          this.spinner.hide();
       }
  
      },
      error:err =>{
        Swal.fire({
          icon:"error",
          title:err.error.message[0]
        });
        this.spinner.hide();
  
      }
      
    }))
  };

  setValue(bankId: number) {
    for (let i = 0; i < this.BankData.length; i++) {
      if (this.BankData[i].bank_id == this.bankId) {
        const selectedCategory = this.BankData.find(item => item.bank_id === bankId);

        this.updateBank.controls.bank_name_ar.setValue(this.BankData[i].bank_name_ar);
        this.updateBank.controls.bank_name_en.setValue(this.BankData[i].bank_name_en);
        this.updateBank.controls.bank_name_he.setValue(this.BankData[i].bank_name_he);
        this.updateBank.controls.bank_code.setValue(this.BankData[i].bank_code);
      }
    };
  };

  getId(id: number) {
    this.bankId = id;
  };

  executeFunctions(BankID: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(BankID);
    this.setValue(BankID);
    this.updateMd(this.updatedData);
  };

  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  updateBankById() {

    const bank_name_ar = this.updateBank.value.bank_name_ar.trim();
    const bank_name_en = this.updateBank.value.bank_name_en.trim();
    const bank_name_he = this.updateBank.value.bank_name_he.trim();
    const bank_code = this.updateBank.value.bank_code.trim();
  
    if (
      bank_name_ar === '' ||
      bank_name_en === '' ||
      bank_name_he === '' ||
      bank_code === ''
    ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
      });
  
      this.updateBank.markAllAsTouched();
      this.validateUpdate = !this.validateUpdate;
      return;
    }

    let model = {
      bank_name_ar: this.updateBank.value.bank_name_ar,
        bank_name_en: this.updateBank.value.bank_name_en,
        bank_name_he: this.updateBank.value.bank_name_he,
        bank_code: this.updateBank.value.bank_code,
        updated_by: Number(this.userRegistId),
    };
  
    this.spinner.show();
    this.service.updateBankById( this.bankId, model).subscribe((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.modalService.dismissAll();
        this.getAllBanks();
      } else if (res.status === 400 || res.status === 403 || res.status === 500) {
        let errorMessage;
        switch (res.status) {
          case 400:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 403:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 500:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          default:
            errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
        }
        Swal.fire({
          icon: 'error',
          title: errorMessage
        });
      }
      this.spinner.hide();
    });


};

openVerticallyCentered(dataDeleted:any) {
  this.modalService.open(dataDeleted, { centered: true });
};

deleteBankByID() {
  this.spinner.show();
  this.service.deleteBankByID(this.bankId).subscribe({
    next: (res) => {
      if (res.status == 400) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.spinner.hide();
        return;
      } else {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.getAllBanks();
        this.modalService.dismissAll();
        this.spinner.hide();
      }
    },
    error: (err) => {
      Swal.fire(err.messageAr);
      this.spinner.hide(); 
    }
  });
};

  ngOnInit() 
  {
  this.getAllBanks();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
  };
}
