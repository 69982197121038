import { UpdateOfferPriceComponent } from './Data/offer-price/update-offer-price/update-offer-price.component';
import { AddOfferPriceComponent } from './Data/offer-price/add-offer-price/add-offer-price.component';
import { OfferPriceComponent } from './Data/offer-price/offer-price.component';
import { UpdateOrderComponent } from './Data/order/update-order/update-order.component';
import { AddOrderComponent } from './Data/order/add-order/add-order.component';
import { UpdateMissionComponent } from './Data/Mission/update-mission/update-mission.component';
import { AddMissionComponent } from './Data/Mission/add-mission/add-mission.component';
import { MissionComponent } from './Data/Mission/Mission.component';
import { HistoryDetailsComponent } from './Data/upload-permanent-file/history/history-details/history-details.component';
import { CompanyEmployeeComponent } from './Data/Accountant/office-companies/company-employee/company-employee.component';
import { HistoryComponent } from './Data/upload-permanent-file/history/history.component';
import { AllMerchantUsersComponent } from './Data/merchant/all-merchant-users/all-merchant-users.component';
import { AllUsersOfficeComponent } from './Data/accounting-offices/all-users-office/all-users-office.component';
 import { UploadAllocationNumberComponent } from './Data/upload-allocation-number/uploadAllocationNumber.component';
import { MoreInfoCompanyComponent } from './Data/company/more-info-company/more-info-company.component';
import { UpdateReverceInvoiceComponent } from './Data/reverce-invoice/update-reverce-invoice/update-reverce-invoice.component';
import { ExportReverceInvoicePdfComponent } from './Data/reverce-invoice/export-reverce-invoice-pdf/export-reverce-invoice-pdf.component';
import { AddReverceInvoiceComponent } from './Data/reverce-invoice/add-reverce-invoice/add-reverce-invoice.component';
import { ReverceInvoiceComponent } from './Data/reverce-invoice/reverce-invoice.component';
import { UploadPermanentFileComponent } from './Data/upload-permanent-file/upload-permanent-file.component';
import { ExportReportsComponent } from './Data/Accountant/export-reports/export-reports.component';
import { DocumentPdfComponent } from './Data/Accountant/document-pdf/document-pdf.component';
import { CompanyDocumentsComponent } from './Data/Accountant/company-documents/company-documents.component';
import { AccountantsComponent } from './Data/accountants/accountants.component';
import { AccountantUserCompaniesComponent } from './Data/Accountant/accountant-user-companies/accountant-user-companies.component';
import { OfficeCompaniesComponent } from './Data/Accountant/office-companies/office-companies.component';
import { OfficeUsersPrivilegesComponent } from './Data/Accountant/office-users-privileges/office-users-privileges.component';
import { OfficeUsersComponent } from './Data/Accountant/office-users/office-users.component';
import { OfficeComponent } from './Data/Accountant/office/office.component';
import { NotificationDetailsComponent } from './shared/components/header/elements/notification/notification-details/notification-details.component';
import { AccountingOfficesCompaniesComponent } from './Data/accounting-offices/accounting-offices-companies/accounting-offices-companies.component';
 import { AccountingOfficesComponent } from './Data/accounting-offices/accounting-offices.component';
import { ExportFinaicalRequestPdfComponent } from './Data/finaical-request/export-finaical-request-pdf/export-finaical-request-pdf.component';
import { ExportInvoiceWithReceiptPdfComponent } from './Data/invoice-and-receipt/export-invoice-with-receipt-pdf/export-invoice-with-receipt-pdf.component';
import { TermsComponent } from './Data/terms/terms.component';
import { UpdateFinaicalRequestComponent } from './Data/finaical-request/update-finaical-request/update-finaical-request.component';
import { FinaicalRequestComponent } from './Data/finaical-request/finaical-request.component';
import { AppInfoComponent } from './Data/app-info/app-info.component';
import { PermanentFileComponent } from './Data/permanent-file/permanent-file.component';
import { ExportReceiptPdfComponent } from './Data/receipts/export-receipt-pdf/export-receipt-pdf.component';
import { AdminGuard } from './admin.guard';
import { ExportPdfComponent } from './Data/invoices/export-pdf/export-pdf.component';
import { UpdateInvoiceComponent } from './Data/invoices/update-invoice/update-invoice.component';
import { AddInvoiceComponent } from './Data/invoices/add-invoice/add-invoice.component';
import { InvoicesComponent } from './Data/invoices/invoices.component';
import { PaymentMethodComponent } from './Data/payment-method/payment-method.component';
import { BanksBranchesComponent } from './Data/banks/banks-branches/banks-branches.component';
import { BanksComponent } from './Data/banks/banks.component';
import { VatListDetailsComponent } from './Data/vat-list/vat-list-details/vat-list-details.component';
import { ReceiptsComponent } from './Data/receipts/receipts.component';
import { UsersPrivilegesComponent } from './Data/users-privileges/users-privileges.component';
import { MeasurementUnitsComponent } from './Data/measurement-units/measurement-units.component';
import { CategoriesComponent } from './Data/categories/categories.component';
import { VatListComponent } from './Data/vat-list/vat-list.component';
import { UpdateCompanyComponent } from './Data/company/update-company/update-company.component';
import { ViewCompanyComponent } from './Data/company/view-company/view-company.component';
import { ViewMerchantComponent } from './Data/merchant/view-merchant/view-merchant.component';
import { UpdateMerchantComponent } from './Data/merchant/update-merchant/update-merchant.component';
import { MerchantComponent } from './Data/merchant/merchant.component';
import { PlaneDetailsComponent } from './Data/dashboard/plane-details/plane-details.component';
import { CompanyComponent } from './Data/company/company.component';
import { PlansComponent } from './Auth/plans/plans.component';
import { ResendotpComponent } from './Auth/resendotp/resendotp.component';
import { OtpComponent } from './Auth/otp/otp.component';
import { AuthGuard } from './auth.guard';
import { Error404Component } from './Auth/error404/error404.component';
import { SubPlansComponent } from './landing-pages/sub-plans/sub-plans.component';
import { ForgotPasswordComponent } from './Auth/forgot-password/forgot-password.component';
import { LoginComponent } from './Auth/login/login.component';
import { RegisterComponent } from './Auth/register/register.component';
import { TermsAndConditionsComponent } from './landing-pages/terms-and-conditions/terms-and-conditions.component';
import { AboutComponent } from './landing-pages/about/about.component';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// import { FirstPageComponent } from "./components/simple-page/first-page/first-page.component";
// import { ContentComponent } from "./shared/components/layout/content/content.component";
// import { FullComponent } from "./shared/components/layout/full/full.component";
// import { full } from "./shared/routes/full.routes";
// import { content } from "./shared/routes/routes";
import { HomeComponent } from "./landing-pages/home/home.component";
import { FirstPageComponent } from './components/simple-page/first-page/first-page.component';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { FullComponent } from './shared/components/layout/full/full.component';
import { content } from "./shared/routes/routes";
import { DashboardComponent } from './Data/dashboard/dashboard.component';
import { PlansInfoComponent } from './Data/plans-info/plans-info.component';
import { CustomersComponent } from './Data/customers/customers.component';
import { ProductsComponent } from './Data/products/products.component';
import { InvoicesTypesComponent } from './Data/invoices-types/invoices-types.component';
import { UsersComponent } from './Data/users/users.component';
import { AddReceiptsComponent } from './Data/receipts/add-receipts/add-receipts.component';
import { AddFinaicalRequestComponent } from './Data/finaical-request/add-finaical-request/add-finaical-request.component';
import { InvoiceAndReceiptComponent } from './Data/invoice-and-receipt/invoice-and-receipt.component';
import { AddInvoiceWithReceiptComponent } from './Data/invoice-and-receipt/add-invoice-with-receipt/add-invoice-with-receipt.component';
import { UpdateInvoiceWithReceiptComponent } from './Data/invoice-and-receipt/update-invoice-with-receipt/update-invoice-with-receipt.component';
import { DocumentsComponent } from './Data/company/documents/documents.component';
import { OrderComponent } from './Data/order/order.component';
import { AllWarningComponent } from './shared/components/header/elements/message-box/all-warning/all-warning.component';


const routes: Routes = [
  { path: "",redirectTo: "dashboard/dashboard", pathMatch: "full"},
  {path:'home' , component:HomeComponent},
  {path:'pricing' , component:SubPlansComponent},
  {path:"about" , component:AboutComponent},
  {path:'terms' , component:TermsAndConditionsComponent},
  {path:"register" , component:RegisterComponent},
  {path:"login" , component:LoginComponent},
  {path:"forgot-password" , component:ForgotPasswordComponent},
  {path: 'otp', component: OtpComponent},
  {path: 'plans', component: PlansComponent},
  

  {path: 'resend-otp', component: ResendotpComponent},
  
  {
    path: "dashboard",
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: [

      {path:'first-page' , component:FirstPageComponent, canActivate: [AdminGuard]},
      {path:'dashboard' ,component: DashboardComponent},
      {path:'terms-and-conditions' , component:TermsComponent , canActivate: [AdminGuard]},
      
      {path:'plan-details/:id', component: PlaneDetailsComponent, },
      {path: 'merchant-info', component: MerchantComponent},
      {path: 'update-merchant/:id', component: UpdateMerchantComponent},
      {path: 'view-merchant/:id', component: ViewMerchantComponent},
      {path: 'all-merchant-users/:id', component: AllMerchantUsersComponent},
      {path:'companies' , component:CompanyComponent},
      {path:'view-company/:id' , component:ViewCompanyComponent},
      {path:'update-company/:id' , component:UpdateCompanyComponent},
      {path:'documents/:id' , component:DocumentsComponent},
      {path:'more-info-company/:id' , component:MoreInfoCompanyComponent},
      {path:'vat-list' , component:VatListComponent, canActivate: [AdminGuard]},
      {path:'vat-list-details/:id' , component:VatListDetailsComponent,},
      {path:'categories' , component:CategoriesComponent, canActivate: [AdminGuard]},
      {path:'plans-info' , component:PlansInfoComponent, canActivate: [AdminGuard]},
      {path:'customers' , component:CustomersComponent},
      {path:'accountants' , component:AccountantsComponent},
      {path:'company-documents/:id' , component:CompanyDocumentsComponent},
      {path:'document-pdf/:id' , component:DocumentPdfComponent},
      {path:'export-reports/:id' , component:ExportReportsComponent},

      {path:'upload-allocation-number', component:UploadAllocationNumberComponent},
      {path:'products' , component:ProductsComponent},
      {path:'upload-permanent-file' , component:UploadPermanentFileComponent},
      {path:'history' , component:HistoryComponent},
      {path:'history-details/:id' , component:HistoryDetailsComponent},
      {path:'measurement-units' , component:MeasurementUnitsComponent , canActivate: [AdminGuard]},
      {path:'users' , component:UsersComponent },
      {path:'user-privileges/:id' , component:UsersPrivilegesComponent},
      {path:'invoices-types' , component:InvoicesTypesComponent , canActivate: [AdminGuard]},
      {path:'receipts' , component:ReceiptsComponent},
      {path:'add-receipts' , component:AddReceiptsComponent},
      {path:'export-receipt-pdf/:id' , component:ExportReceiptPdfComponent},
      {path:'permanent-file' , component:PermanentFileComponent},
      {path:'application-info' , component:AppInfoComponent , canActivate: [AdminGuard]},
      {path:'banks' , component:BanksComponent, canActivate: [AdminGuard]},
      {path:'accounting-offices' , component:AccountingOfficesComponent, canActivate: [AdminGuard]},
      {path:'accounting-offices-companies/:id' , component:AccountingOfficesCompaniesComponent },
      {path:'all-office-users/:id' , component:AllUsersOfficeComponent },
      {path:'banks-branches/:id' , component:BanksBranchesComponent, },
      {path:'payment-method' , component:PaymentMethodComponent, canActivate: [AdminGuard]},
      {path:'invoices' , component:InvoicesComponent},
      {path:'add-invoice' , component:AddInvoiceComponent},
      {path:'update-invoice/:id' , component:UpdateInvoiceComponent},

      {path:'mission' , component:MissionComponent},
      {path:'add-mission' , component:AddMissionComponent},
      {path:'update-mission/:id' , component:UpdateMissionComponent},

      {path:'order' , component:OrderComponent},
      {path:'add-order' , component:AddOrderComponent},
      {path:'update-order/:id' , component:UpdateOrderComponent},
      
      {path:'offer-price' , component:OfferPriceComponent},
      {path:'add-offer-price' , component:AddOfferPriceComponent},
      {path:'update-offer-price/:id' , component:UpdateOfferPriceComponent},
      
      

      {path:'reverce-invoice' , component:ReverceInvoiceComponent},
      {path:'add-reverce-invoice' , component:AddReverceInvoiceComponent},
      {path:'update-reverce-invoice/:id' , component:UpdateReverceInvoiceComponent},
      {path:'export-reverce-invoice-pdf/:id' , component:ExportReverceInvoicePdfComponent},
    

      {path:'add-finaical-request' , component:AddFinaicalRequestComponent},
      {path:'export-pdf/:id' , component:ExportPdfComponent},
      {path:'export-invoice-with-receipt-pdf/:id' , component:ExportInvoiceWithReceiptPdfComponent},
      {path:'export-finaical-request-pdf/:id' , component:ExportFinaicalRequestPdfComponent},
      {path:'finaical-request' , component:FinaicalRequestComponent},
      {path:'update-finaical-request/:id' , component:UpdateFinaicalRequestComponent},
      {path:'notification-details' , component:NotificationDetailsComponent},
      {path:'all-warning' , component:AllWarningComponent},

      {path:'terms-and-conditions' , component:TermsAndConditionsComponent, canActivate: [AdminGuard]},
      { path: 'invoice-with-receipt', component: InvoiceAndReceiptComponent },
      { path: 'add-invoice-with-receipt', component: AddInvoiceWithReceiptComponent },
      { path: 'update-invoice-with-receipt/:id', component: UpdateInvoiceWithReceiptComponent },
      {path:'office' , component:OfficeComponent},
      {path:'office-users' , component:OfficeUsersComponent},
      {path:'office-users-privileges/:id' , component:OfficeUsersPrivilegesComponent},
      {path:'office-companies' , component:OfficeCompaniesComponent},
      {path:'accountant-user-companies/:id' , component:AccountantUserCompaniesComponent},
      {path:'company-employee/:id' , component:CompanyEmployeeComponent}

      
    ]

  },

  {path: '**', component: Error404Component}

];
@NgModule({
  imports: [
[

      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
