<app-breadcrumb
  [title]="curentLang === 'en' ? 'Permanent File ' : (curentLang === 'ar' ? '   رفع الملف الموحد' : 'קובץ קבוע')"
  [items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['לוח הבקרה'])"
  [active_item]="curentLang === 'en' ? ' Permanent File ' : (curentLang === 'ar' ? '   رفع الملف الموحد ' : 'קובץ קבוע')">
</app-breadcrumb>

<div class="container-fluid basic_table">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div>
          <!-- HTML: إضافة رسالة للتحذير من تجاوز الحد الأقصى -->
          <div class="form-group m-3">
            <label class="form-label" style="color: black;" for="multiFiles">Upload ZIP or Excel files (Max 50
              files):</label>
            <input type="file" id="multiFiles" (change)="onMultipleFilesSelected($event)" multiple class="form-control">
          </div>

          <!-- عرض رسالة استجابة بعد الرفع -->
          <div *ngIf="uploadResponseMessage" class="alert alert-info">
            {{ uploadResponseMessage }}
          </div>

          <!-- عرض رسالة خطأ في حالة تجاوز عدد الملفات المسموح به -->
          <div *ngIf="fileLimitExceeded" class="alert alert-danger">
            You can upload a maximum of 50 files.
          </div>

          <!-- زر رفع الملفات -->
          <button (click)="onSubmitMultiple()" [disabled]="multipleFiles.length === 0 || fileLimitExceeded"
            class="btn m-3" style="background-color: #28a745; color: #fff;">
            Upload Multiple Files
          </button>

          <!-- زر قائمة الانتظار -->
          <button type="button" class="btn m-3" style="background-color: #007bff; color: #fff;"
            (click)="openFileQueueModal(content)">
            View Waiting List
          </button>

          <!-- زر تشغيل قائمة الانتظار -->
          <button (click)="runManualJobs()" [disabled]="isRunning" class="btn m-3"
            style="background-color: #17a2b8; color: #fff; margin-top: 10px;">
            Run Waiting List
          </button>

          <!-- زر رفع رقم التخصيص -->
          <button class="btn m-3" style="background-color: #ffc107; color: #fff; margin-top: 10px;"
            routerLink="/dashboard/upload-allocation-number">
            <i class="fa fa-upload mx-1"></i>
            Upload Allocation Number
          </button>

          <!-- زر عرض سجل التحميل -->
          <button class="btn m-3" style="background-color: #6c757d; color: #fff; margin-top: 10px;"
            routerLink="/dashboard/history">
            <i class="fa fa-upload mx-1"></i>
            View Upload History
          </button>



          <!-- مودال لعرض قائمة الملفات -->
          <ng-template #content let-modal>
            <div class="modal-header d-flex justify-content-between">
              <h5 class="modal-title">Waiting List</h5>
              <button type="button" class="close btn-close" style="color: grey;" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="container-fluid">
                <div class="edit-profile">
                  <!-- عرض عدد الملفات في قائمة الانتظار -->
                  <div class="row mb-3">
                    <h6>Total Files in Waiting List: {{ fileQueue.length }}</h6> <!-- إظهار عدد الملفات -->
                  </div>

                  <!-- عرض قائمة الملفات في صفوف مع زر حذف لكل ملف -->
                  <div class="row">
                    <!-- قائمة الانتظار مع زر "مراجعة" لكل ملف -->
                    <div *ngFor="let file of fileQueue" class="d-flex justify-content-between align-items-center mb-2">
                      <span>{{ file }}</span>
                      <div>
                        <button class="btn btn-danger" (click)="deleteFile(file)">Delete</button>
                        <button class="btn btn-warning m-2" (click)="reviewFile(file, modal)">Review</button>
                        <!-- تمرير الـ modal كمعامل -->
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="modal.dismiss()">Close</button>
            </div>
          </ng-template>


          <div class="container">
            <div *ngIf="isRunning" class="d-flex align-items-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only text-danger">Processing...</span>
              </div>
              <span class="ml-3 text-danger">جاري العمل على الملفات الموجوده في قائمة الانتظار ...</span>
            </div>

            <div *ngIf="responseMessage" class="alert alert-info mt-3">
              {{ responseMessage }}
            </div>
          </div>




        </div>






        <!-- عرض حالة رفع الملفات المتعددة -->
        <div *ngFor="let fileStatus of multipleFileStatusList" class="mt-4 d-flex flex-column align-items-start">
          <div class="d-flex align-items-center mb-2">
            <i class="fa"
              [ngClass]="{'fa-spinner fa-pulse': fileStatus.loading, 'fa-check-circle text-success': fileStatus.success, 'fa-times-circle text-danger': fileStatus.error}"
              class="mr-2"></i>
            <span
              [ngClass]="{'text-success': fileStatus.success, 'text-danger': fileStatus.error, 'text-primary': fileStatus.loading}">{{
              fileStatus.fileName }}</span>
          </div>
          <div>
            <span *ngIf="fileStatus.loading">Processing and saving data...</span>
            <span *ngIf="fileStatus.success" style="color: green;">Saved successfully</span>
            <span *ngIf="fileStatus.error" style="color: red;">{{ fileStatus.response }}</span>
          </div>
        </div>


        <div class="container mt-5   ">



          <div class="row d-flex justify-content-between">
            <div class="col-md-9 ">
              <form (ngSubmit)="onSubmit()" #fileForm="ngForm" class="form-inline mb-3">
                <div class="form-group mr-2">
                  <label for="file" class="mr-2" style="color: black;">Select File:</label>
                  <input type="file" id="file" (change)="onFileSelected($event)" required class="form-control mr-2" />
                </div>
                <button type="submit" [disabled]="!file" class="btn text-light"
                  style="background-color: black; color: #fff;">
                  <span class="text-white">Upload and Review</span>
                </button>


              </form>


              <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff"
                type="ball-scale-multiple"></ngx-spinner>

              <ul class="nav nav-tabs mt-4">
                <li class="nav-item">
                  <a class="nav-link" [class.active]="activeTab === 'rawDataReview'"
                    (click)="setActiveTab('rawDataReview')">Raw Data Review Report</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [class.active]="activeTab === 'companyDetails'"
                    (click)="setActiveTab('companyDetails')">Company Details</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [class.active]="activeTab === 'invoiceTypes'"
                    (click)="setActiveTab('invoiceTypes')">Document Types</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [class.active]="activeTab === 'errors'" (click)="setActiveTab('errors')">
                    Errors
                    <span *ngIf="report && report.errors && report.errors.length > 0" class="badge badge-danger">{{
                      report.errors.length }}</span>
                    <span *ngIf="report && report.errors && report.errors.length === 0"
                      class="fa fa-check-circle text-success"></span>
                  </a>
                </li>
              </ul>

              <div *ngIf="errorMessage" class="alert alert-danger mt-4">
                {{ errorMessage }}
              </div>

              <div *ngIf="activeTab === 'rawDataReview' && reviewReport && reviewReport.length > 0 && !errorMessage"
                class="mt-4">
                <h3 style="color: black;">Raw Data Review Report</h3>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Document Type</th>
                      <th>Description</th>
                      <th>C100 Count</th>
                      <th>C100 Amount Before Discount</th>
                      <th>D110 Count</th>
                      <th>D110 Total Line Amount</th>
                      <th>D120 Count</th>
                      <th>D120 Total Document Amount</th>
                      <th>D120 Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let summary of reviewReport">
                      <td>{{ summary.documentType }}</td>
                      <td>{{ summary.description }}</td>
                      <td>{{ summary.c100Count }}</td>
                      <td>{{ summary.c100AmountBeforeDiscount.toFixed(2) }}</td>
                      <td>{{ summary.d110Count }}</td>
                      <td>{{ summary.d110TotalLineAmount.toFixed(2) }}</td>
                      <td>{{ summary.d120Count }}</td>
                      <td>{{ summary.d120TotalDocumentAmount.toFixed(2) }}</td>
                      <td>{{ summary.d120TotalAmount.toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div *ngIf="activeTab === 'companyDetails' && report && !errorMessage" class="mt-4">
                <h3 style="color: black;">Company Details</h3>
                <div class="card mb-3">
                  <div class="card-header">
                    <h4 style="color: black;">Company Details</h4>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <img [src]="report.company.logo" alt="Company Logo" class="img-fluid"
                          *ngIf="report.company.logo" style="max-width: 100px;">
                      </div>
                      <div class="col-md-8">
                        <p style="color: black;"><strong>Company Name:</strong> <span style="color: black;">{{
                            report.company.company_name }}</span></p>
                        <p style="color: black;"><strong>Tax Register:</strong> <span style="color: black;">{{
                            report.company.tax_register }}</span></p>
                        <p style="color: black;"><strong>Merchant Name:</strong> <span style="color: black;">{{
                            report.company.merchant_name_he }}</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="activeTab === 'invoiceTypes' && report && !errorMessage" class="mt-4">
                <h4 style="color: black;">Document Types</h4>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Document Type</th>
                      <th>Count</th>
                      <th>Total Amount Incl. Tax</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let invoiceType of objectKeys(report.invoiceTypes)">
                      <td>{{ invoiceType }}</td>
                      <td>{{ report.invoiceTypes[invoiceType].count }}</td>
                      <td>{{ report.invoiceTypes[invoiceType].totalAmount.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td><strong>Total</strong></td>
                      <td><strong>{{ getInvoiceTypesTotal().totalCount }}</strong></td>
                      <td><strong>{{ getInvoiceTypesTotal().totalAmount.toFixed(2) }}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div *ngIf="activeTab === 'errors' && report && !errorMessage" class="mt-4">
                <h4 style="color: red;">Errors</h4>
                <div *ngIf="report.errors && report.errors.length > 0" class="mt-3" style="color: red;">
                  <ul>
                    <li *ngFor="let error of report.errors">
                      <i class="fa fa-times-circle text-danger mr-2"></i> {{ error }}
                    </li>
                  </ul>
                </div>
                <div *ngIf="report.errors && report.errors.length === 0" class="mt-3">
                  <p><i class="fa fa-check-circle text-success mr-2" style="color: black;"></i> No errors</p>
                </div>
              </div>

              <div *ngFor="let fileStatus of fileStatusList" class="mt-4 d-flex flex-column align-items-start">
                <div class="d-flex align-items-center mb-2">
                  <i class="fa"
                    [ngClass]="{'fa-spinner fa-pulse': fileStatus.loading, 'fa-check-circle text-success': fileStatus.success, 'fa-times-circle text-danger': fileStatus.error}"
                    class="mr-2 fa" style="color: black;"></i>
                  <span
                    [ngClass]="{'text-success': fileStatus.success, 'text-danger': fileStatus.error, 'text-primary': fileStatus.loading}"
                    class="mr-2">{{ fileStatus.fileName }}</span>
                </div>
                <div>
                  <span *ngIf="fileStatus.loading" style="color: black;">Processing and saving data...</span>
                  <span *ngIf="fileStatus.success" style="color: green;">Saved successfully</span>
                  <span *ngIf="fileStatus.error" style="color: red;">{{ fileStatus.response }}</span>
                </div>
              </div>



              <!-- <button *ngIf="!errorMessage && report" (click)="confirmUpload()" class="btn btn-success mt-2">Confirm and Save</button> -->
              <div *ngIf="!isFromModal && !errorMessage && report">
                <!-- الزر الأول لتأكيد الحفظ -->
                <button (click)="confirmUpload()" class="btn btn-success m-3" [disabled]="uploadConfirmed">
                  Confirm and Save
                </button>

                <!-- الزر الثاني لإضافة إلى قائمة الانتظار -->
                <button *ngIf="!errorMessage && report" (click)="addToQueue()" class="btn btn-warning m-3">
                  Add to Waiting List
                </button>
              </div>


              <!-- إظهار زر "Remove from Waiting List" في حالة أن الرد أتى من المودال -->
              <div *ngIf="isFromModal" class="mt-4">
                <button class="btn btn-danger" (click)="deleteFromQueue(report.fileName)">Remove from Waiting
                  List</button>

                <!-- عرض استجابة الحذف -->
                <div *ngIf="deleteResponseMessage" class="alert mt-2"
                  [ngClass]="{'alert-success': deleteSuccess, 'alert-danger': !deleteSuccess}">
                  {{ deleteResponseMessage }}
                </div>
              </div>



              <div *ngIf="confirmationResponse && confirmationResponse.length > 0" class="mt-4">
                <h3 style="color: black;">Confirmation Responses</h3>
                <div *ngFor="let fileResponse of confirmationResponse" class="card mb-3">
                  <div class="card-header d-flex justify-content-between" (click)="toggleOpen(fileResponse)"
                    style="cursor: pointer;">
                    <h4 class="card-title" style="color: black;">{{ fileResponse.fileName }}</h4>
                    <i class="fa"
                      [ngClass]="{'fa-chevron-down': fileResponse.isOpen, 'fa-chevron-right': !fileResponse.isOpen, 'text-black': true}"></i>
                  </div>
                  <div *ngIf="fileResponse.isOpen" class="card-body">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let invoice of fileResponse.savedInvoices"
                          [ngClass]="{'bg-success text-white': invoice.status === 200, 'bg-danger text-white': invoice.status === 400}">
                          <td>
                            <i class="fa" [class.fa-check-circle]="invoice.status === 200"
                              [class.fa-times-circle]="invoice.status === 400"
                              [style.color]="invoice.status === 200 ? 'green' : 'red'"></i>
                            {{ invoice.status === 200 ? 'Success' : 'Error' }}
                          </td>
                          <td>{{ invoice.message }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>








          </div>




        </div>


      </div>
    </div>
  </div>
</div>