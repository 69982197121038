
<div class="page-title-area page-title-style-two  ">
   <div class="container">
       <div class="page-title-content">
           <h2 style="color: #141414;">{{ curentLang === 'en' ? 'About Us  ' : (curentLang === 'ar' ? '      من نحن '  : '    מי אנחנו  ')}}</h2>
           <ul>
               <li><a routerLink="/" style="color: #141414;">{{ curentLang === 'en' ? 'Home/  ' : (curentLang === 'ar' ? '      الرئيسية/   '  : '   רָאשִׁי/ ')}}</a></li>
               <li style="color: #E62E2D;">{{ curentLang === 'en' ? 'About Us  ' : (curentLang === 'ar' ? '      من نحن '  : '    מי אנחנו  ')}}</li>
           </ul>
       </div>
   </div>
   <div class="divider"></div>
   <div class="lines">
       <div class="line"></div>
       <div class="line"></div>
       <div class="line"></div>
       <div class="line"></div>
       <div class="line"></div>
   </div>
   <div class="banner-shape1"><img src="../../../assets/shape/shape9.png" alt="image"></div>
</div>

<div class="about-area  ">
   <div class="container">
       <div class="row align-items-center">
           <div class="col-lg-6 col-md-12">
               <div class="about-content">
                   <span class="sub-title" style="color: #141414;">{{ curentLang === 'en' ? 'About Us  ' : (curentLang === 'ar' ? '      من نحن '  : '    מי אנחנו  ')}}</span>
                   <h2 style="color: #141414;">{{ curentLang === 'en' ? '
                    Most Probably You Are Getting Best App Ever  ' : (curentLang === 'ar' ? ' على الأرجح أنك تحصل على أفضل تطبيق على الإطلاق '  : ' ככל הנראה אתה מקבל את האפליקציה הטובה ביותר אי פעם ')}}</h2>
                <h6 style="color: #141414;">{{ curentLang === 'en' ? 'Our Company ' : (curentLang === 'ar' ? 'عن الشركة  '  : ' אודות חברה ')}}</h6>

                   <p style="color: #E62E2D;">
                    {{ curentLang === 'en' ? ' At THI INVOICES , we specialize in providing smart and innovative technology solutions that support small and medium enterprises on their digital transformation journey ' : (curentLang === 'ar' ? 'في THI INVOICES ، نتخصص في توفير حلول تقنية ذكية ومبتكرة تدعم المؤسسات الصغيرة والمتوسطة في رحلتهم نحو التحول الرقمي'  : ' ב-THI INVOICES , אנו מתמחים במתן פתרונות טכנולוגיים חכמים וחדשניים התומכים בארגונים קטנים ובינוניים במסע הטרנספורמציה הדיגיטלית שלהם ')}}
                   </p>
                   <div class="features-text">
                       <h6 style="color: #141414;">{{ curentLang === 'en' ? 'Our App ' : (curentLang === 'ar' ? 'عن تطبيقنا  '  : 'לגבי האפליקציה שלנו ')}}</h6>
                       <p style="color: #E62E2D;">
                        {{ curentLang === 'en' ? 'We aim to empower our clients with advanced technologies that improve business and financial performance and increase operational efficiency. ' : (curentLang === 'ar' ? ' نهدف إلى تمكين عملائنا من خلال تقنيات متقدمة تحسن الأداء التجاري والمالي وتزيد من الكفاءة العملية.'  : 'אנו שואפים להעצים את לקוחותינו בטכנולוגיות מתקדמות המשפרות את הביצועים העסקיים והפיננסיים ומגבירות את היעילות התפעולית.')}}
                       </p>
                   </div>
                    
            
               </div>
           </div>
           <div class="col-lg-6 col-md-12">
               <div class="about-img">
                   <img src="../../../assets/bg/4023504.jpg" data-aos="fade-up" alt="about" class="w-100 rounded">
               </div>
           </div>
       </div>
   </div>
</div>
   <!---->
   <section id="why-us" class="why-us ">
    <div class="container" data-aos="fade-up">
       <div class="section-title">
          <h2 class="py-2" style="color: #E62E2D;">{{ curentLang === 'en' ? 'Why Choose Our THI INVOICES ' : (curentLang === 'ar' ? 'لماذا تختار THI INVOICES '  : 'למה לבחור בפוטרה?')}}</h2>
        
          <p style="color: #141414;">{{ curentLang === 'en' ? 'Why Choose Our THI INVOICES ' : (curentLang === 'ar' ? 'لماذا تختار THI INVOICES '  : 'למה לבחור בפוטרה?')}}</p>
          <h6>{{ curentLang === 'en' ? 'THIs Invoice Management software combines simplicity and effectiveness, and offers a range of advanced features' : (curentLang === 'ar' ? ' برنامج "إدارة الفواتير" من THI INVOICES  يجمع بين البساطة والفعالية، ويقدم مجموعة من المزايا المتقدمة'  : 'תוכנת ניהול החשבוניות של THI משלבת פשטות ויעילות, ומציעה מגוון תכונות מתקדמות')}} </h6>

       </div>
       <div class="row">
          <div class="col-lg-4">
             <div class="box" data-aos="zoom-in" data-aos-delay="100">
                <span>01</span>
                <h4>{{ curentLang === 'en' ? 'Improve Efficiency' : (curentLang === 'ar' ? ' تحسين الكفاءة '  : 'שפר את היעילות   ')}}</h4>
                <p>{{ curentLang === 'en' ? 'Speed ​​up billing and collections with process automation.' : (curentLang === 'ar' ? 'تسريع عمليات إصدار الفواتير والتحصيل باستخدام أتمتة العمليات. '  : 'האץ את החיוב והגביה עם אוטומציה של תהליכים.')}} </p>
             </div>
          </div>
          <div class="col-lg-4 mt-4 mt-lg-0">
             <div class="box" data-aos="zoom-in" data-aos-delay="200">
                <span>02</span>
                <h4>{{ curentLang === 'en' ? 'Compliance with laws    ' : (curentLang === 'ar' ? '   التوافق مع القوانين    '  : '   עמידה בחוקים  ')}}</h4>
                <p>{{ curentLang === 'en' ? 'Issuing electronic invoices compatible with the requirements of the new system' : (curentLang === 'ar' ? '     إصدار فواتير إلكترونية متوافقة مع متطلبات النظام الجديد '  : 'הוצאת חשבוניות אלקטרוניות התואמות את דרישות המערכת החדשה')}}</p>
             </div>
          </div>
          <div class="col-lg-4 mt-4 mt-lg-0">
             <div class="box" data-aos="zoom-in" data-aos-delay="300">
                <span>03</span>
                <h4>{{ curentLang === 'en' ? 'Easy to use interface  ' : (curentLang === 'ar' ? '       واجهة سهلة الاستخدام  '  : '  ממשק קל לשימוש')}}</h4>
                <p>{{ curentLang === 'en' ? 'An attractive user interface makes it easy for all users to deal with the program.' : (curentLang === 'ar' ? '    واجهة مستخدم جذابة  تسهل على جميع المستخدمين التعامل مع البرنامج.'  : ' ממשק משתמש אטרקטיבי מקל על כל המשתמשים להתמודד עם התוכנית. ')}}</p>
             </div>
          </div>
       </div>
    </div>
 </section>


   <!---->
   <section id="why-us" class="why-us ">
      <div class="container" data-aos="fade-up">
         <div class="section-title">
            

           
            <p style="color: #141414;">{{ curentLang === 'en' ? 'Technical Support' : (curentLang === 'ar' ? 'الدعم الفني'  : 'תמיכה טכנית')}}</p>
            <h6>{{ curentLang === 'en' ? 'At THI INVOICES , we provide comprehensive technical support services to ensure a smooth experience for our customers:' : (curentLang === 'ar' ? 'نقدم في THI INVOICES  خدمات دعم فني شاملة لضمان تجربة سلسة لعملائنا:'  : 'ב-THI INVOICES , אנו מספקים שירותי תמיכה טכנית מקיפים כדי להבטיח חוויה חלקה ללקוחותינו:')}} </h6>

         </div>
         <div class="row">
            <div class="col-lg-4">
               <div class="box" data-aos="zoom-in" data-aos-delay="100">
                  <span>01</span>
                  <h4>{{ curentLang === 'en' ? 'Instant Support' : (curentLang === 'ar' ? ' دعم فوري '  : ' תמיכה מיידית   ')}}</h4>
                  <p>{{ curentLang === 'en' ? 'A dedicated support team is available to answer your inquiries and solve technical problems.' : (curentLang === 'ar' ? ' فريق دعم متخصص متاح للإجابة على استفساراتكم وحل المشكلات التقنية.'  : 'צוות תמיכה ייעודי זמין לענות על פניותיך ולפתור בעיות טכניות.')}} </p>
               </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
               <div class="box" data-aos="zoom-in" data-aos-delay="200">
                  <span>02</span>
                  <h4>{{ curentLang === 'en' ? 'Program Training ' : (curentLang === 'ar' ? '     تدريب على البرنامج      '  : '   הדרכה בתוכנית    ')}}</h4>
                  <p>{{ curentLang === 'en' ? 'Providing training to institutions to ensure mastery of the use of the program and exploitation of all its features.' : (curentLang === 'ar' ? '   تقديم تدريبات للمؤسسات لضمان إتقان استخدام البرنامج واستغلال جميع ميزاته.  '  : '   מתן הדרכה למוסדות להבטחת שליטה בשימוש בתוכנית וניצול כל תכונותיה.')}}</p>
               </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
               <div class="box" data-aos="zoom-in" data-aos-delay="300">
                  <span>03</span>
                  <h4>{{ curentLang === 'en' ? 'Continuous follow-up ' : (curentLang === 'ar' ? '       متابعة مستمرة    '  : '  מעקב רציף    ')}}</h4>
                  <p>{{ curentLang === 'en' ? 'Providing periodic follow-up to evaluate program utilization and updates to improve performance..' : (curentLang === 'ar' ? 'توفير متابعة دورية لتقييم الاستفادة من البرنامج وتحديثات لتحسين الأداء. '  : 'מתן מעקב תקופתי להערכת ניצול התוכנית ועדכונים לשיפור הביצועים.')}}</p>
               </div>
            </div>
         </div>
      </div>
   </section>
  