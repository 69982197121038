import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { ActivatedRoute } from "@angular/router";
import { UsersPrivilegesEndPoient } from "../service/global.service";

@Component({
  selector: "app-users-privileges",
  templateUrl: "./users-privileges.component.html",
  styleUrls: ["./users-privileges.component.scss"]
})

export class UsersPrivilegesComponent implements OnInit {
  term:any;
  p: any;
  allPrivileges:any= [];
  allPrivilegesScreen:any= [];
  userDetails:any;
  userPrivilegeId:any;
  dataId:any;
  userId:number;
  id:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  curentLang:string="";
  layoutVersion:any;
  addPrivilege=new FormGroup({
    user_id :new FormControl('' ),  
    privilege_id :new FormControl('' , Validators.required),  
    created_by:new FormControl('' ),
  });
  selectedPrivilege: any;
  @Input() isInsideModal: boolean = false;
  constructor (
    private modalService: NgbModal, 
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    activatedRoute:ActivatedRoute,
    private cdRef:ChangeDetectorRef ,
) {    this.dataId = localStorage.getItem('user_id');
  this.id = +activatedRoute.snapshot.params['id'];
this.curentLang = localStorage.getItem('curentLang');
this.user_type = JSON.parse(localStorage.getItem('user_type'));
this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));

};


getAllPrivileges() {
  this.spinner.show();
  this.service.getAllPrivileges(this.id).subscribe({
    next: (res) => {
      this.allPrivileges = res;
      this.spinner.hide();
    },
    error: (error) => {
      if (
        error.status === 400 &&
        error.error.messageEn === 'User privilege already exists' &&
        error.error.messageAr === 'المستخدم موجود بالفعل'
      ) {
        Swal.fire({
          icon: 'error',
          title: error.error.messageAr,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: error.error.message || 'Something went wrong!',
        });
      }
      this.spinner.hide();
    },
  });
};

getAllPrivilegesScreen(){
  this.spinner.show();
  this.service.getAllPrivilegesScreen().subscribe(({
   
  next: res =>{
      this.allPrivilegesScreen = res;
       this.spinner.hide();
  },
  error: error =>{
   this.spinner.hide();
  }
  }));       
};

getUserDetailsById(){

  this.spinner.show()
  this.service.getUserById(this.id  ).subscribe(({
    next:res => {
      this.userDetails = res;
      this.spinner.hide();
    },
    error:err => {
    }
  }))
};

changePrivilegeStatus(userPrivilegeId:any, status:any){
  this.spinner.show();

  let model =
  {
    "status": status == 1 ? 59 : 1,
   "updated_by": this.userRegistId
  }

  this.service.changePrivilegeStatus(userPrivilegeId , model ).subscribe(({
    
    next:res =>{
      Swal.fire({
        icon: 'success',
        title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
      })
        this.getAllPrivileges();
        this.getAllPrivilegesScreen();
        this.getUserDetailsById();
        this.spinner.hide();

    },
    
    error:err =>{
      Swal.fire({
        icon:"error",
        title:err.error.message
      });
      this.spinner.hide();

    }
    
  }))
};
addedPriviligesData() {
  let model = {
    user_id: this.id,
    privilege_id: this.addPrivilege.value.privilege_id,
    created_by: Number(this.userRegistId),
  };
   this.spinner.show();
   this.service.Post(UsersPrivilegesEndPoient.POST, model).subscribe((res) => {
    if (res.status === 200) {
      Swal.fire({
        icon: 'success',
        title: this.curentLang === 'ar' ? 'تم اضافة صلاحية للمستخدم بنجاح ' : (this.curentLang === 'en' ? 'User privileges has been added successfully' : 'הרשאת משתמש נוספה בהצלחה')
      });
      this.modalService.dismissAll();
      this.getAllPrivileges();
      this.getAllPrivilegesScreen();
      this.addPrivilege.reset();
    } else if (res.status === 400 || res.status === 403 || res.status === 500) {
      let errorMessage;
      switch (res.status) {
        case 400:
          errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          break;
        case 403:
          errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          break;
        case 500:
          errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          break;
        default:
          errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
      }
      Swal.fire({
        icon: 'error',
        title: errorMessage
      });
    }
    this.spinner.hide();
  });
  
  
};

getId(id:number)
{
   this.userPrivilegeId = id ;
};
filterPrivilege(event:any)
{
  let x = this.selectedPrivilege;
 };

deletePrivilege() {

  this.service.deletePrivilege( this.userPrivilegeId ).subscribe(({
    next:res=> {
      
      Swal.fire({
        icon: 'success',
        title: this.curentLang === 'ar' ? 'تم حذف صلاحية الموظف بنجاح ' : (this.curentLang === 'en' ? ' User privilege deleted successfully' : 'סמכות העובד נמחקה בהצלחה'),
      })
          this.getAllPrivileges();
          this.getAllPrivilegesScreen();
          this.getUserDetailsById();
          this.allPrivileges = res;
          this.modalService.dismissAll()
    },
    error:err =>{
      Swal.fire(err)
    }
  }))
};

openXl(content:any) {
  this.modalService.open(content, { size: 'md' , centered:true });
};

openVerticallyCentered(dataDeleted:any) {
  this.modalService.open(dataDeleted, { centered: true });
};
  


  ngOnInit() 
  {
  this.getAllPrivileges();
  this.getAllPrivilegesScreen();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
  };
}
