import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "src/app/Data/service/http-help.service";
 
import Swal from "sweetalert2";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { baseUrl } from 'src/app/Data/service/global.service';
import { HttpClient } from "@angular/common/http";
@Component({
  selector: "app-all-warning",
  templateUrl: "./all-warning.component.html",
  styleUrls: ["./all-warning.component.scss"]
})

export class AllWarningComponent implements OnInit {
  userRegistId: any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  curentLang: string = "";
  LockedMonthInvoicesData: any = [];
  invoiceId:  any;
  public openMessageBox: boolean = false;
  term:any;
  p: any;
  invoiceInfoData: any = [];
  curentLangDocument: string = ''
  Confirm: any;
  pdfInvoiceId:  any;
  DocumentNumber: any;
  CompanyVat:any;
 MerchantNameEn:any;
 selectedInvoiceUuid: string | null = null;

  constructor(
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private service: HttpHelpService,
    private modalService: NgbModal,
    private http: HttpClient,
  ) {

    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
   }



  getLockedMonthInvoicesByAccess() {

    this.spinner.show();
    this.service.getLockedMonthInvoicesByAccess().subscribe({
      next: res => {
        this.LockedMonthInvoicesData = res;
 
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }

  getId(id: any) {
    this.invoiceId = id;
    this.getPdfByInvoiceId()
    this.selectedInvoiceUuid = id; // تعيين الصف النشط
   }

  openVerticallyCenteredRejected(dataReject:any) {
    this.modalService.open(dataReject, { centered: true });
  };

  cancleAert() {
    this.spinner.show();
    
    const updatedBy = this.userRegistId;
    this.service.cancleAert(this.invoiceId, updatedBy).subscribe({
      next: (res) => {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        });
        this.getLockedMonthInvoicesByAccess();
        this.spinner.hide();
           this.modalService.dismissAll();
      },
      error: (err) => {
        Swal.fire({
          icon: 'error',
          title: err.error.message
        });
        this.spinner.hide();
      }
    });
  }


  getPdfByInvoiceId() {
    this.spinner.show();
    this.service.getInvoiceAndRecipeToPdf(this.invoiceId).subscribe({
      next: res => {
        this.invoiceInfoData = res;   
              
        this.Confirm = this.invoiceInfoData?.confirm;
        this.curentLangDocument = this.invoiceInfoData?.language;
        this.pdfInvoiceId  = this.invoiceInfoData?.invoice_id;        

        this.DocumentNumber = this.invoiceInfoData?.document_number;
        this.CompanyVat = this.invoiceInfoData?.company_vat;
        this.MerchantNameEn = this.invoiceInfoData?.merchant?.merchant_name_en;
        this.spinner.hide();

      },
      error: error => {
        this.spinner.hide();
      }
    });
  };


  updateVersionCopy() {
    this.spinner.show();
    this.service.updateVersionCopy(this.invoiceId).subscribe({
      next: res => {
        this.invoiceInfoData = res;
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }

  
  downloadDocumentPdf() {
    
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      color: 'green',
      backdrop: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
    Toast.fire({
      icon: 'success',
      title: this.curentLang === 'ar' ? 'جاري الآن تجهيز الملف' : (this.curentLang === 'en' ? 'The file is now being prepared' : 'הקובץ נמצא כעת בהכנה')
    });

    this.service.downloadDocumentPdf(this.pdfInvoiceId, this.curentLangDocument).subscribe({
      next: res => {
        this.spinner.hide();
   
        
        // إنشاء رابط لتحميل الـ PDF
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Invoice-${this.pdfInvoiceId}.pdf`; // تحديد اسم الملف
        link.click();
  
        // إطلاق الـ URL لتجنب أي تسرب في الذاكرة
        window.URL.revokeObjectURL(url);
        this.getPdfByInvoiceId();
      },
      error: error => {
        this.spinner.hide();
        
      }
    });
  }
  

  async generatePDF2() {
    if (this.invoiceInfoData?.confirm === 0) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'لا يمكن تصدير الفاتورة لأنها غير مؤكدة' : (this.curentLang === 'en' ? 'The invoice cannot be exported because it is not confirmed' : 'לא ניתן לייצא את החשבונית מכיוון שהיא לא אושרה')
      });
      return;
    }
  
    try {
      this.spinner.show();
      const pages = document.querySelectorAll('.page');
      const pdf = new jspdf.jsPDF('p', 'mm', 'a4');
  
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const xOffset = 10;
      const yOffset = 10;
      const pdfPageHeight = pageHeight - yOffset * 2;
      const totalNumberOfPages = pages.length;
  
      for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const pageElement = pages[pageIndex] as HTMLElement;
        const canvas = await html2canvas(pageElement, {
          scale: 2,
          useCORS: true,
          scrollX: 0,
          scrollY: -window.scrollY,
          windowWidth: pageElement.scrollWidth,
          windowHeight: pageElement.scrollHeight
        });
  
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const imgHeight = (canvas.height * pageWidth) / canvas.width;
        let remainingHeight = canvas.height;
        let yPosition = 0;
  
        while (remainingHeight > 0) {
          if (yPosition > 0) {
            pdf.addPage();
          }
  
          const sliceHeight = Math.min(remainingHeight, pdfPageHeight * canvas.width / pageWidth);
  
          const canvasSlice = document.createElement('canvas');
          canvasSlice.width = canvas.width;
          canvasSlice.height = sliceHeight;
  
          const ctx = canvasSlice.getContext('2d');
          ctx.drawImage(canvas, 0, yPosition, canvas.width, sliceHeight, 0, 0, canvas.width, sliceHeight);
  
          const imgSlice = canvasSlice.toDataURL('image/jpeg', 1.0);
          pdf.addImage(imgSlice, 'JPEG', xOffset, yOffset, pageWidth - 2 * xOffset, (sliceHeight * pageWidth) / canvas.width);
  
          yPosition += sliceHeight;
          remainingHeight -= sliceHeight;
  
          // Adding page numbers
          pdf.setFontSize(10);
          pdf.text(`Page ${pageIndex + 1} of ${totalNumberOfPages}`, pageWidth - 20, pageHeight - 5);
        }
      }
  
      // Add company logo and signature on the last page
      const signatureImg = await this.getLogosignatureImgeData();
      const logoImg = await this.getLogoImageData();
  
      // Drawing a circle for the signature
      const signatureX = pageWidth - 200;
      const signatureY = pageHeight - 160;
      const signatureRadius = 20;
  
      // Add the signature image inside the circle
      pdf.addImage(signatureImg, 'PNG', signatureX, signatureY, 1.5 * signatureRadius, 2 * signatureRadius, undefined, 'FAST');
  
      // Add the company logo
      pdf.addImage(logoImg, 'PNG', pageWidth - 220, pageHeight - 15, 50, 10);
  
      // Add a border (line) below the signature image
      const lineYPosition = signatureY + 1.5 * signatureRadius + 3; // Position it slightly below the signature
      pdf.setLineWidth(0); // Optional: set line thickness
      const lineStartX = signatureX + 10; // Move the line start 10mm to the right
      const lineEndX = signatureX + 1 * signatureRadius + 10; // Adjust the line end accordingly
      pdf.line(signatureX, lineYPosition, signatureX + 2 * signatureRadius, lineYPosition); // Draw the line
  
      const pdfBlob = pdf.output('blob');
      await this.sendPdfToApi(pdfBlob, `thinvoices-${this.DocumentNumber}.pdf`);
      this.updateVersionCopy();
      this.getPdfByInvoiceId();
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: this.curentLang === 'ar' ? 'حدث خطأ أثناء تصدير الفاتورة' : (this.curentLang === 'en' ? 'An error occurred while exporting the invoice' : 'אירעה שגיאה במהלך ייצוא החשבונית')
      });
    }
  }
  
  

  async sendPdfToApi(pdfBlob: Blob, fileName: string) {
    const formData: FormData = new FormData();
    formData.append('file', pdfBlob, fileName);
    formData.append('companyName', this.CompanyVat); // الرقم الضريبي للشركه
    formData.append('signerName', this.MerchantNameEn); // اسم التاجر انجلش

    try {
      const response: Blob = await this.http.post(`${baseUrl}sign-pdf/sign`, formData, { responseType: 'blob' }).toPromise();
      this.downloadSignedPdf(response, `${fileName}`);
      // Swal.fire({
      //   icon: 'success',
      //   title: this.curentLang === 'ar' ? 'تم إرسال الفاتورة بنجاح' : (this.curentLang === 'en' ? 'Invoice sent successfully' : 'החשבונית נשלחה בהצלחה')
      // });
    } catch (error) {
      // Swal.fire({
      //   icon: 'error',
      //   title: this.curentLang === 'ar' ? 'حدث خطأ أثناء إرسال الفاتورة' : (this.curentLang === 'en' ? 'An error occurred while sending the invoice' : 'אירעה שגיאה במהלך שליחת החשבונית')
      // });
    }
  }

  downloadSignedPdf(blob: Blob, fileName: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  async getLogoImageData(): Promise<any> {
    const img = new Image();
    img.src = '../../../../assets/images/logo/thi.png'; // Adjust path as needed
    img.crossOrigin = 'Anonymous';
    img.style.width = '10px';
    img.style.height = '10px';
    await new Promise<void>(resolve => {
      img.onload = () => resolve();
    });
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL('image/png');
  }

  async getLogosignatureImgeData(): Promise<any> {
    const img = new Image();
    img.src = '../../../../assets/images/logo/12.png'; // Adjust path as needed
    img.crossOrigin = 'Anonymous';
    img.style.width = '10px';
    img.style.height = '10px';
    await new Promise<void>(resolve => {
      img.onload = () => resolve();
    });
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL('image/png');
  }

  openInvoiceXl(content4: any) {
    this.modalService.open(content4, { size: 'xl' }).result.then(() => {
      // شيء يمكن تنفيذه بعد إغلاق المودال، إذا كان ضروريًا
    }, () => {
      // إذا تم إلغاء المودال
    });
  };


  ngOnInit() {
  this. getLockedMonthInvoicesByAccess()
  }
}
