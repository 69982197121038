<app-breadcrumb [title]="curentLang === 'en' ? 'Invoices Types Info' : (curentLang === 'ar' ? ' معلومات أنواع الفواتير   ' : '   מידע על סוגי חשבוניות')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['לוח הבקרה  '])" 
[active_item]="curentLang === 'en' ? 'Invoices Types Info' : (curentLang === 'ar' ? ' معلومات أنواع الفواتير   ' : '    מידע על סוגי חשבוניות ')">
</app-breadcrumb>

<!--invoices types info -->

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Invoices Types Info' : (curentLang === 'ar' ? '  معلومات أنواع الفواتير ' : 'מידע על סוגי חשבוניות')}}
        </h5>

       <div>
        <button  class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add invoices type' : (curentLang === 'ar' ? ' اضافة نوع فاتورة ' : '    הוסף סוג חשבונית')}}
        </button>

        

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllInvoicesTypes()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Invoice Type Name Ar' : (curentLang === 'ar' ? '  أسم نوع الفاتورة عربي  ' : 'שם סוג החשבונית הוא ערבית')}} </th>
                   <th scope="col">  {{ curentLang === 'en' ? 'Invoice Type Name En' : (curentLang === 'ar' ? '  اسم نوع الفاتورة انجليزي   ' : ' שם סוג החשבונית: אנגלית ')}}</th>
                   <th scope="col">  {{ curentLang === 'en' ? 'Invoice Type Name He' : (curentLang === 'ar' ? '  اسم نوع الفاتورة عبري   ' : ' שם סוג החשבונית הוא עברי')}}</th>
                   <th scope="col>">{{ curentLang === 'en' ? 'Invoice Type Code' : (curentLang === 'ar' ? '     كود الفاتورة عربي   ' : '  קוד החשבונית הוא ערבית')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  לצפות   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of invoicesListData | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td>{{item?.invoice_type_name_ar}} </td>
                   <td>{{item?.invoice_type_name_en}}</td>
                   <td>{{item?.invoice_type_name_he}}</td>
                   <td>{{item?.invoice_type_code}}</td>

                   <td>
                    <button *ngIf="item.status==1" class=" btn" style="border: none;" (click)="changeStatus(item?.invoice_type_id, item.status)">
                        <i class="fa solid fa-solid fa-toggle-on " style=" color: #213FA6;" ></i>
                    </button> 

                    <button *ngIf="item.status==59" class=" btn"  style="border: none;"(click)="changeStatus(item?.invoice_type_id, item.status)">
                        <i class="fa solid fa-solid fa-toggle-off " style=" color: red;"></i>
                    </button> 

                  </td>

                   <td>{{ item.createdDate | date: 'dd/MM/yyyy'}}</td>

                  <td>
                    <button class=" btn" style="border: none;" (click)="executeFunctionsView(  item?.invoice_type_id,viewData ) ">
                        <i class="fa fa-eye" style="color: grey;   cursor: pointer;"></i>
                    </button>
                  </td> 

                  <td >
                  <button  class=" btn" style="border: none;"
                   (click)="executeFunctions(  item?.invoice_type_id,updatedData ) ">
                    <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                   </i>
                  </button>
                   </td>

                   <td >
                    <button 
                    [ngStyle]="{ 'pointer-events': item.status == 1 ? 'none' : 'auto', 'color': item.status == 1 ? 'gray' : 'red' }" 
                    class="btn" 
                    style="border: none;" 
                    (click)="getId(item?.invoice_type_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                      </button>
            
                     </td>

                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>


<!--add invoices types  -->

<ng-template #content let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Add invoice type' : (curentLang === 'ar' ? ' اضافة نوع فاتورة ' : '    הוסף סוג חשבונית')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="addInvoiceType">
                        <div class="row">
                            <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Invoice Type Name Ar' : (curentLang === 'ar' ? '  اسم نوع الفاتورة عربي   ' : '  שם סוג החשבונית הוא ערבית')}}
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="invoice_type_name_ar" 
                                  />
                              </div>
              
                              <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Invoice Type Name En' : (curentLang === 'ar' ? '  اسم نوع الفاتورة انجليزي   ' : '   שם סוג החשבונית: אנגלית')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="invoice_type_name_en"
                                   />
                                </div>
              
                                <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Invoice Type Name He' : (curentLang === 'ar' ? '  اسم نوع الفاتورة عبري   ' : '  שם סוג החשבונית הוא עברי ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="invoice_type_name_he" 
                                   maxlength="80"
                                  />
                                   
                                </div>

                                <div class="mb-3  col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Invoice Type Code' : (curentLang === 'ar' ? '     كود الفاتورة عربي   ' : '  קוד החשבונית הוא ערבית')}}
                                   </label>
                                    <input style="color: grey;" class="form-control"
                                     type="number" 
                                     formControlName="invoice_type_code" 
                                     maxlength="80"
                                    />
                                     
                                  </div>

                          
                        </div>
                  
                          <div class="modal-footer">

                            <button type="button" 
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="addedData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--update invoices types  -->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Update invoice type' : (curentLang === 'ar' ? ' تعديل نوع الفاتورة ' : '     ערוך את סוג החשבונית    ')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="updateInvoiceType">
                        <div class="row">
                            <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Invoice Type Name Ar' : (curentLang === 'ar' ? '  اسم نوع الفاتورة عربي   ' : '  שם סוג החשבונית הוא ערבית')}}
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="invoice_type_name_ar" 
                                  />
                              </div>
              
                              <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Invoice Type Name En' : (curentLang === 'ar' ? '  اسم نوع الفاتورة انجليزي   ' : '   שם סוג החשבונית: אנגלית')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="invoice_type_name_en"
                                   />
                                </div>
              
                                <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Invoice Type Name He' : (curentLang === 'ar' ? '  اسم نوع الفاتورة عبري   ' : '  שם סוג החשבונית הוא עברי ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="invoice_type_name_he" 
                                   maxlength="80"
                                  />
                                   
                                </div>

                                <div class="mb-3  col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Invoice Type Code' : (curentLang === 'ar' ? '     كود الفاتورة عربي   ' : '  קוד החשבונית הוא ערבית')}}
                                   </label>
                                    <input style="color: grey;" class="form-control"
                                     type="number" 
                                     formControlName="invoice_type_code" 
                                     maxlength="80"
                                    />
                                     
                                  </div>

                          
                        </div>
                  
                          <div class="modal-footer">

                            <button type="button" 
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updateInvoicesTypesById()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--view invoices types  -->

<ng-template #viewData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'View Info ' : (curentLang === 'ar' ? ' مشاهدة المعلومات ' : '     הצג מידע      ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="updateInvoiceType">
                        <div class="row">
                            <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Invoice Type Name Ar' : (curentLang === 'ar' ? '  اسم نوع الفاتورة عربي   ' : '  שם סוג החשבונית הוא ערבית')}}
                               </label>
                                <input style="color: grey;" readonly
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="invoice_type_name_ar" 
                                  />
                              </div>
              
                              <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Invoice Type Name En' : (curentLang === 'ar' ? '  اسم نوع الفاتورة انجليزي   ' : '   שם סוג החשבונית: אנגלית')}}
                                 </label>
                                  <input style="color: grey;" readonly
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="invoice_type_name_en"
                                   />
                                </div>
              
                                <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Invoice Type Name He' : (curentLang === 'ar' ? '  اسم نوع الفاتورة عبري   ' : '  שם סוג החשבונית הוא עברי ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control" readonly
                                   type="text" 
                                   formControlName="invoice_type_name_he" 
                                   maxlength="80"
                                  />
                                   
                                </div>

                                <div class="mb-3  col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Invoice Type Code' : (curentLang === 'ar' ? '     كود الفاتورة عربي   ' : '  קוד החשבונית הוא ערבית')}}
                                   </label>
                                    <input style="color: grey;" class="form-control" readonly
                                     type="number" 
                                     formControlName="invoice_type_code" 
                                     maxlength="80"
                                    />
                                     
                                  </div>

                          
                        </div>
                  
                       
                      </form>
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>

  <!--Delete Measurement-->

  <ng-template #dataDeleted let-modal>
    <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
          {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : 'האם אתה בטוח שברצונך למחוק אותו ?') }}
        </p>
    </div>
    <div class="modal-footer">

      <button type="button"
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="deleteInvoicesTypesById()"
       > 
        {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>
</ng-template>