import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ForgotPasswordEndPoient } from "src/app/Data/service/global.service";
import { AuthService } from "../service/auth.service";
import { Router } from "@angular/router";
const Swal = require('sweetalert2')

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})

export class ForgotPasswordComponent implements OnInit {
  
  public show: boolean = false;
  userRegistId :any;
  otpCode: number;
  userLanguage:string;

  userForgetPassword:any;

  ForgetPassword = new FormGroup({
    'user_id': new FormControl(null),
    'otp': new FormControl('',[Validators.required ]),
    'password': new FormControl('', [Validators.required , Validators.pattern(/^(?=.*[!@#$%^&*()_\-+=<>?])[A-Z][A-Za-z0-9!@#$%^&*()_\-+=<>?]{3,20}$/)]),
    });
  constructor
  ( private authService:AuthService ,
    private spinner: NgxSpinnerService , 
    private Router:Router,
  ) 
  {

  }

  submitForget(ForgetPassword) {
    let model =  
    {
      "user_id": Number(this.userForgetPassword ),
      "otp": this.ForgetPassword.value.otp ,
      "password": this.ForgetPassword.value.password
    }
    this.spinner.show();

    this.authService.ForgotPassword(ForgotPasswordEndPoient.POST, model).subscribe(({
      
      next:response =>
      {
        this.spinner.show()
        if(response.status == 200)
        {
          Swal.fire({
            icon: 'success',
            title:response.messageEn,
          });
          this.Router.navigate(['/login']);
          localStorage.removeItem('userForgetPassword')
        }
        else{
          if(response.status == 404){
            
            Swal.fire({
              icon: 'error',
              title: response.messageEn 
            })

          }
          this.spinner.hide()
        }

     
         
    },
    error:error =>
    {
      alert(error.error);

    }

    }));
  
  };

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);

    this.userForgetPassword = JSON.parse(localStorage.getItem('userForgetPassword'));
    this.userLanguage = localStorage.getItem('curentLang');
  } 

  showPassword() {
    this.show = !this.show;
  }
}
