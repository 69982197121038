import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../service/http-help.service";

@Component({
  selector: "app-uploadAllocationNumber",
  templateUrl: "./uploadAllocationNumber.component.html",
  styleUrls: ["./uploadAllocationNumber.component.scss"]
})
export class UploadAllocationNumberComponent implements OnInit {
  file: File | null = null;
  responseMessages: any[] | null = null;
  companyInfo: any | null = null;
  successCount: number = 0;
  failureCount: number = 0;
  curentLang: string;

  constructor(private spinner: NgxSpinnerService, private service: HttpHelpService, private router: Router) {
    this.curentLang = localStorage.getItem('curentLang');
  }

  ngOnInit() {}

  onFileChange(event: any) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.file = selectedFile;
    }
  }

  async onSubmit() {
    if (!this.file) {
      return;
    }

    this.spinner.show();

    const formData = new FormData();
    formData.append('file', this.file);

    try {
      this.service.uploadExcelFile(formData).subscribe(
        response => {
          this.responseMessages = (response as any).messages;
          this.companyInfo = (response as any).company;
          this.updateCounts();
          this.spinner.hide();
        },
        error => {
          this.responseMessages = [{ status: 'failure', message: 'Error uploading file. Please try again later.' }];
          this.updateCounts();
          this.spinner.hide();
        }
      );
    } catch (error) {
      this.responseMessages = [{ status: 'failure', message: 'Error uploading file. Please try again later.' }];
      this.updateCounts();
      this.spinner.hide();
    }
  }

  updateCounts() {
    this.successCount = this.responseMessages.filter(message => message.status === 'success').length;
    this.failureCount = this.responseMessages.filter(message => message.status === 'failure').length;
  }

  viewInvoice(invoiceUuid: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard/export-receipt-pdf', invoiceUuid]));
    window.open(url, '_blank');
  }

  revertInvoice(invoiceUuid: string, index: number) {
    this.spinner.show();
    this.service.deleteConfirmationNumberByUuid(invoiceUuid).subscribe(
      response => {
        this.responseMessages[index] = {
          status: response.status,
          message: response.message,
          reverted: true
        };
        this.updateCounts();
        this.spinner.hide();
      },
      error => {
        this.responseMessages[index] = {
          status: 'failure',
          message: `Error reverting invoice UUID: ${invoiceUuid}`,
          reverted: true
        };
        this.updateCounts();
        this.spinner.hide();
      }
    );
  }
}
