<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <div class="login-main">
              <form class="theme-form" (ngSubmit)="otp()">
                <h4 style="color: #141414; text-align: center;" class="mb-3">
                  {{ curentLang === 'en' ? "OTP" : (curentLang === 'ar' ? "كود التحقق" : "קוד אימות")}}
                  </h4>
                <div class="text-center mt-4 mb-4">
                  <span class="reset-password-link">
                    {{ curentLang === 'en' ? "If don't receive OTP ?" : (curentLang === 'ar' ? "إذا لم تستلم رمز التحقق؟" : "אם לא קיבלת קוד אימות?")}}
                    <a class="btn-link text-danger" [routerLink]="'/resend-otp'">
                      {{ curentLang === 'en' ? "Resend" : (curentLang === 'ar' ? "إعادة إرسال" : "שלח מחדש")}}
                    </a>
                  </span>
                </div>
                <label class="col-form-label pt-0 m-auto text-center" style="color: #141414;">
                  {{ curentLang === 'en' ? "Enter Verification Code" : (curentLang === 'ar' ? "ادخل رمز التحقق" : "הזן קוד אימות")}}</label>
                <div class="form-group">
                  <div class="row">
                    <div class="col">
                      <input class="form-control text-center opt-text"
                             type="text"
                             placeholder="0000"
                             maxlength="4"
                             [(ngModel)]="otpCode"
                             inputmode="numeric"
                             pattern="[0-9]*"
                             name="otpCode"
                             (keypress)="isNumber($event)">
                    </div>
                  </div>
                </div>
                <button [disabled]="!otpCode || otpCode.length !== 4"
                        class="btn d-block w-100 m-t-10"
                        style="background-color: #141414; color: #fff;"
                        type="submit">
                        {{ curentLang === 'en' ? "Submit" : (curentLang === 'ar' ? "إرسال" : "שלח")}}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
