import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbModal, NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AddClosedMonthEndPoient } from "src/app/Data/service/global.service";
import { HttpHelpService } from "src/app/Data/service/http-help.service";
import Swal from "sweetalert2";
@Component({
  selector: "app-closed-month",
  templateUrl: "./closed-month.component.html",
  styleUrls: ["./closed-month.component.scss"]
})

export class ClosedMonthComponent implements OnInit {
  curentLang: string = "";
  layoutVersion: any;
  userRegistId: any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  public active1 = 1;
  public active2 = 1;
  public active3 = 1;
  public active4 = 1;
  public active5 = 1;
  selectedFilterYear:  any;
  selectedFilterMonth:  any;
  closedMonthForm: FormGroup;
  years: number[] = [];
  idMerchant:  any;
  companyData: any = [];
  id: any;
  companyUUid: any;
  uuid: any;
  companyClosedMonthData: any = [];
   allInvoicescCompanyDataClosed: any = [];
   LockedMonthInvoicesData: any = [];
   closeRowId:  any;
   InvoiceId:any
   selectedInvoiceUuid: string | null = null;
   invoiceInfoData: any = [];
   DocumentNumber: any;
   CompanyVat:any;
  MerchantNameEn:any;
  Confirm: any;
  curentLangDocument: string = ''
  pdfInvoiceId:  any;
  viewData: any;
  routeId: any;

  constructor(
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private service: HttpHelpService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) 
  { 

    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
    this.uuid = this.activatedRoute.snapshot.params['id'];
    this.companyUUid = this.uuid;

    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year <= currentYear + 1; year++) {
     this.years.push(year);
          }


   this.closedMonthForm = new FormGroup({
     year: new FormControl(this.selectedFilterYear , Validators.required),
     month : new FormControl(this.selectedFilterMonth , Validators.required),
     merchant_id : new FormControl(this.idMerchant),
     company_id: new FormControl(this.id),
     office_id: new FormControl(this.officeIdNumber ),
     created_by: new FormControl(this.userRegistId),
   });

  }

  onNavChange1(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 5) {
      changeEvent.preventDefault();
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 5) {
      changeEvent.preventDefault();
    }
  }

  filterYear(event: any) {
    this.selectedFilterYear = event.target.value;
    // ضع هنا منطق فلترة البيانات بناءً على السنة المختارة
 };

 filterCloseMonth(event: any) {
    this.selectedFilterMonth= event.target.value;
   }

  closeMonth() {
       this.spinner.show();
       let model = {
         year: Number(this.closedMonthForm.value.year),
         month: Number(this.closedMonthForm.value.month),
         merchant_id: Number(this.idMerchant),
         company_id: Number(this.id),
         office_id: Number(this.officeIdNumber),
         created_by: Number(this.userRegistId),
     };

       this.service.Post(AddClosedMonthEndPoient.POST, model).subscribe((res) => {
           if (res.status === 201 ) {
               Swal.fire({
                   icon: 'success',
                   title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
               });
               this.modalService.dismissAll();
               this.getAllClosedMonthCompany();
            } else if (res.status === 400 || res.status === 403 || res.status === 500 || res.status === 409) {
               let errorMessage;
               switch (res.status) {
                   case 400:
                       errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                       break;
                   case 403:
                       errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                       break;
                   case 500:
                       errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                       break;
                   default:
                       errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
               }
               Swal.fire({
                   icon: 'error',
                   title: errorMessage
               });
           }
           this.spinner.hide();
       });
   };

   getCompanyInfoById() {
    this.service.getCompanyInfoById(this.id).subscribe({
      next: res => {
        this.companyData = res;
       this.idMerchant = this.companyData?.merchant_id
           
      
      },
      error: error => {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'فشل في الحصول على معلومات الشركة' :
                 (this.curentLang === 'en' ? 'Failed to get company information' : 'נכשל בהשגת מידע על החברה')
        });
      }
    });
  };
   getAllClosedMonthCompany() {

     this.spinner.show();
     this.service.getAllClosedMonthCompany(this.id, this.officeIdNumber).subscribe({
       next: res => {
         this.companyClosedMonthData = res;
          
          this.spinner.hide();
       },
       error: error => {
         this.spinner.hide();
       }
     });
   }

   getAllInvoiceLockedByCompanyId() {

     this.spinner.show();
     this.service.getAllInvoiceLockedByCompanyId(this.id).subscribe({
       next: res => {
         this.allInvoicescCompanyDataClosed = res;
           
          this.spinner.hide();
       },
       error: error => {
         this.spinner.hide();
       }
     });
   }
   
   getId(id: number) {
     this.closeRowId = id;
    }
   
   changeLockClosedStatus() {
     this.spinner.show();
     
     const updatedBy = this.userRegistId;
     this.service.changeLockClosedStatus(this.closeRowId, updatedBy).subscribe({
       next: (res) => {
         Swal.fire({
           icon: 'success',
           title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
         });
         this.getAllClosedMonthCompany();
         this.spinner.hide();
       },
       error: (err) => {
         Swal.fire({
           icon: 'error',
           title: err.error.message
         });
         this.spinner.hide();
       }
     });
   }
   
   deleteClosedMonth() {
     this.service.deleteClosedMonth(this.closeRowId).subscribe({
       next: (res) => {
         if (res.status === 200) {
           Swal.fire({
             icon: 'success',
             title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
           });
           this.getAllClosedMonthCompany();
           this.modalService.dismissAll();
         }
       },
       error: (err) => {
         Swal.fire({
           icon: 'error',
           title: err.error?.message || 'حدث خطأ أثناء محاولة الحذف'
         });
       }
     });
   }
   
   openVerticallyCentered(dataDeleted:any) {
     this.modalService.open(dataDeleted, { centered: true });
   };

   openVerticallyCenteredRejected(dataReject:any) {
     this.modalService.open(dataReject, { centered: true });
   };

   cancleAert() {
     this.spinner.show();
     
     const updatedBy = this.userRegistId;
     this.service.cancleAert(this.InvoiceId, updatedBy).subscribe({
       next: (res) => {
         Swal.fire({
           icon: 'success',
           title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
         });
         this.getAllInvoiceLockedByCompanyId();
      
         window.location.reload();
          this.spinner.hide();
            this.modalService.dismissAll();
       },
       error: (err) => {
         Swal.fire({
           icon: 'error',
           title: err.error.message
         });
         this.spinner.hide();
       }
     });
   }

  
   getIdInvoiceId(id: number) {
     this.InvoiceId = id;
    }

    getCompanyIdByUuid(): Promise<void> {
      return new Promise((resolve, reject) => {
        this.service.getCompanyIdByUuid(this.uuid).subscribe({
          next: res => {
            this.id = res.company_id;
        
            resolve();
          },
          error: error => {
            Swal.fire({
              icon: 'error',
              title: this.curentLang === 'ar' ? 'فشل في الحصول على معلومات الشركة' : (this.curentLang === 'en' ? 'Failed to get company information' : 'נכשל בהשגת מידע על החברה')
            });
            reject(error);
          }
        });
      });
    };

    getInvoiceId(id:any) {
      this.InvoiceId = id
       
      this.getPdfByInvoiceId()
      this.selectedInvoiceUuid = id; // تعيين الصف النشط
  
    };

    getPdfByInvoiceId() {
      this.spinner.show();
      this.service.getInvoiceAndRecipeToPdf(this.InvoiceId).subscribe({
        next: res => {
          this.invoiceInfoData = res;   
                
          this.Confirm = this.invoiceInfoData?.confirm;
          this.curentLangDocument = this.invoiceInfoData?.language;
          this.pdfInvoiceId  = this.invoiceInfoData?.invoice_id;        
  
          this.DocumentNumber = this.invoiceInfoData?.document_number;
          this.CompanyVat = this.invoiceInfoData?.company_vat;
          this.MerchantNameEn = this.invoiceInfoData?.merchant?.merchant_name_en;
          this.spinner.hide();
  
        },
        error: error => {
          this.spinner.hide();
        }
      });
    };

    executeFunctions(ProductID: number, uploadData: any) {
      this.viewData = uploadData;
      this.getCompanyId(ProductID);
     
      this.openInvoiceXl(this.viewData);
    };

    getCompanyId(id: number) {
      this.routeId = id;
      localStorage.setItem('routeId', this.routeId);
    }

    openInvoiceXl(content4: any) {
      this.modalService.open(content4, { size: 'xl' }).result.then(() => {
        // شيء يمكن تنفيذه بعد إغلاق المودال، إذا كان ضروريًا
      }, () => {
        // إذا تم إلغاء المودال
      });
    };
  
    downloadDocumentPdf() {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        color: 'green',
        backdrop: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });
      Toast.fire({
        icon: 'success',
        title: this.curentLang === 'ar' ? 'جاري الآن تجهيز الملف' : (this.curentLang === 'en' ? 'The file is now being prepared' : 'הקובץ נמצא כעת בהכנה')
      });
      this.service.downloadDocumentPdf(this.pdfInvoiceId, this.curentLangDocument).subscribe({
        next: res => {
          this.spinner.hide();
          
          // إنشاء رابط لتحميل الـ PDF
          const blob = new Blob([res], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `Invoice-${this.pdfInvoiceId}.pdf`; // تحديد اسم الملف
          link.click();
    
          // إطلاق الـ URL لتجنب أي تسرب في الذاكرة
          window.URL.revokeObjectURL(url);
        },
        error: error => {
          this.spinner.hide();
          console.error('Error downloading the file', error);
        }
      });
    }

  ngOnInit() {
    this.getCompanyIdByUuid().then(() => {
      this.getCompanyInfoById()
      this.getAllClosedMonthCompany();
      this.getAllInvoiceLockedByCompanyId();
    }).catch(error => {
     });

  }
}
