 
    <app-breadcrumb [title]="curentLang === 'en' ? 'Companies Info' : (curentLang === 'ar' ? ' معلومات الشركات  ' : '   מידע על חברות  ')" 
    [items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['    הגדרות'])" 
    [active_item]="curentLang === 'en' ? 'Companies Info' : (curentLang === 'ar' ? ' معلومات الشركات  ' : '   מידע על חברות ')">
    </app-breadcrumb>
    
   
    
    <!--company info -->
    
    <div class="container-fluid basic_table">
        <div class="row">
    <div class="col-sm-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h5 style="color: grey;"> 
                {{ curentLang === 'en' ? 'Document Sequence Info' : (curentLang === 'ar' ? '  معلومات تسلسل المستندات ' : 'מידע על רצף המסמכים')}}
            </h5>
               
           <div>
            <button *ngIf="user_type == 200 || user_type == 201" class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
              <i class="fa fa-plus-circle mx-1"></i>
              {{ curentLang === 'en' ? 'Add Sequence ' : (curentLang === 'ar' ? ' اضافة تسلسل ' : 'הוסף רצף')}} 
            
          </button>
    
       
    
          <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getSequencesByCompanyId()">
            <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}</button>
           </div>
         
          </div>
    
          <div class="row">
          
    
            <div class="col-sm-5">
              <div class="form-group search">
                <label for="search" style="color: grey;" class="mx-2">
                  {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                </label>
                <div class="input-group mx-2" >
                  <input class="form-control form-control-search" type="text" name="search"
                         [ngModelOptions]="{standalone: true}" id="search" [(ngModel)]="term" style="color: black;"
                         placeholder=" {{curentLang === 'en' ? 'You can search by document type' : (curentLang === 'ar' ? 'يمكنك البحث عن طريق نوع المستند ' : 'אפשר לחפש לפי סוג מסמך')}}"
    
                         >
       
                </div>
              </div>
            </div>
        </div>
    
          <div class="card-block row">
            <div class="col-sm-12 col-lg-12 col-xl-12">
              <div class="table-responsive">
                <table class="table table-light text-center table-hover  " >
                  <thead>
                    <tr>
                      <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                      <th scope="col"> {{ curentLang === 'en' ? 'Document Type  ' : (curentLang === 'ar' ? '     نوع المستند  ' : '    סוג מסמך ')}} </th>
                       <th scope="col"> {{ curentLang === 'en' ? 'Last Document Value' : (curentLang === 'ar' ? 'قيمة أخر مستند ' : 'ערך המסמך האחרון')}}</th>
                       <th scope="col"> {{ curentLang === 'en' ? 'Next Value' : (curentLang === 'ar' ? 'القيمة التالية' : 'הערך הבא')}}</th>

                       <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>

    
                    </tr>
                  </thead>
    
                  <tbody>
                    <tr *ngFor="let item of sequanseData  | filter:term ; let i = index ">
                      <td scope="row">{{i+1}}</td>
                      <td *ngIf=" item?.docType == 305 "><span>{{curentLang === 'en' ? 'Tax Invoice' : (curentLang === 'ar' ? 'فاتورة ضريبية' : ' חשבונית מס') }}</span></td>
                      <td *ngIf=" item?.docType == 320 "><span>{{curentLang === 'en' ? 'Invoice With Receipt' : (curentLang === 'ar' ? 'فاتورة مع ايصال' : ' חשבונית עם חשבונית') }}</span></td>
                      <td *ngIf=" item?.docType == 332 "><span>{{curentLang === 'en' ? ' Financial Request' : (curentLang === 'ar' ? 'مطالبة مالية' : ' בקשה פינאלית') }}</span></td>
                      <td *ngIf=" item?.docType == 400 "><span>{{curentLang === 'en' ? 'Receipt' : (curentLang === 'ar' ? ' ايصال' : '  חשבונית') }}</span></td>
                      <td *ngIf=" item?.docType == 330 "><span>{{curentLang === 'en' ? 'Reverce Invoice' : (curentLang === 'ar' ? ' فاتورة عكسية' : '  חשבונית עם חשבונית') }}</span></td>
                      <td *ngIf=" item?.docType == 100 "><span>{{curentLang === 'en' ? 'order ' : (curentLang === 'ar' ? ' طلبية  ' : '     לְהַזמִין   ') }}</span></td>
                      <td *ngIf=" item?.docType == 200 "><span>{{curentLang === 'en' ? 'mission ' : (curentLang === 'ar' ? ' ارسالية  ' : 'תעודת משלוח') }}</span></td>
                      <td *ngIf=" item?.docType == 000 "><span>{{curentLang === 'en' ? 'Offer Price ' : (curentLang === 'ar' ? ' عرض سعر  ' : ' מחיר הצעה') }}</span></td>
    
                       <td>{{item.currentValue == null ?  0 : item.currentValue}}</td>
                       <td>{{ (item.currentValue ? +item.currentValue : 0) + 1 }}</td>

                       <td>
                        <i class="fa fa-edit" style=" cursor: pointer; color: green;" 
                          (click)="executeFunctions(  item,updatedModelData ) "
                         >
                       </i>
                      </td>
                  </tr>
        
                  </tbody>
                </table>
               
              </div>
    
              <div class=" d-flex justify-content-between"> 
                <div></div>
    
         
    
               <div *ngIf="user_type == 200 || user_type == 201">
                <a routerLink="/dashboard/companies">
                    <button class="btn btn-danger m-2 " >
                      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                      <i class="fa fa-rotate-left mx-1"></i>
                    </button>
                  </a>
               </div>
    
              </div>
    
            </div>
          </div>
        </div>
      </div>
     
        </div>
    </div>
    


    <ng-template #content let-modal>
        <div class="modal-header d-flex justify-content-between">
            <h6 class="modal-title"style="color: grey;">
                    {{ curentLang === 'en' ? 'Add Sequence ' : (curentLang === 'ar' ? ' اضافة تسلسل ' : 'הוסף רצף')}} 
            </h6>
            <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid ">
                <div class="edit-profile">
                  <div class="row d-flex justify-content-between">
                          <form  [formGroup]=" sequansesForm" novalidate [ngClass]="{ 'was-validated': addvalidateCompanyMerchant }" >
                            <div class="row">
                               
                                <div class="mb-3 col-xl-12 col-md-12 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Document Type  ' : (curentLang === 'ar' ? '     نوع المستند  ' : '    סוג מסמך ')}}
                                      <span class="text-danger">*</span>
                                   </label>
                                   <select name="" id="" class="form-select-sm form-select text-dark "
                                    style="cursor: pointer;" formControlName="docType" (change)="filterDocType($event)">

                                     <option [value]="305" > {{curentLang === 'en' ? 'Tax Invoice' : (curentLang === 'ar' ? 'فاتورة ضريبية' : ' חשבונית מס') }} </option>
                                    <option [value]="320" >{{curentLang === 'en' ? 'Invoice With Receipt' : (curentLang === 'ar' ? 'فاتورة مع ايصال' : ' חשבונית עם חשבונית') }} </option>
                                    <option [value]="332" >  {{curentLang === 'en' ? ' Financial Request' : (curentLang === 'ar' ? 'مطالبة مالية' : ' בקשה פינאלית') }} </option>
                                    <option [value]="400" >  {{curentLang === 'en' ? 'Receipt' : (curentLang === 'ar' ? ' ايصال' : '  חשבונית') }} </option>
                                    <option [value]="330" >  {{curentLang === 'en' ? 'Reverce Invoice' : (curentLang === 'ar' ? ' فاتورة عكسية' : '  חשבונית עם חשבונית') }} </option>
                                    <option [value]="100" >  {{curentLang === 'en' ? 'order ' : (curentLang === 'ar' ? ' طلبية  ' : '     לְהַזמִין   ') }} </option>
                                    <option [value]="200" >  {{curentLang === 'en' ? 'mission ' : (curentLang === 'ar' ? ' ارسالية  ' : 'תעודת משלוח') }} </option>
                                    <option [value]="000" >  {{curentLang === 'en' ? 'Offer Price ' : (curentLang === 'ar' ? ' عرض سعر  ' : ' מחיר הצעה') }}</option>

                                   </select>
                                    <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم الشركة ' : ' נא להזין את שם החברה  ')}}  </div>
  
                                  </div>

                                    <div class="mb-3 col-xl-12 col-md-12 ">
                                      <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Start Sequense ' : (curentLang === 'ar' ? '  بداية التسلسل  ' : 'תחילת הרצף')}}
                                        <span class="text-danger">*</span>
                                     </label>
                                      <input style="color: grey;" class="form-control" onlyAlphabets required=""
                                       type="text" 
                                       formControlName="startWith"  
                                      
                                      />
                                      <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter Start Sequense' : (curentLang === 'ar' ? '   الرجاء ادخال قيمة التسلسل  ' : 'אנא הזן את ערך הרצף')}}  </div>
    
                                    </div>
    
                           
    
                                    
                                
                                      
                  
                            </div>
                      
                        
            
                              <div class="modal-footer">
    
                                <button type="button"  
                                 class="btn btn-sm m-auto mt-2" 
                                style="background-color: #141414; color: #fff;"
                                [disabled]="sequansesForm.invaild"
                                 (click)="addedData()" 
                                 > 
                                  {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                                  <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                              </button>
                          
                                  <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                      <i class="fa fa-rotate-left mx-1"></i>
                                  </button>
                          
                                
                          
                              </div>
                          </form>
                    
                   
                   
                
                  
                </div>
                
              </div>
            
            </div>
        </div>
       
    </ng-template>



    <ng-template #updatedModelData let-modal>
        <div class="modal-header d-flex justify-content-between">
            <h6 class="modal-title"style="color: grey;">
                    {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : '    עריכה   ')}} 
            </h6>
          
            <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="m-2">
            <span class="text-danger my-2">{{curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? 'ملاحظة' : 'פֶּתֶק')}}</span>
            <h6 class="text-danger " >
                {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? 'يرجي العلم ان التحديث يجري علي القيمة التالية ' : '    עריכה   ')}}  
            
                <hr>
                {{curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? '    مثال : اذا اردت ان تكون قيمة المستند بعد التحديث تساوي 1000 ادخل 999' : 'פֶּתֶק')}}
             <hr>
                
                {{curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? '      ملحوظة اذا اردت ان تكون القيمة 1 ادخل 1' : 'פֶּתֶק')}}
          
            </h6>
        </div>
       
        <div class="modal-body">
            <div class="container-fluid ">
                <div class="edit-profile">
                  <div class="row d-flex justify-content-between">
                          <form  [formGroup]="updateSequansesForm"  novalidate [ngClass]="{ 'was-validated': validateUpdate }">
                            <div class="row">
                                 <div class="mb-3 col-xl-12 col-md-12 ">
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'New Sequense' : (curentLang === 'ar' ? '  التسلسل الجديد  ' : 'רצף חדש')}}
                                      <span class="text-danger">*</span>
                                   </label>
                                    <input style="color: grey;"  onlyAlphabets required=""
                                     class="form-control" maxlength="80" 
                                     type="text" formControlName="newValue" 
                                      />
                                      <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter new sequense ' : (curentLang === 'ar' ? '   الرجاء ادخال رقم التسلسل  ' : 'אנא הזן את המספר הסידורי')}}  </div>
    
                                  </div> 
                  
                                 
                  
                                  
                            </div>
                      
                        
            
                              <div class="modal-footer">
    
                                <button type="button"  
                                 class="btn btn-sm m-auto mt-2" 
                                style="background-color: #141414; color: #fff;"
                                 (click)="updateSequenceValue()"
                                 > 
                                  {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                                  <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                              </button>
                          
                                  <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                      <i class="fa fa-rotate-left mx-1"></i>
                                  </button>
                          
                                
                          
                              </div>
                          </form>
                  
                </div>
                
              </div>
            
            </div>
        </div>
       
    </ng-template>