<div class="page-wrapper">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">     
          <div class="login-card">
            <div>
              <div>
                <!-- <a class="logo" routerLink='/'>
                  <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
                  <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
                </a> -->
              </div>
              <div class="login-main"> 
                <form class="theme-form" [formGroup]="ForgetPassword">
                  <h4  style=" color: #141414; text-align: center; " > {{'forget.reset'|translate}}</h4>
           
                  <div class="text-center mt-4 mb-4"><span class="reset-password-link"> {{'forget.if'|translate}}  <a class="btn-link text-danger" routerLink='/resend-otp'> {{'forget.resend'|translate}} </a></span></div>
                
                  <div class="form-group">
                    <label class="col-form-label" style=" color: #141414;"> {{'forget.otp'|translate}}</label>
                    <input class="form-control" type="number"  required=""  formControlName="otp">
                    <div *ngIf="ForgetPassword.get('otp')?.errors != null && ForgetPassword.get('otp')?.touched" class=" alert text-danger ">
                      <p  *ngIf="ForgetPassword.get('otp')?.errors?.required" class=" text-danger"> {{'forget.otpreq'|translate}}</p>
                     </div>
                     
                  </div>

                  <div class="form-group">
                    <label class="col-form-label" style=" color: #141414;"> {{'Register.password'|translate}}</label>
                    <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********"
                     formControlName="password" >
                     <div *ngIf="ForgetPassword.get('password')?.errors != null && ForgetPassword.get('password')?.touched" class=" alert text-danger ">
                      <p  *ngIf="ForgetPassword.get('password')?.errors?.required" class=" text-danger"> {{'Register.passwordRequierd'|translate}}</p>
                     </div>
                  </div>
                 
                 
                  <p class="mt-4 mb-0"> {{'forget.Already'|translate}} <a class="ms-2" [routerLink]="'/login'" style=" color: #141414;"> {{'Login.login'|translate}}</a></p>
                  <button class="btn d-block w-100"
                   style="background-color: #141414; color: #fff;" 
                   type="submit" (click)="submitForget(ForgetPassword)"
                   [disabled]="!ForgetPassword.valid"> {{'forget.submit'|translate}}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>