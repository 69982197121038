import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2';
import { AddSequense } from "../../service/global.service";
@Component({
  selector: "app-sequanse",
  templateUrl: "./sequanse.component.html",
  styleUrls: ["./sequanse.component.scss"]
})

export class SequanseComponent implements OnInit {
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  id:number;
  sequansesForm:FormGroup;
  updateSequansesForm:FormGroup;
  sequanseData:any=[];
  updatedModelData:any
  rowData:any
  DocType:string=''
  public addvalidateCompanyMerchant = false;
  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
    private toastr: ToastrService ,
    private fb: FormBuilder,
    activatedRoute:ActivatedRoute,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.id = activatedRoute.snapshot.params['id'];

    this.sequansesForm = new FormGroup
    ({
      companyId: new FormControl(this.id),
      docType: new FormControl('', Validators.required),
      startWith: new FormControl('', Validators.required),
     });

     this.updateSequansesForm = new FormGroup
     ({
       companyId: new FormControl(this.id),
       docType: new FormControl(''),
       newValue: new FormControl(''),
      });

  };

  getSequencesByCompanyId() 
  {
    this.spinner.show();
    this.service.getSequencesByCompanyId(this.id).subscribe({
      next: res => {
        this.sequanseData = res
       
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
      }
    });
  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'md'  });
  };

  filterDocType(event:any)
  {
    let x = event.target.value;
   };

  addedData() {
 
    if ( this.sequansesForm.invalid   ) 
      {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة' : (this.curentLang === 'en' ? 'Please fill out the required fields' : (this.curentLang === 'he' ? "נא למלא את השדות הנדרשים" : ''))
      });
  
     
      this.sequansesForm.markAllAsTouched();
       this.addvalidateCompanyMerchant = !this.addvalidateCompanyMerchant;
      return;
    }

 
  
    let model = {
      startWith: this.sequansesForm.value.startWith,
      docType: this.sequansesForm.value.docType,
      companyId: Number(this.id),
      created_by: Number(this.userRegistId),
    };
  
    this.spinner.show();
    this.service.Post(AddSequense.POST, model).subscribe(
      (res) => {
        this.spinner.hide();
        if (res.success === true) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
           this.getSequencesByCompanyId();
       
          // إعادة تعيين النموذج مع القيم الافتراضية
          this.sequansesForm.reset();
        } 
        else if (  res.success == false) 
         {

        Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.spinner.hide()
        }
      },
      (err) => {
        this.spinner.hide();
        this.handleErrorResponse(err);
      }
    );
  }
  resetForm() {
    throw new Error("Method not implemented.");
  }
  handleErrorResponse(res: any) {
    throw new Error("Method not implemented.");
  };

  executeFunctions(itemId: number, uploadData: any) {
    this.updatedModelData = uploadData;
    this.getItemRow(itemId);
   // this.setValue(itemId);
    this.updateSequenseModal(this.updatedModelData);
  };

  updateSequenseModal(updatedModelData: any) {
    this.modalService.open(updatedModelData, { size: 'md'  });
  };


  getItemRow(data: any) {
    this.rowData = data;
     
    this.DocType = this.rowData.docType
    
  };

  updateSequenceValue() {
 
    
   let model = {
       docType: this.DocType,
       newValue: this.updateSequansesForm.value.newValue,
       companyId: Number(this.id),
       updated_by: Number(this.userRegistId),
   };


   this.spinner.show();
   this.service.updateSequenceValue( model).subscribe((res) => {
       if (res.success === true) {
           Swal.fire({
               icon: 'success',
               title: this.curentLang === 'ar' ? res.messageAr : 
                      (this.curentLang === 'en' ? res.messageEn : res.messageHe)
           });
           this.modalService.dismissAll();
           this.getSequencesByCompanyId();
      
       } else if (res.success === false) {
           let errorMessage;
           switch (res.success) {
               case 400:
                   errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                  (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                   break;
               case 403:
                   errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                  (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                   break;
               case 500:
                   errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                  (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                   break;
               default:
                   errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : 
                                  (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
           }
           Swal.fire({
               icon: 'error',
               title: errorMessage
           });
       }
       this.spinner.hide();
   });
}
 

  ngOnInit()
  {
    this.getSequencesByCompanyId();
  };
};
