<app-breadcrumb [title]="curentLang === 'en' ? 'Users Privileges Info' : (curentLang === 'ar' ? ' معلومات صلاحيات المستخدمين ' : '   מידע על הרשאות משתמש')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה  '])" 
[active_item]="curentLang === 'en' ? 'Users Privileges Info' : (curentLang === 'ar' ? ' معلومات صلاحيات المستخدمين ' : '  מידע על הרשאות משתמש')">
</app-breadcrumb>
<!--Users Privileges info -->

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
          {{ curentLang === 'en' ? 'Users Privileges Info' : (curentLang === 'ar' ? ' معلومات صلاحيات المستخدمين ' : '  מידע על הרשאות משתמש')}}
        </h5>

       <div>
        <button  class="btn" style=" background-color: #141414; color: #fff;" (click)="openXl(content)" >
            <i class="fa fa-plus-circle mx-1"></i>
             {{ curentLang === 'en' ? 'Add User Privilege' : (curentLang === 'ar' ? '  اضافة صلاحية للمستخدم ' : '  הוסף הרשאה למשתמש ') }}  
        </button>


        <button class="btn mx-1 btn-outline-warning"  (click)="getAllPrivileges()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>
      
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                    <th scope="col" >  {{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }} </th>
                    <th scope="col" >   {{ curentLang === 'en' ? 'Privilege Name EN' : (curentLang === 'ar' ? '   اسم الصلاحية عربي ' : '  שם הרשות הוא עברי  ')}} </th>
                    <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th>
                    <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th>

                  </tr>
              </thead>

              <tbody>
                <tr  *ngFor="let privilige of allPrivileges| filter:term | paginate: {itemsPerPage:20, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td>{{ curentLang === 'en' ? privilige?.privilege_en_name : (curentLang === 'ar' ? privilige?.privilege_ar_name : privilige?.privilege_he_name) }}</td>


                  <td>
                    <button  *ngIf="privilige.status == 1" class="btn" style="border: none;" 
                    (click)="changePrivilegeStatus(privilige?.user_privilege_id, privilige.status)">
                      <i class="fa solid fa-solid fa-toggle-on" style=" color: #213FA6;"></i>
                    </button> 

                    <button *ngIf="privilige.status == 59" class="btn" style="border: none;"
                     (click)="changePrivilegeStatus(privilige?.user_privilege_id, privilige.status)">
                      <i class="fa solid fa-solid fa-toggle-off" style=" color: red;"></i>
                    </button> 
                  </td>





                         <td >
                            <button 
                            [ngStyle]="{ 'pointer-events': privilige.status == 1 ? 'none' : 'auto', 'color': privilige.status == 1 ? 'gray' : 'red' }" 
                            class="btn" 
                            style="border: none;" 
                            (click)="getId(privilige?.user_privilege_id);openVerticallyCentered(dataDeleted)">
                              <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                              </button>
                    
                             </td>





                </tr>
          
              </tbody>
            </table>
            <div *ngIf="allPrivileges?.length == 0" class="alert text-danger mt-3">
                <span> {{ curentLang === 'en' ? 'There are no privileges for this user yet... ' : (curentLang === 'ar' ? ' لا توجد صلاحيات لهذا المستخدم حتي الأن ... ' : '  אין עדיין הרשאות עבור משתמש זה... ')}}</span>
            </div>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>


        <div class=" d-flex justify-content-between"> 
            <div></div>

           <div>
            <a routerLink="/dashboard/office-users">
                <button class="btn btn-danger m-2 " >
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

          

          </div>



      </div>
    </div>
  </div>

    </div>
</div>



   <!-- Add -->

   <ng-template #content let-modal >
    <div class="modal-header d-flex justify-content-between" >
        <h6 class="modal-title"style="color: grey;">
          {{ curentLang === 'en' ? 'Add User Privilege' : (curentLang === 'ar' ? '  اضافة صلاحية للمستخدم ' : '  הוסף הרשאה למשתמש ') }} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                   
                <form  [formGroup]="addPrivilege">
                    <div class="row">
                        <div class="mb-3 col-xl-12 col-md-12 ">
                            <label class="form-label" style="color: grey;"> 
                              {{ curentLang === 'en' ? 'Privilege Name' : (curentLang === 'ar' ? '  أسم الصلاحية ' : '   שם תוקף ') }}
                           </label>
                           <!-- <select class="js-example-basic-single col-xl-12 job-select2 form-select-sm"
                            (change)="filterPrivilege($event)" formControlName="privilege_id"
                            style="border: solid 1px grey; ">
                            <option value=""*ngFor='let screen of allPrivilegesScreen; let i = index '
                            class="c-pointer"
                            [value]="screen?.privilege_id">{{ curentLang === 'en' ? screen?.privilege_en_name : (curentLang === 'ar' ? screen?.privilege_ar_name : screen?.privilege_he_name) }}
                        </option>
                        </select> -->

                        <ng-select formControlName="privilege_id" [(ngModel)]="selectedPrivilege" (change)="filterPrivilege(selectedPrivilege)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                          <ng-option *ngFor="let item of allPrivilegesScreen; let i = index " [value]="item.privilege_id">
                            {{ curentLang === 'en' ? item.privilege_en_name : (curentLang === 'ar' ? item.privilege_ar_name : item.privilege_he_name) }}
                          </ng-option>
                        </ng-select>

                          </div>
                        <!---->

                      
                    </div>
              
                      <div class="modal-footer">

                        <button type="button" [disabled]="addPrivilege.invalid"
                         class="btn btn-sm m-auto mt-2" 
                        style="background-color: #141414; color: #fff;"
                         (click)="addedData()"
                         > 
                         {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                      </button>
                  
                          <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                              <i class="fa fa-rotate-left mx-1"></i>
                          </button>
                  
                        
                  
                      </div>
                  </form> 
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>

<!--Delete-->