<div class="container-fluid">
    <div class="row">
      <div class="col-xl-5" [ngStyle]="{'background-image': 'url(../../../assets/bg/download.jpg)',
                                        'background-size': 'cover',
                                        'background-position': 'center center',
                                        'display': 'block'}">
        <img class="bg-img-cover bg-center d-none" src="assets/images/login/3.jpg" alt="looginpage">
      </div>
      <div class="col-xl-7 p-0">    
        <div class="login-card">
          <div>
            <div>
              <!-- <a class="logo text-start" href="#">
                <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
              </a> -->
            </div>
            <div class="login-main"> 
              <form class="theme-form" [formGroup]="LoginForm">
                <h4 style="color: #141414;"> 
                  {{ curentLang === 'en' ? 'Sign in to your account  ' : (curentLang === 'ar' ? "تسجيل الدخول إلى الحساب" : "התחבר לחשבונך")}}</h4>
                <p style="color: #f90404;">
                  {{ curentLang === 'en' ? "Enter your phone number & password to log in" : (curentLang === 'ar' ? " ادخل رقم الهاتف & كلمة المرور لتسجيل الدخول":  "הזן מספר הטלפון והסיסמה כדי להתחבר")}}
                </p>
                <div class="form-group">
                  <label class="col-form-label" style="color: #141414;">
                    {{ curentLang === 'en' ? "Phone number" : (curentLang === 'ar' ? "رقم الهاتف" : "מספר טלפון")}}  
                  </label>
                 
                  <div class="input-group">
                    <span style="color: #141414;" class="input-group-text">+972</span>
                    <input class="form-control" type="text" maxlength="10" required=""   formControlName="phone_number" autocomplete="username">
                  </div>
                  <div *ngIf="LoginForm.get('phone_number')?.errors != null && LoginForm.get('phone_number')?.touched" class="alert text-danger my-1">
                    <p *ngIf="LoginForm.get('phone_number')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p>
                    <p *ngIf="LoginForm.get('phone_number')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{LoginForm.get('phone_number')?.errors?.invalidMobilePrefix.message}}</p>
                    <p *ngIf="LoginForm.get('phone_number')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{LoginForm.get('phone_number')?.errors?.invalidPhoneNumber.message}}</p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label"style="color: #141414;">
                    
                    {{ curentLang === 'en' ? "Password" : (curentLang === 'ar' ? " كلمة المرور " : "סיסמה")}} </label>
                  <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]"  formControlName="password" required="" placeholder="*********" autocomplete="current-password">
                  <div *ngIf="LoginForm.get('password')?.errors != null && LoginForm.get('password')?.touched" class=" alert text-danger ">
                    <p  *ngIf="LoginForm.get('password')?.errors?.required" class=" text-danger"> {{'Register.passwordRequierd'|translate}}</p>
                   </div>

                   <div *ngIf="LoginForm.get('password')?.errors?.pattern && LoginForm.get('password')?.touched" class="alert text-danger my-1">
                    {{ curentLang === 'en' ? 'Password does not match the criteria' : (curentLang === 'ar' ? 'كلمة المرور غير مطابقة للمعايير' : 'הסיסמה אינה תואמת את הקריטריונים') }}
                  </div>

                  <div class="show-hideAr" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                  <div class="show-hideAr" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div> 
                </div>
                <div class="form-group mb-0">
                  <div class=" p-0">
                    <label class="" for="checkbox1"></label>
                  </div>
                  <a [routerLink]="'/resend-otp'" class="link" style=" color: #f90404;">
                    {{ curentLang === 'en' ? "Forgot password" : (curentLang === 'ar' ? "نسيت كلمة المرور" : "שכחת סיסמה")}} 
                  </a>
                  
                  <div>
                    <button class="btn  d-block w-100 mt-4" [disabled]="!LoginForm.valid"
                    style="background-color: #141414; color: #fff;" 
                    type="submit"
                    [disabled]="LoginForm.get('password').invalid"
                    (click)="submitLoginForm(LoginForm)" >
                    {{ curentLang === 'en' ? "Log in" : (curentLang === 'ar' ? "تسجيل الدخول" : "התחבר")}}
                    
                   </button>
                  </div>

              
                </div>
                <div class="social mt-4">
                 
                </div>
                <p class="mt-4 mb-0">{{ curentLang === 'en' ? "Don't have an account?": (curentLang === 'ar' ? "ليس لديك حساب؟" : "אין לך חשבון?")}}
                  <a [routerLink]="'/register'" class="ms-2" style="color: #f90404;">
                    {{ curentLang === 'en' ? "Create an account": (curentLang === 'ar' ? "انشاء حساب" : "צור חשבון")}}  
                  </a>

                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>