import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { HttpHelpService } from "../../service/http-help.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { DatePipe } from "@angular/common";
import { EMPTY, catchError, concatMap, of, throwError } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-update-order",
  templateUrl: "./update-order.component.html",
  styleUrls: ["./update-order.component.scss"]
})

export class UpdateOrderComponent implements OnInit {
  
  vatTypeList: any;
  vatPersentageData: any;
  VatPercentage: any;
  invoiceId: any;
  vatListId: number;
  today: any;
  mastercompany: any;
  oneCompanyData: any=[];
  oneCustomerData: any=[];
  id:number;
  masterInvoiceTypeList: any;
  curentLang:string="";
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  CompanyNameEn:string = '';
  CompanyNameAr:string = '';
  CompanyNameHe:string = '';

  CustomerNameEn:string = '';
  CustomerNameAr:string = '';
  CustomerNameHe:string = '';
   CustomerAddrees:string = '';
   customerIdNumber:any;
   companyNameAr :any;
   companyNameEn :any;
   companyNameHe :any;
     selectedCompany:any;
   // CustomerNameHe:string = '';
   uuId:any;
  invoiceData : any 
  CompanyAddress:string = '';
  TaxCompany:string = '';
  customerVatNumber:string='';
  LOGO:string='';
  companyId:any;
  CompanyAddress_str: any;
  CompanyAddress_number: any;
  CompanyAddress_city: any;
  CompanyAddress_zip_code: any;
  companyDefault = JSON.parse(localStorage.getItem('companyDefault'));
  selectedMonth:any
  selectedYear:any
  constructor(private builder: FormBuilder, private service: HttpHelpService, private router: Router, private alert: ToastrService,
    private activeroute: ActivatedRoute, private spinner: NgxSpinnerService,  private datePipe: DatePipe , private modalService: NgbModal,
    private toastr: ToastrService ,
    
  )
   { 
    this.curentLang = localStorage.getItem('curentLang');
    this.id = activeroute.snapshot.params['id'];
    this.companyDefault = localStorage.getItem('companyDefault');
   }
   invoicedetail !: FormArray<any>;
  invoiceproduct !: FormGroup<any>;

  mastercustomer: any;
  masterproduct: any;
  editinvoiceno: any;
  isedit = false;
  editinvdetail: any;

  ngOnInit(): void {
    this.curentLang = localStorage.getItem('curentLang');
    this.companyNameAr = localStorage.getItem('company_name_ar');
  
    
    this.companyNameEn  = localStorage.getItem('company_name_en');
    this.companyNameHe  = localStorage.getItem('company_name_he');
    
    this.editinvoiceno = this.activeroute.snapshot.paramMap.get('invoiceno');
    this.loadInvoiceData();
    const today = new Date();
    this.selectedMonth = today.getMonth() + 1;  
    this.selectedYear = today.getFullYear();  
    this.checkClosedMonth();
    this.GetCustomers();
    this.GetProducts();
    this.vatTypechange();
    this.GetCompany();
    this.GetInvoiceTypeList();
    this.stopVatPercentageSubscription();
    if (this.companyDefault) {
      this.selectedCompany = this.companyDefault;
     
      
      if (this.curentLang == 'ar'){
        this.selectedCompany = this.companyNameAr;
       
        }
         else if (this.curentLang === 'he') {
          this.selectedCompany = this.companyNameHe;
         
        } 

        else if (this.curentLang === 'en') {
          this.selectedCompany = this.companyNameEn;
        } 

        else {
          this.curentLang = 'en';
        }
      

    }

    
}

loadInvoiceData() {
  this.spinner.show(); // عرض رمز التحميل قبل استدعاء البيانات

  this.service.GetInvoiceForUpdate(this.id).subscribe(res => {
    this.invoiceData = res;
      
      
    this.companyId = this.invoiceData?.company_id;
    this.uuId = this.invoiceData?.invoice_uuid
    this.CompanyNameAr = this.invoiceData?.company?.company_name_ar 
    this.CompanyNameEn = this.invoiceData?.company?.company_name_en
    this.CompanyNameHe = this.invoiceData?.company?.company_name_he
    
           

    this.invoiceform.patchValue({
      invoice_type: this.invoiceData.invoice_type,
      customer_id: this.invoiceData.customer_id,
      company_id: this.invoiceData.company_id,
      invoice_date: this.invoiceData.invoice_date,
      customer_id_number : this.invoiceData?.customer_id_number,
      customer_name : this.invoiceData.customer_name,
      customer_vat_number: this.invoiceData.customer_vat_number,
      vat_list_id: this.invoiceData.vat_list_id,
      document_language: this.invoiceData.document_language,
      vat_percentage: this.invoiceData.vat_percentage,
      invoice_note: this.invoiceData.invoice_note,
    });
     

    
    // استدعاء getCompanyInfoById() بعد اكتمال الباتش
    if (this.invoiceData.soldItems && this.invoiceData.soldItems.length > 0) {
      const linesFormArray = this.invoiceform.get('lines') as FormArray;
      linesFormArray.clear(); // قم بمسح أي بيانات قديمة
      this.invoiceData.soldItems.forEach(item => {
        const lineFormGroup = this.builder.group({
          index: item.index,
          product_id: item.product_id, 
          category: item.category,
          measure_unit_description: item.measure_unit_description,
          measurement_unit_id: item.measurement_unit_id,
          description: item.description,
          catalog_id: item.catalog_id,
          item_name: item.item_name,
          quantity: item.quantity,    
          price_per_unit: item.price_per_unit, 
          discount: item.discount,    
          sub_total: item.sub_total,    
          vat_rate: item.vat_rate,    
          vat_amount: item.vat_amount,    
          total_amount: item.total_amount    
        });
        linesFormArray.push(lineFormGroup); 
      });
      this.getCompanyInfoById();
      this.getVatPercentageByVatListIdAndDate();
      this.getCustomerById();
    }
  });
   this.spinner.hide();
}




  onDateChange(event: any) {
    const selectedDate: Date = event;
    const formattedDate = selectedDate.toISOString().slice(0, 10).replace(/-/g, "");
 }

  
  availableLanguages = [
    { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "he", name: "Hebrew" }
];

// تعيين curentLang كلغة افتراضية
  
  invoiceform = this.builder.group({
    invoice_type : this.builder.control('100', Validators.required),
    customer_id: this.builder.control('', Validators.required),
    company_id: this.builder.control('', Validators.required),
    vat_list_id: this.builder.control(null, Validators.required),
    vat_percentage: this.builder.control({ value: 0, disabled: true }),
    invoice_date: this.builder.control('' , Validators.required),
    invoice_note: this.builder.control('', Validators.maxLength(255)),
    document_language: this.builder.control(this.curentLang ||'en'),
    amount_before_discount : this.builder.control({ value: 0, disabled: true }),
    all_discount: this.builder.control({ value: 0, disabled: true }),
    payment_amount: this.builder.control({ value: 0, disabled: true }),
    all_vat_amount: this.builder.control({ value: 0, disabled: true }),
    payment_amount_including_vat: this.builder.control({ value: 0, disabled: true }),
    confirm: this.builder.control({ value: 0, disabled: true }),
    allocation_number: 0,
    merchant_id : Number(this.merchantId),
    created_by: Number(this.userRegistId),
    customer_name: this.builder.control('', Validators.required),
    customer_id_number : this.builder.control(''),
    customer_vat_number : this.builder.control(''),
    lines: this.builder.array([] , Validators.required),

  });

 
  confirmInvoice() {
    
    this.spinner.show();   
  
    if (this.invoiceform.valid) {
        this.spinner.show();

        
        return this.service.SaveUpdatedInvoice( this.id,this.invoiceform.getRawValue()).pipe(

          concatMap((res: any) => {

             let result: any = res;
             this.invoiceId = result?.data;
            this.uuId = result?.data?.invoice_uuid;
            if (result.status === 200) {
                
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: 'green',
              backdrop:true,
               
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })
            
            Toast.fire({
              icon: 'success',
              title: this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe)
            })
                return of(null);
            } else if (result.status === 400 || result.status === 403 || result.status === 500) {
                this.spinner.hide();
                let errorMessage;
                switch (result.status) {
                    case 400:
                    case 403:
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                        break;
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }
                Swal.fire({
                    icon: 'error',
                    title: errorMessage
                });
                // Return an observable here
                return throwError(errorMessage);
            }
            // Return an observable here
            return of(null);
          }),
          catchError(error => {
            this.spinner.hide();
             return throwError(error);
          }),
          concatMap(() => this.service.confirmInvoice(this.invoiceId))
       
          
        ).subscribe((res) => {
            if (res.status === 200) {
              this.router.navigate([`/dashboard/order`]);
              const Toast = Swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                color: 'green',
                backdrop:true,
                 
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              
              Toast.fire({
                icon: 'success',
                title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
              })
               

                this.modalService.dismissAll();
            } else if (res.status === 400 || res.status === 403 || res.status === 500) {
                let errorMessage;
                switch (res.status) {
                    case 400:
                    case 403:
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                        this.router.navigate(['/dashboard/order']);

                        break;
                        
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }
                this.toastr.error(errorMessage)

            }
            this.spinner.hide();
        });
    } else {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'נא למלא כל השדות' : ''))
        });
        this.spinner.hide();
        return EMPTY;
    }
    
  }

SaveUpdatedInvoice() {
   if (this.invoiceform.valid) {
     
      this.spinner.show(); // تشغيل الاسبينر قبل استدعاء الخدمة

      this.service.SaveUpdatedInvoice(this.id , this.invoiceform.getRawValue()).subscribe(res => {
          let result: any;
          result = res;
          this.spinner.hide(); // إيقاف الاسبينر بعد الانتهاء من الطلب
          this.invoiceId = result?.data;
          this.uuId = result?.data?.invoice_uuid;
           
          if (result.status === 200) {
            this.spinner.hide();
            this.router.navigate(['/dashboard/order']);

            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: 'green',
              backdrop:true,
               
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })
            
            Toast.fire({
              icon: 'success',
              title: this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe)
            })
              this.invoiceform.reset();
          } else if (result.status === 400 || result.status === 403 || result.status === 500) {
              let errorMessage;
              switch (result.status) {
                  case 400:
                      errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                      break;
                  case 403:
                      errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                      break;
                  case 500:
                      errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                      break;
                  default:
                      errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
              }
              this.toastr.error(errorMessage)
          }
      });
  } else {
      Swal.fire({
          icon: 'warning',
          title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'נא למלא כל השדות' : ''))
      });
  }
  this.spinner.hide()

};


  

  addnewproduct() {
  
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  
    let customercode = this.invoiceform.get("customer_id")?.value;
    let vatListId = this.invoiceform.get("vat_list_id")?.value;
    let invoiceTypeCode = this.invoiceform.get("invoice_type")?.value;
    let invoiceDate = this.invoiceform.get("invoice_date")?.value;
    let companyid = this.invoiceform.get("company_id")?.value;
    
    // يمكنك إضافة شروط إضافية هنا بحسب احتياجات التطبيق
    if (
      (customercode != null && customercode != '') &&
      (vatListId != null && vatListId != '') &&
      (invoiceTypeCode != null && invoiceTypeCode != '') &&
      (invoiceDate != null && invoiceDate != '') &&
      (companyid != null && companyid != '') ||
      this.isedit
    ) {
      this.invoicedetail.push(this.Generaterow());
      
    } else {
      
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'נא למלא כל השדות' : ''))
      });
    }
  }
  

  get invproducts() {
    return this.invoiceform.get("lines") as FormArray;
  }

  Generaterow() {
    this.stopVatPercentageSubscription();

    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
    const newIndex = this.invoicedetail.length + 1;  // قم بحساب الطول الحالي للـ FormArray
    this.stopVatPercentageSubscription();

    return this.builder.group({
      index: this.builder.control(newIndex),  // تعيين قيمة index
      product_id: this.builder.control('', Validators.required),
      category: this.builder.control('', Validators.required),
      measure_unit_description: this.builder.control('', Validators.required),
      measurement_unit_id: this.builder.control('', Validators.required),
      description: this.builder.control('', Validators.required),
      catalog_id: this.builder.control('', Validators.required),
      item_name: this.builder.control('', Validators.required),
      quantity: this.builder.control(1),
      price_per_unit: this.builder.control(0),
      discount: this.builder.control(0),
      sub_total: this.builder.control({ value: 0, disabled: true }),
      vat_rate: this.builder.control({ value: this.VatPercentage, disabled: true }),
      vat_amount: this.builder.control({ value: 0, disabled: true }),
      total_amount: this.builder.control(0),
    });
  }


  stopVatPercentageSubscription()
{
    this.invoiceform.get('vat_list_id')?.disable();
    this.invoiceform.get('invoice_date')?.disable();
};

resumeVatPercentageSubscription()
{
  this.invoiceform.get('vat_list_id')?.enable();
  this.invoiceform.get('invoice_date')?.enable();
};



  GetCustomers() {
    this.spinner.show();
    this.service.getAllActiveCustomersBasedOnMerchantId().subscribe(res => {
      this.mastercustomer = res;
      this.spinner.hide();
    })
  }



  getCompanyInfoById() {

    const companyId = this.invoiceform.get("company_id")?.value;
     
    if (companyId != null && companyId != '') {
    this.service.getCompanyInfoById(this?.companyId).subscribe({
      next: res => {
        this.oneCompanyData = res;
          
        
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
    }
    
  };

  getIdd(id: number) {
    this.id = id;
  }
  getCustomerById() {
    const customerId = this.invoiceform.get("customer_id")?.value;
    if (customerId != null && customerId !== '') {
      this.spinner.show();
      this.service.getCustomerById(Number(customerId)).subscribe({
        next: res => {
          this.oneCustomerData = res;
  
          // تحديث النموذج ببيانات العميل المستلمة
          this.invoiceform.patchValue({
            customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
            customer_id_number: this.oneCustomerData?.id_number,
            customer_vat_number: this.oneCustomerData?.customer_vat_number || 0 ,
          });
  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
    }
  }


  GetCompany() {
    this.service.GetCompany().subscribe(res => {
      this.mastercompany = res;
    })
  }

  GetProducts() {
    this.service.getAllActiveProductsBasedOnMerchantId().subscribe(res => {
      this.masterproduct = res;

    })
  }

  GetInvoiceTypeList() {
    this.spinner.show();

    this.service.GetInvoiceTypeList().subscribe(res => {
      this.masterInvoiceTypeList = res;
      this.spinner.hide();
    })
  }


  customerchange() {
    let customer_id = this.invoiceform.get("customer_id")?.value;
    this.service.GetCustomerbyId(customer_id).subscribe(res => {
      let custdata: any;
      custdata = res;

    });
  }


  companychange() {
    let company_id = this.invoiceform.get("company_id")?.value;
    this.service.GetCompanybyId(company_id).subscribe(res => {
      let custdata: any;
      custdata = res;

    });
  }
  getCompanyId(id:any)
  {
    this.companyId =  id;
  };

  getVatPercentageByVatListIdAndDate() {
    const vatListId = this.invoiceform.get("vat_list_id")?.value;
    const invoiceDate = this.invoiceform.get("invoice_date")?.value;
  
    if (vatListId && invoiceDate) {
      this.spinner.show();
      this.service.getVatPercentageByVatListIdAndDate(vatListId, invoiceDate).subscribe({
        next: res => {
          this.vatPersentageData = res;
          if (this.vatPersentageData && this.vatPersentageData.length > 0) {
            this.VatPercentage = this.vatPersentageData[0].vat_percentage;
            this.invoiceform.patchValue({
              vat_percentage: this.VatPercentage // تحديث القيمة في النموذج
              
            });
            this.summarycalculation(); // إعادة حساب القيم الإجمالية للفاتورة
          } else {
            // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
            this.invoiceform.patchValue({
              vat_percentage: 0
            });
          }
        },
        error: error => {
           // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
          this.invoiceform.patchValue({
            vat_percentage: 0
          });
        },
        complete: () => {
          this.spinner.hide(); // إخفاء المحمل بعد الحصول على البيانات بنجاح أو بعد حدوث خطأ
        }
      });
    } else {
      // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
      this.invoiceform.patchValue({
        vat_percentage: 0
      });
      this.spinner.hide(); // إخفاء المحمل إذا لم يتم تحديد كلا من vatListId و invoiceDate
    }
  }
  
  filter(event:any)
  {
    let x = new Date(event.target.value);    
    this.selectedMonth = x.getMonth() + 1; // Month is 0-based, so add 1
    this.selectedYear = x.getFullYear();
      
    this.checkClosedMonth();
    
  };

  checkClosedMonth() {
    this.spinner.show();
    this.service.checkClosedMonth(this.companyDefault, this.selectedMonth, this.selectedYear).subscribe({
      next: res => {
        this.spinner.hide();
        if (res.status == 200) {  
          this.toastr.warning(
            this.curentLang === 'en' 
              ? res.messageEn
              : this.curentLang === 'ar' 
              ? res.messageAr
              : res.messageHe,
            '',
            { timeOut: 7000 } // زيادة مدة العرض إلى 5 ثوانٍ 
          );
        } else {
          this.toastr.info(
            this.curentLang === 'en' 
              ? 'The selected month and year are open.'
              : this.curentLang === 'ar' 
              ? 'الشهر والسنة المختارة مفتوحة.'
              : 'החודש והשנה שנבחרו פתוחים.',
            '',
            { timeOut: 7000 } // زيادة مدة العرض إلى 5 ثوانٍ
          );
        }
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }

  getId(id: number) {
    this.invoiceId = id;
  };
  vatTypechange() {
    this.spinner.show();

    this.service.GetVatTypeList().subscribe(res => {
      this.vatTypeList = res;
    });

    this.spinner.hide();
  }

  productchange(index: any) {
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

    // استماع للتغيرات على Total Amount
    this.invoiceproduct.get('total_amount').valueChanges.subscribe(newTotalAmount => {
        this.calculateReverse(index, newTotalAmount);
    });

    let product_id = this.invoiceproduct.get("product_id")?.value;

    this.service.GetProductbycode(product_id).subscribe(res => {
        let proddata: any;
        proddata = res;

        // التحقق من إذا كانت بيانات المنتج غير مكتملة
        if (proddata?.product_code === '' || proddata?.product_code === null ||
            proddata?.categories_id === null || proddata?.measurement_unit_id === null) {
            
            // عرض الرسالة التحذيرية وإدخال للمستخدم لإضافة الكود
            Swal.fire({
              icon: 'warning',
              title: this.curentLang === 'ar' ? ' برجاء استكمال معلومات المنتج والمحاولة مرة اخري' : 
                     (this.curentLang === 'en' ? 'Please complete the product information and try again' : 
                     (this.curentLang === 'he' ? 'אנא מלא את פרטי המוצר ונסה שוב' : '')),
              input: 'text', // إدخال نص للمستخدم
              inputPlaceholder: this.curentLang === 'ar' ? 'أدخل كود المنتج' : 
                                 (this.curentLang === 'en' ? 'Enter product code' : 
                                 (this.curentLang === 'he' ? 'הזן קוד מוצר' : '')),
              showCancelButton: true,
              confirmButtonText: this.curentLang === 'ar' ? 'إرسال' : 
                                 (this.curentLang === 'en' ? 'Submit' : 
                                 (this.curentLang === 'he' ? 'שלח' : '')),
              preConfirm: (value) => {
                if (!value || value.trim() === '') {
                    Swal.showValidationMessage(
                        this.curentLang === 'ar' ? 'لا يمكن إدخال قيمة فارغة أو مسافة فقط' : 
                        (this.curentLang === 'en' ? 'Empty or whitespace-only values are not allowed' : 
                        (this.curentLang === 'he' ? 'אין להזין ערך ריק או הכולל רק רווחים' : ''))
                    );
                    return false;
                }
                return value.trim();
              }
            }).then(result => {
                if (result.isConfirmed && result.value) {
                    let newProductCode = result.value;
                    this.invoiceproduct.get("catalog_id")?.setValue(newProductCode);

                    // إرسال الكود الجديد إلى الخادم
                    const model = {
                      product_code: newProductCode,
                      updated_by: this.userRegistId
                    };

                    this.spinner.show();
                    this.service.updateProductsById(product_id, model).subscribe((res) => {
                        

                        // جلب البيانات المحدثة بعد التعديل
                        this.service.GetProductbycode(product_id).subscribe(updatedProdData => {
                            this.updateFormWithProductData(updatedProdData, index);
                        });
                    });
                    this.spinner.hide();
                }
            });

            return;
        }

        if (proddata != null) {
            this.updateFormWithProductData(proddata, index);
        }
    });
}

// دالة لتحديث حقول النموذج بقيم المنتج المحدثة
updateFormWithProductData(proddata: any, index: number) {
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
    this.invoiceproduct.get("productName")?.setValue(proddata.name);
    this.invoiceproduct.get("price_per_unit")?.setValue(proddata.price);
    this.invoiceproduct.get("category")?.setValue(proddata.categories.categories_code);
    this.invoiceproduct.get("measure_unit_description")?.setValue(proddata.measurementUnits['measurement_unit_name_' + this.curentLang].substring(0, 20));
    this.invoiceproduct.get("measurement_unit_id")?.setValue(proddata.measurementUnits.measurement_unit_id);
    this.invoiceproduct.get("description")?.setValue(proddata['des_' + this.curentLang].substring(0, 30));
    this.invoiceproduct.get("catalog_id")?.setValue(proddata.product_code.substring(0, 13));
    this.invoiceproduct.get("item_name")?.setValue(proddata['product_name_' + this.curentLang]);
    this.Itemcalculation(index);
}

//new
onTotalAmountChange(index: number) {
  this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

  const newTotalAmount = parseFloat(this.invoiceproduct.get("total_amount")?.value);
  if (!isNaN(newTotalAmount)) {
      this.calculateReverse(index, newTotalAmount);
  }
}


/// new
calculateReverse(index: any, newTotalAmount: number) {
  this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

  let quantity = this.invoiceproduct.get("quantity")?.value || 1; // التأكد من أن quantity ليس صفرًا
  let vat_rate = this.invoiceproduct.get("vat_rate")?.value || 0;
  let discount = this.invoiceproduct.get("discount")?.value || 0;

  // حساب Sub Total قبل تطبيق الضريبة
  let sub_total_before_vat = newTotalAmount / (1 + (vat_rate / 100));
  
  // حساب الخصم الكامل (يتم تطبيق الخصم على السعر الإجمالي للمنتجات)
  let total_without_discount = sub_total_before_vat + discount;

  // حساب السعر لكل وحدة
  let price_per_unit = parseFloat(total_without_discount) / quantity;

  // حساب مبلغ الضريبة
  let vat_amount = parseFloat((sub_total_before_vat * (vat_rate / 100)).toFixed(2));

  // تعيين القيم المحسوبة إلى النموذج
  this.invoiceproduct.get("sub_total")?.setValue(sub_total_before_vat.toFixed(2));
  this.invoiceproduct.get("price_per_unit")?.setValue(price_per_unit.toFixed(2));
  this.invoiceproduct.get("vat_amount")?.setValue(vat_amount.toFixed(2));
  
  // تحديث القيم الإجمالية للفاتورة
  this.summarycalculation();
}

  Itemcalculation(index: any) {
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
    
    let quantity = this.invoiceproduct.get("quantity")?.value;
    let price = this.invoiceproduct.get("price_per_unit")?.value;
    let discount = this.invoiceproduct.get("discount")?.value;
    let vat_rate = this.invoiceproduct.get("vat_rate")?.value;
  
    let sub_total = (quantity * price) - discount; // الحساب الفرعي للعنصر بعد الخصم
    let vat_amount = parseFloat((sub_total * (vat_rate / 100)).toFixed(2)); // حساب مبلغ الضريبة المضافة
    let total_amount = parseFloat((sub_total + vat_amount).toFixed(2)); // حساب السعر النهائي بإضافة مبلغ الضريبة المضافة إلى الحساب الفرعي
    
    this.invoiceproduct.get("sub_total")?.setValue(sub_total); // تعيين قيمة الحساب الفرعي
    this.invoiceproduct.get("vat_amount")?.setValue(vat_amount); // تعيين قيمة مبلغ الضريبة المضافة
    this.invoiceproduct.get("total_amount")?.setValue(total_amount); // تعيين السعر النهائي للعنصر
    
    this.summarycalculation(); // إعادة حساب القيم الإجمالية للفاتورة
  }

  
  
  
  
  Removeproduct(index: any) {

    
      this.invproducts.removeAt(index);

      this.summarycalculation();
      if( this.invproducts.length == 0){
        this.resumeVatPercentageSubscription();
      }
    
  }

  summarycalculation() {
    let array = this.invoiceform.getRawValue().lines;
    
    let amount_before_discount = 0;
    let all_discount = 0;
    let all_vat_amount = 0;
  
    array.forEach((x: any) => {
      amount_before_discount += parseFloat(x.sub_total);
      all_discount += parseFloat(x.discount);
      all_vat_amount += parseFloat(x.vat_amount);
    });
  
    let payment_amount = amount_before_discount - all_discount; // المبلغ المطلوب للدفع
    let payment_amount_including_vat = payment_amount + all_vat_amount; // المبلغ المطلوب للدفع بما فيه ضريبة القيمة المضافة
  
    this.invoiceform.get("amount_before_discount")?.setValue(parseFloat(amount_before_discount.toFixed(2)));
    this.invoiceform.get("all_discount")?.setValue(parseFloat(all_discount.toFixed(2)));
    this.invoiceform.get("payment_amount")?.setValue(parseFloat(payment_amount.toFixed(2)));
    this.invoiceform.get("all_vat_amount")?.setValue(parseFloat(all_vat_amount.toFixed(2)));
    this.invoiceform.get("payment_amount_including_vat")?.setValue(parseFloat(payment_amount_including_vat.toFixed(2)));
    
  }
}
