<app-breadcrumb [title]="curentLang === 'en' ? 'Plan Details' : (curentLang === 'ar' ? ' تفاصيل الخطة  ' : '  פְּקִידוּת')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Plan Details' : (curentLang === 'ar' ? ' تفاصيل الخطة  ' : '  פְּקִידוּת')">
</app-breadcrumb>

<!-- plan details -->
<div class="container-fluid">
  <div class="row widget-grid">
    <div class="card-header mx-4">
      <h5 style="color: grey;">  {{ curentLang === 'en' ? 'Plan Details' : (curentLang === 'ar' ? '  تفاصيل الخطة  ' : '  פְּקִידוּת')}}</h5>
    </div>
    <div class="row  d-flex justify-content-between">
  

      <div class="col-md-12 ">
        <div class="card">
        <div class="row m-2">
          <div class="col-xxl-3 col-sm-6 box-col-6">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Plan Name' : (curentLang === 'ar' ? '  اسم الخطة    ' : '  שם התוכנית') }}</span>
                <div class="d-flex align-items-end gap-1">
                  <h4 style="color: grey;"> {{  curentLang === 'en' ? planDetails?.plan_name_en : (curentLang === 'ar' ? planDetails?.plan_name_ar : planDetails?.plan_name_he) }} </h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
         <!---->
           <div class="col-xxl-3 col-sm-6 box-col-6">
          <div class="card small-widget">
            <div class="card-body {{ newOrders.colorClass2 }}">
              <span class="f-light">{{ curentLang === 'en' ? 'Number Of Documents' : (curentLang === 'ar' ? '    عدد المستندات    ' : '      מספר מסמכים         ') }}</span>
              <div class="d-flex align-items-end gap-1">
                <h4 *ngIf="planDetails?.is_unlimited_documents ==false" style="color: grey;">
                  {{ curentLang === 'en' ? '(Limited)' : (curentLang === 'ar' ? ' (محدود) ' : ' (מוגבל) ') }}
                  {{planDetails?.number_of_documents}}
                  {{ curentLang === 'en' ? 'Document' : (curentLang === 'ar' ? ' مستند ' : ' מסמך ') }}
                </h4>

                <h4 *ngIf="planDetails?.is_unlimited_documents ==true" style="color: grey;">
                  {{ curentLang === 'en' ? 'Unlimited' : (curentLang === 'ar' ? ' غير محدود ' : ' ללא הגבלה ') }} 
                </h4>
              </div>
              <div class="bg-gradient">
                <svg class="stroke-icon svg-fill">
                  <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                </svg>
              </div>
            </div>
          </div>
           </div>

            <!---->
            <div class="col-xxl-3 col-sm-6 box-col-6">
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass2 }}">
                  <span class="f-light">{{ curentLang === 'en' ? 'Duration' : (curentLang === 'ar' ? ' المدة ' : ' מֶשֶׁך ') }}</span>
                  <div class="d-flex align-items-end gap-1">
                    <h4  style="color: grey;">
                      {{ planDetails?.duration }} {{ curentLang === 'en' ? 'Day' : (curentLang === 'ar' ? ' يوم ' : ' יום ') }}
                    </h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
               </div>
            <!----> 
            <div class="col-xxl-3 col-sm-6 box-col-6">
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass4 }}">
                  <span class="f-light">{{ curentLang === 'en' ? 'Price' : (curentLang === 'ar' ? '  السعر ' : ' מחיר' ) }}</span>
                  <div class="d-flex align-items-end gap-1">
                    <h4 style="color: grey;">{{ planDetails?.price }}</h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon3 }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          <!---->
          <div class="col-xxl-4 col-sm-6 box-col-6">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass2 }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Number Of Companies' : (curentLang === 'ar' ? '    عدد الشركات    ' : '     מספר חברות       ') }}</span>
                <div class="d-flex align-items-end gap-1">
                  <h4 style="color: grey;">{{ planDetails?.number_of_company }}</h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div class="col-xxl-4 col-sm-6 box-col-6 ">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass2 }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Number Of Users' : (curentLang === 'ar' ? ' عدد المستخدمين  ' : '  מספר משתמשים  ') }}</span>
                <div class="d-flex align-items-end gap-1"> 
                  <h4 style="color: grey;">{{ planDetails?.number_of_users }}</h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon2 }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div class="col-xxl-4 col-sm-6 box-col-6">
                  <div class="card small-widget">
                    <div class="card-body {{ newOrders.colorClass3 }}">
                      <span class="f-light">{{ curentLang === 'en' ? 'Status' : (curentLang === 'ar' ? 'الحالة' : 'סטטוס')}}</span>
                      <div class="d-flex align-items-end gap-1">
                        <span *ngIf="planDetails?.status_plan == 1" class="font-success">{{ curentLang === 'en' ? 'Avilable' : (curentLang === 'ar' ? ' متوفر' : ' זמין')}}</span>
                      </div>
                      <div class="bg-gradient">
                        <svg class="stroke-icon svg-fill">
                          <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                        </svg>
                      </div>
                    </div>
                  </div>
           </div>
         <!---->
         <div class="col-xxl-12 col-sm-6 box-col-6">
          <div class="card small-widget">
            <div class="card-body {{ newOrders.colorClass3 }}">
              <span class="f-light">{{ curentLang === 'en' ? 'Desc Plan' : (curentLang === 'ar' ? '    وصف الخطة ' : '    תיאור התוכנית     ') }}</span>
              <div class="d-flex align-items-end gap-1">
                <span style="color: grey;">{{  curentLang === 'en' ? planDetails?.description_en : (curentLang === 'ar' ? planDetails?.description_ar : planDetails?.description_he)}}</span>
              </div>
              <div class="bg-gradient">
                <svg class="stroke-icon svg-fill">
                  <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <button class="btn" style="background-color:#141414 ; color: #fff;" (click)="openVerticallyCentered(content)" *ngIf="user_type ==100">
            {{ curentLang === 'en' ? 'Subscribe to the plan' : (curentLang === 'ar' ? 'الأشتراك في الخطة ' : '    הרשמה לתוכנית') }}
            <i class="fa fa-check-circle mx-1"></i>
          </button>

          <a routerLink="/dashboard/dashboard">
            <button class="btn btn-danger" >
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
            </button>
          </a>
       

        </div>
          
        </div>
      </div>
    
  
    </div>
    
    </div>
   

    </div>
 
  </div>





  <ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p class="text-center" style="color: grey;">  <i class="fa fa-check-circle mx-1" style="color: gray;"></i>
         {{ curentLang === 'en' ? 'Do you want to confirm your subscription to this plan? ' : (curentLang === 'ar' ? 'هل تريد تأكيد الأشتراك في هذة الخطة ؟' : 'האם אתה רוצה לאשר את המנוי שלך לתוכנית זו?')}} </p>
    </div>
    <div class="modal-footer">

      <button type="button" class="btn btn-sm m-auto" style="background-color: #141414; color: #fff;" (click)="addedData()"> 
        {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>
</ng-template>