<app-breadcrumb [title]="curentLang === 'en' ? 'Merchant Info' : (curentLang === 'ar' ? ' معلومات التاجر  ' : '  מידע סוחר')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['   לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Merchant Info' : (curentLang === 'ar' ? ' معلومات التاجر  ' : '  מידע סוחר')">
</app-breadcrumb> 


<div class="container-fluid ">
    <div class="edit-profile">
      <div class="row">
        <div class="col-xl-4 ">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0" style="color:  grey;"> 
                {{curentLang === 'en' ? 'Merchant Info' : (curentLang === 'ar' ? ' معلومات التاجر  ' : '  מידע סוחר')}}
            </h5>
              <div class="card-options">
                <a class="card-options-collapse" href="javascript:void(0)" data-bs-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="javascript:void(0)" data-bs-toggle="card-remove"><i class="fe fe-x"></i></a>
              </div>
            </div>
            <div class="card-body">
              <form  [formGroup]=" updateMerchant" novalidate [ngClass]="{ 'was-validated': validate }">
                <div class="row mb-2">
                  <!-- <div class="col-auto"><img class="img-70 rounded-circle" alt="" src="assets/images/user/7.jpg" /></div> -->
                </div>

                <div class="mb-3">
                  <label class="form-label" style="color: grey;"> 
                    {{ curentLang === 'en' ? 'Merchant Name Ar' : (curentLang === 'ar' ? '  اسم التاجر عربي   ' : '  שם בערבית ')}}
                 </label>
                  <input style="color: grey;" onlyAlphabets
                   class="form-control" maxlength="80"  
                   type="text" formControlName="merchant_name_ar" 
                    [(ngModel)]="MerchantNameAr"/>
                    <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter merchant name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم التاجر ' : 'נא להזין את שם הסוחר')}}  </div>

                </div>

                <div class="mb-3">
                    <label class="form-label" style="color: grey;"> 
                      {{ curentLang === 'en' ? 'Merchant Name En' : (curentLang === 'ar' ? '  اسم التاجر انجليزي   ' : 'שם באנגלית ')}}
                   </label>
                    <input style="color: grey;" onlyAlphabets
                    class="form-control"
                     type="text" 
                    maxlength="80" 
                      formControlName="merchant_name_en"
                       [(ngModel)]=" MerchantNameEn"/>
                       <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter merchant name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم التاجر ' : 'נא להזין את שם הסוחר')}}  </div>

                  </div>

                 

                  <div class="mb-3">
                    <label class="form-label" style="color: grey;">
                      {{ curentLang === 'en' ? 'Merchant Name He' : (curentLang === 'ar' ? '  اسم التاجر عبري   ' : 'שם בעברית')}}
                    </label>
                    <input style="color: grey;" class="form-control" onlyAlphabets type="text" formControlName="merchant_name_he" 
                    required="" maxlength="80" [(ngModel)]="MerchantNameHe" />
                    <div class="invalid-feedback text-danger" *ngIf="updateMerchant.get('merchant_name_he').hasError('whitespace')">
                      {{ curentLang === 'en' ? 'Merchant name cannot be empty or contain only spaces' : (curentLang === 'ar' ? 'اسم التاجر لا يمكن أن يكون فارغًا أو يحتوي فقط على مسافات' : 'שם הסוחר לא יכול להיות ריק או להכיל רק רווחים') }}
                    </div>
                  </div>

                  <div class="mb-3">
                    <label class="form-label" style="color: grey;"> 
                      {{ curentLang === 'en' ? 'Merchant Address En' : (curentLang === 'ar' ? '  عنوان التاجر انجليزي   ' : ' כתובת באנגלית          ')}}
                   </label>
                    <input style="color: grey;" onlyAlphabets
                    class="form-control" type="text" 
                     formControlName="merchant_address"
                     maxlength="400"
                       [(ngModel)]=" MerchantAddress"/>
                       <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter merchant address' : (curentLang === 'ar' ? '   الرجاء ادخال عنوان التاجر ' : 'נא להזין את כתובת הסוחר')}}  </div>

                  </div>

                  <div class="mb-3"  *ngIf="user_type == 200 || user_type == 201">
                    <label class="form-label" style="color: grey;"> 
                      {{ curentLang === 'en' ? 'Id Number' : (curentLang === 'ar' ? ' رقم الهوية  ' : ' מספר תעודת זהות  ')}}
                   </label>
                    <input style="color: grey;"
                      
                      (keypress)="isNumber($event)"
                    class="form-control" type="text" 
                    inputmode="numeric"
                    min="0"
                     formControlName="id_number"
                     maxlength="20" 
                     pattern="[0-20]*"
                       [(ngModel)]=" IdNumber"/>
                       <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter id number  ' : (curentLang === 'ar' ? '   الرجاء ادخال رقم الهوية ' : '  נא להזין את מספר תעודת הזהות שלך')}}  </div>

                  </div>

            

                  <div class="form-footer" style="margin-top: 74px;">
                    <button class="btn d-block w-100 me-1" style=" background-color: #141414;color: #fff;" (click)="updateMerchantById()"> 
                      {{ curentLang === 'en' ? 'Update' : (curentLang === 'ar' ? 'تعديل ' : 'עדכן')}}
                      <i class="fa fa-check-circle mx-1"></i>
                    </button>
                    </div>
              </form>
            </div>
          </div>
        </div>
       
        <div class="col-xl-8 ">
            <div class="card ">
                <div class="card-header   ">

                  <div class="row  " *ngIf="user_type == 200 || user_type == 201">
                    <div class="d-flex justify-content-between">
                      <div class="col-md-4 p-2">
                        <h5 style="color: grey;">  {{ curentLang === 'en' ? 'Subscription Plan Details' : (curentLang === 'ar' ? ' تفاصيل خطة الأشتراك  ' : '  פרטי תוכנית מנוי')}}</h5>
                      </div>

                      <div class="col-md-4 p-2">
                        <button class="btn d-block w-100 me-1" style=" background-color: #141414;color: #fff;" (click)="openXl2(content2)" > 
                          {{ curentLang === 'en' ? 'Increase plan details' : (curentLang === 'ar' ? 'زيادة تفاصيل الخطة' : '   הגדל את פרטי התוכנית  ')}}
                          <i class="fa fa-check-circle mx-1"></i>
                        </button>
                      </div>
                 
  
                      <div class="col-md-4 p-2">
                        <button class="btn d-block w-100 me-1" style=" background-color: #141414;color: #fff;"
                         (click)="openXl(content)" > 
                          {{ curentLang === 'en' ? 'Add New Plan ' : (curentLang === 'ar' ? '  اضافة خطة جديدة' : '   הוסף תוכנית חדשה')}}
                          <i class="fa fa-plus-circle mx-1"></i>
                        </button>
                      </div>
                   
                    </div>
                  </div>
                   
                  </div>

                 

                <div class="card-body">
            <div class="row">
              <div class="col-xxl-4 col-sm-6 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <span class="f-light">
                        {{ curentLang === 'en' ? 'Plan Name' : (curentLang === 'ar' ? '  اسم الخطة' : '  שם התוכנית')}}
                    </span>
                    <div class="d-flex align-items-end gap-1">
                      <h4 style="color: grey;">
                        {{curentLang === 'en' ? planNameEn : (curentLang === 'ar' ? planNameAr : planNamehE)}}
                    </h4>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <!---->
              <div class="col-xxl-4 col-sm-6 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <span class="f-light">
                        {{ curentLang === 'en' ? 'Price' : (curentLang === 'ar' ? '  سعر الخطة' : '  מחיר')}}
                    </span>
                    <div class="d-flex align-items-end gap-1">
                      <h4 style="color: grey;">
                        {{Price}}
                    </h4>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon3 }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <!---->
              <div class="col-xxl-4 col-sm-6 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <span class="f-light">
                        {{ curentLang === 'en' ? ' Number Of Documents' : (curentLang === 'ar' ? ' عدد المستندات' : '  מס׳ המסמכים  ')}}
                    </span>
                    <div class="d-flex align-items-end gap-1">
                      <h4 style="color: grey;">
                        {{numberOfDocuments}}
                    </h4>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <!---->
              <div class="col-xxl-4 col-sm-6 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <span class="f-light">
                        {{ curentLang === 'en' ? ' Number Of Companies' : (curentLang === 'ar' ? ' عدد الشركات' : '   מס׳ החברות   ')}}
                    </span>
                    <div class="d-flex align-items-end gap-1">
                      <h4 style="color: grey;">
                        {{numberOfCompany}}
                    </h4>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <!---->
              <div class="col-xxl-4 col-sm-6 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <span class="f-light">
                        {{ curentLang === 'en' ? ' Number Of Users' : (curentLang === 'ar' ? ' عددالمستخدمين' : 'מס׳ משתמשים')}}
                    </span>
                    <div class="d-flex align-items-end gap-1">
                      <h4 style="color: grey;">
                        {{numberOfUsers}}
                    </h4>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon2 }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <!---->
              <!-- <div class="col-xxl-4 col-sm-6 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <span class="f-light">
                        {{ curentLang === 'en' ? ' Amount Paid' : (curentLang === 'ar' ? '  مبلغ الدفع' : '  כמות שולת ')}}
                    </span>
                    <div class="d-flex align-items-end gap-1">
                      <h4 style="color: grey;">
                        {{amountPaid}}
                    </h4>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon3 }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div> -->
              <!---->
              <div class="col-xxl-4 col-sm-6 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <span class="f-light">
                        {{ curentLang === 'en' ? '  Payment Type  ' : (curentLang === 'ar' ? '   نوع الدفع ' : '   סוג תשלום  ')}}
                    </span>
                    <div class="d-flex align-items-end gap-1">
                      <h6 style="color: grey;">
                        {{paymentType}}
                    </h6>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <!---->
              <div class="col-xxl-4 col-sm-6 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <span class="f-light">
                        {{ curentLang === 'en' ? 'Subscribe Plan Status' : (curentLang === 'ar' ? ' حالة خطة الأشتراك' : '  סטטוס החבילה     ')}}
                    </span>
                    <div class="d-flex align-items-end gap-1">
                        <h4 style="color: green;" *ngIf="status == 1">
                            {{ curentLang === 'en' ? 'Active' : (curentLang === 'ar' ? ' نشط' : ' פעיל ') }}
                        </h4>
                        <h4 style="color: red;" *ngIf="status == 59">
                            {{ curentLang === 'en' ? 'Active' : (curentLang === 'ar' ? ' نشط' : ' פעיל ') }}
                        </h4>
                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <!---->
              <div class="col-xxl-4 col-sm-6 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <span class="f-light">
                        {{ curentLang === 'en' ? 'Subscribe Status' : (curentLang === 'ar' ? ' حالة الأشتراك' : '  סטטוס ההרשמה    ')}}
                    </span>
                    <div class="d-flex align-items-end gap-1">
                        <h4 style="color: green;" *ngIf="lookup_detail_id == 99">
                            {{ curentLang === 'en' ? 'Subscribed' : (curentLang === 'ar' ? ' مشترك' : '  מחובר ') }}
                        </h4>
                        <h4 style="color: red;" *ngIf="lookup_detail_id == 90">
                            {{ curentLang === 'en' ? SubscribeStatusEN : (curentLang === 'ar' ? SubscribeStatusAr : SubscribeStatushE) }}
                        </h4>
                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <!---->
            </div>
          </div>
          <!--back-->
          <div class=" d-flex justify-content-end"> 
            <a routerLink="/dashboard/merchant-info">
              <button class="btn btn-danger m-2 ml-auto" >
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                <i class="fa fa-rotate-left mx-1"></i>
              </button>
            </a>
          </div>
            </div>

      </div>
      
    </div>
    
  </div>

</div>


<!-- add new plan-->
<ng-template #content let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
        {{ curentLang === 'en' ? 'Add New Plan ' : (curentLang === 'ar' ? '  اضافة خطة جديدة' : '   הוסף תוכנית חדשה')}}
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
               <form [formGroup]="addPlan">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group  ">
                        <label class="col-form-label pt-0">{{ curentLang === 'en' ? 'Select Plan Name' : (curentLang === 'ar' ? 'اختر اسم الخطة' : 'בחר שם תוכנית  ')}}
                          <span class="text-danger">*</span>
                        </label>
                        <ng-select formControlName="plan_id" (change)="filter($event)" style="cursor: pointer;" onlyAlphabets
                        placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                        <ng-option *ngFor="let item of planData; let i = index" [value]="item.plan_id">
                          {{ curentLang === 'en' ? item.plan_name_en : (curentLang === 'ar' ? item.plan_name_ar : item.plan_name_he) }} /
                          {{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? 'عدد المستندات' : 'מספר מסמכים') }} =
                          ({{item.number_of_documents}})
                        </ng-option>
                        
                         </ng-select>
                        
                    </div>
                  </div>
                </div>
                
                <div class="modal-footer">
                  <button type="button" class="btn btn-sm m-auto mt-2" style="background-color: #141414; color: #fff;" 
                          (click)="addedData()" [disabled]="addPlan.invalid">
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                  </button>
                  
                  <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                    {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                    <i class="fa fa-rotate-left mx-1"></i>
                  </button>
                </div>
              </form>
                 
              
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>



<!--add increase plan-->

 
<ng-template #content2 let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
        {{ curentLang === 'en' ? ' Add More Details To The Plan ' : (curentLang === 'ar' ? ' اضافة مزيد من التفاصيل الي الخطة' : '   הוסף פרטים נוספות לתוכנית ')}}
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
              <form class="theme-form" [formGroup]="increasePlan" novalidate [ngClass]="{ 'was-validated': updatevalidate }">
              
                <div class="form-group ">

                  <div class="row">

                    <div class="col-lg-6 col-md-6 col-sm-12 my-1 ">
                      <label class="col-form-label p-1" style="color: #141414;">
                        {{ curentLang === 'en' ? 'Number of documents' : (curentLang === 'ar' ? 'عدد المستندات' : 'מספר מסמכים') }}<span class="text-danger">*</span></label>
                      <input class="form-control" type="number" maxlength="50" required=""
                      onlyAlphabets    formControlName="numberOfDocuments">
                      <div *ngIf="increasePlan.get('numberOfDocuments')?.errors != null && increasePlan.get('numberOfDocuments')?.touched" class=" alert text-danger  my-1">
                        <p  *ngIf="increasePlan.get('numberOfDocuments')?.errors?.required" class=" my-1 text-danger"> 
                          {{ curentLang === 'en' ? 'this field is required' : (curentLang === 'ar' ? '   هذا الحقل مطلوب  ' : '   ערך זה הינו חובה') }}
                        </p>
                        <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter number of documents ' : (curentLang === 'ar' ? '   الرجاء ادخال عدد المستندات' : '   אנא הזן מספר מסמכים')}}  </div>

                       </div>
                    </div>

                   
                    <div class="col-lg-6 col-md-6 col-sm-12 my-1 ">
                      <label class="col-form-label p-1" style="color: #141414;">
                        {{ curentLang === 'en' ? 'Number of  companies' : (curentLang === 'ar' ? 'عدد الشركات' : '  מספר חברות  ') }}<span class="text-danger">*</span></label>
                      <input class="form-control" type="number" maxlength="50" required=""
                      onlyAlphabets    formControlName="numberOfCompanies">
                      <div *ngIf="increasePlan.get('numberOfCompanies')?.errors != null && increasePlan.get('numberOfCompanies')?.touched" class=" alert text-danger  my-1">
                        <p  *ngIf="increasePlan.get('numberOfCompanies')?.errors?.required" class=" my-1 text-danger"> 
                          {{ curentLang === 'en' ? 'this field is required' : (curentLang === 'ar' ? '   هذا الحقل مطلوب  ' : '   ערך זה הינו חובה') }}
                        </p>
                        <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter number of  companies ' : (curentLang === 'ar' ? '   الرجاء ادخال عدد الشركات' : '   אנא הזן מספר חברות  ')}}  </div>

                       </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-6 col-sm-12 my-1 ">
                      <label class="col-form-label p-1" style="color: #141414;">
                        {{ curentLang === 'en' ? 'Number of users' : (curentLang === 'ar' ? 'عدد المستخدمين' : ' מספר משתמשים') }}<span class="text-danger">*</span></label>
                      <input class="form-control" type="number" maxlength="50" required=""
                      onlyAlphabets    formControlName="numberOfUsers">
                      <div *ngIf="increasePlan.get('numberOfUsers')?.errors != null && increasePlan.get('numberOfUsers')?.touched" class=" alert text-danger  my-1">
                        <p  *ngIf="increasePlan.get('numberOfUsers')?.errors?.required" class=" my-1 text-danger"> 
                          {{ curentLang === 'en' ? 'this field is required' : (curentLang === 'ar' ? '   هذا الحقل مطلوب  ' : '   ערך זה הינו חובה') }}
                        </p>
                        <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter number of users  ' : (curentLang === 'ar' ? '   الرجاء ادخال عدد المستخدمين' : '   אנא הזן מספר משתמשים ')}}  </div>

                       </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 my-1">
                      <label class="col-form-label p-1" style="color: #141414;">
                        {{ curentLang === 'en' ? 'Number of dayes' : (curentLang === 'ar' ? 'عدد الأيام' : ' מספר משתמשים') }}<span class="text-danger">*</span></label>
                      <input class="form-control" type="number" maxlength="50" required=""
                      onlyAlphabets    formControlName="numberOfDays">
                      <div *ngIf="increasePlan.get('numberOfDays')?.errors != null && increasePlan.get('numberOfDays')?.touched" class=" alert text-danger  my-1">
                        <p  *ngIf="increasePlan.get('numberOfDays')?.errors?.required" class=" my-1 text-danger"> 
                          {{ curentLang === 'en' ? 'this field is required' : (curentLang === 'ar' ? '   هذا الحقل مطلوب  ' : '   ערך זה הינו חובה') }}
                        </p>
                        <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter number of dayes  ' : (curentLang === 'ar' ? '   الرجاء ادخال عدد الأيام' : '   אנא הזן מספר ימים')}}  </div>

                       </div>
                    </div>
             
 
                  </div>
                </div>
                <!---->

 <!---->
               
             
              
           
            
            

               
                <div class="modal-footer ">
                  <button type="button"  
                   class="btn btn-sm m-auto mt-2" 
                  style="background-color: #141414; color: #fff;"
                
                   (click)="increasePlanDetailsByAdmin(increasePlan)"
                   > 
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                </button>
            
                    <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                        <i class="fa fa-rotate-left mx-1"></i>
                    </button>
            
                </div>
                 
               </form>
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>