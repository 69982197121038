<app-breadcrumb [title]="curentLang === 'en' ? 'All Office Users Info' : (curentLang === 'ar' ? ' معلومات مستخدمين التاجر' : '   מידע על משתמשי סוחר')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['    לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Users Info' : (curentLang === 'ar' ? ' معلومات مستخدمين التاجر ' : '  מידע על משתמשי סוחר' )">
</app-breadcrumb>

<!--Users info -->

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Users Info' : (curentLang === 'ar' ? 'معلومات مستخدمين التاجر ' : 'מידע על משתמשי סוחר')}}
        </h5>

       <div>
        

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllUserByMerchantId()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div> 
     
      </div>
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'User Name' : (curentLang === 'ar' ? ' اسم المستخدم ' : ' שם המשתמש')}} </th>
                   <th scope="col">  {{ curentLang === 'en' ? 'Phone Number' : (curentLang === 'ar' ? '  رقم الهاتف  ' : ' מספר הטלפון')}}</th>
                   <th scope="col">  {{ curentLang === 'en' ? 'User Type' : (curentLang === 'ar' ? '  نوع المستخدم ' : ' סוג המשתמש')}}</th> 
               
                   <!-- <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th> -->

                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                  <th scope="col">  {{ curentLang === 'en' ? 'Mverified' : (curentLang === 'ar' ? ' تم التحقق ' : ' מְאוּמָת  ')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update Password  ' : (curentLang === 'ar' ? '   تعديل الرقم السري  ' : '  שנה את הסיסמה ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of userData  | paginate: {itemsPerPage:100, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td>{{item?.user_name}} </td>
                   <td>{{item?.phone_number}}</td>

                   <td style="font-weight: 600;">
                    {{curentLang === 'en' ? item?.lookupDetailsUserType.lookupDetailEnName : (curentLang === 'ar' ? item?.lookupDetailsUserType.lookupDetailArName : item?.lookupDetailsUserType.lookupDetailHeName)}}
                  </td>
 

                  <td>{{ item.createdDate | date: 'dd/MM/yyyy' }}</td>

                   <td *ngIf="item?.mverified == 1">
                      <i class="fa fa-check-circle" style="font-size: 14px; color: green;"></i>
                   </td>
                   
                   <td *ngIf="item?.mverified == 0">
                    <i class="fa fa-close" style="font-size: 14px; color: red;"></i>
                 </td>
                

                 <!-- <td>
                    <button [disabled]="item.user_type === 200 || item.user_type === 100" *ngIf="item.user_status == 1" 
                    class="btn"
                     style="border: none;" 
                    (click)="changeUserStatus(item?.user_id, item.user_status)">
                      <i class="fa solid fa-solid fa-toggle-on" style=" color: #213FA6;"></i>
                    </button> 
                    
                    <button *ngIf="item.user_status == 59" class="btn" style="border: none;" (click)="changeUserStatus(item?.user_id, item.user_status)">
                      <i class="fa solid fa-solid fa-toggle-off" style=" color: red;"></i>
                    </button> 
                  </td> -->
                  
                  <td>
                    <i class="fa fa-edit" style=" cursor: pointer; color: green;"
                    (click)="executeFunctions(  item?.user_id,updatedData ) ">
                   </i>
                  </td>

                  <td>
                    <i class="fa fa-lock" style=" cursor: pointer; color: black;"
                    (click)="getId(item?.user_id);openXl(content)">
                   </i>
                  </td>
                


                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>
        <div class=" d-flex justify-content-between"> 
            <div></div>

           <div>
            <a routerLink="/dashboard/merchant-info">
                <button class="btn btn-danger m-2 " >
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

          

          </div>
      </div>
    </div>
  </div>

    </div>
</div>




<!--update user-->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : '     ערוך מידע    ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]=" updateUserForm" novalidate [ngClass]="{ 'was-validated': validateUpdate }">
                        <div class="row">
                            <div class="mb-3 col-xl-12 col-md-12 ">
                                <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'User Name' : (curentLang === 'ar' ? ' اسم المستخدم ' : ' שם המשתמש')}} 
                               </label>
                                <input style="color: grey;" required="" onlyAlphabets
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="user_name" 
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter user name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم المستخدم  ' : '      אנא הזן שם משתמש ')}}  </div>
    
                              </div>
                            <!---->
                            <div class="mb-3 col-xl-12 col-md-12 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? ' Phone Number' : (curentLang === 'ar' ? ' رقم الهاتف   ' : ' מספר הטלפון')}}
                               </label>
                               <div class="input-group">
                                <span style="color: #141414;" class="input-group-text">+972</span>
                                <input class="form-control" type="text" maxlength="10"    formControlName="phone_number">
                              </div>
                              <div *ngIf="updateUserForm.get('phone_number')?.errors != null && updateUserForm.get('phone_number')?.touched" class="alert text-danger my-1">
                                <p *ngIf="updateUserForm.get('phone_number')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p>
                                <p *ngIf="updateUserForm.get('phone_number')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{updateUserForm.get('phone_number')?.errors?.invalidMobilePrefix.message}}</p>
                                <p *ngIf="updateUserForm.get('phone_number')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{updateCustomer.get('phone_number')?.errors?.invalidPhoneNumber.message}}</p>
                              </div>
                              </div>
              
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button"  
                             class="btn btn-sm m-auto my-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updateUserInfo()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto my-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>



<!--update password -->

<ng-template #content let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Update Password ' : (curentLang === 'ar' ? '      تعديل كلمة المرور    ' : '  שנה סיסמה   ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="updatePassword" novalidate [ngClass]="{ 'was-validated': validate }">
                        <div class="row">
   
  
                            <div class="mb-3 col-xl-12 col-md-12 ">
                              <div class="form-group">
                                <label class="col-form-label"style="color: grey;">{{'Login.password'|translate}}</label>
                                <input class="form-control" [type]="show ? 'text' : 'password'" name="Login[password]" 
                                required="" onlyAlphabets formControlName="password" required="" placeholder="*********">
                              
                                
                                <div *ngIf="updatePassword.get('password')?.errors != null && updatePassword.get('password')?.touched" class=" alert text-danger my-1">
                                  <p  *ngIf="updatePassword.get('password')?.errors?.required" class=" "> {{'Login.error18'|translate}}</p>
                                          <ul  *ngIf="updatePassword.get('password')?.errors?.pattern" class="text-start"> 
                                              <li>{{'Login.error19'|translate}}</li>
                                              <li>{{'Login.error20'|translate}}</li>
                                              <li>{{'Login.error21'|translate}}</li>
                                              <li>{{ curentLang === 'en' ? 'Password does not match the criteria' : (curentLang === 'ar' ? 'كلمة المرور غير مطابقة للمعايير' : 'הסיסמה אינה תואמת את הקריטריונים') }}
  
                                          </ul>
                                   </div>
                                   <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show-1"></span></div>
                                   <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide-1"></span></div> 
                                 
                              </div>
                              </div>
                        
                        </div>
                  
                    
        
                          <div class="modal-footer">
  
                            <button type="button"  
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="ChangeUserPasswordByAdmin()" [disabled]="updatePassword.invalid"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                    

            </div>
            
          </div>
        
        </div>
    </div>
   
  </ng-template>