
import { env } from "process";
import { environment } from 'src/environments/environment';
//import { baseUrl } from 'src/app/Data/service/global.service';
//export const baseUrl : string = 'https://sub.thinvoices.com/';
 // export const baseUrl : string = 'http://localhost:8000/';

  //export const baseUrl : string = 'http://154.56.63.71:8000/'
  export const baseUrl : string = environment.baseUrl
        
         
/* start auth */
export enum RegisterEndPoient     
{
    POST = 'auth/signup',
};

export enum LoginEndPoient 
{
    POST = 'auth/login',
};

export enum OtpEndPoient 
{
    POST = 'auth/verifiedUser',
};

export enum resendOtpEndPoient 
{
    POST = 'auth/resendOtp',
};

export enum ForgotPasswordEndPoient 
{ 
    POST = 'auth/forgetPassword',
};

export enum addNewMerchantByAdmin 
{
    POST = 'auth/addNewMerchantByAdmin',
};

export enum SubscribeEndPoient 
{
    POST = 'subscribe-to-plans/subscribeToPlanFree',
};

export enum AddCompanyEndPoient 
{
    POST = 'company',
};

export enum AddVatEndPoient 
{
    POST = 'vat-list',
};

export enum AddCategoryEndPoient 
{
    POST = 'categories',
};

export enum AddPlanEndPoient 
{
    POST = 'plan',
};

export enum AddCustomerEndPoient 
{
    POST = 'customers',
};

export enum AddProductEndPoient 
{
    POST = 'products',
};

export enum AddMeasurementEndPoient 
{
    POST = 'measurement-units/create',
};

export enum AddInoviceTypeEndPoient 
{
    POST = 'invoice-type-list',
};

export enum AddReceiptEndPoient 
{
    POST = 'receipt',
};

export enum AddUserEndPoient 
{
    POST = 'users/createUserAdmin',
};


export enum AddMerchantUserEndPoient 
{
    POST = 'users/createUserForMerchant',
};

export enum UsersPrivilegesEndPoient 
{
    POST = 'users-privileges',
};

export enum AddVatDetailsEndPoient 
{
    POST = 'vat-details',
};

export enum AddBankEndPoient 
{
    POST = 'banks',
};

export enum AddBankBranchEndPoient 
{
    POST = 'banks-branches',
};

export enum AddPaymeentMethodEndPoient 
{
    POST = 'payment-methods',
};


export enum AddInvoiceEndPoient 
{
    POST = 'invoice',
};

export enum AddAppInfoEndPoient 
{
    POST = 'app-info',
};

export enum AddZipEndPoient 
{
    POST = 'zip/create',
};

export enum AddTermsConditionPoient 
{
    POST = 'terms-and-conditions/create',
};

export enum AddOfficeAccountingByAdminEndPoient 
{
    POST = 'auth/addOfficeAccountingByAdmin',
};

export enum AddCompanyPermissionsEndPoient 
{
    POST = 'office-company-permissions',
};

export enum AddAccountantUserEndPoient 
{
    POST = 'users/createUserForOffice',
};

export enum AddAccountantUsersCompanyEndPoient 
{
    POST = 'accountant-users-company-access',
};

export enum AddClosedMonthEndPoient 
{
    POST = 'closed-months',
};