<app-breadcrumb [title]="curentLang === 'en' ? 'Mission Info' : (curentLang === 'ar' ? ' معلومات الارساليات  ' : 'פירוט תעודת משלוח    ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Mission Info' : (curentLang === 'ar' ? ' معلومات الارساليات  ' : '   פירוט תעודת משלוח    ')">
</app-breadcrumb>

<!--Mission Info -->

<div class="container-fluid basic_table">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Mission Info' : (curentLang === 'ar' ? '  معلومات الارساليات ' : ' פירוט תעודת משלוח  ')}}
        </h5>
           
       <div>
        <button [hidden]="!isValidMerchant" class="btn" style=" background-color: #141414; color: #fff;" 
        routerLink="/dashboard/add-mission">
          <i class="fa fa-plus-circle mx-1"></i>
        {{ curentLang === 'en' ? 'Add Mission' : (curentLang === 'ar' ? '  اضافة ارسالية  ' : '  הוספת תעודת משלוח   ')}}
        
      </button>
 
      <!-- <button class="btn mx-1 btn-outline-warning"  >
        <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? 'تحديث' : 'רענן')}}
      </button> -->
      
       </div>
     
      </div>

      <div class="container-fluid basic_table" >
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group search px-1">
              <label for="seaarch" class="ml-2" style="color: grey;">
                {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
              </label>
              <div class="input-group">
                <input class="form-control w-100" type="text" name="search"
                       [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term"
                       placeholder="{{curentLang === 'en' ? 'Search by customer name or document number ' : (curentLang === 'ar' ? 'بحث بأسم العميل او رقم المستند' : 'חפש לפי שם הלקוח או מספר המסמך')}}"
                       />
                <!-- <div class="input-group-append m-auto">
                  <button class="btn" style="background-color: #141414; color: #fff;" type="button"
                          (click)="getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                    <i class="fa fa-search mx-1"></i>
                    {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                  </button>
                  <button class="btn btn-outline-secondary" type="button" (click)="term='';getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                    <i class="fa fa-times"></i>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
         
          <div class="col-sm-3 mt-2" >
            <label class="form-label px-1" style="color: grey;">
              {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה   ') }}
            </label>
            <ng-select [(ngModel)]="selectedCompany" (change)="filter($event)" class="px-1"
                       style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
              <ng-option [value]="allCompaniesOption">
                {{ curentLang === 'en' ? 'All Companies' : (curentLang === 'ar' ? 'الكل' : 'הכל') }}
              </ng-option>
              <ng-option *ngFor="let item of companyData" [value]="item">
                {{ curentLang === 'en' ? item?.company_name_en : (curentLang === 'ar' ? item?.company_name_ar : item?.company_name_he)}}
              </ng-option>
            </ng-select>
            <ng-template *ngIf="companyData.length === 0" class="px-1">
              <span style="color: red;">
                {{ curentLang === 'en' ? 'There are no mission for this company yet' : (curentLang === 'ar' ? "لا توجد ارساليات لهذه الشركة حتى الآن" : "אין עדיין משלוחים לחברה זו")}}
              </span>
            </ng-template>
          </div>
         
          <form [formGroup]="documentForm"   >
  
        
  <div class="row d-flex justify-content-between ">
    <div class="col-md-3 mt-2 "  >
      <h6 class="form-label px-1 " style="color: grey;"> 
        {{ curentLang === 'en' ? 'Period' : (curentLang === 'ar' ? 'الفترة' : 'תקופה  ')}}
      </h6>
      <div class="px-1">
        <select class="form-control  px-1  form-select" style="cursor: pointer;" (change)="filterMonth($event)" (click)="confirmAction()">
          <option [value]="1">{{ curentLang === 'en' ? 'Current month' : (curentLang === 'ar' ? 'الشهر الحالي' : 'חודש נוכחי  ') }}</option>
          <option [value]="2">{{ curentLang === 'en' ? 'Last month' : (curentLang === 'ar' ? 'الشهر الماضي' : '  חודש קודם') }}</option>
          <option [value]="5">{{ curentLang === 'en' ? 'Last two month' : (curentLang === 'ar' ? ' أخر شهران ' : ' שני חודשים קודמים  ') }}</option>
          <option [value]="3">{{ curentLang === 'en' ? 'Start of the year' : (curentLang === 'ar' ? 'من بداية العام' : ' מתחילת שנה  ') }}</option>
          <option [value]="4">{{ curentLang === 'en' ? 'Last year' : (curentLang === 'ar' ? 'العام الماضي' : ' שנה קודמת  ') }}</option>
        </select>
      </div>
   
    </div>
  
    <div class="col-md-6 ">
      <div class="row d-flex justify-content-between">
  
      
        <div class="col-md-6 ">
        <h6 for="fromDate" class="ml-2" style="color: grey;">
          {{curentLang === 'en' ? 'From Date' : (curentLang === 'ar' ? 'من تاريخ' : '  מתאריך')}}
        </h6>
        <div  >
          <p-calendar [(ngModel)]="fromDate" style="display: block; width: 100%;"
           showIcon="true" (ngModelChange)="onFromDateChange($event)"
              formControlName="startDate" 
              dateFormat="dd/mm/yy"
               placeholder="DD/MM/YYYY"
                >
              </p-calendar>
  
        </div>
      </div>
  
        <div class="col-md-6">
          <h6 for="toDate"  style="color: grey;">
           {{curentLang === 'en' ? 'To Date' : (curentLang === 'ar' ? 'الي تاريخ' : ' עד תאריך   ')}}
         </h6>
         <div class="">
         <p-calendar 
         style="display: block; width: 100%;"
           [(ngModel)]="toDate" 
           showIcon="true" 
           (ngModelChange)="onToDateChange($event)" 
           formControlName="endDate" 
           dateFormat="dd/mm/yy" 
           placeholder="DD/MM/YYYY">
         </p-calendar>
        </div>
     </div>
  
      </div>
    </div>
    
   
    
     
    
    <div class="col-md-3 mt-4 " style="padding-top: 6px;">
      <div class="form-group search  ">
        <button class="btn btn-sm mx-1  mt-2 " style="background-color: #141414; color: #fff;" 
        [disabled]="!fromDate || !toDate"
        (click)="getInvoicesFilters(fromDate, toDate)">
          <i class="fa fa-refresh mx-1"></i>
          {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
        </button>
      </div>
    </div>
  </div>
     
     
    
      </form>
    
      </div>
        </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive mt-2">
            <table class="table table-light text-center table-hover d-none d-lg-table " >
              <thead>
                <tr>
                  <th scope="col"> {{ curentLang === 'en' ? 'Confirmation Number' : (curentLang === 'ar' ? '  رقم التأكيد  ' : '  מס׳ אישור ')}} </th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? '   رقم المستند ' : ' מס׳    ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Invoice Reference Number' : (curentLang === 'ar' ? '    رقم مرجع الفاتورة  ' : 'מס מסמך')}}</th>
                 <!-- <th scope="col"> {{ curentLang === 'en' ? 'Vat Number' : (curentLang === 'ar' ? '      الرقم الضريبي  ' : 'מספר מס ')}}</th> -->
                 <th scope="col"> {{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? '  تاريخ الفاتورة ' : '    תאריך  ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Company Name  ' : (curentLang === 'ar' ? ' أسم الشركة ' : 'שם החברה  ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Customer Name  ' : (curentLang === 'ar' ? '  أسم العميل   ' : ' שם   ')}}</th>
                 <!-- <th scope="col"> {{ curentLang === 'en' ? 'Customer Vat Number  ' : (curentLang === 'ar' ? '  الرقم الضريبي العميل   ' : '    מספר מס לקוח')}}</th> -->
                 <th scope="col"> {{ curentLang === 'en' ? 'Amount Before Discount  ' : (curentLang === 'ar' ? ' المبلغ قبل الخصم   ' : 'מחיר לפני הנחה   ')}}</th>
                 <!-- <th scope="col"> {{ curentLang === 'en' ? ' Discount  ' : (curentLang === 'ar' ? 'الخصم  ' : ' הנחה   ')}}</th> -->
                 <th scope="col"> {{ curentLang === 'en' ? ' Payment Amount  ' : (curentLang === 'ar' ? 'المبلغ المدفوع  ' : ' סה"כ ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? ' Vat Amount ' : (curentLang === 'ar' ? ' قيمة الضريبة  ' : '   מע"מ   ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? ' Invoice Type' : (curentLang === 'ar' ? 'نوع الفاتورة' : '    סוג מסמך     ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? ' Document Status' : (curentLang === 'ar' ? 'حالة المستند  ' : 'מצב מסמך')}}</th>

                 <th scope="col"> {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '   تأكيد' : '    אישור   ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  עריכה ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'View  ' : (curentLang === 'ar' ? ' معاينة المستند  ' : 'הצגה ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of invoicesDataFilters | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} | filter: term; let i = index"
                [ngClass]="{'table-danger': selectedInvoiceUuid === item.invoice_uuid }"
                 >
                  <td>{{item.confirmation_number}}</td>
                  <td>{{item.document_number}}</td>
                  <td>{{item.invoice_reference_number}}</td>
                  <td>{{ item.invoice_date | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ curentLang === 'en' ? item.company?.company_name_en.substring(0, 10) : (curentLang === 'ar' ? item.company?.company_name_ar.substring(0, 10) : item.company?.company_name_he.substring(0, 10)) }}</td>
                  <td>{{ curentLang === 'en' ? item?.customer_name.substring(0, 10) : (curentLang === 'ar' ? item?.customer_name.substring(0, 10) : item?.customer_name.substring(0, 10)) }}</td>
                  <td>{{ item.amount_before_discount }}</td>
                  <td>{{ item.payment_amount }}</td>
                  <td>{{ item.all_vat_amount }}</td>
                  <td *ngIf=" item?.invoice_type == 305 "><span>{{curentLang === 'en' ? 'Tax Invoice' : (curentLang === 'ar' ? 'فاتورة ضريبية' : ' חשבונית מס') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 320 "><span>{{curentLang === 'en' ? 'Invoice With Receipt' : (curentLang === 'ar' ? 'فاتورة مع ايصال' : ' חשבונית עם חשבונית') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 332 "><span>{{curentLang === 'en' ? ' Financial Request' : (curentLang === 'ar' ? 'مطالبة مالية' : ' בקשה פינאלית') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 400 "><span>{{curentLang === 'en' ? 'Receipt' : (curentLang === 'ar' ? ' ايصال' : '  חשבונית') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 330 "><span>{{curentLang === 'en' ? 'Reverce Invoice' : (curentLang === 'ar' ? ' فاتورة عكسية' : '  חשבונית עם חשבונית') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 100 "><span>{{curentLang === 'en' ? 'order ' : (curentLang === 'ar' ? ' طلبية  ' : '     לְהַזמִין   ') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 200 "><span>{{curentLang === 'en' ? 'mission ' : (curentLang === 'ar' ? ' ارسالية  ' : 'תעודת משלוח') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 000 "><span>{{curentLang === 'en' ? 'Offer Price ' : (curentLang === 'ar' ? ' عرض سعر  ' : ' מחיר הצעה') }}</span></td>

                  <td *ngIf=" item?.is_deleted == 1 "><span style="color: red;">{{curentLang === 'en' ? 'Cancelled    ' : (curentLang === 'ar' ? '  ملغي' : ' מסמך בוטל  ') }}</span></td>
                  <td *ngIf=" item?.is_deleted == 0 "><span >{{curentLang === 'en' ? 'Not cancelled' : (curentLang === 'ar' ? '  مؤكد ' : 'סופי') }}</span></td>

                  <td *ngIf="item.confirm == 0">
                    <button [disabled]="user_type == 200" style="background-color: #213FA6; color: white;" (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCenteredConfirmed(dataConfirm)" class="btn btn-xs">
                      <i class="fa fa-check-circle-o text-white mx-1"></i> {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    </button>
                  </td>

                  <td *ngIf="item.confirm == 1">
                    <span class="text-success" style="font-weight: 700;" ><i class="fa fa-check-circle" ></i></span>
                  </td>

                  <td *ngIf="item.confirm == 0">
                    <i [hidden]="user_type == 200" class="fa fa-edit" style="cursor: pointer; color: green;" routerLink="/dashboard/update-mission/{{item.invoice_uuid}}"></i>
                    <i [hidden]="user_type == 100" class="fa fa-close" style="color: grey;"></i>
                  </td>

                  <td *ngIf="item.confirm == 1">
                    <i class="fa fa-close" style="color: grey;"></i>
                  </td>

                  <td>
                    <i class="fa fa-file-pdf-o"   *ngIf="item.document_source != 20"
                     [ngStyle]="{'pointer-events': item.confirm == 0 ? 'none' : 'auto', 'opacity': item.confirm == 0 ? 0.6 : 1}" style="color: grey; font-size: 15px; cursor: pointer; color: #213FA6;"
                     (click)="executeFunctions(item.invoice_uuid );openPdfModal(item , content4)"  ></i>

                     <i *ngIf="item.document_source == 20" 
                     class="fa fa-close"
                     style="color: gray; font-size: 15px;">
                  </i>
                  </td>

                  <td *ngIf="item.confirm == 0">
                    <button [disabled]="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'" 
                            [ngStyle]="{'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red'}" 
                            class="btn" style="border: none;" 
                            (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" [ngStyle]="{'font-size': '15px', 'cursor': 'pointer', 'color': (user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted') ? 'gray' : 'red'}"></i>
                    </button>
                  </td>
                  
                  <td *ngIf="item.confirm != 0">
                    <button *ngIf="item.confirm == 1 && !(item.is_deleted == 1)" 
                            [disabled]="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'" 
                            [ngStyle]="{'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red'}" 
                            class="btn" style="border: none;" 
                            (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCenteredReverse(reverseInvoice)">
                      <i class="fa fa-trash-o" [ngStyle]="{'font-size': '15px', 'cursor': 'pointer', 'color': (user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted') ? 'gray' : 'red'}"></i>
                    </button>
                    
                    <button *ngIf="item.is_deleted == 1 || !item.confirm == 1" 
                            [disabled]="true"  
                            class="btn" style="border: none;">
                      <i class="fa fa-close" style="color: gray;"></i>
                    </button>
                  </td>
                  
                  

                  
                </tr>
              </tbody>
              
            </table>



<!-- invoices Info on phone -->
 

  <div class="container-fluid basic_table d-lg-none my-2" >

    <div class="row">
<div class="col-sm-12">
   
    <div class="card"  id="headingTwo"
     *ngFor="let item of invoicesDataFilters  | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} |filter:term ; let i = index ">
    
            <div   
            
            (click)="toggleCollapse(i)" 
            [attr.aria-expanded]="openedIndex === i" 
            aria-controls="collapse"
            style="cursor: pointer; background-color: #F6F6F9;" 
            class="py-1">

            <h6>
              <i class="fa fa-file mx-1"  style="color: #000; font-size: 15px;" > </i>
              <span style="color: #000; font-size: 15px;" class="mx-2">
                {{ curentLang === 'en' ? 'Mission Number' : (curentLang === 'ar' ? '   رقم الارسالية ' : 'מס׳  ')}} :
              </span>
              <span style="color: #000; font-size: 15px;" class="mx-2">{{item.document_number}}</span>
            </h6>

              <h6>
                <i class="icofont icofont-businessman mx-1"  style="color: #000; font-size: 15px;" > </i>
                <span style="color: #000; font-size: 15px;" class="mx-2">
                  {{ curentLang === 'en' ? 'Customer Name ' : (curentLang === 'ar' ? 'أسم الزبون ' : 'שם הלקוח')}} :
                </span>
                <span style="color: #000; font-size: 15px;" class="mx-2">{{item.customer_name}}</span>
              </h6>

            </div>
      
             
            <div class="row d-flex justify-content-between">

              <div class="col-md-12  col-sm-12 ">
                <div class="row collapse   show" id="collapseTwo" [ngbCollapse]="openedIndex !== i">

               
                <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'Confirmation Number' : (curentLang === 'ar' ? '  رقم التأكيد  ' : '      מס׳ אישור     ')}} 
                  </span>
                    <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>
                    <span class="col-sm-4   text-end ">
                      <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item.confirmation_number}}</span>
                    </span>
                 </div>
                 <hr style="border: solid 1px #141414;" >
                 <!---->
      
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? '   رقم المستند ' : 'מס׳  ')}}
                  </span>

                  <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>

                  <span class="col-sm-4   text-end ">
                    <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item.document_number}}</span>
                  </span>
                  
                     
     
                 </div>
                 <hr style="border: solid 1px #141414;" >

                 <!---->
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                    {{ curentLang === 'en' ? 'mission Reference Number' : (curentLang === 'ar' ? '    رقم مرجع الارسالية  ' : '   תעודת משלוח מספר      ')}}
                  </span>
                  
                  <div class="text-center col-sm-4">  </div>

                    <span style="color: #141414;   " class="col-sm-4   text-end "  >
                      <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item.invoice_reference_number}}</span>
                  </span>

                
                 </div>
                 <hr style="border: solid 1px #141414;" >

                   <!---->

                   <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                      {{ curentLang === 'en' ? 'Mission Date' : (curentLang === 'ar' ? '  تاريخ الارسالية ' : 'תאריך  ')}}

                    </span>
                    
                    <div class="text-center col-sm-4">  </div>
  
                      <span style="color: #141414;   " class="col-sm-4   text-end "  >
                        <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > {{ item.invoice_date | date: 'dd/MM/yyyy' }}</span>
                    </span>
  
                  
                   </div>
                   <hr style="border: solid 1px #141414;" >
                    <!---->

                    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                        {{ curentLang === 'en' ? 'Company Name  ' : (curentLang === 'ar' ? ' أسم الشركة ' : 'שם החברה   ')}}  
                      </span>
                      
                      <div class="text-center col-sm-4">  </div>
    
                        <span style="color: #141414;   " class="col-sm-4   text-end "  >
                          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > 
                            {{ curentLang === 'en' ? item.company?.company_name_en.substring(0, 10) : (curentLang === 'ar' ? item.company?.company_name_ar.substring(0, 10) : item.company?.company_name_he.substring(0, 10)) }}
                          </span>
                      </span>
    
                    
                     </div>
         
                   <hr style="border: solid 1px #141414;" >
                    <!---->

                    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                        {{ curentLang === 'en' ? 'Customer Name  ' : (curentLang === 'ar' ? '  أسم العميل   ' : '   שם')}}                
                      </span>
                      
                      <div class="text-center col-sm-4">  </div>
                
                        <span style="color: #141414;   " class="col-sm-4   text-end "  >
                          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ curentLang === 'en' ? item?.customer_name.substring(0, 10) : (curentLang === 'ar' ? item?.customer_name.substring(0, 10) : item?.customer_name.substring(0, 10)) }}</span>
                      </span>
                
                    
                     </div>
                 <hr style="border: solid 1px #141414;" >
                    <!---->

    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
        {{ curentLang === 'en' ? 'Amount Before Discount  ' : (curentLang === 'ar' ? ' المبلغ قبل الخصم   ' : 'מחיר לפני הנחה ')}}
      </span>
      
      <div class="text-center col-sm-4">  </div>

        <span style="color: #141414;   " class="col-sm-4   text-end "  >
          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > {{ item.amount_before_discount }}</span>
      </span>

    
     </div>
    
   <hr style="border: solid 1px #141414;" >

       <!---->

       <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

        <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
          {{ curentLang === 'en' ? ' Payment Amount  ' : (curentLang === 'ar' ? 'المبلغ المدفوع  ' : '    סה"כ    ')}}  
        </span>
        
        <div class="text-center col-sm-4">  </div>
        <span style="color: #141414;   " class="col-sm-4   text-end "  >
          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ item.payment_amount }}</span>
      </span>
  
      
       </div>
     <hr style="border: solid 1px #141414;" >
      <!---->

        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? ' Vat Amount ' : (curentLang === 'ar' ? ' قيمة الضريبة  ' : '    מע"מ       ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          <span style="color: #141414;   " class="col-sm-4   text-end "  >
            <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ item.all_vat_amount }}</span>
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->


        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? ' Invoice Type' : (curentLang === 'ar' ? 'نوع الفاتورة' : '     סוג מסמך      ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          <span style="color: #141414;   " class="col-sm-4   text-end "  >
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 305 "><span>{{curentLang === 'en' ? 'Tax Invoice' : (curentLang === 'ar' ? 'فاتورة ضريبية' : ' חשבונית מס') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 320 "><span>{{curentLang === 'en' ? 'Invoice With Receipt' : (curentLang === 'ar' ? 'فاتورة مع ايصال' : ' חשבונית עם חשבונית') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 332 "><span>{{curentLang === 'en' ? ' Financial Request' : (curentLang === 'ar' ? 'مطالبة مالية' : ' בקשה פינאלית') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 400 "><span>{{curentLang === 'en' ? 'Receipt' : (curentLang === 'ar' ? ' ايصال' : '  חשבונית') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 330 "><span>{{curentLang === 'en' ? 'Reverce Invoice' : (curentLang === 'ar' ? ' فاتورة عكسية' : '  חשבונית עם חשבונית') }}</span></span>
             <span class="  px-5 " *ngIf=" item?.invoice_type == 100 "><span>{{curentLang === 'en' ? 'order ' : (curentLang === 'ar' ? ' طلبية  ' : '     לְהַזמִין   ') }}</span></span>
            <span class="  px-5 " *ngIf=" item?.invoice_type == 200 "><span>{{curentLang === 'en' ? 'mission ' : (curentLang === 'ar' ? ' ارسالية  ' : 'תעודת משלוח') }}</span></span>
            <span class="  px-5 " *ngIf=" item?.invoice_type == 000 "><span>{{curentLang === 'en' ? 'Offer Price ' : (curentLang === 'ar' ? ' عرض سعر  ' : ' מחיר הצעה') }}</span></span>

        </span>
    
        
         </div>
         <hr style="border: solid 1px #141414;" >
           <!---->


        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? ' Document Status' : (curentLang === 'ar' ? 'حالة المستند  ' : 'מצב מסמך')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          <span style="color: #141414;   " class="col-sm-4   text-end "  >
            <span class="  px-5 "  *ngIf=" item?.is_deleted == 1 "><span style="color: red;">{{curentLang === 'en' ? 'Cancelled    ' : (curentLang === 'ar' ? '  ملغي' : ' מסמך בוטל  ') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.is_deleted == 0 "><span >{{curentLang === 'en' ? 'Not cancelled' : (curentLang === 'ar' ? '  مؤكد ' : 'סופי') }}</span></span>
   
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->


        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '   تأكيد' : '    אישור   ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          <span style="color: #141414;   " class="col-sm-4    text-end "  >
             <span *ngIf="item.confirm == 0" class="px-5">
                    <button [disabled]="user_type == 200" style="background-color: #213FA6; color: white;" (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCenteredConfirmed(dataConfirm)" class="btn btn-xs mb-3">
                      <i class="fa fa-check-circle-o text-white mx-1"></i> {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    </button>
                  </span>
                  
                  <span *ngIf="item.confirm == 1" class="px-5">
                    <span class="text-success" style="font-weight: 700;"><i class="fa fa-check-circle"></i></span>
                  </span>
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->


        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  עריכה ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          
          <span style="color: #141414;   " class="col-sm-4    text-end "  >
            <span *ngIf="item.confirm == 0" class="px-5">
              <i [hidden]="user_type == 200" class="fa fa-edit" style="cursor: pointer; color: green;" routerLink="/dashboard/update-mission/{{item.invoice_uuid}}"></i>
              <i [hidden]="user_type == 100" class="fa fa-close" style="color: grey;"></i>
            </span>

            <span *ngIf="item.confirm == 1"class="px-5">
              <i class="fa fa-close" style="color: grey;"></i>
            </span>
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->

        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'View  ' : (curentLang === 'ar' ? ' معاينة المستند  ' : 'הצגה ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          
          <span style="color: #141414;   " class="col-sm-4    text-end "  >
            <span class="px-5" >
              <i class="fa fa-file-pdf-o" 
               *ngIf="item.document_source != 20"
              (click)="executeFunctions(item.invoice_uuid );openPdfModal(item , content4)"  style="cursor: pointer;"
              [ngStyle]="{ 'pointer-events': item.confirm == 0 ? 'none' : 'auto', 'opacity': item.confirm == 0 ? 0.6 : 1 }" 
               ></i>

               <i *ngIf="item.document_source == 20" 
               class="fa fa-close"
               style="color: gray; font-size: 15px;">
            </i>
            </span >
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->

        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}

          </span>
          
          <div class="text-center col-sm-4">  </div>
          
          <span style="color: #141414;" class="col-sm-4 text-end">
            <!-- زر الحذف عند item.confirm == 0 -->
            <span *ngIf="item.confirm == 0">
              <button
                [disabled]="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'"
                [ngStyle]="{
                  'pointer-events': item.customer_status == 1 ? 'none' : 'auto',
                  color: item.customer_status == 1 ? 'gray' : 'red'
                }"
                class="btn px-5"
                style="border: none;"
                (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCentered(dataDeleted)"
              >
                <i
                  *ngIf="!(user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted')"
                  class="fa fa-trash-o"
                  [ngStyle]="{
                    'font-size': '15px',
                    cursor: 'pointer',
                    color: 'red'
                  }"
                ></i>
                <i
                  *ngIf="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'"
                  class="fa fa-close"
                  [ngStyle]="{
                    'font-size': '15px',
                    color: 'gray'
                  }"
                ></i>
              </button>
            </span>
          
            <!-- زر الحذف عند item.confirm == 1 -->
            <span *ngIf="item.confirm == 1 && item.is_deleted != 1">
              <button
                [disabled]="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'"
                [ngStyle]="{
                  'pointer-events': item.customer_status == 1 ? 'none' : 'auto',
                  color: item.customer_status == 1 ? 'gray' : 'red'
                }"
                class="btn px-5"
                style="border: none;"
                (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCenteredReverse(reverseInvoice)"
              >
                <i
                  *ngIf="!(user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted')"
                  class="fa fa-trash-o"
                  [ngStyle]="{
                    'font-size': '15px',
                    cursor: 'pointer',
                    color: 'red'
                  }"
                ></i>
                <i
                  *ngIf="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'"
                  class="fa fa-close"
                  [ngStyle]="{
                    'font-size': '15px',
                    color: 'gray'
                  }"
                ></i>
              </button>
            </span>
          </span>
          
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->


                </div>
              </div>

            </div>
            <hr style="color: #141414; border: solid 1px;">
    </div>

 


  </div>

    </div>
</div>










            <div *ngIf="invoicesDataFilters.length == 0" class="alert text-danger mt-3">
              <span> {{ curentLang === 'en' ? 'There are no mission for this company yet... ' : (curentLang === 'ar' ? ' لا توجد ارساليات لهذة الشركة حتي الأن ... ' : '   אין עדיין משלוחים לחברה זו... ')}}</span>
            </div>
            
            <div class="pagination-container">
              <pagination-controls 
                (pageChange)="onPageChange($event)" 
                (totalItems)="total" 
                (currentPage)="currentPage">
              </pagination-controls>

              <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange()">

            </div>
          
         
          </div>

          <div class=" d-flex justify-content-between"> 
            <div></div>

           <div *ngIf="user_type == 100 || user_type == 101">
            <a routerLink="/dashboard/merchant-info">
                <button class="btn btn-danger m-2 " *ngIf="!isInsideModal">
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

           <div *ngIf="user_type == 200 || user_type == 201">
            <a routerLink="/dashboard/users">
                <button class="btn btn-danger m-2 " >
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

          </div>

        </div>
      </div>
    </div>
  </div>

    </div>
</div>





     <!--Delete invoice-->

     <ng-template #dataDeleted let-modal>
      <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close btn-close"  style="color: grey;"  aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
          <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
            {{curentLang === 'en' ? 'Are you sure you want to delete this mission?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذف هذة الارسالية ؟' : '  האם אתה בטוח שברצונך למחוק הודעה זו ?   ') }}
          </p>
      </div>
      <div class="modal-footer">

        <button type="button" 
         class="btn btn-sm m-auto mt-2" 
        style="background-color: #141414; color: #fff;"
         (click)="deleteInvoiceById()"
         > 
          {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
      </button>
  
          <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
          </button>
  
        
  
      </div>
  </ng-template>


   <!--Reverse Invoice-->

<ng-template #reverseInvoice let-modal>
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close"  style="color: grey;"  aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-center text-danger">
      <i class="fa fa-trash text-danger"></i>
      {{curentLang === 'en' ? 'Sorry: You cannot delete a confirmed mission. Do you want to convert this mission to a canceled document ?' : (curentLang === 'ar' ? 'عذرا: لا يمكنك حذف ارسالية مؤكدة, هل تريد تحويل هذه الارسالية إلى مستند ملغي ؟' : 'מצטער: אינך יכול למחוק הודעה מאושרת. האם אתה רוצה להמיר הודעה זו למסמך שבוטל ?')}}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm m-auto mt-2" style="background-color: #141414; color: #fff;" (click)="checkAndConvertToReverse(invoiceId, companyId)">
      {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
      <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>
    <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
      <i class="fa fa-rotate-left mx-1"></i>
    </button>
  </div>
</ng-template>


  



              <!--confirm-->

              <ng-template #dataConfirm let-modal>
                <div class="modal-header">
                <h4 class="modal-title"></h4>
                <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                  <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
                    {{curentLang === 'en' ? 'Are you sure you want to confirm this mission?' : (curentLang === 'ar' ? '  هل تريد تأكيد هذةالارسالية؟' : ' האם ברצונך לאשר הודעה זו?') }}
                  </p>
                </div>
              
                <div class="modal-footer">

                  <button type="button" 
                   class="btn btn-sm m-auto mt-2" 
                  style="background-color: #141414; color: #fff;"
                   (click)="confirmInvoice()"
                   > 
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                </button>
            
                    <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                        <i class="fa fa-rotate-left mx-1"></i>
                    </button>
            
                  
            
                </div>


              </ng-template>





              <ng-template #content4 let-modal>
                <div class="modal-header d-flex justify-content-between">
                  <h6 class="modal-title"style="color: grey;">
                    {{curentLangDocument === 'en' ? 'Mission Details' : (curentLangDocument === 'ar' ? 'تفاصيل الارسالية ' : ' פירוט תעודת משלוח    ')}} 
                  </h6>
                  <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-4 mt-4">
                        <button type="button" (click)="downloadDocumentPdf(); modal.close('Close click')" class="btn btn-sm mx-4 mt-4"
                          style="background-color: #141414; color: #fff;">
                          <i class="fa fa-file-pdf-o mx-1"></i>
                          {{ curentLang === 'en' ? 'Print' : (curentLang === 'ar' ? 'طباعة نسخة' : 'הפקת העתק') }}
                        </button>
                      </div>
              
                      <div class="col-md-4 mt-4">
                        <div class="dropdown mx-4 mt-4">
                          <button class="btn dropdown-toggle w-100" type="button" id="shareInvoiceDropdown" data-bs-toggle="dropdown"
                            aria-expanded="false" style="background-color: #141414; color: #fff;">
                            {{ curentLang === 'en' ? 'Share Original Version' : (curentLang === 'ar' ? 'مشاركة النسخة الأصلية' : 'שתף את המקור') }}
                            <i class="fa fa-share-alt mx-1 text-white" style="font-size: 15px;"></i>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="shareInvoiceDropdown">
                            <li style="border-bottom: 1px solid #000;">
                              <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" (click)="copyLinkToClipboard(rawPdfUrl)">
                                <i class="fa fa-copy mx-1" style="font-size: 15px; color: rgb(158, 40, 40);"></i>
                                {{ curentLang === 'en' ? 'Copy Link' : (curentLang === 'ar' ? 'نسخ الرابط' : 'העתק קישור') }}
                              </a>
                            </li>
                            <li style="border-bottom: 1px solid #000;">
                              <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" href="https://wa.me/?text={{ rawPdfUrl }}" target="_blank">
                                  <i class="fa fa-whatsapp mx-1" style="font-size: 15px; color: rgb(32, 92, 32);"></i>
                                  {{ curentLang === 'en' ? 'Share via WhatsApp' : (curentLang === 'ar' ? 'مشاركة عبر واتساب' : 'שתף בוואטסאפ') }}
                              </a>
                          </li>
                            <li>
                              <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" href="mailto:?subject=Invoice&body={{ rawPdfUrl }}">
                                <i class="fa fa-envelope mx-1" style="font-size: 15px; color: rgb(137, 137, 16);"></i>
                                {{ curentLang === 'en' ? 'Share via Email' : (curentLang === 'ar' ? 'مشاركة عبر البريد الإلكتروني' : 'שתף באימייל') }}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
              
                      <div class="col-md-4 my-2">
                        <div class="form-group mx-4">
                          <label class="mt-2" style="color: #141414;">
                            {{ curentLang === 'en' ? 'Change Document Language' : (curentLang === 'ar' ? 'تغيير لغة المستند' : 'שינוי שפת המסמך') }}
                          </label>
                          <select class="form-control" (change)="filterDocumentLanguage($event.target.value)">
                            <option *ngFor="let lang of availableLanguages" [value]="lang.code">
                              {{ lang.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
              
                    <div class="col-md-12">
                      <!-- عرض رسالة جاري تجهيز الملف أثناء عرض الهيكل العظمي -->
                      <div *ngIf="isSkelton">
                        <p class="text-center" style="color: grey;">{{ curentLang === 'en' ? 'The file is being prepared...' : (curentLang === 'ar' ? 'جاري تجهيز الملف' : 'הקובץ בהכנה') }}</p>
                        <div class="skeleton-loader">
                          <div class="border-round border-1 surface-border p-4 surface-card">
                            <div class="flex mb-3">
                              <div>
                                <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
                                <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
                                <p-skeleton height=".5rem"></p-skeleton>
                              </div>
                            </div>
                            <p-skeleton width="100%" height="150px"></p-skeleton>
                            <div class="flex justify-content-between mt-3">
                              <p-skeleton width="4rem" height="2rem"></p-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
              
                      <!-- عرض الـ PDF عند انتهاء الهيكل العظمي -->
                      <iframe *ngIf="!isSkelton && pdfUrl" [src]="pdfUrl" width="100%" height="500px" frameborder="0"></iframe>
                    </div>
                  </div>
              
                  <div class="modal-footer my-2">
                    <button type="button" class="btn btn-sm mt-2 btn-danger" (click)="modal.close('Close click')">
                      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                      <i class="fa fa-rotate-left mx-1"></i>
                    </button>
                  </div>
                </div>
              </ng-template>