import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { baseUrl } from '../service/global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-upload-permanent-file',
  templateUrl: './upload-permanent-file.component.html',
  styleUrls: ['./upload-permanent-file.component.scss']
})
export class UploadPermanentFileComponent implements OnInit {
  file: File | null = null;
  fileName: string = '';
  reviewReport: any = null;
  report: any = null;
  confirmationResponse: any[] = [];
  errorMessage: string | null = null;
  activeTab: string = 'rawDataReview';
  confirmingUpload: boolean = false;
  fileStatusList: any[] = [];
  uploadConfirmed: boolean = false;
  curentLang: string;
  multipleFiles: File[] = [];
  multipleFileStatusList: any[] = [];
  uploadResponseMessage: string | null = null;
  allowedFileTypes = ['application/zip', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']; // أنواع الملفات المسموحة
  fileLimitExceeded: boolean = false; // متغير لتحديد إذا كان الحد الأقصى قد تم تجاوزه
  maxFileLimit: number = 50; // الحد الأقصى لعدد الملفات المسموح بها
  fileQueue: string[] = [];  // قائمة الملفات في قائمة الانتظار
  showFileQueue: boolean = false;  // متغير للتحكم بعرض وإخفاء قائمة الانتظار
  isRunning: boolean = false; // لتتبع حالة الفانكشن
  responseMessage: string | null = null; // لتخزين رسالة الاستجابة
  checkInterval: any; // لمراقبة الحالة بشكل دوري
  isFromModal: boolean = false;  // متغير لمعرفة إذا كان الرد أتى من المودال
  deleteResponseMessage: string | null = null; // لتخزين رسالة استجابة الحذف
  deleteSuccess: boolean = true; // لتحديد نجاح عملية الحذف

  constructor(private http: HttpClient, private spinner: NgxSpinnerService, private modalService: NgbModal,) {
    this.curentLang = localStorage.getItem('curentLang');
  }

  ngOnInit(): void {
    this.checkRunningState();
    this.checkInterval = setInterval(() => {
      this.checkRunningState();
    }, 60000); // التحقق كل 60 ثانية (1 دقيقة)
  }

  addToQueue() {
    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file);

      const fileStatus = {
        fileName: this.file.name,
        loading: true,
        success: false,
        error: false,
        response: null
      };

      this.fileStatusList.push(fileStatus);
      this.http.post(`${baseUrl}zip/addToQueue`, formData).subscribe(
        (response: any) => {
          fileStatus.loading = false;
          if (response.status === 200) {
            fileStatus.success = true;
            fileStatus.response = 'File added to queue successfully.';
            // تصفير الريسبونس الخاص بالمراجعة
            this.reviewReport = null;
            this.report = null;
            this.errorMessage = null;
            this.uploadConfirmed = false; // إذا كان هذا يؤثر على واجهة المستخدم أيضًا
          } else {
            fileStatus.error = true;
            fileStatus.response = response.message;
          }
        },
        error => {
          fileStatus.loading = false;
          fileStatus.error = true;
          fileStatus.response = 'An error occurred while adding file to queue.';
        }
      );
    }
  }



  onFileSelected(event: any) {
    this.file = event.target.files[0];
    this.fileName = this.file ? this.file.name : '';
    this.uploadConfirmed = false;
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  onSubmit() {
    this.isFromModal = false;
    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file);

      // Reset previous data
      this.reviewReport = null;
      this.report = null;
      this.errorMessage = null;
      this.confirmationResponse = [];
      this.confirmingUpload = false;
      this.spinner.show();

      // Call the review API
      this.http.post(`${baseUrl}zip/TestReviewZipFile`, formData).subscribe(
        (response: any) => {
          this.spinner.hide();
          if (response.status === 200) {
            this.reviewReport = response.rawDataReport;
            this.report = response.report;
          } else {
            this.errorMessage = response.message;
          }
        },
        error => {
          this.spinner.hide();
          this.errorMessage = 'An error occurred during file review.';
        }
      );
    }
  }

  confirmUpload() {
    this.isFromModal = false;
    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file);

      const fileStatus = {
        fileName: this.file.name,
        loading: true,
        success: false,
        error: false,
        response: null
      };

      this.fileStatusList.push(fileStatus);

      // Call the save API
      this.http.post(`${baseUrl}zip/saveFileAfterReview`, formData).subscribe(
        (response: any) => {
          fileStatus.loading = false;
          if (response.status === 200) {
            fileStatus.success = true;
            fileStatus.response = response;
            this.confirmationResponse.push({ fileName: fileStatus.fileName, savedInvoices: response.savedInvoices });
            this.uploadConfirmed = true;
          } else {
            fileStatus.error = true;
            fileStatus.response = response.message;
          }
        },
        error => {
          fileStatus.loading = false;
          fileStatus.error = true;
          fileStatus.response = 'An error occurred during file saving.';
        }
      );
    }
  }



  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  getInvoiceTypesTotal(): { totalCount: number, totalAmount: number } {
    let totalCount = 0;
    let totalAmount = 0;
    if (this.report && this.report.invoiceTypes) {
      for (let invoiceType of this.objectKeys(this.report.invoiceTypes)) {
        totalCount += this.report.invoiceTypes[invoiceType].count;
        totalAmount += this.report.invoiceTypes[invoiceType].totalAmount;
      }
    }
    return { totalCount, totalAmount };
  }

  toggleOpen(fileResponse: any) {
    fileResponse.isOpen = !fileResponse.isOpen;
  }





  onMultipleFilesSelected(event: any) {
    const selectedFiles: File[] = Array.from(event.target.files) as File[]; // تحديد النوع بشكل صريح

    // التحقق من عدد الملفات
    if (selectedFiles.length > this.maxFileLimit) {
      this.fileLimitExceeded = true; // إذا تجاوز عدد الملفات الحد الأقصى
      this.uploadResponseMessage = null; // مسح رسالة النجاح
      this.multipleFiles = []; // مسح الملفات المختارة
      (document.getElementById('multiFiles') as HTMLInputElement).value = ''; // مسح الملفات المختارة من الحقل
    } else {
      this.fileLimitExceeded = false; // إذا لم يتجاوز الحد الأقصى
      this.multipleFiles = selectedFiles; // حفظ الملفات الصحيحة فقط
      this.uploadResponseMessage = null; // مسح رسالة الخطأ في حالة رفع ملفات صحيحة
    }
  }

  // رفع الملفات
  onSubmitMultiple() {
    if (this.multipleFiles.length > 0) {
      const formData = new FormData();
      this.multipleFiles.forEach(file => {
        formData.append('files', file);
      });

      this.spinner.show();
      this.uploadResponseMessage = null; // إعادة تعيين رسالة الاستجابة

      this.http.post(`${baseUrl}zip/multiple`, formData).subscribe(
        (response: any) => {
          this.spinner.hide();
          this.uploadResponseMessage = `Successfully uploaded ${this.multipleFiles.length} files.`; // عرض رسالة النجاح
          this.multipleFiles = []; // مسح الملفات المختارة
          (document.getElementById('multiFiles') as HTMLInputElement).value = ''; // مسح الملفات من الفورم
        },
        error => {
          this.spinner.hide();
          this.errorMessage = 'An error occurred during the upload process.'; // عرض رسالة الخطأ في حالة الفشل
        }
      );
    }
  }

  openXl(content: any) {
    this.modalService.open(content, { size: 'xl', centered: true });
  };
  openFileQueueModal(content: any) {
    this.http.get(`${baseUrl}zip/getUploadedFiles`).subscribe(
      (response: any) => {
        this.fileQueue = response.files; // حفظ الملفات في المتغير fileQueue
        this.modalService.open(content, { size: 'lg', centered: true }); // فتح المودال
      },
      error => {
        console.error('Error fetching file queue:', error); // التعامل مع الأخطاء
      }
    );
  }

  deleteFile(fileName: string) {
    this.http.delete(`${baseUrl}zip/${fileName}`).subscribe(
      () => {
        // بعد الحذف من الخادم، إزالة الملف من القائمة المحلية
        this.fileQueue = this.fileQueue.filter(file => file !== fileName);
       },
      error => {
        console.error(`Error deleting file ${fileName}:`, error); // التعامل مع الأخطاء
      }
    );
  }
  ngOnDestroy(): void {
    clearInterval(this.checkInterval);
  }

  checkRunningState() {
    this.http.get(`${baseUrl}zip/checkStatus`).subscribe(
      (response: any) => {
        this.isRunning = response.isRunning;
      },
      (error) => {
        console.error('Failed to check status:', error);
      }
    );
  }

  runManualJobs() {
    this.isRunning = true;
    this.http.get(`${baseUrl}zip/run-jobs-manually`)
      .subscribe(
        (response: any) => {
          this.isRunning = false;
          this.responseMessage = response.message;
        },
        (error) => {
          this.isRunning = false;
          this.responseMessage = 'Error executing jobs.';
        }
      );
  }


  reviewFile(fileName: string, modal: any) {
    this.isFromModal = true; // نحدد أن الاستدعاء من المودال

    // نقوم هنا بعمل المراجعة باستخدام اسم الملف
    this.http.get(`${baseUrl}zip/review-file/${fileName}`).subscribe(
      (response: any) => {
        this.spinner.hide(); // إخفاء سبينر بعد اكتمال المعالجة
        if (response.status === 200) {
          this.reviewReport = response.rawDataReport;
          this.report = response.report;
          this.report.fileName = fileName; // حفظ اسم الملف في التقرير
          this.errorMessage = null; // مسح أي رسالة خطأ سابقة
          this.isFromModal = true; // تحديد أن الرد أتى من الـ modal

          // إغلاق الـ modal بعد استلام الرد بنجاح
          modal.dismiss();
        } else {
          this.errorMessage = response.message;
        }
      },
      error => {
        this.spinner.hide(); // إخفاء سبينر في حالة حدوث خطأ
        this.errorMessage = 'An error occurred during file review.';
      }
    );
  }


  // دالة لحذف الملف من قائمة الانتظار
  deleteFromQueue(fileName: string) {
    this.http.delete(`${baseUrl}zip/${fileName}`).subscribe(
      () => {
        // بعد الحذف من الخادم، إزالة الملف من القائمة المحلية
        this.fileQueue = this.fileQueue.filter(file => file !== fileName);
        this.deleteResponseMessage = `File ${fileName} deleted successfully.`;
        this.deleteSuccess = true; // تحديد أن الحذف كان ناجحًا

        // تصفير الريسبونس الخاص بالمراجعة
        this.reviewReport = null;
        this.report = null;
        this.errorMessage = null;
        this.uploadConfirmed = false; // إذا كان هذا يؤثر على واجهة المستخدم أيضًا
      },
      error => {
        this.deleteResponseMessage = `Error deleting file ${fileName}: ${error.message}`;
        this.deleteSuccess = false; // تحديد أن الحذف فشل
        console.error(`Error deleting file ${fileName}:`, error); // التعامل مع الأخطاء
      }
    );
  }



}
