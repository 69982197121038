 
 
 

<div class="notification-box mx-2 position-relative" (click)="toggleMessageBox()">
  <i class="fa fa-exclamation-triangle mt-1" style="font-size: 18px; color: #141414;"></i>
  
  <span class="badge rounded-pill notification-badge">
    {{ LockedMonthInvoicesData?.length }}
  </span>
</div>


<div class="onhover-show-div notification-dropdown" [class.active]="openMessageBox">
  <h6 class="f-18 mb-0 dropdown-title" style="color: #141414;">
    {{ curentLang === 'en' ? 'Warnings  ' : (curentLang === 'ar' ? ' التحذيرات' : '  אזהרות')}}
  </h6>
  <ul *ngFor="let item of LockedMonthInvoicesData.slice(0 , 3) ; let i = index">
    <p style="font-size: 12px; color: #141414;" class="mx-1">
      {{ curentLang === 'en' ? item.messageEn : (curentLang === 'ar' ? item.messageAr  : item.messageHe)}}
    </p>
    <li class="b-l-primary border-4 d-flex justify-content-between">
     
      <p style="font-size: 12px;" class="mx-1">
     
        <span class="mx-1">
          <a [href]="item.pdf_link" target="_blank" rel="noopener noreferrer">
            <button style=" background-color: black; color: white;"
           
            class="btn  btn-sm"  ><i class="fa fa-check-circle-o text-white "></i> {{ curentLang === 'en' ? 'View Invoice' : (curentLang === 'ar' ? 'عرض الفاتورة' : 'הצג חשבונית')}}
          </button>
          </a>
      
        </span> 

        <span class="mx-1">
          <button class="btn btn-sm" style="background-color: rgb(28, 80, 221); color: whitesmoke;"
          (click)="getId(item?.invoice_id);openVerticallyCenteredRejected(dataReject)">
           <i class="fa fa-times-circle " style="color: #fff;"></i>
          {{ curentLang === 'en' ?'Cancel warning' : (curentLang === 'ar' ? 'الغاء التحذير' : 'אזהרת ביטול')}}
          </button>   
        </span>

      </p>
    </li>
    
    
  </ul>
  <li class="text-center">
    <a class="f-w-700" style="color: black;" routerLink="/dashboard/all-warning" *ngIf="LockedMonthInvoicesData.length > 0">
      {{ curentLang === 'en' ? 'See All' : (curentLang === 'ar' ? 'عرض الجميع' : 'הצג הכל') }}
    </a>
    <span *ngIf="LockedMonthInvoicesData.length === 0" style="color: #141414;">
      {{ curentLang === 'en' ? 'No warnings yet' : (curentLang === 'ar' ? 'لا توجد تحذيرات حتى الآن' : "עדיין אין אזהרות") }}
    </span>
  </li>
   

  
</div>




   <!--reject-->

   <ng-template #dataReject let-modal>
    <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body text-center">
      <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
        {{curentLang === 'en' ? 'Are you sure you want to cancel the warning on this bill?' : (curentLang === 'ar' ? 'هل تريد بالتأكيد الغاء التحذير علي هذة الفاتورة ؟' : 'האם אתה בטוח שאתה רוצה לבטל את האזהרה על החשבון הזה?') }}
      </p>
    </div>
  
    <div class="modal-footer">

      <button type="button" 
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="cancleAert()"
       > 
       {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تأكيد' : 'כדי להיות בטוח')}}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>


  </ng-template>