<app-breadcrumb [title]="curentLang === 'en' ? 'Notifications Info' : (curentLang === 'ar' ? ' معلومات التحذيرات' : '   מידע על הודעה  ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['    לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Notifications Info' : (curentLang === 'ar' ? ' معلومات التحذيرات ' : '    מידע על הודעה  ')">
</app-breadcrumb>

<div class="row">
<div class="col-sm-12">
<div class="card">
  

    <div class="card-header card-no-border d-flex justify-content-between">
      <div class="header-top">
        <h5 class="m-0" style="color: black;">
            {{ curentLang === 'en' ? 'Warnings  ' : (curentLang === 'ar' ? ' التحذيرات' : '  אזהרות')}}
        </h5>
       
      </div>

      <div>

  

      </div>

    </div>
    <div class="card-body pt-0 campaign-table">
      <div class="recent-table table-responsive currency-table">
        <table class="table text-center table-hover">
          <thead style="background-color: grey; color: #141414;">
            <tr>
               <th scope="col">{{ curentLang === 'en' ? 'Details' : (curentLang === 'ar' ? ' التفاصيل   ' : 'הפרטים') }}</th>
              <th scope="col">{{ curentLang === 'en' ? 'View Invoice' : (curentLang === 'ar' ? 'عرض الفاتورة' : 'הצג חשבונית')}}</th>
              <th scope="col">  {{ curentLang === 'en' ?'Cancel warning' : (curentLang === 'ar' ? 'الغاء التحذير' : 'אזהרת ביטול')}}</th>
             </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of LockedMonthInvoicesData ; let i = index"
            [ngClass]="{'table-danger': selectedInvoiceUuid === item.invoice_uuid }">
                 
           
              <td> {{ curentLang === 'en' ? item.messageEn : (curentLang === 'ar' ? item.messageAr  : item.messageHe)}}</td>
                
              <td>
                <a *ngIf="item.pdf_link !=  null" [href]="item.pdf_link" target="_blank" rel="noopener noreferrer">
                    <button style=" background-color: #141414; color: white;"
                   
                    class="btn  btn-sm"  ><i class="fa fa-check-circle-o text-white "></i> {{ curentLang === 'en' ? 'View Invoice' : (curentLang === 'ar' ? 'عرض الفاتورة' : 'הצג חשבונית')}}
                  </button>
                  </a>

                  <a *ngIf="item.pdf_link === null"  >
                    <button style=" background-color: #141414; color: white;"
                    (click)="getId(item?.invoice_uuid);openInvoiceXl(content4)"
                    class="btn  btn-sm"  ><i class="fa fa-check-circle-o text-white "></i> {{ curentLang === 'en' ? 'View Invoice' : (curentLang === 'ar' ? 'عرض الفاتورة' : 'הצג חשבונית')}}
                  </button>
                  </a>

              </td>

              <td>
                <button class="btn btn-sm" style="background-color: rgb(28, 80, 221); color: whitesmoke;"
                (click)="getId(item?.invoice_id);openVerticallyCenteredRejected(dataReject)">
                 <i class="fa fa-times-circle " style="color: #fff;"></i>
                {{ curentLang === 'en' ?'Cancel warning' : (curentLang === 'ar' ? 'الغاء التحذير' : 'אזהרת ביטול')}}
                </button>       
             </td>
           
            </tr>
          </tbody>
        </table>

        <div class="pagination-container">
          <pagination-controls 
          (pageChange)="p = $event" class="m-auto my-3 current" 
          >
          </pagination-controls>
 
        </div>  


      </div>

      <div class=" d-flex justify-content-between"> 
        <div></div>

     

       <div *ngIf="user_type == 300 || user_type == 301">
        <a routerLink="/dashboard/office-companies">
            <button class="btn btn-danger m-2 " >
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
            </button>
          </a>
       </div>

      </div>

    </div>
  </div>

 

</div>
</div>




  <!--cancle-->

  <ng-template #dataReject let-modal>
    <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body text-center">
      <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
        {{curentLang === 'en' ? 'Are you sure you want to cancel the warning on this bill?' : (curentLang === 'ar' ? 'هل تريد بالتأكيد الغاء التحذير علي هذة الفاتورة ؟' : 'האם אתה בטוח שאתה רוצה לבטל את האזהרה על החשבון הזה?') }}
      </p>
    </div>
  
    <div class="modal-footer">

      <button type="button" 
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="cancleAert()"
       > 
       {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تأكيد' : 'כדי להיות בטוח')}}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>


  </ng-template>




  <!--view pdf -->
  <ng-template #content4 let-modal>
    <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
        {{curentLangDocument === 'en' ? 'Invoice Details' : (curentLangDocument === 'ar' ? 'تفاصيل الفاتورة ' : '  פרטי חשבונית   ')}} 
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container-fluid">
        <button type="button" (click)="downloadDocumentPdf();modal.close('Close click')" class="btn btn-sm my-3"
      style=" background-color: #141414; color: #fff;">
      <i class="fa fa-file-pdf-o mx-1"></i>
      {{ curentLang === 'en' ? ' Print ' : (curentLang === 'ar' ? 'طباعة نسخة  ' : 'הפקת העתק') }}
    </button>
  <div class="container" [hidden]="invoiceInfoData?.confirm == 0" >
      <div class="row m-auto">
          <div class="col-sm-12 ">
  
              <div class="page m-auto  invoice-content"  id="printDiv">
  
                  <div class="card-2" style="background-color: #fff; box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);">
  
                      <div class="row m-2 ">
                          <div class="col-lg-12">
                              <div class="invoice-inner" id="invoice_wrapper">
                                  <div class="invoice-top">
                                      <div class="row">
                                          <div class="col-sm-4">
                                              <div class="logo my-2"  *ngIf="invoiceInfoData?.logo" >
                                                  <img 
                                                      [src]="invoiceInfoData?.logo"
                                                       title="company logo"
                                                      style="width: 100px;height: 100px; border-radius: 5%;">
                                              </div>
                                          </div>
  
                                          <div [class.py-5]="invoiceInfoData?.logo" [class.py-2]="!invoiceInfoData?.logo" class="col-sm-4" *ngIf="user_type == 300 || user_type == 301">
                                              <h2 class="text-center water-mark">
                                                  {{ curentLangDocument === 'en' ? ' Accounting Copy' : (curentLangDocument === 'ar' ? 'نسخة المحاسب' : ' עותק רואה חשבון')}}
                                              </h2>
                                          </div>
  
                                          <div [class.py-5]="invoiceInfoData?.logo" [class.py-2]="!invoiceInfoData?.logo" class="col-sm-4" *ngIf="user_type == 200 || user_type == 201">
                                              <h2 class="text-center water-mark">{{invoiceInfoData?.copy_version}}</h2>
                                          </div>
                                          
                                          <div class="col-sm-4">
                                       
                                          </div>
                                      </div>
                                  </div>
                                  <div class="invoice-info py-2">
                                      <div class="row">
                                         
                                          <div class="col-sm-6 mb-30">
                                              <div class="invoice-number ">
                                                  <h4 class="inv-title-1" style="font-weight: bold; font-size: 14px;">{{ curentLangDocument === 'en' ? 'For ' : (curentLangDocument === 'ar' ? '   لحضرة' : 'לכבוד  ')}}</h4>
                                                  <div class="invo-addr-1">
                                                      <div style="font-weight: 400;">
                                                          <!-- <strong> {{ curentLangDocument === 'en' ? 'Customer Name' : (curentLangDocument === 'ar' ? 'اسم العميل' : 'שם הלקוח')}} / </strong> -->
                                                          <strong>{{ invoiceInfoData?.customer_name }}</strong>
                                                      </div>
                                                      
                                                      <div *ngIf="invoiceInfoData?.customer_address; else noAddress" style="font-weight: 300;" class="m-0">
                                                          <strong>{{ invoiceInfoData?.customer_address }}</strong>
                                                      </div>
                                                      
                                                      <ng-template #noAddress>
                                                          <div style="font-weight: 300;">
                                                              <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : 'מס עוסק מורשה    ')}} </strong>
                                                              <div style="font-weight: 300;">
                                                                  <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                              </div>
                                                          </div>
                                                      </ng-template>
                                                      
                                                      <div *ngIf="invoiceInfoData?.customer_address" style="font-weight: 300;">
                                                          <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : '    מס עוסק מורשה ')}} </strong>
                                                          <div style="font-weight: 300;">
                                                              <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                          </div>
                                                      </div>
                                                      
  
                                                      
                                                      <div style="font-weight: 300;">
                                                          <!-- <strong>{{ curentLangDocument === 'en' ? 'Customer Id Number' : (curentLangDocument === 'ar' ? '  رقم الهوية للعميل' : '  מספר זיהוי לקוח ')}} / </strong> -->
                                                          <strong>{{ invoiceInfoData?.customer_id_number }}</strong>
                                                      </div>
                                                      
                                                  </div>
                                              </div>
                                              
                                          </div>
  
                                          <div class="col-sm-6 mb-30">
                                              <div class="invoice-number text-end">
                                                  <!-- <h4 class="inv-title-1"> {{ curentLangDocument === 'en' ? 'Invoice From' : (curentLangDocument === 'ar' ? 'فاتورة من' : 'חשבונית מ')}} </h4> -->
                                                  <div class="invo-addr-1">
                                                      <div style="font-weight: bold;">
                                                          <!-- <strong>{{ curentLangDocument === 'en' ? 'Company Name' : (curentLangDocument === 'ar' ? 'اسم الشركة' : 'שם החברה')}} / </strong>  -->
                                                          <strong>{{ invoiceInfoData?.company_name }}</strong>
                                                      </div>
                                                      
                                                      <div style="font-weight: 300;">
                                                          <!-- <strong> {{ curentLangDocument === 'en' ? 'Company Address' : (curentLangDocument === 'ar' ? 'عنوان الشركة' : 'כתובת החברה')}} / </strong> -->
                                                          <strong>{{ invoiceInfoData?.company_address_str }} -</strong>
                                                          <strong>{{ invoiceInfoData?.company_Building_number }} -</strong>
  
                                                          
                                                          <strong>{{ invoiceInfoData?.company_address_city }}</strong>
                                                      </div>
                                                      
                                                    
                                                      <!-- <div style="font-weight: 400;">{{ invoiceInfoData?.company_address_zip_code }}</div> -->
                                                      <!-- <div style="font-weight: 300;">
                                                          <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? '  الرقم الضريبي للشركة' : 'מספר מס חברה')}} / </strong>
                                                          <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                      
                                                      </div> -->
  
                                                      <div  style="font-weight: 300;"> 
                                                          <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : '     מס עוסק מורשה     ')}}  </strong> 
                                                     </div>
                                                     <div  style="font-weight: 300;"> 
                                                     <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                     </div>
                                                    
  
                                                    
                                                      
                                                  </div>
                                              </div>
                                              
                                              
                                              
                                          </div>
                                      </div>
                                      <div class="row  ">
                                          <div class="col-sm-4"></div>
  
                                          
  
                                          <div class="invoice text-center   col-sm-4">
                                              <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Invoice Details' : (curentLangDocument === 'ar' ? 'تفاصيل الفاتورة' : ' פרטי החשבונית  ')}}</h4> -->
  
                                         
  
                                              <div class="mb-1"*ngIf="invoiceInfoData?.document_number !== null">
                                             <strong *ngIf="invoiceInfoData?.invoice_type == 305" style="font-weight: bold;" class="mx-1">
                                              {{curentLangDocument === 'en' ? 'Tax Invoice Number' : (curentLangDocument === 'ar' ? 'فاتورة ضريبية رقم' : '  חשבונית מס מספר       ') }}
                                            </strong> 
  
                                            <strong *ngIf="invoiceInfoData?.invoice_type == 320" style="font-weight: bold;" class="mx-1">
                                              {{curentLangDocument === 'en' ? 'Invoice With Receipt Number' : (curentLangDocument === 'ar' ? 'فاتورة مع  ايصال رقم' : 'חשבונית עם מספר קבלה') }}
                                           </strong> 
  
                                           <strong *ngIf="invoiceInfoData?.invoice_type == 332" style="font-weight: bold;" class="mx-1">
                                              {{curentLangDocument === 'en' ? ' Financial Request Number' : (curentLangDocument === 'ar' ? 'مطالبة مالية رقم' : ' תביעה כספית מס  ') }}
                                           </strong> 
  
                                           <strong *ngIf="invoiceInfoData?.invoice_type == 400" style="font-weight: bold;" class="mx-1">
                                              {{curentLangDocument === 'en' ? 'Receipt Number' : (curentLangDocument === 'ar' ? ' ايصال رقم' : ' מס קבלה  ') }}
                                           </strong> 
  
                                           <strong *ngIf="invoiceInfoData?.invoice_type == 330" style="font-weight: bold;" class="mx-1">
                                              {{curentLangDocument === 'en' ? 'Reverce Invoice Number' : (curentLangDocument === 'ar' ? '  فاتورة عكسية رقم  ' : '      חשבונית זיכוי קבלה ') }}
                                           </strong> 
  
                                           <strong *ngIf="invoiceInfoData?.invoice_type == 100" style="font-weight: bold;" class="mx-1">
                                            {{curentLang === 'en' ? '    order' : (curentLang === 'ar' ? 'طلبية    ' : '   לְהַזמִין   ') }}
                                         </strong> 
  
                                         <strong *ngIf="invoiceInfoData?.invoice_type == 200" style="font-weight: bold;" class="mx-1">
                                            {{curentLang === 'en' ? '    mission' : (curentLang === 'ar' ? 'ارسالية    ' : '   תעודת משלוח   ') }}
                                         </strong> 
  
                                         <strong *ngIf="invoiceInfoData?.invoice_type == 000" style="font-weight: bold;" class="mx-1">
                                            {{curentLang === 'en' ? '    Offer Price' : (curentLang === 'ar' ? 'عرض سعر     ' : ' מחיר הצעה') }}
                                         </strong> 
  
                                           <strong style="font-weight: 400; color: black;">{{invoiceInfoData?.document_number}}</strong>
  
  
                                              
                                          </div>
                                          <div [hidden]="invoiceInfoData?.confirmation_number == null"  style="font-weight: 300;color: black;">
                                              <strong style="font-weight: bold;" class="mx-1">{{ curentLangDocument === 'en' ? 'Confirmation Number' : (curentLangDocument === 'ar' ? ' رقم تخصيص' : ' מספר הקצאה  ')}} : </strong> 
                                             <strong>{{ invoiceInfoData?.confirmation_number }}  </strong>
                                         </div>
                                             
                                             
                                          </div>
  
                                          <div class="col-sm-4    text-end">
                                              <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' التاريخ  ' : ' תאריך  ')}}</h4> -->
                                             
                                                   <div class="mb-0" style="font-weight: bold;">
                                                      {{ curentLangDocument === 'en' ? ' Date' : (curentLangDocument === 'ar' ? 'تاريخ ' : 'תאריך ')}}
                                                  </div> 
                                                  <div style="font-weight: 400; color: black;">{{ invoiceInfoData?.invoice_date| date:'dd/MM/yyyy' }}</div>
                                          </div>
  
  
                                      </div>
                                  </div>
                                  <div class="order-summary" *ngIf="invoiceInfoData?.invoice_type !== 400" >
                                      <div class="table-responsive">
                                          <table class="table invoice-table">
                                              <thead class="bg-active" style="font-weight: bold;   ">
                                                  <tr>
                                                      <th
                                                          class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Item Name' : (curentLangDocument === 'ar' ? 'اسم الصنف' : 'פריט  ')}}</th>
                                                       <th
                                                          class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Description' : (curentLangDocument === 'ar' ? 'الوصف' : 'תיאור')}}</th> 
                                                      <!-- <th
                                                          class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Measure Unit' : (curentLangDocument === 'ar' ? 'وحدة القياس' : 'יחידת מידה')}}</th> -->
                                                      <th
                                                          class="text-center" style="font-weight: bold;"> {{ curentLangDocument === 'en' ? 'Price' : (curentLangDocument === 'ar' ? 'السعر  ' : ' מחיר  ')}}</th>
                                                      <th
                                                          class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Quantity' : (curentLangDocument === 'ar' ? 'الكمية' : 'כמות')}}</th>
                                                      <th
                                                          class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Discount' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</th>
                                                      <th class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}</th>
  
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr
                                                      *ngFor=" let item of invoiceInfoData?.lines">
                                                      <td class="text-center" style="font-weight: 400; color: black;" >{{item?.item_name}}</td>  
                                                      
                                                      <td  class="text-center  description-column"style="font-weight: 400; color: black;">{{item?.description}}</td>
  
                                                    <!--   <td class="text-center  description-column" style="font-weight: 400; color: black;">{{item?.measure_unit_description}}</td> -->
  
                                                      <td class="text-center" style="font-weight: 400; color: black;" >{{item?.price_per_unit | number:'1.2-2'}}</td>
  
                                                      <td class="text-center" style="font-weight: 400; color: black;" >{{item?.quantity}}</td>
  
                                                      <td class="text-center" style="font-weight: 400; color: black;" >{{item?.discount}}</td>
  
                                                      <td class="text-center" style="font-weight: 400; color: black;" >{{item?.sub_total | number:'1.2-2'}}</td>
  
                                                  </tr>
  
                                              </tbody>
                                          </table>
  
                                        
  
                                      </div>
                                      <div class="small-table my-4">
                                          <div class="row">
                                              <div class="col-md-12  ">
                                                  <div class="d-flex justify-content-between" style="background-color: #F1F1F1;">
                                                      <div class="col-md-6  p-2 ">
                                                          <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Amount Before Discount:' : (curentLangDocument === 'ar' ? 'المبلغ قبل الخصم' : '   מחיר לפני הנחה    ')}}</div>
                                                          <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Discount:' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</div>
                                                          <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Payment Amount:' : (curentLangDocument === 'ar' ? '   المبلغ المدفوع    ' : '      סה"כ      ')}}</div>
                                                          <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'VAT Amount:' : (curentLangDocument === 'ar' ? '       المبلغ الضريبي    ' : 'מע"מ')}}</div>
                                                          <div style="width: 300px; font-weight: bold;" class="m-1"> {{ curentLangDocument === 'en' ? 'Payment Amount Including VAT:' : (curentLangDocument === 'ar' ? ' المبلغ المدفوع شامل الضريبة ' : 'סה"כ לשתלום')}}</div>
  
                                                      </div>
  
                                                   
  
                                                      <div class="col-md-6 text-end px-3 p-2">
                                                          <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.amount_before_discount | number:'1.2-2'}}</div>
                                                          <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.all_discount | number:'1.2-2'}}</div>
                                                          <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount | number:'1.2-2'}}</div>
                                                          <div style="font-weight: 400; color: black;" class="my-1">({{invoiceInfoData?.vat_percentage}} %)   {{invoiceInfoData?.all_vat_amount | number:'1.2-2'}}</div>
                                                          <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount_including_vat | number:'1.2-2'}}</div>
  
                                                      </div>
                                                     
                                                   
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      
                                      
  
                                  </div>
  
  
                                    <div class="order-summary  " *ngIf="invoiceInfoData?.invoice_type == 320 || invoiceInfoData?.invoice_type == 400">
  
                                      <div class="col-lg-12 " >
                                          <ng-container  >
                                              <div class="mt-4 d-flex justify-content-between">
                                                  <span style="font-weight: bold; " class="mx-2">{{ curentLangDocument === 'en' ? 'Payment Info' : (curentLangDocument === 'ar' ? 'بيانات الدفع  ' : ' נתוני תשלום')}} </span>
                                                  <!-- <span style="font-weight: 500;" class="mx-3">{{ receiptInfoData.checks_amount | number:'1.0-0' }}</span> -->
                                              </div>  
                                      
                                              <div class="table-responsive ">
                                                  <table class="table invoice-table">
                                                      <thead>
                                                      
                                                          <tr>
                                                              <th
                                                                  class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Payment Method ' : (curentLangDocument === 'ar' ? 'طرق الدفع  ' : 'דרכי תשלום')}}
                                                              </th>
                                                              <!-- <th
                                                                  class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Ref' : (curentLangDocument === 'ar' ? 'مرجع  ' : 'רפ')}}</th> -->
                                                              <!-- <th
                                                                  class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك  ' : 'בַּנק')}}
                                                              </th> -->
                                                              <!-- <th
                                                                  class="text-center" style="font-weight: bold;" > {{ curentLangDocument === 'en' ? 'Branch' : (curentLangDocument === 'ar' ? 'فرع  ' : 'סניף')}}
                                                              </th>
                                                              
                                                              <th
                                                                  class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Account' : (curentLangDocument === 'ar' ? 'حساب  ' : 'חֶשְׁבּוֹן')}}
                                                              </th> -->
                      
                                                              <th
                                                                  class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Details' : (curentLangDocument === 'ar' ? 'تفاصيل  ' : 'פרטים')}}</th>
                                                                  <th
                                                                  class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' تاريخ  ' : ' תאריך')}}</th>
                                                              <th class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع  ' : 'סכום')}}</th>
                      
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr *ngFor=" let item of invoiceInfoData?.receiptsDetails">
                                                              <td class="text-center" style="font-weight: 400; color: black;">
                                                                  <span *ngIf=" item?.payment_type == 1"> {{ curentLangDocument === 'en' ? 'Cash' : (curentLangDocument === 'ar' ? 'كاش' : 'מְזוּמָנִים')}}</span>
                                                                  <span *ngIf=" item?.payment_type == 2"> {{ curentLangDocument === 'en' ? 'Check' : (curentLangDocument === 'ar' ? 'شيك' : 'לִבדוֹק')}}</span>
                                                                  <span *ngIf=" item?.payment_type == 3"> {{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك' : 'בַּנק')}}</span>
                                                                  <span *ngIf=" item?.payment_type == 4"> {{ curentLangDocument === 'en' ? 'Credit' : (curentLangDocument === 'ar' ? 'ائتمان' : 'אַשׁרַאי')}}</span>
                                                              </td>
                                                              <!-- <td class="text-center">{{ item.bank }}</td>
                                                              <td class="text-center">{{ item.branch }}</td>
                                                              <td class="text-center">{{ item.account }}</td>
                                                               -->
                      
                                                              <td class="text-center description-column" style="font-weight: 400; color: black;">{{ item.details }}</td>
                                                              <td class="text-center" style="font-weight: 400; color: black;">{{ item.date | date: 'yyyy-MM-dd' }}</td>
                                                              <td class="text-center" style="font-weight: 400; color: black;">{{ item.amount | number:'1.2-2'}}</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </ng-container>
                                      </div>
  
  
                
  
                  <div class="small-table my-4" >
                      <div class="row">
                          <div class="col-md-12 d-flex justify-content-between" >
                              <div class="col-md-6 mt-5">
                                <!-- <span>Weighted date: 05/05/24</span> -->
                               </div>
                               <div class="col-md-6">
                                  <div class="row d-flex justify-content-between mx-1" style="background-color: #F1F1F1;">
                                      <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                          <span class="py-1"> {{ curentLangDocument === 'en' ? 'Deduction at source' : (curentLangDocument === 'ar' ? 'الخصم من المصدر  ' : '  ניכוי במקור')}} :  </span>
                                      </div>
  
                                      <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                          <span class="mx-1">{{invoiceInfoData.deduction_from_source | number:'1.0-0'}}</span>
                                      </div>
  
                                      <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                          <span>{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}  :  </span>
                                      </div>
  
                                      <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                          <span class="mx-1">{{ invoiceInfoData.total_amount | number:'1.2-2' }}</span>
  
                                      </div>
  
  
                                  </div>
                                  
                               </div>
  
                               <div class="col-md-6">
  
                               </div>
                          </div>
                      </div>
                  </div>
                  
                  
                  
                  <!-- <div class=" d-flex justify-content-between"> 
  
                      <button type="button"  hidden
                      class="btn   m-2" 
                     style="background-color: #141414; color: #fff;"
                      
                      > 
                       {{ curentLangDocument === 'en' ? 'Update' : (curentLangDocument === 'ar' ? '     تحديث ' : '     עדכן  ') }}
                       <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                   </button>
              
                     
  
                          <img src="../../../../assets/images/logo/thi.png"  class="  m-2 "  alt="">
                    
                        
                      
              
                    </div> -->
                  
              </div>
                                      
                                      
                                  </div>
  
                              </div>
  
                          </div>
                      </div>
                  </div>
              </div>
  
  
              
  
  
  
          </div>
  
          
  
      </div>
  
  
  
  
      <!--back-->
      <div class=" d-flex justify-content-between"> 
  
          <button type="button"  hidden
          class="btn   m-2" 
         style="background-color: #141414; color: #fff;"
          
          > 
           {{ curentLangDocument === 'en' ? 'Update' : (curentLangDocument === 'ar' ? '     تحديث ' : '     עדכן  ') }}
           <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
       </button>
  
      <!--    <a routerLink="/dashboard/company-documents/{{invoiceInfoData?.company_uuid}}" *ngIf="user_type == 300 || user_type == 301">
            <button class="btn btn-danger m-2 " >
              {{ curentLangDocument === 'en' ? 'Back' : (curentLangDocument === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
            </button>
          </a>
  -->
        
  
            <button  
             type="button" class="btn btn-sm mt-2 btn-danger" *ngIf="user_type == 300 || user_type == 301"
            (click)="modal.close('Close click')">
        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
        <i class="fa fa-rotate-left mx-1"></i>
    </button>
  
        </div>
      </div>
    </div>
  </ng-template>