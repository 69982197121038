<app-breadcrumb [title]="curentLang === 'en' ? 'Companies Info' : (curentLang === 'ar' ? ' معلومات الشركات  ' : '   מידע על חברות  ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Companies Info' : (curentLang === 'ar' ? ' معلومات الشركات  ' : '   מידע על חברות ')">
</app-breadcrumb>

<!--company info -->

<div class="container-fluid basic_table" *ngIf="user_type == 300">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Companies Info' : (curentLang === 'ar' ? '  معلومات الشركات ' : '  מידע על חברות ')}}
        </h5>
           
       <div>
        <!-- <button [hidden]="user_type == 200" class="btn" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
          <i class="fa fa-plus-circle mx-1"></i>
        {{ curentLang === 'en' ? 'Add Company' : (curentLang === 'ar' ? '  اضافة شركة  ' : '  הוספת חברה ')}}
        
      </button> -->

    


      <button class="btn mx-1 btn-outline-warning"  (click)="getAllAgreedPermissionsByOfficeId()">
        <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}</button>
       </div>
     
      </div>

      <div class="row">
        <div class="col-sm-5">
          <div class="form-group search m-3">
            <label for="search" class="ml-2" style="color: grey;">
              {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
            </label>
            <div class="input-group">
              <input class="form-control" type="text" name="search"
                     [ngModelOptions]="{standalone: true}" id="search" [(ngModel)]="term" 
                     placeholder="{{curentLang === 'en' ? 'You can search by name or tax number ' : (curentLang === 'ar' ? 'يمكنك البحث عن طريق الأسم أو الرقم الضريبي' : 'ניתן לחפש לפי שם או מספר מס')}}">
              <!-- <div class="input-group-append">
                <button class="btn" style="background-color: #141414; color: #fff;" type="button"
                        (click)="getAllAgreedPermissionsByOfficeId(limit, offset, term)">
                  <i class="fa fa-search mx-1"></i>
                  {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                </button>
                <button class="btn btn-outline-secondary" type="button" (click)="term='';getAllAgreedPermissionsByOfficeId(limit, offset, term)">
                  <i class="fa fa-times"></i>
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      
      

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? '  أسم الشركة  ' : '    שם החברה  ')}} </th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Merchant Name' : (curentLang === 'ar' ? '  أسم التاجر  ' : ' שם הסוחר ')}} </th>

                   <th scope="col"> {{ curentLang === 'en' ? 'Tax registration number' : (curentLang === 'ar' ? '   رقم السجل الضريبي  ' : 'מספר רישום מס')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Commercial register' : (curentLang === 'ar' ? ' رقم السجل التجاري  ' : '     מרשם מסחרי ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Employee Name' : (curentLang === 'ar' ? '  أسم الموظف  ' : 'שם הפקיד')}} </th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Office employees of the company  ' : (curentLang === 'ar' ? '  موظفيين المكتب لدي الشركة    ' : 'עובדי משרד של החברה  ')}} </th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Company Documents And Export Reports ' : (curentLang === 'ar' ? 'مستندات الشركة واصدار التقارير ' : 'מסמכי החברה והנפקת דוחות')}}</th> 
                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'Export Reports ' : (curentLang === 'ar' ? ' اصدار تقارير ' : 'הוצאת דוחות')}}</th>  -->

                  <th scope="col"> {{ curentLang === 'en' ? 'Add Employee  ' : (curentLang === 'ar' ? ' اضافة موظف ' : 'הוסף עובד')}}</th>

                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  לצפות   ')}}</th> -->
                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th> -->
                  <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th> 

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of companyData | paginate: { itemsPerPage: limit, currentPage: currentPage, totalItems: total } | filter: term; let i = index">
                  <td scope="row" routerLink="/dashboard/company-documents/{{item.company.company_uuid}}" style="cursor: pointer;">{{i + 1}}</td>
                  <td routerLink="/dashboard/company-documents/{{item.company.company_uuid}}" style="cursor: pointer;"> 
                    {{curentLang === 'en' ? item?.company?.company_name_en : (curentLang === 'ar' ? item?.company?.company_name_ar : item?.company?.company_name_he)}}
                  </td>
                  <td routerLink="/dashboard/company-documents/{{item.company.company_uuid}}" style="cursor: pointer;"> 
                    {{curentLang === 'en' ? item?.merchant?.merchant_name_en : (curentLang === 'ar' ? item?.merchant?.merchant_name_ar : item?.merchant?.merchant_name_he)}}
                  </td>
                  <td routerLink="/dashboard/company-documents/{{item.company.company_uuid}}" style="cursor: pointer;">{{item?.company?.tax_register}}</td>
                  <td routerLink="/dashboard/company-documents/{{item.company.company_uuid}}" style="cursor: pointer;">{{item?.company?.commercial_register}}</td>
                  <td routerLink="/dashboard/company-documents/{{item.company.company_uuid}}" style="cursor: pointer;">{{ item?.company?.createdDate | date: 'dd/MM/yyyy'}}</td>
                  <td>
                    <ng-container *ngIf="item?.accountantUsersCompanyAccesses.length === 1; else multipleEmployees">
                      <span style="cursor: default; text-decoration: none;">
                        {{ curentLang === 'en' ? item?.accountantUsersCompanyAccesses[0]?.user?.user_name : 
                        (curentLang === 'ar' ? item?.accountantUsersCompanyAccesses[0]?.user?.user_name : 
                        item?.accountantUsersCompanyAccesses[0]?.user?.user_name) }}
                      </span>
                    </ng-container>
                  
                    <ng-template #multipleEmployees>
                      <span style="cursor: pointer; text-decoration: underline;" (click)="getRowId(item.company_id); openXl4(content4)">
                        <!-- هنا يمكنك تخصيص عرض الاسم كما تريد -->
                        {{ curentLang === 'en' ? item?.accountantUsersCompanyAccesses[0]?.user?.user_name : 
                        (curentLang === 'ar' ? item?.accountantUsersCompanyAccesses[0]?.user?.user_name : 
                        item?.accountantUsersCompanyAccesses[0]?.user?.user_name) }}
                                              </span>
                    </ng-template>
                  </td>
                  
                  
                  
                  
                  <td>
                    <i class="fa fa-users" style="cursor: pointer; color: black;" routerLink="/dashboard/company-employee/{{item.company.company_uuid}}"></i>
                  </td>
                  <td>
                    <i class="fa fa-file-text-o" style="color: black; cursor: pointer;" routerLink="/dashboard/company-documents/{{item.company.company_uuid}}"></i>
                  </td>
                  <td>
                    <i class="fa fa-plus-circle" style="color: rgb(0, 0, 0); cursor: pointer;" (click)="getCompanyId(item.company_id, item.permission_id);openXl3(content2)"></i>
                  </td>
                  <td>
                    <button class="btn" style="border: none;" (click)="getRowId(item?.permission_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style="cursor: pointer; color: red;"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              
            </table>
            <div class="pagination-container">
              <pagination-controls 
                (pageChange)="onPageChange($event)" 
                (totalItems)="total" 
                (currentPage)="currentPage">
              </pagination-controls>
              <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange()">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>



<div class="container-fluid basic_table" *ngIf="user_type == 301">
  <div class="row">
<div class="col-sm-12">
  <div class="card">
    <div class="card-header d-flex justify-content-between">
      <h5 style="color: grey;"> 
          {{ curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? '  معلومات الشركة ' : '   מידע על החברה    ')}}
      </h5>

     <div>
     

      <button class="btn mx-1 btn-outline-warning"  (click)="getAllAccessByUserId()">
        <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
    </button>

       </div>
   
    </div>
    
    <div class="row">
      <div class="col-sm-5">
          <div class="form-group search m-3">
            <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
               <input class="form-control  w-100" type="text" name="search"
               [ngModelOptions]="{standalone: true}" id="search" [(ngModel)]="term" 
                placeholder=" {{curentLang === 'en' ? 'You can search by name or tax number ' : (curentLang === 'ar' ? 'يمكنك البحث عن طريق الأسم أو الرقم الضريبي' : 'ניתן לחפש לפי שם או מספר מס')}}"/>

          </div>
      </div>
  </div>

    <div class="card-block row">
      <div class="col-sm-12 col-lg-12 col-xl-12">
        <div class="table-responsive">
          <table class="table table-light text-center table-hover  " >
            <thead>
              <tr>
                <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? '  أسم الشركة  ' : '    שם החברה  ')}} </th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Merchant Name' : (curentLang === 'ar' ? '  أسم التاجر  ' : ' שם הסוחר ')}} </th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Company Address' : (curentLang === 'ar' ? ' عنوان الشركة ' : ' כתובת החברה ')}}</th>
                 <th scope="col">                            
                    {{ curentLang === 'en' ? ' Company Tax Number' : (curentLang === 'ar' ? 'الرقم الضريبي للشركة  ' : ' מספר מס חברה ')}}  
                  </th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'Office employees of the company  ' : (curentLang === 'ar' ? '  موظفيين المكتب لدي الشركة    ' : 'עובדי משרד של החברה  ')}} </th> -->
                 <!--   <th scope="col"> {{ curentLang === 'en' ? 'Employee Name' : (curentLang === 'ar' ? '  أسم الموظف  ' : 'שם הפקיד')}} </th> --> 

                  
                <th scope="col"> {{ curentLang === 'en' ? 'Company Documents  ' : (curentLang === 'ar' ? 'مستندات الشركة  ' : ' מסמכי החברה ')}}</th> 
                <th scope="col"> {{ curentLang === 'en' ? 'Export Reports ' : (curentLang === 'ar' ? ' اصدار تقارير ' : 'הוצאת דוחות')}}</th> 
 
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let item of accountingUserCompanyData  | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} |filter:term ;let i = index">
                <td scope="row" routerLink="/dashboard/company-documents/{{item.company.company_uuid}}" style="cursor: pointer;">{{i+1}}</td>
               
                <td routerLink="/dashboard/company-documents/{{item.company.company_uuid}}" style="cursor: pointer;"> 
                  {{curentLang === 'en' ? item?.company?.merchant?.merchant_name_en : (curentLang === 'ar' ? item?.company?.merchant?.merchant_name_ar : item?.company?.merchant?.merchant_name_he)}}
                </td>

                 <td routerLink="/dashboard/company-documents/{{item.company.company_uuid}}" style="cursor: pointer;"> 
                  {{curentLang === 'en' ? item?.company?.company_name_en : (curentLang === 'ar' ? item?.company?.company_name_ar : item?.company?.company_name_he)}}
                </td>
                 <td routerLink="/dashboard/company-documents/{{item.company.company_uuid}}" style="cursor: pointer;">{{item?.company?.company_address_str}}</td>
                 <td routerLink="/dashboard/company-documents/{{item.company.company_uuid}}" style="cursor: pointer;">{{item?.company?.tax_register}}</td>
                 
                  <td routerLink="/dashboard/company-documents/{{item.company.company_uuid}}" style="cursor: pointer;">{{ item.createdDate | date: 'dd/MM/yyyy'}}</td>
                 
                    <!-- إذا كان هناك موظف واحد فقط، عرض الاسم بدون خط وبدون فتح المودال -->
                 <!--     <ng-container *ngIf="MerchantData[i]?.accountantUsersCompanyAccesses.length === 1; else multipleEmployees">
                      <span style="cursor: default; text-decoration: none;">
                        {{EmployeeName[i]}}
                      </span>
                    </ng-container>-->
                  
                    <!-- إذا كان هناك أكثر من موظف، عرض الاسم بخط وفتح المودال -->
                  <!--    <ng-template #multipleEmployees>
                      <span style="cursor: pointer; text-decoration: underline;" (click)="getRowId(item.company_id); openXl4(content4)">
                        {{EmployeeName[i]}}
                      </span>
                    </ng-template>-->
                 
                  
              
                  <!-- <td> 
                    <i class="fa fa-users" style="cursor: pointer; color: black;" routerLink="/dashboard/company-employee/{{item.company.company_uuid}}"></i>
                  </td> -->

                  <td> 
                    <i class="fa fa-file-text-o" style="color: black;   cursor: pointer;"
                     routerLink="/dashboard/company-documents/{{item.company.company_uuid}}"></i>
                  </td>

                  <td>
                    <i class="fa fa-upload" style="color: black;   cursor: pointer;"
                     routerLink="/dashboard/export-reports/{{item.company.company_uuid}}"></i>
                  </td>

                 <!-- <td >
                  <button   
                   
                  class="btn" 
                  style="border: none;" 
                  (click)="getId(item?.access_id);openVerticallyCentered(dataDeleted)">
                    <i class="fa fa-trash-o" style="font-size: 15px; cursor: pointer; color: red;"></i>
                    </button>
                   </td>  -->

              </tr>
        
            </tbody>
          </table>
          <div class="pagination-container">
              <div *ngIf="accountingUserCompanyData?.length == 0" class="alert text-danger mt-3">
                  <span> {{ curentLang === 'en' ? 'There are no companies yet... ' : (curentLang === 'ar' ? ' لا توجد شركات  حتي الأن ... ' : ' אין עדיין חברות ')}}</span>
              </div>
              <pagination-controls 
                (pageChange)="onPageChange2($event)" 
                (totalItems)="total" 
                (currentPage)="currentPage">
              </pagination-controls>
              <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange2()">
          </div>
        </div>

       

      <div class=" d-flex justify-content-between"> 
          <div></div>

         <div>
          <a routerLink="/dashboard/office-users">
              <button class="btn btn-danger m-2 " >
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                <i class="fa fa-rotate-left mx-1"></i>
              </button>
            </a>
         </div>

        

        </div>
      </div>
    </div>
  </div>
</div>

  </div>
</div>







<!--add company-->

<ng-template #content let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Add Company ' : (curentLang === 'ar' ? ' اضافة شركة ' : '  הוספת חברה')}} 
        </h6>
        <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]=" addCompany" >
                        <div class="row">
                            <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Name Ar' : (curentLang === 'ar' ? '  اسم الشركة عربي   ' : ' שם החברה הוא ערבי')}}
                               </label>
                                <input style="color: grey;"  
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="company_name_ar" 
                                  />
                              </div>
              
                              <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company Name En' : (curentLang === 'ar' ? '  اسم الشركة انجليزي   ' : 'שם החברה באנגלית ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                 
                                    formControlName="company_name_en"
                                   />
                                </div>
              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company Name He' : (curentLang === 'ar' ? '  اسم الشركة عبري   ' : ' שמו של הסוחר הוא עברי ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="company_name_he"  
                                  
                                  />
                                   
                                </div>

                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company City' : (curentLang === 'ar' ? ' مدينة الشركة' : 'עיר חברה ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="company_address_city"  
                                   maxlength="100"
                                  />
                                   
                                </div>

                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company Street Name' : (curentLang === 'ar' ? '  اسم شارع الشركة' : 'שם רחוב של החברה')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="company_address_str"  
                                   maxlength="100"
                                  />
                                   
                                </div>

                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company Building Number' : (curentLang === 'ar' ? '  رقم مبني الشركة' : 'מספר בניין החברה ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="number" 
                                   formControlName="company_Building_number"  
                                   maxlength="100"
                                  />
                                   
                                </div>


                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? '    Post number' : (curentLang === 'ar' ? ' الرقم البريدي  ' : ' מיקוד דואר')}}
                                  </label>
                                  <input style="color: grey;" class="form-control"
                                   type="number" 
                                   formControlName="company_address_zip_code"  
                                   maxlength="100"
                                  />
                                   
                                </div>

              
                                <div class="mb-3 col-xl-4 col-md-4">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Tax Register' : (curentLang === 'ar' ? 'رقم السجل الضريبي' : 'מספר תעודת הצילום') }}
                                  </label>
                                  <input style="color: grey;" 
                                         class="form-control" 
                                         type="text"
                                         inputmode="numeric" 
                                         formControlName="tax_register" 
                                         pattern="[0-9]{9}"
                                         title="{{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}" />
                                  <div *ngIf="addCompany?.get('tax_register').invalid && (addCompany?.get('tax_register').dirty || addCompany?.get('tax_register').touched)" class="text-danger">
                                    {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                                  </div>
                                </div>
                                
                                

                                <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Commercial Register' : (curentLang === 'ar' ? '  رقم السجل التجاري   ' : 'מספר רישום מסחרי')}}
                                   </label>
                                    <input style="color: grey;" 
                                    class="form-control" type="number" 
                                     formControlName="commercial_register"
                                   
                                       />
                                  </div>

                                  <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Start Invoice Number ' : (curentLang === 'ar' ? '  بداية رقم الفاتورة   ' : ' מספר חשבונית התחלה')}}  
                                   </label>
                                    <input style="color: grey;" 
                                    class="form-control" type="number" 
                                     formControlName="start_invoice_number"
                                   
                                       />
                                  </div>
                                  <!---->

                                  
              
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button" [disabled]="!addCompany.valid"
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="addedData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>




<!--Delete company-->

              
<ng-template #dataDeleted let-modal>
  <div class="modal-header">
      <h4 class="modal-title"></h4>
      <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
        {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : 'האם אתה בטוח שברצונך למחוק אותו?') }}

      </p>
  </div>
  <div class="modal-footer">

    <button type="button" 
     class="btn btn-sm m-auto mt-2" 
    style="background-color: #141414; color: #fff;"
     (click)="deleteRelatedOfficeToCompany()"
     > 
     {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
      <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
  </button>

      <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
          <i class="fa fa-rotate-left mx-1"></i>
      </button>

  </div>
</ng-template>


<!--add Company to users accountant-->

<ng-template #content2 let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
          {{ curentLang === 'en' ? 'Add an employee to the company' : (curentLang === 'ar' ? '  اضافة موظف لدي الشركة ' : 'הוסף עובד לחברה')}}
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
               <form [formGroup]="addAccountantUserCompanyForm">
                <div class="row">
                  <div class="mb-3 col-xl-12 col-md-12">
                    <label class="form-label" style="color: grey;">
                      {{ curentLang === 'en' ? 'Employee Name' : (curentLang === 'ar' ? 'اسم الموظف' : ' שם הפקיד ') }}
                    </label>
                    <ng-select formControlName="companyUser" (change)="onCompanyMerchantChange($event)" style="cursor: pointer;" [multiple]="true"
                               placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                      <ng-option *ngFor="let item of userData" [value]="item" >
                        {{ curentLang === 'en' ? item?.user_name : (curentLang === 'ar' ? item?.user_name : item?.user_name) }} 
                       </ng-option>
                    </ng-select>
                  </div>
                </div>
                
                <div class="modal-footer">
                  <button type="button" class="btn btn-sm m-auto mt-2" style="background-color: #141414; color: #fff;" 
                  [disabled]="addAccountantUserCompanyForm.invalid"
                          (click)="addedAccountingUserCompany()">
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                  </button>
                  
                  <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                    {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                    <i class="fa fa-rotate-left mx-1"></i>
                  </button>
                </div>
              </form>
                 
              
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>



<ng-template #content4 let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
      {{curentLang === 'en' ? 'Names Of Responsible Employees' : (curentLang === 'ar' ? ' أسماء الموظفيين المسؤليين ' : 'שמות עובדים אחראיים')}} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="edit-profile">
        <div class="row d-flex justify-content-start align-items-start text-start">
          <div class="col-md-12 py-0 text-start mt-1" style="color: black;">
            <p>
              {{curentLang === 'en' ? 'Employee Name :' : (curentLang === 'ar' ? 'أسم الموظف:' : 'שם הפקיד:')}}
            </p>
            <hr style="color: grey;">
            <span style="color: #E62E2D; margin: 0; padding: 0;" *ngFor="let item of employeeData |paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index">
              {{curentLang === 'en' ? item?.user?.user_name : (curentLang === 'ar' ? item?.user?.user_name : item?.user?.user_name)}}
              <hr style="color: grey;">
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>