<app-breadcrumb [title]="curentLang === 'en' ? 'Permanent File Info' : (curentLang === 'ar' ? ' معلومات الملف الدائم  ' : ' מידע על קובץ קבוע  ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : [' לוח הבקרה '])" 
[active_item]="curentLang === 'en' ? 'Permanent File Info' : (curentLang === 'ar' ? ' معلومات الملف الدائم  ' : ' מידע על קובץ קבוע  ')">
</app-breadcrumb>


<div class="container-fluid ">
    <div class="edit-profile">
      <div class="row">
        
       
        <div class="col-xl-12 ">
            <div class="card ">
                <div class="card-header mx-4">
                    <h5 style="color: grey;"> 
                      {{curentLang === 'en' ? 'Permanent File Info' : (curentLang === 'ar' ? ' معلومات الملف الدائم  ' : ' מידע על קובץ קבוע  ')}}
                    </h5>
                  </div>
                <div class="card-body  ">
            <div class="row d-flex justify-content-between  ">
                    <div class="col-md-12 row d-flex justify-content-between">
                        <form [formGroup]="createZip" >
                       <div class="row d-flex justify-content-between ">
                        <div class="col-xxl-4 col-sm-6 box-col-6 p-2 " >
                    
                                  <label class="form-label" style="color: grey;">{{ curentLang === 'en' ? 'Select Company Name' : (curentLang === 'ar' ? 'اختر اسم الشركة' : 'בחר את שם החברה') }}</label>
                                  <!-- <select formControlName="company_id" (change)="filter($event.target.value)" class="form-control form-select" style="cursor: pointer;">
                                    <option *ngFor="let item of mastercompany" [value]="item.company_id">
                                      {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar' ? item.company_name_ar : item.company_name_he) }}
                                    </option>
                                  </select> -->
                                  <ng-select formControlName="company_id" [(ngModel)]="selectedCompany" (change)="filter(selectedCompany)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                    <ng-option *ngFor="let item of mastercompany" [value]="item.company_id">
                                      {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar' ? item.company_name_ar : item.company_name_he) }}
                                    </ng-option>
                                  </ng-select>
                                  

                       
                          </div>
                          <!---->
            
                          <div class="col-xxl-4 col-sm-6 box-col-6 p-2" >
                          
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Start Date' : (curentLang === 'ar' ? 'بداية تاريخ  ' : ' תחילתה של ההיסטוריה  ')}}
                                   </label>
                                    <input style="color: grey;" class="form-control" type="date"  formControlName="start_date" />
                           
                          </div>
                          <!---->
            
                          <div class="col-xxl-4 col-sm-6 box-col-6 p-2" >
                       
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'End Date' : (curentLang === 'ar' ? 'نهاية تاريخ' : 'סוף ההיסטוריה ')}}
                                   </label> 
                                    <input style="color: grey;" class="form-control" type="date" formControlName="end_date" />
                            
                          </div>
                       </div>
    
    
                        <ng-container>
                          <button type="button" [disabled]="!createZip.valid" class="btn btn-sm m-auto mt-2"
                           style="background-color: #141414; color: #fff;" (click)="addedData()">
                           {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                               <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                                </button>
                                </ng-container>
                            
    
                      
                          </form>
                    </div>
                  
                 
          
              <!---->
            
            </div>
          </div>
          <!--back-->
         
            </div>

      </div>
      
    </div>
    
  </div>

</div>



<!-- <div class="container-fluid basic_table" >
  <div class="row">
<div class="col-sm-12">
  <div class="card">
    <div class="card-header d-flex justify-content-between">
      <h5 style="color: grey;"> 
          {{ curentLang === 'en' ? 'Transactions Info' : (curentLang === 'ar' ? '  معلومات التعاملات ' : ' מידע עסקה   ')}}
      </h5>

     <div>
   

      <button class="btn mx-1 btn-outline-warning"  (click)="getAllTransactionsByMerchantId()">
        <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
    </button>

       </div>
   
    </div>
    
    <div class="row">
      <div class="col-sm-5">
          <div class="form-group search m-3">
            <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
               <input class="form-control  w-100" type="text" name="search"
                [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
          </div>
      </div>
  </div>

    <div class="card-block row">
      <div class="col-sm-12 col-lg-12 col-xl-12">
        <div class="table-responsive">
          <table class="table table-light text-center table-hover  " >
            <thead>
              <tr>
                <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                <th scope="col"> {{ curentLang === 'en' ? 'Start Date ' : (curentLang === 'ar' ? ' من تاريخ  ' : ' מהתאריך של')}} </th>
                 <th scope="col"> {{ curentLang === 'en' ? 'End Date' : (curentLang === 'ar' ? '   الي تاريخ    ' : 'עד היום')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? ' File Path' : (curentLang === 'ar' ? ' مسار الملف ' : ' נתיב הקובץ ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'File Type ' : (curentLang === 'ar' ? 'نوع الملف' : ' סוג קובץ  ')}}</th>
                 <th scope="col">                            
                    {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? ' أسم الشركة ' : ' שם החברה ')}}  
                  </th>

                 <th scope="col"> {{ curentLang === 'en' ? 'Tax Register' : (curentLang === 'ar' ? ' السجل الضريبي  ' : '  פנקס מס  ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Commercial Register' : (curentLang === 'ar' ? ' السجل التجاري  ' : '  רישום מסחרי    ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך רישום    ')}}</th>
   
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let item of allTransactionData | paginate: {itemsPerPage:7, currentPage:p } |filter:term ; let i = index ">
                <td scope="row">{{i+1}}</td>
                <td>{{item.start_date}} </td>
                 <td>{{item.end_date}}</td>
                 <td>
                  <a [href]="item.filePath" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: red;">
                      <i class="fa fa-download" aria-hidden="true"></i>  {{ curentLang ==='en' ? 'Download' : (curentLang === 'ar' ? 'تحميل' : 'הורדה') }}
                  </a>
              </td>
              

                 <td>{{item.fileType}}</td>

                 <td>     
                     {{curentLang === 'en' ? item?.company?.company_name_en.substring(0, 20) : (curentLang === 'ar' ? item?.company?.company_name_ar.substring(0, 20) : item?.company?.company_name_he.substring(0, 20))}}
                </td>
                 <td>{{item?.company?.tax_register}}</td>
                 <td>{{item?.company?.commercial_register}}</td>
                 <td>{{ item.createdDate | date:'shortDate'}}</td>
              </tr>
        
            </tbody>
          </table>
          <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

        </div>
      </div>
    </div>
  </div>
</div>

  </div>
</div> -->