import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { EMPTY, Observable, catchError, concatMap, throwError } from "rxjs";
import { of } from 'rxjs/internal/observable/of';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AddCustomerEndPoient, AddInvoiceEndPoient, AddProductEndPoient } from "../../service/global.service";
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { israelPhoneNumberValidator } from "src/app/validators/israelPhoneNumberValidator";


@Component({
  selector: 'app-add-finaical-request',
  templateUrl: './add-finaical-request.component.html',
  styleUrls: ['./add-finaical-request.component.scss']
})
export class AddFinaicalRequestComponent implements OnInit {

  vatTypeList: any;
  vatPersentageData: any;
  VatPercentage: any;
  invoiceId: number; 
  vatListId: number;
  today: any;
  mastercompany: any;
  oneCompanyData: any=[];
  oneCustomerData: any=[];
  id:number;
  masterInvoiceTypeList: any;
  curentLang:string="";
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  CompanyNameEn:string = '';
  CompanyNameAr:string = '';
  CompanyNameHe:string = '';

  CustomerNameEn:string = '';
  CustomerNameAr:string = '';
  CustomerNameHe:string = '';


  CompanyAddress:string = '';
  TaxCompany:string = '';
  customerVatNumber:string='';
  LOGO:string='';
  companyId:any;
  CompanyAddress_zip_code: any;
  CompanyAddress_city: any;
  CompanyAddress_number: any;
  CompanyAddress_str: any;
  invoice = 7;
  Deleted = 8;
  financialRequest = 9;
  financialRequestConverted = 14
  reverseInvoice = 10;
  invoice_date: string | number | Date;
  companyDefault = JSON.parse(localStorage.getItem('companyDefault'));
  uuId:any;
  addCustomer:FormGroup;
  public validate = false;
  customerTypeData:any=[];
  addProduct:FormGroup;
  YearData:any =  new Date().getFullYear();
  MaxAmount:any
  Year:any
  constructor(private builder: FormBuilder, private service: HttpHelpService, private router: Router, private alert: ToastrService,
    private activeroute: ActivatedRoute, private spinner: NgxSpinnerService,  private datePipe: DatePipe ,private modalService: NgbModal,
    private toastr: ToastrService ,
    
  )
   { 
    this.curentLang = localStorage.getItem('curentLang');
    this.companyDefault = localStorage.getItem('companyDefault');

    this.addCustomer=new FormGroup({
      customer_name_en :new FormControl(''),
      customer_name_ar:new FormControl(''),
      customer_name_he :new FormControl(''),  
      // customer_address_str :new FormControl(''),
      // customer_address_city :new FormControl(''),
      // customer_address_zip_code :new FormControl(''),
      // customer_Building_number :new FormControl(''),
      // customer_phone :new FormControl('', [israelPhoneNumberValidator()]),  
      customer_vat_number :new FormControl(null), 
      customer_vat_tax :new FormControl(null), 
      //id_number :new FormControl(null),  
      id_number :new FormControl(0),
      customer_type :new FormControl(''),  
      merchant_id :new FormControl(this.merchantId),  
       created_by:new FormControl(this.userRegistId),
      });

      this.addProduct=new FormGroup({
        product_name_en :new FormControl('' , Validators.required),
        product_name_ar:new FormControl('' , Validators.required),
        product_name_he :new FormControl('' , Validators.required),  
        des_ar :new FormControl('' , Validators.required), 
        des_en :new FormControl('' , Validators.required), 
        des_he :new FormControl('' , Validators.required), 
        product_code :new FormControl('' , Validators.required),
        price :new FormControl('' , Validators.required),  
        measurement_unit_id :new FormControl('' , Validators.required),  
        categories_id :new FormControl('' , Validators.required),  
        merchant_id :new FormControl(this.merchantId),  
        created_by:new FormControl(this.userRegistId),
        });
    }
  pagetitle = "Create Invoice"
  invoicedetail !: FormArray<any>;
  invoiceproduct !: FormGroup<any>;

  mastercustomer: any;
  masterproduct: any;
  editinvoiceno: any;
  isedit = false;
  editinvdetail: any;
  selectedProduct:any;
  selectedMeasurementUnit:any;
  categoriesData:any=[];
  unitMesauereData:any=[];
  selectedMonth:any
  selectedYear:any
  getAllActiveCategories() {
    this.spinner.show();
    this.service.getAllActiveCategories().subscribe({
      next: res => {
        this.categoriesData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getAllActiveMeasurementUnits() {
    this.spinner.show();
    this.service.getAllActiveMeasurementUnits().subscribe({
      next: res => {
        this.unitMesauereData = res;
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  filterProduct(event:any)
  {
      this.selectedProduct = event;
     
   };

   filterMeasurementUnit(event:any)
  {
      this.selectedMeasurementUnit = event;
     
   };
  openXl2(content2:any) {
    this.modalService.open(content2, { size: 'xl' , centered:true });
  };

  addedProductData() {
    const product_name_he = String(this.addProduct.value.product_name_he)?.trim() || '';
    const product_code = String(this.addProduct.value.product_code)?.trim() || '';
    const price = Number(this.addProduct.value.price) || 0;
    const des_he = String(this.addProduct.value.des_he)?.trim() || '';
    const measurement_unit_id = Number(this.addProduct.value.measurement_unit_id) || 0;
    const categories_id = Number(this.addProduct.value.categories_id) || 0;

    if (
        product_name_he === '' ||
        product_code === '' ||
        price === 0 ||
        des_he === '' ||
        measurement_unit_id === 0 ||
        categories_id === 0
    ) {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : 
                   (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : 
                   (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
        });

        this.addProduct.markAllAsTouched();
        this.validate = !this.validate;
        return;
    }

    // تعيين قيمة product_name_he إلى الحقول الأخرى
    const productNameHe = this.addProduct.value.product_name_he;
    const descriptionNameHe = this.addProduct.value.des_he;
    this.addProduct.patchValue({
        product_name_ar: productNameHe,
        product_name_en: productNameHe,
        des_ar: descriptionNameHe,
        des_en: descriptionNameHe,
    });

    let model = {
        product_name_en: this.addProduct.value.product_name_en,
        product_name_ar: this.addProduct.value.product_name_ar,
        product_name_he: this.addProduct.value.product_name_he,
        product_code: this.addProduct.value.product_code,
        price: price,
        des_ar: this.addProduct.value.des_ar,
        des_en: this.addProduct.value.des_en,
        des_he: this.addProduct.value.des_he,
        measurement_unit_id: measurement_unit_id,
        categories_id: categories_id,
        merchant_id: Number(this.merchantId),
        created_by: Number(this.userRegistId),
    };


    this.spinner.show();
    this.service.Post(AddProductEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
            Swal.fire({
                icon: 'success',
                title: this.curentLang === 'ar' ? res.messageAr : 
                       (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
         
            this.getAllCustomerType();
            this.GetProducts();
         

            this.addProduct.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
            let errorMessage;
            switch (res.status) {
                case 400:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                case 403:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                case 500:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                default:
                    errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : 
                                   (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            }
            Swal.fire({
                icon: 'error',
                title: errorMessage
            });
        }
        this.spinner.hide();
    });
};

  ngOnInit(): void {
    this.curentLang = localStorage.getItem('curentLang');

    // تعيين اللغة الافتراضية استنادًا إلى لغة الموقع الحالية
    if (this.curentLang === 'ar') {
      this.curentLang = 'ar'; // لغة الموقع العربية
    } else if (this.curentLang === 'he') {
      this.curentLang = 'he'; // لغة الموقع العبرية
    } else {
      this.curentLang = 'en'
    }
        // تحديث النموذج بالقيم المستلمة
        this.invoiceform.patchValue({
          vat_list_id: 8,

        });
        this.getVatPercentageByVatListIdAndDate()

    
 //   this.invoiceform.get('document_language')?.setValue(this.curentLang);
 const today = new Date();
 this.selectedMonth = today.getMonth() + 1;  
 this.selectedYear = today.getFullYear();  
 this.checkClosedMonth(); 
    this.getAllCustomerType();
    this.GetYear()
    this.GetCustomers();
    this.GetProducts();
    this.vatTypechange();
    this.GetCompany();
    this.GetInvoiceTypeList();
    this.getCustomerById();
    this.getCompanyInfoById();
    this.getAllActiveCategories()
    this.getAllActiveMeasurementUnits();
    this.editinvoiceno = this.activeroute.snapshot.paramMap.get('invoiceno');
    if (this.editinvoiceno != null) {
      this.pagetitle = "Edit Invoice";
      this.isedit = true;
      // this.SetEditInfo(this.editinvoiceno);
    }
    this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));

    if (this.companyDefault !== null && this.companyDefault !== 'null') {
      this.invoiceform.patchValue({
        company_id: Number(this.companyDefault),
      });
      this.checkSequenceExists(Number(this.companyDefault));
    }
  
    
  
    this.invoiceform.get('company_id').valueChanges.subscribe(companyId => {
      if (companyId) {
        this.checkSequenceExists(companyId);
      }
    });

  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  filterCustomer(event:any)
  {
    let x = event.target.value;
   };
  getAllCustomerType() {
    this.spinner.show();
    this.service.getAllCustomerType().subscribe({
      next: res => {
        this.customerTypeData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };
  addedData() {
    // إزالة الصفر الأولي من رقم الهاتف إذا كان موجودًا
    // let customerPhone = this.addCustomer.value.customer_phone;
    // if (customerPhone.startsWith('0')) {
    //   customerPhone = customerPhone.substring(1);
    // }
  
   
    const customerType = this.addCustomer.value.customer_type;

    // التحقق من القيم المطلوبة بناءً على نوع العميل
    if (
      this.addCustomer.value.customer_name_he.trim() === '' ||
      (customerType === 6 && this.addCustomer.value.customer_vat_number.trim() === '' || null) ||
   //   (customerType === 5 && this.addCustomer.value.id_number.trim() === '' || null) ||
      customerType == null
  ) {
      Swal.fire({
          icon: 'warning',
          title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة' : (this.curentLang === 'en' ? 'Please fill the required fields' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ' : ''))
      });
      this.validate = !this.validate;
      return;
  }
   else {
  
      let merchantTaxNumber = this.addCustomer.value.customer_vat_number;
      if (merchantTaxNumber !== '' && merchantTaxNumber?.length < 9) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'يجب ألا يقل الرقم الضريبي عن 9 أرقام' : 
                 (this.curentLang === 'en' ? 'The tax number must be at least 9 digits long' : 
                 (this.curentLang === 'he' ? 'מספר המס חייב להיות לפחות 9 מספרים' : ''))
        });
        return;
      }
  
      let merchantPhone = this.addCustomer.value.customer_phone;
      // if (merchantPhone !== '' && merchantPhone.length < 7) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: this.curentLang === 'ar' ? 'يجب ألا يقل رقم الهاتف عن 7 أرقام' : 
      //            (this.curentLang === 'en' ? 'The phone number must be at least 7 digits long' : 
      //            (this.curentLang === 'he' ? 'מספר הטלפון חייב להיות בן 7 ספרות לפחות' : ''))
      //   });
      //   return;
      // }
  
      // const validPrefixes = ['50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
      // let validationStartPhoneNumber = merchantPhone.substring(0, 2);
  
      // if (merchantPhone !== '' && !validPrefixes.includes(validationStartPhoneNumber)) {
      //   Swal.fire({
      //     icon: 'warning',
      //     title: this.curentLang === 'ar' ? 'يجب أن يبدأ رقم الهاتف بأي رقم من هذة الأرقام (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
      //            (this.curentLang === 'en' ? 'Phone number must start with one of these numbers (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
      //            (this.curentLang === 'he' ? 'צריך להתחיל עם אחד מהמספרים (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : ''))
      //   });
      //   return;
      // }
  
      // إزالة الصفر الأولي من رقم الهاتف إذا كان موجودًا
      // let customerPhoneFinal = this.addCustomer.value.customer_phone;
      // if (customerPhoneFinal.startsWith('0')) {
      //   customerPhoneFinal = customerPhoneFinal.substring(1);
      // }
  
      // تعيين قيمة customer_name_he إلى الحقول الأخرى
      const customerNameHe = this.addCustomer.value.customer_name_he;
      this.addCustomer.patchValue({
        customer_name_ar: customerNameHe,
        customer_name_en: customerNameHe
      });
  
      let model = {
        customer_name_en: this.addCustomer.value.customer_name_en,
        customer_name_ar: this.addCustomer.value.customer_name_ar,
        customer_name_he: this.addCustomer.value.customer_name_he,
        // customer_address_str: this.addCustomer.value.customer_address_str,
        // customer_address_city: this.addCustomer.value.customer_address_city,
        // customer_address_zip_code: this.addCustomer.value.customer_address_zip_code,
        // customer_Building_number: this.addCustomer.value.customer_Building_number,
        // customer_phone: customerPhoneFinal !== '' ? '+972' + customerPhoneFinal : '',
        customer_vat_number: this.addCustomer.value.customer_vat_number,
        customer_vat_tax: this.addCustomer.value.customer_vat_tax,
        id_number: this.addCustomer.value.id_number,
        customer_type: Number(this.addCustomer.value.customer_type),
        merchant_id: Number(this.merchantId),
        created_by: Number(this.userRegistId),
      };
  
      this.spinner.show();
      this.service.Post(AddCustomerEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getCustomerById();
          this.GetCustomers();
          this.getAllCustomerType();
          this.addCustomer.reset({
            // customer_name_en: '',
            // customer_name_ar: '',
            // customer_name_he: '',
            // customer_address_str: '',
            // customer_address_city: '',
            // customer_address_zip_code: '',
            // customer_Building_number: '',
            // customer_phone: '',
            customer_vat_number: null,
            customer_vat_tax: null,
            id_number: null,
            customer_type: null,
          });
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
            case 403:
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }
  };

  isNumber(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    } else {
      return true;
    }
  }

  
  checkSequenceExists(companyId: number): void {
    const docType = '332'; // نوع المستند ثابت
    this.spinner.show();
    this.service.checkSequenceExists(companyId, docType).subscribe(res => {
      const sequenceExists = res;
   
      if (!sequenceExists) {
        Swal.fire({
          title: this.curentLang === 'en' ? 'Enter the starting number for the document sequence:' : this.curentLang === 'ar' ? 'أدخل الرقم الابتدائي لتسلسل المستند:' : 'הזן את המספר ההתחלתי של רצף המסמכים:',
          input: 'number',
          inputAttributes: {
            min: '1',
            step: '1'
          },
          inputValue: 1, // تعيين القيمة الافتراضية إلى 1
          showCancelButton: false,
          confirmButtonText: this.curentLang === 'en' ? 'Submit' : this.curentLang === 'ar' ? 'إرسال' : 'שלח',
          allowOutsideClick: false,
          allowEscapeKey: false,
          preConfirm: (value) => {
            if (!value || value < 1) {
              Swal.showValidationMessage(
                this.curentLang === 'en' ? 'Please enter a valid number greater than 0' : this.curentLang === 'ar' ? 'يرجى إدخال رقم صالح أكبر من 0' : 'אנא הזן מספר חוקי גדול מ-0'
              );
            }
          }
        }).then(result => {
          if (result.value) {
            this.createSequence(companyId, parseInt(result.value, 10));
          }
        });
      }
  
      this.spinner.hide();
    }, error => {
       this.spinner.hide();
    });
  }
  
  createSequence(companyId: number, startWith: number): void {
    this.spinner.show();
    const docType = '332'; // نوع المستند ثابت
  
    this.service.createSequence(companyId, docType, startWith).subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: this.curentLang === 'en' ? 'Sequence created successfully' : this.curentLang === 'ar' ? 'تم إنشاء التسلسل بنجاح' : 'רצף נוצר בהצלחה'
      });
    }, error => {
     });
    this.spinner.hide();
  }


  onDateChange(event: any) {
    const selectedDate: Date = event;
    const formattedDate = selectedDate.toISOString().slice(0, 10).replace(/-/g, "");
 }

  
  availableLanguages = [
    // { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "he", name: "Hebrew" }
];

// تعيين curentLang كلغة افتراضية
  
  invoiceform = this.builder.group({
    invoice_type : this.builder.control('332', Validators.required),
    customer_id: this.builder.control('', Validators.required),
    company_id: this.builder.control(null, Validators.required),
    vat_list_id: this.builder.control(null, Validators.required),
    vat_percentage: this.builder.control({ value: 0, disabled: true }),
    invoice_date: this.builder.control(this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required),
    invoice_note: this.builder.control('', Validators.maxLength(255)),
    document_language: this.builder.control('he'),
    allocation_number: this.builder.control(null || 1, Validators.required),  
    amount_before_discount : this.builder.control({ value: 0, disabled: true }),
    all_discount: this.builder.control({ value: 0, disabled: true }),
    payment_amount: this.builder.control({ value: 0, disabled: true }),
    all_vat_amount: this.builder.control({ value: 0, disabled: true }),
    payment_amount_including_vat: this.builder.control({ value: 0, disabled: true }),
    confirm: this.builder.control({ value: 0, disabled: true }),
    invoice_category: this.builder.control({ value: this.financialRequest, disabled: true }),
    merchant_id : Number(this.merchantId),
    customer_name: this.builder.control('', Validators.required),
    customer_id_number : this.builder.control(''),
    customer_vat_number : this.builder.control(''),
    created_by: Number(this.userRegistId),
    lines: this.builder.array([] , Validators.required),

  });


  confirmInvoice() {
    this.spinner.show();
    
    let isValidCustomer = true; // متغير علم لتحديد صلاحية العميل
  
if (this.invoiceform.get("payment_amount_including_vat")?.value >= this.MaxAmount) {
  const customerType = this.oneCustomerData.customer_type;
  const customerId = this.oneCustomerData.customer_id;

 

  if (customerType === 6) {
  //  const customerVatNumber = Number(this.oneCustomerData.customer_vat_number);
    const customerVatNumber = Number(this.invoiceform.get("customer_vat_number")?.value)
    if (customerVatNumber === 0 || customerVatNumber === null) {
      isValidCustomer = false; // إيقاف علم صلاحية العميل مؤقتًا
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'الرقم الضريبي للعميل غير صحيح' : (this.curentLang === 'en' ? 'The customer tax number is incorrect' : 'מספר המס של הלקוח שגוי'),
        showCancelButton: true,
        confirmButtonText: this.curentLang === 'ar' ? 'استمر رغم ذلك' : 'Keep going though',
        cancelButtonText: this.curentLang === 'ar' ? 'تعديل بيانات العميل' : 'Edit Customer Data',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.invoiceform.patchValue({
            customer_vat_number: '999999998',
            customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
            customer_id_number: this.oneCustomerData?.id_number,
          });
          isValidCustomer = true; // إعادة صلاحية العميل

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            title: this.curentLang === 'ar' ? 'تحديث الرقم الضريبي للعميل' : 'Update Customer VAT Number',
            input: 'text',
            inputValue: this.oneCustomerData.customer_vat_number,
            showCancelButton: true,
            confirmButtonText: this.curentLang === 'ar' ? 'تحديث' : 'Update',
            inputValidator: (value) => {
              if (!value || value.trim() === '' || value.startsWith('0') || /^0+$/.test(value) || value.length < 9) {
                return this.curentLang === 'ar' ? 'الرجاء إدخال رقم ضريبي صالح لا يقل عن 9 أرقام ولا يبدأ بصفر' : 'Please enter a valid VAT number with at least 9 digits and does not start with 0';
              }
            }
          }).then((inputResult) => {
            if (inputResult.isConfirmed) {
              this.invoiceform.patchValue({
                customer_vat_number: inputResult.value,
                customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
                customer_id_number: this.oneCustomerData?.id_number
              });
              isValidCustomer = true; // إعادة صلاحية العميل بعد التحديث

              const model = { customer_vat_number: inputResult.value, updated_by: this.userRegistId };
              this.service.updateCustomersById(customerId, model).subscribe();
            }
          });
        }
      });
    }
  }

  if (customerType === 5) {
    const customerIdNumber = Number(this.invoiceform.get("customer_id_number")?.value);
     if (customerIdNumber === 0 || customerIdNumber === null || customerIdNumber === undefined) {
      isValidCustomer = false;
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'رقم الهوية للعميل غير صحيح' : 'The customer ID number is incorrect',
        showCancelButton: true,
        confirmButtonText: this.curentLang === 'ar' ? 'استمر رغم ذلك' : 'Keep going though',
        cancelButtonText: this.curentLang === 'ar' ? 'تعديل بيانات العميل' : 'Edit Customer Data',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.invoiceform.patchValue({
            customer_id_number: '999999998',
            customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
            customer_vat_number: this.oneCustomerData?.customer_vat_number || 0,
          });
          isValidCustomer = true;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            title: this.curentLang === 'ar' ? 'تحديث رقم الهوية للعميل' : 'Update Customer ID Number',
            input: 'text',
            inputValue: this.oneCustomerData.id_number,
            showCancelButton: true,
            confirmButtonText: this.curentLang === 'ar' ? 'تحديث' : 'Update',
            inputValidator: (value) => {
              if (!value || value.trim() === '' || value.startsWith('0') || /^0+$/.test(value) || value.length < 9) {
                return this.curentLang === 'ar' ? 'الرجاء إدخال رقم هوية صالح لا يقل عن 9 أرقام ولا يبدأ بصفر' : 'Please enter a valid ID number with at least 9 digits and does not start with 0';
              }
            }
          }).then((inputResult) => {
            if (inputResult.isConfirmed) {
              this.invoiceform.patchValue({
                customer_id_number: inputResult.value,
                customer_vat_number: this.oneCustomerData.customer_vat_number,
                customer_name: this.oneCustomerData['customer_name_' + this.curentLang].substring(0, 25),
              });
              isValidCustomer = true;

              const model = { id_number: inputResult.value, updated_by: this.userRegistId };
              this.service.updateCustomersById(customerId, model).subscribe();
            }
          });
        }
      });
    }
  }
}

    if (isValidCustomer && this.invoiceform.valid) {
      this.service.SaveInvoice(this.invoiceform.getRawValue()).pipe(
        concatMap((res: any) => {
          let result: any = res;
          this.invoiceId = result?.data?.invoice_id;
            this.uuId = result?.data?.invoice_uuid;
          if (result.status === 200) {
            return this.service.confirmInvoice(this.invoiceId);
          } else {
            this.spinner.hide();
            this.handleErrors(result);
            return throwError(result.message);
          }
        }),
        catchError(error => {
          this.spinner.hide();
          this.handleErrors(error);
          return throwError(error);
        })
      ).subscribe((res) => {
        if (res.status === 200) {
          this.router.navigate([`/dashboard/finaical-request`]);
         
          
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            color: 'green',
            backdrop:true,
             
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
          Toast.fire({
            icon: 'success',
             title: this.getSuccessMessage(res)
          }).then(() => {
            // توجيه المستخدم إلى المسار المطلوب بعد نجاح تأكيد الفاتورة
            
          });
          this.modalService.dismissAll();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
              case 400:
              case 403:
              case 500:
                  errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                  this.router.navigate(['/dashboard/finaical-request']);

                  break;
                  
              default:
                  errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          this.toastr.error(errorMessage)
      }
        this.spinner.hide();
      });
    }
    else if (!isValidCustomer) {
      this.spinner.hide();
    } 
    else {
      this.spinner.hide();
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : 'נא למלא כל השדות')
      });
    }
  }
  
  
  SaveInvoice() {
     
    this.spinner.show();

    let isValidCustomer = true; // متغير علم لتحديد صلاحية العميل
  
    if (this.invoiceform.get("payment_amount_including_vat")?.value >= this.MaxAmount) {
      const customerType = this.oneCustomerData.customer_type;
      const customerId = this.oneCustomerData.customer_id;
  
      if (customerType === 6) {
       // const customerVatNumber = Number(this.oneCustomerData.customer_vat_number);
          const customerVatNumber = Number(this.invoiceform.get("customer_vat_number")?.value)
        if (customerVatNumber === 0 || customerVatNumber === null) {
          isValidCustomer = false; // إيقاف علم صلاحية العميل مؤقتًا
          Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'الرقم الضريبي للعميل غير صحيح' : (this.curentLang === 'en' ? 'The customer tax number is incorrect' : 'מספר המס של הלקוח שגוי'),
            showCancelButton: true,
            confirmButtonText: this.curentLang === 'ar' ? 'استمر رغم ذلك' : 'Keep going though',
            cancelButtonText: this.curentLang === 'ar' ? 'تعديل بيانات العميل' : 'Edit Customer Data',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              this.invoiceform.patchValue({
                customer_vat_number: '999999998',
                customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
                customer_id_number: this.oneCustomerData?.id_number,
              });
              isValidCustomer = true; // إعادة صلاحية العميل
  
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire({
                title: this.curentLang === 'ar' ? 'تحديث الرقم الضريبي للعميل' : 'Update Customer VAT Number',
                input: 'text',
                inputValue: this.oneCustomerData.customer_vat_number,
                showCancelButton: true,
                confirmButtonText: this.curentLang === 'ar' ? 'تحديث' : 'Update',
                inputValidator: (value) => {
                  if (!value || value.trim() === '' || value.startsWith('0') || /^0+$/.test(value) || value.length < 9) {
                    return this.curentLang === 'ar' ? 'الرجاء إدخال رقم ضريبي صالح لا يقل عن 9 أرقام ولا يبدأ بصفر' : 'Please enter a valid VAT number with at least 9 digits and does not start with 0';
                  }
                }
              }).then((inputResult) => {
                if (inputResult.isConfirmed) {
                  this.invoiceform.patchValue({
                    customer_vat_number: inputResult.value,
                    customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
                    customer_id_number: this.oneCustomerData?.id_number
                  });
                  isValidCustomer = true; // إعادة صلاحية العميل بعد التحديث
  
                  const model = { customer_vat_number: inputResult.value, updated_by: this.userRegistId };
                  this.service.updateCustomersById(customerId, model).subscribe();
                }
              });
            }
          });
        }
      }
  
      if (customerType === 5) {
        const customerIdNumber = Number(this.invoiceform.get("customer_id_number")?.value);
  
        if (customerIdNumber === 0 || customerIdNumber === null || customerIdNumber === undefined) {
          isValidCustomer = false;
          Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'رقم الهوية للعميل غير صحيح' : 'The customer ID number is incorrect',
            showCancelButton: true,
            confirmButtonText: this.curentLang === 'ar' ? 'استمر رغم ذلك' : 'Keep going though',
            cancelButtonText: this.curentLang === 'ar' ? 'تعديل بيانات العميل' : 'Edit Customer Data',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              this.invoiceform.patchValue({
                customer_id_number: '999999998',
                customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
                customer_vat_number: this.oneCustomerData?.customer_vat_number || 0,
              });
              isValidCustomer = true;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire({
                title: this.curentLang === 'ar' ? 'تحديث رقم الهوية للعميل' : 'Update Customer ID Number',
                input: 'text',
                inputValue: this.oneCustomerData.id_number,
                showCancelButton: true,
                confirmButtonText: this.curentLang === 'ar' ? 'تحديث' : 'Update',
                inputValidator: (value) => {
                  if (!value || value.trim() === '' || value.startsWith('0') || /^0+$/.test(value) || value.length < 9) {
                    return this.curentLang === 'ar' ? 'الرجاء إدخال رقم هوية صالح لا يقل عن 9 أرقام ولا يبدأ بصفر' : 'Please enter a valid ID number with at least 9 digits and does not start with 0';
                  }
                }
              }).then((inputResult) => {
                if (inputResult.isConfirmed) {
                  this.invoiceform.patchValue({
                    customer_id_number: inputResult.value,
                    customer_vat_number: this.oneCustomerData.customer_vat_number,
                    customer_name: this.oneCustomerData['customer_name_' + this.curentLang].substring(0, 25),
                  });
                  isValidCustomer = true;
  
                  const model = { id_number: inputResult.value, updated_by: this.userRegistId };
                  this.service.updateCustomersById(customerId, model).subscribe();
                }
              });
            }
          });
        }
      }
    }
  
    
    if (isValidCustomer && this.invoiceform.valid) {
      this.service.SaveInvoice(this.invoiceform.getRawValue()).subscribe(res => {
        let result: any;
        result = res;
        this.invoiceId = result?.data?.invoice_id;
        this.uuId = result?.data?.invoice_uuid;
        if (result.status === 200) {
          this.spinner.hide();
          
          this.router.navigate(['/dashboard/finaical-request']);
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            color: 'green',
            backdrop:true,
             
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
          Toast.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe)
          })

          .then(() => {
            this.spinner.hide();
            this.invoiceform.reset();
            
            this.spinner.hide();
          });
          this.spinner.hide();
        } else if (result.status === 400 || result.status === 403 || result.status === 500) {
          this.spinner.hide();
          let errorMessage;
          switch (result.status) {
            case 400:
            case 403:
            case 500:
              errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
           
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            color: 'green',
            backdrop:true,
             
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
          Toast.fire({
            icon: 'error',
            title: errorMessage
            })
          
          .then(() => {
            this.spinner.hide();
          });
        }
      }, error => {
        this.spinner.hide();
      });
    }
    else if (!isValidCustomer) {
      this.spinner.hide();
    }
    else {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : 'נא למלא כל השדות')
      }).then(() => {
        this.spinner.hide();
      });
    }
    this.spinner.hide()

  }

  GetYear() {
    this.spinner.show();
    this.service.GetYear(Number(this.YearData)).subscribe({
      next: res => {
        this.YearData = res;
        this.MaxAmount = this.YearData.maxAmount 
        this.Year = this.YearData.year
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
   
}

  handleErrors(result: any) {
    let errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
    // Swal.fire({
    //   icon: 'error',
    //   title: errorMessage
    // });

    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      color: 'green',
      backdrop:true,
       
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: 'error',
      title: errorMessage
      })
  }

  getSuccessMessage(res: any) {
    return this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
  }

  // SaveInvoice() {
  //   this.spinner.show(); // عرض الـ spinner
    
  //   if (this.invoiceform.valid) {
  //     this.service.SaveInvoice(this.invoiceform.getRawValue()).subscribe(res => {
  //       let result: any;
  //       result = res;
  //       if (result.status === 200) {
  //         this.spinner.hide();
  //         Swal.fire({
  //           icon: 'success',
  //           title: this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe)
  //         }).then(() => {
  //           this.spinner.hide(); // إخفاء الـ spinner بعد عرض الرسالة
  //           this.invoiceform.reset();
  //           this.router.navigate(['/dashboard/finaical-request']);
  //           this.spinner.hide(); 
  //         });
  //         this.spinner.hide(); 
  //       } else if (result.status === 400 || result.status === 403 || result.status === 500) {
  //         this.spinner.hide();
  //         let errorMessage;
  //         switch (result.status) {
  //           case 400:
  //           case 403:
  //           case 500:
  //             errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
  //             break;
  //           default:
  //             errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
  //         }
  //         Swal.fire({
  //           icon: 'error',
  //           title: errorMessage
  //         }).then(() => {
  //           this.spinner.hide(); // إخفاء الـ spinner بعد عرض الرسالة
  //         });
  //       }
  //     }, error => {
  //       // معالجة الخطأ
  //       this.spinner.hide(); // إخفاء الـ spinner في حالة حدوث خطأ
  //     });
  //   } else {
  //     Swal.fire({
  //       icon: 'warning',
  //       title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
  //     }).then(() => {
  //       this.spinner.hide(); // إخفاء الـ spinner بعد عرض الرسالة
  //     });
  //   }
  // };
  
  

  addnewproduct() {
  
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  
    let customercode = this.invoiceform.get("customer_id")?.value;
    let vatListId = this.invoiceform.get("vat_list_id")?.value;
    let invoiceTypeCode = this.invoiceform.get("invoice_type")?.value;
    let invoiceDate = this.invoiceform.get("invoice_date")?.value;
    let companyid = this.invoiceform.get("company_id")?.value;
    
    // يمكنك إضافة شروط إضافية هنا بحسب احتياجات التطبيق
    if (
      (customercode != null && customercode != '') &&
      (vatListId != null && vatListId != '') &&
      (invoiceTypeCode != null && invoiceTypeCode != '') &&
      (invoiceDate != null && invoiceDate != '') &&
      (companyid != null && companyid != '') ||
      this.isedit
    ) {
      this.invoicedetail.push(this.Generaterow());
      
    } else {
      
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'נא למלא כל השדות' : ''))
      });
    }
  }
  

  get invproducts() {
    return this.invoiceform.get("lines") as FormArray;
  }

  Generaterow() {
    const newIndex = this.invoicedetail.length + 1; // Calculate the new index
     
    this.stopVatPercentageSubscription();


    return this.builder.group({
        index: this.builder.control(newIndex),
        product_id: this.builder.control('', Validators.required),
        category: this.builder.control('', Validators.required),
        measure_unit_description: this.builder.control('', Validators.required),
        measurement_unit_id: this.builder.control('', Validators.required),
        description: this.builder.control('', Validators.required),
        catalog_id: this.builder.control('', Validators.required),
        item_name: this.builder.control('', Validators.required),
        quantity: this.builder.control(1),
        price_per_unit: this.builder.control(0),
        discount: this.builder.control(0),
        sub_total: this.builder.control({ value: 0, disabled: true }),
        vat_rate: this.builder.control({ value: this.VatPercentage, disabled: true }),
        vat_amount: this.builder.control({ value: 0, disabled: true }),
        total_amount: this.builder.control(0),
    });
}


  stopVatPercentageSubscription() {
    this.invoiceform.get('vat_list_id')?.disable();
    this.invoiceform.get('invoice_date')?.disable();
    
}

resumeVatPercentageSubscription() {
  // استئناف الاشتراك في التغييرات لـ 'vat_list_id'
  this.invoiceform.get('vat_list_id')?.enable();
  this.invoiceform.get('invoice_date')?.enable();
}



  GetCustomers() {
    this.spinner.show();
    this.service.getAllActiveCustomersBasedOnMerchantId().subscribe(res => {
      this.mastercustomer = res;
      this.spinner.hide();
    })
  }



  getCompanyInfoById() {

    const companyId = this.invoiceform.get("company_id")?.value;
    if (companyId != null && companyId != '') {
    this.service.getCompanyInfoById(this?.companyId).subscribe({
      next: res => {
        this.oneCompanyData = res;
    
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
    }
    
  };

  getIdd(id: number) {
    this.id = id;
  }
  
  
  getCustomerById() {
    const customerId = this.invoiceform.get("customer_id")?.value;
    if (customerId != null && customerId !== '') {
      this.spinner.show();
      this.service.getCustomerById(Number(customerId)).subscribe({
        next: res => {
          this.oneCustomerData = res;
          // تحديث النموذج ببيانات العميل المستلمة
          this.invoiceform.patchValue({
            customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
            customer_id_number: this.oneCustomerData?.id_number,
            customer_vat_number: this.oneCustomerData?.customer_vat_number || 0 ,
          });
  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
    }
  }


  GetCompany() {
    this.service.GetCompany().subscribe(res => {
      this.mastercompany = res;
    })
  }

  GetProducts() {
    this.service.getAllActiveProductsBasedOnMerchantId().subscribe(res => {
      this.masterproduct = res;

    })
  }

  GetInvoiceTypeList() {
    this.spinner.show();

    this.service.GetInvoiceTypeList().subscribe(res => {
      this.masterInvoiceTypeList = res;
      this.spinner.hide();
    })
  }


  customerchange() {
    let customer_id = this.invoiceform.get("customer_id")?.value;
    this.service.GetCustomerbyId(customer_id).subscribe(res => {
      let custdata: any;
      custdata = res;

    });
  }


  companychange() {
    let company_id = this.invoiceform.get("company_id")?.value;
    this.service.GetCompanybyId(company_id).subscribe(res => {
      let custdata: any;
      custdata = res;

    });
  }
  getCompanyId(id:any)
  {
    this.companyId =  id;
  };

  getVatPercentageByVatListIdAndDate() {
    const vatListId = this.invoiceform.get("vat_list_id")?.value;
    const invoiceDate = this.invoiceform.get("invoice_date")?.value;
  
    if (vatListId && invoiceDate) {
      this.spinner.show();
      this.service.getVatPercentageByVatListIdAndDate(vatListId, invoiceDate).subscribe({
        next: res => {
          this.vatPersentageData = res;
          if (this.vatPersentageData && this.vatPersentageData.length > 0) {
            this.VatPercentage = this.vatPersentageData[0].vat_percentage;
            this.invoiceform.patchValue({
              vat_percentage: this.VatPercentage // تحديث القيمة في النموذج
            });
            this.summarycalculation(); // إعادة حساب القيم الإجمالية للفاتورة
          } else {
            // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
            this.invoiceform.patchValue({
              vat_percentage: 0
            });
          }
        },
        error: error => {
           // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
          this.invoiceform.patchValue({
            vat_percentage: 0
          });
        },
        complete: () => {
          this.spinner.hide(); // إخفاء المحمل بعد الحصول على البيانات بنجاح أو بعد حدوث خطأ
        }
      });
    } else {
      // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
      this.invoiceform.patchValue({
        vat_percentage: 0
      });
      this.spinner.hide(); // إخفاء المحمل إذا لم يتم تحديد كلا من vatListId و invoiceDate
    }
  }
  
  
  filter(event:any)
  {
    let x = new Date(event.target.value);    
    this.selectedMonth = x.getMonth() + 1; // Month is 0-based, so add 1
    this.selectedYear = x.getFullYear();
      
    this.checkClosedMonth();
    
  };

  checkClosedMonth() {
    this.spinner.show();
    this.service.checkClosedMonth(this.companyDefault, this.selectedMonth , this.selectedYear).subscribe({
      next: res => {
        this.spinner.hide();
        if (res.status == 200) {  
          this.toastr.warning(this.curentLang === 'en' 
                ? res.messageEn
                : this.curentLang === 'ar' 
                ? res.messageAr
                : res.messageHe);
        } else {
          this.toastr.info(this.curentLang === 'en' 
                ? 'The selected month and year are open.'
                : this.curentLang === 'ar' 
                ? 'الشهر والسنة المختارة مفتوحة.'
                : 'החודש והשנה שנבחרו פתוחים.');
        }
      },
      error: error => {
        this.spinner.hide();
        console.error('Error checking closed month:', error);
      }
    });
  }

  getId(id: number) {
    this.invoiceId = id;
  };
  vatTypechange() {
    this.spinner.show();

    this.service.GetVatTypeList().subscribe(res => {
      this.vatTypeList = res;
    });

    this.spinner.hide();
  }


  productchange(index: any) {
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

    // استماع للتغيرات على Total Amount
    this.invoiceproduct.get('total_amount').valueChanges.subscribe(newTotalAmount => {
        this.calculateReverse(index, newTotalAmount);
    });

    let product_id = this.invoiceproduct.get("product_id")?.value;

    this.service.GetProductbycode(product_id).subscribe(res => {
        let proddata: any;
        proddata = res;

        // التحقق من إذا كانت بيانات المنتج غير مكتملة
        if (proddata?.product_code === '' || proddata?.product_code === null ||
            proddata?.categories_id === null || proddata?.measurement_unit_id === null) {
            
            // عرض الرسالة التحذيرية وإدخال للمستخدم لإضافة الكود
            Swal.fire({
              icon: 'warning',
              title: this.curentLang === 'ar' ? ' برجاء استكمال معلومات المنتج والمحاولة مرة اخري' : 
                     (this.curentLang === 'en' ? 'Please complete the product information and try again' : 
                     (this.curentLang === 'he' ? 'אנא מלא את פרטי המוצר ונסה שוב' : '')),
              input: 'text', // إدخال نص للمستخدم
              inputPlaceholder: this.curentLang === 'ar' ? 'أدخل كود المنتج' : 
                                 (this.curentLang === 'en' ? 'Enter product code' : 
                                 (this.curentLang === 'he' ? 'הזן קוד מוצר' : '')),
              showCancelButton: true,
              confirmButtonText: this.curentLang === 'ar' ? 'إرسال' : 
                                 (this.curentLang === 'en' ? 'Submit' : 
                                 (this.curentLang === 'he' ? 'שלח' : '')),
              preConfirm: (value) => {
                if (!value || value.trim() === '') {
                    Swal.showValidationMessage(
                        this.curentLang === 'ar' ? 'لا يمكن إدخال قيمة فارغة أو مسافة فقط' : 
                        (this.curentLang === 'en' ? 'Empty or whitespace-only values are not allowed' : 
                        (this.curentLang === 'he' ? 'אין להזין ערך ריק או הכולל רק רווחים' : ''))
                    );
                    return false;
                }
                return value.trim();
              }
            }).then(result => {
                if (result.isConfirmed && result.value) {
                    let newProductCode = result.value;
                    this.invoiceproduct.get("catalog_id")?.setValue(newProductCode);

                    // إرسال الكود الجديد إلى الخادم
                    const model = {
                      product_code: newProductCode,
                      updated_by: this.userRegistId
                    };

                    this.spinner.show();
                    this.service.updateProductsById(product_id, model).subscribe((res) => {
                        

                        // جلب البيانات المحدثة بعد التعديل
                        this.service.GetProductbycode(product_id).subscribe(updatedProdData => {
                            this.updateFormWithProductData(updatedProdData, index);
                        });
                    });
                    this.spinner.hide();
                }
            });

            return;
        }

        if (proddata != null) {
            this.updateFormWithProductData(proddata, index);
        }
    });
}
// دالة لتحديث حقول النموذج بقيم المنتج المحدثة
updateFormWithProductData(proddata: any, index: number) {
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
    this.invoiceproduct.get("productName")?.setValue(proddata.name);
    this.invoiceproduct.get("price_per_unit")?.setValue(proddata.price);
    this.invoiceproduct.get("category")?.setValue(proddata.categories.categories_code);
    this.invoiceproduct.get("measure_unit_description")?.setValue(proddata.measurementUnits['measurement_unit_name_' + this.curentLang].substring(0, 20));
    this.invoiceproduct.get("measurement_unit_id")?.setValue(proddata.measurementUnits.measurement_unit_id);
    this.invoiceproduct.get("description")?.setValue(proddata['des_' + this.curentLang].substring(0, 30));
    this.invoiceproduct.get("catalog_id")?.setValue(proddata.product_code.substring(0, 13));
    this.invoiceproduct.get("item_name")?.setValue(proddata['product_name_' + this.curentLang]);
    this.Itemcalculation(index);
}

//new
onTotalAmountChange(index: number) {
  this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

  const newTotalAmount = parseFloat(this.invoiceproduct.get("total_amount")?.value);
  if (!isNaN(newTotalAmount)) {
      this.calculateReverse(index, newTotalAmount);
  }
}

/// new
calculateReverse(index: any, newTotalAmount: number) {
  this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

  let quantity = this.invoiceproduct.get("quantity")?.value || 1; // التأكد من أن quantity ليس صفرًا
  let vat_rate = this.invoiceproduct.get("vat_rate")?.value || 0;
  let discount = this.invoiceproduct.get("discount")?.value || 0;

  // حساب Sub Total قبل تطبيق الضريبة
  let sub_total_before_vat = newTotalAmount / (1 + (vat_rate / 100));
  
  // حساب الخصم الكامل (يتم تطبيق الخصم على السعر الإجمالي للمنتجات)
  let total_without_discount = sub_total_before_vat + discount;

  // حساب السعر لكل وحدة
  let price_per_unit = total_without_discount / quantity;

  // حساب مبلغ الضريبة
  let vat_amount = parseFloat((sub_total_before_vat * (vat_rate / 100)).toFixed(2));

  // تعيين القيم المحسوبة إلى النموذج
  this.invoiceproduct.get("sub_total")?.setValue(sub_total_before_vat.toFixed(2));
  this.invoiceproduct.get("price_per_unit")?.setValue(price_per_unit.toFixed(2));
  this.invoiceproduct.get("vat_amount")?.setValue(vat_amount.toFixed(2));
  
  // تحديث القيم الإجمالية للفاتورة
  this.summarycalculation();
}


  Itemcalculation(index: any) {
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
    
    let quantity = this.invoiceproduct.get("quantity")?.value;
    let price = this.invoiceproduct.get("price_per_unit")?.value;
    let discount = this.invoiceproduct.get("discount")?.value;
    let vat_rate = this.invoiceproduct.get("vat_rate")?.value;
  
    let sub_total = (quantity * price) - discount; // الحساب الفرعي للعنصر بعد الخصم
    let vat_amount = parseFloat((sub_total * (vat_rate / 100)).toFixed(2)); // حساب مبلغ الضريبة المضافة
    let total_amount = parseFloat((sub_total + vat_amount).toFixed(2)); // حساب السعر النهائي بإضافة مبلغ الضريبة المضافة إلى الحساب الفرعي
    
    this.invoiceproduct.get("sub_total")?.setValue(sub_total); // تعيين قيمة الحساب الفرعي
    this.invoiceproduct.get("vat_amount")?.setValue(vat_amount); // تعيين قيمة مبلغ الضريبة المضافة
    this.invoiceproduct.get("total_amount")?.setValue(total_amount); // تعيين السعر النهائي للعنصر
    
    this.summarycalculation(); // إعادة حساب القيم الإجمالية للفاتورة
  }

  
  
  
  
  Removeproduct(index: any) {

    
      this.invproducts.removeAt(index);

      this.summarycalculation();
      if( this.invproducts.length == 0){
        this.resumeVatPercentageSubscription();
      }
    
  }

  summarycalculation() {
    let array = this.invoiceform.getRawValue().lines;
    
    let amount_before_discount = 0;
    let all_discount = 0;
    let all_vat_amount = 0;
  
    array.forEach((x: any) => {
      amount_before_discount += parseFloat(x.sub_total);
      all_discount += parseFloat(x.discount);
      all_vat_amount += parseFloat(x.vat_amount);
    });
  
    let payment_amount = amount_before_discount - all_discount; // المبلغ المطلوب للدفع
    let payment_amount_including_vat = payment_amount + all_vat_amount; // المبلغ المطلوب للدفع بما فيه ضريبة القيمة المضافة
  
    this.invoiceform.get("amount_before_discount")?.setValue(parseFloat(amount_before_discount.toFixed(2)));
    this.invoiceform.get("all_discount")?.setValue(parseFloat(all_discount.toFixed(2)));
    this.invoiceform.get("payment_amount")?.setValue(parseFloat(payment_amount.toFixed(2)));
    this.invoiceform.get("all_vat_amount")?.setValue(parseFloat(all_vat_amount.toFixed(2)));
    this.invoiceform.get("payment_amount_including_vat")?.setValue(parseFloat(payment_amount_including_vat.toFixed(2)));
    
  }
  

}
