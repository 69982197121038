<app-breadcrumb [title]="curentLang === 'en' ? 'Information Of The Companys Office Employees' : (curentLang === 'ar' ? ' معلومات موظفيين المكتب لدي الشركة   ' : '   מידע על עובדי משרד החברה ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Information Of The Companys Office Employees' : (curentLang === 'ar' ? ' معلومات موظفيين المكتب لدي الشركة   ' : '   מידע על עובדי משרד החברה')">
</app-breadcrumb>

<!--company info -->

<div class="container-fluid basic_table" *ngIf="user_type == 300">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Information Of The Companys Office Employees' : (curentLang === 'ar' ? '  معلومات موظفيين المكتب لدي الشركة  ' : '  מידע על עובדי משרד החברה')}}
        </h5>
           
       <div>
        <button *ngIf="user_type == 300" class="btn" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
          <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add Employee To The Company ' : (curentLang === 'ar' ? ' اضافة موظف للشركة ' : 'הוסף עובד לחברה  ')}}        
      </button>

    


      <button class="btn mx-1 btn-outline-warning"  (click)="getAllUserAssesToCompany()">
        <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}</button>
       </div>
     
      </div>

      <div class="row">
        <div class="col-sm-5">
          <div class="form-group search m-3">
            <label for="search" class="ml-2" style="color: grey;">
              {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
            </label>
            <div class="input-group">
              <input class="form-control" type="text" name="search"
                     [ngModelOptions]="{standalone: true}" id="search" [(ngModel)]="term" 
                     placeholder="{{curentLang === 'en' ? 'You can search by name or tax number ' : (curentLang === 'ar' ? 'يمكنك البحث عن طريق الأسم أو الرقم الضريبي' : 'ניתן לחפש לפי שם או מספר מס')}}">
              <!-- <div class="input-group-append">
                <button class="btn" style="background-color: #141414; color: #fff;" type="button"
                        (click)="getAllAgreedPermissionsByOfficeId(limit, offset, term)">
                  <i class="fa fa-search mx-1"></i>
                  {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                </button>
                <button class="btn btn-outline-secondary" type="button" (click)="term='';getAllAgreedPermissionsByOfficeId(limit, offset, term)">
                  <i class="fa fa-times"></i>
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      
      

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? '  أسم الشركة  ' : '    שם החברה  ')}} </th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Empolyee Name' : (curentLang === 'ar' ? '  أسم الموظف  ' : 'שם הפקיד')}} </th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Phone number' : (curentLang === 'ar' ? 'رقم الهاتف' : 'מספר רישום מס')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Company Documents And Export Reports ' : (curentLang === 'ar' ? 'مستندات الشركة واصدار التقارير ' : 'מסמכי החברה והנפקת דוחות')}}</th> 
                   <!-- <th scope="col"> {{ curentLang === 'en' ? 'Export Reports ' : (curentLang === 'ar' ? ' اصدار تقارير ' : 'הוצאת דוחות')}}</th>  -->
                   <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th> 

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of employeeData  |paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index">
                  <td scope="row">{{i+1}}</td>
                  <td> 
                    {{curentLang === 'en' ? item?.company?.company_name_en : (curentLang === 'ar' ? item?.company?.company_name_ar : item?.company?.company_name_he)}}
                  </td>

                  <td> 
                    {{curentLang === 'en' ? item?.user?.user_name : (curentLang === 'ar' ? item?.user?.user_name : item?.user?.user_name)}}
                  </td>
                 
                  <td>
                    {{ item?.formattedPhoneNumber }}
                  </td>
                    <td>{{ item?.company?.createdDate | date: 'dd/MM/yyyy'}}</td>

              

                  <td>
                    <i class="fa fa-file-text-o" style="color: black;   cursor: pointer;"
                     routerLink="/dashboard/company-documents/{{item.company.company_uuid}}"></i>
                  </td>

                  <!-- <td>
                    <i class="fa fa-upload" style="color: black;   cursor: pointer;"
                     routerLink="/dashboard/export-reports/{{item.company.company_uuid}}"></i>
                  </td> -->

             

                  <td >
                    <button   
                     
                    class="btn" 
                    style="border: none;" 
                    (click)="getId(item?.access_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style="font-size: 15px; cursor: pointer; color: red;"></i>
                      </button>
                     </td> 

                </tr>
          
              </tbody>
            </table>
            <div class="pagination-container">
                <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

             </div>
          </div>

          <div class=" d-flex justify-content-between"> 
            <div></div>

           <div>
            <a routerLink="/dashboard/office-companies">
                <button class="btn btn-danger m-2 " >
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

          

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>





<!--add Company to users accountant-->

<ng-template #content let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
          {{ curentLang === 'en' ? 'Add an employee to the company' : (curentLang === 'ar' ? '  اضافة موظف لدي الشركة ' : 'הוסף עובד לחברה')}}
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
               <form [formGroup]="addAccountantUserCompanyForm">
                <div class="row">
                  <div class="mb-3 col-xl-12 col-md-12">
                    <label class="form-label" style="color: grey;">
                      {{ curentLang === 'en' ? 'Employee Name' : (curentLang === 'ar' ? 'اسم الموظف' : ' שם הפקיד ') }}
                    </label>
                    <ng-select formControlName="companyUser" (change)="onCompanyMerchantChange($event)" style="cursor: pointer;" [multiple]="true"
                               placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                      <ng-option *ngFor="let item of userData" [value]="item" >
                        {{ curentLang === 'en' ? item?.user_name : (curentLang === 'ar' ? item?.user_name : item?.user_name) }} 
                       </ng-option>
                    </ng-select>
                  </div>
                </div>
                
                <div class="modal-footer">
                  <button type="button" class="btn btn-sm m-auto mt-2" style="background-color: #141414; color: #fff;" 
                  [disabled]="addAccountantUserCompanyForm.invalid"
                          (click)="addedAccountingUserCompany()">
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                  </button>
                  
                  <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                    {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                    <i class="fa fa-rotate-left mx-1"></i>
                  </button>
                </div>
              </form>
                 
              
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>






<!--Delete-->

              
<ng-template #dataDeleted let-modal>
  <div class="modal-header">
      <h4 class="modal-title"></h4>
      <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
        {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : 'האם אתה בטוח שברצונך למחוק אותו?') }}

      </p>
  </div>
  <div class="modal-footer">

    <button type="button" 
     class="btn btn-sm m-auto mt-2" 
    style="background-color: #141414; color: #fff;"
     (click)="deleteAccessCompanyForUser()"
     > 
     {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
      <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
  </button>

      <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
          <i class="fa fa-rotate-left mx-1"></i>
      </button>

  </div>
</ng-template>