import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-view-merchant",
  templateUrl: "./view-merchant.component.html",
  styleUrls: ["./view-merchant.component.scss"]
})

export class ViewMerchantComponent implements OnInit {
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  merchantInfoData:any=[];
  id:number;
  planNameAr:string='';
  planNameEn:string='';
  planNamehE:string='';
  Price:string='';
  numberOfDocuments:number;
  numberOfCompany:number;
  numberOfUsers:number;
  status:number;
  amountPaid:number;
  paymentType:string='';
  SubscribeStatusAr:string='';
  SubscribeStatusEn:string='';
  SubscribeStatushE:string='';
  lookup_detail_id:number;
  MerchantNameEn:string;
  MerchantNameAr:string;
  MerchantNameHe:string;
  MerchantAddress:string;
  IdNumber:string;
  public validate = false;

  updateMerchant=new FormGroup({
    merchant_name_en:new FormControl('' , Validators.required),
    merchant_name_ar :new FormControl('' , Validators.required),  
    merchant_name_he:new FormControl('', Validators.required),
    merchant_address:new FormControl('', Validators.required),
    id_number:new FormControl('', Validators.required),
    updated_by:new FormControl(''),
  });

  newOrders = {
    colorClass: "primary",
    icon: "new-order",
    colorClass2: "warning",
    icon2: "customers",
    colorClass3: "success",
    icon4: "invoice",
    colorClass4: "secondary",
    icon3: "profit"
  };
  constructor
  (  
     private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    activatedRoute:ActivatedRoute,
    ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.id = activatedRoute.snapshot.params['id'];
  };

  getMerchantInfoById() {
    this.spinner.show();
    this.service.getMerchantInfoById(this.id).subscribe({
      next: res => {
        this.merchantInfoData = res;       
           
        this.planNameEn = this.merchantInfoData[0]?.subscribe_plan_details[0]?.plan_name_en
        this.planNameAr = this.merchantInfoData[0]?.subscribe_plan_details[0]?.plan_name_ar
        this.planNamehE = this.merchantInfoData[0]?.subscribe_plan_details[0]?.plan_name_he
        this.Price = this.merchantInfoData[0]?.subscribe_plan_details[0]?.price
        this.numberOfDocuments = this.merchantInfoData[0]?.subscribe_to_plans[0]?.number_of_documents
        this.numberOfCompany = this.merchantInfoData[0]?.subscribe_to_plans[0]?.number_of_company
        this.numberOfUsers = this.merchantInfoData[0]?.subscribe_to_plans[0]?.number_of_users
        this.status = this.merchantInfoData[0]?.subscribe_plan_details[0]?.status
        this.amountPaid = this.merchantInfoData[0]?.subscribe_plan_details[0]?.amount_paid
        this.paymentType = this.merchantInfoData[0]?.subscribe_plan_details[0]?.payment_method
        this.lookup_detail_id = this.merchantInfoData[0]?.lookupDetailsSubscribe?.lookup_detail_id
        this.SubscribeStatusAr = this.merchantInfoData[0]?.subscribe_plan_details[0]?.lookupDetailArName
        this.SubscribeStatusEn = this.merchantInfoData[0]?.subscribe_plan_details[0]?.lookupDetailEnName
        this.SubscribeStatushE = this.merchantInfoData[0]?.subscribe_plan_details[0]?.lookupDetailHeName
        this.MerchantNameEn = this.merchantInfoData[0]?.merchant_name_en
         
        this.MerchantNameAr = this.merchantInfoData[0]?.merchant_name_ar
        this.MerchantNameHe = this.merchantInfoData[0]?.merchant_name_he
        this.MerchantAddress = this.merchantInfoData[0]?.merchant_address
        this.IdNumber = this.merchantInfoData[0]?.id_number
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };


  ngOnInit() {
    this.getMerchantInfoById();

  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   } else {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only") {
       document.body.classList.add("dark-only");
     } else {
       document.body.classList.add("light-only");

     }
   }
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));

  };
}
